import styled from "styled-components";
import { InputGroupWithToggle } from "../../../../../components/styled/Forms";
import Button from "../../../../../components/button/Button";
import { Paragraph16px300Light } from "../../../../../styles/style-guide";

const NewSubCategoryWrapper = styled.div`
  width: 945px;
  height: 60vh;
  min-width: 700px;
  min-height: 550px;
  padding: 4px;
`;

const NewSubCategoryForm = styled.form`
  margin-right: 5px;
  display: flex;
  flex-direction: column;
`;

const NewSubCategoryPhotoContent = styled.div`
  display: flex;
`;

const NewSubCategoryPhotoTextContent = styled.div`
  flex: 1;
  height: 320px;
  margin-right: 8px;
`;

const NewSubCategoryPhotoArea = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 320px;
`;

const NewSubCategoryPhotoAreaContent = styled.div`
  margin-left: 35px;
  align-self: flex-end;
  height: 100%;
  width: 100%;
  max-width: 426px;
`;

const NewSubCategoryPhotoParagraph = styled.p`
  margin-top: 16px;
  ${Paragraph16px300Light};
  color: ${({ theme }) => theme.colors.textColor};
`;

const NewSubCategoryInputGroupWithToggle = styled(InputGroupWithToggle)`
  margin-right: 32px;
  margin-top: 0 !important;
  div {
    margin-top: 0 !important;
  }

  div {
    flex: 2 1 0;
  }

  label {
    flex: 1 1 0;
  }

  label[for="subcategoryName"] {
    flex: 0 0 0;
  }
`;

const NewSubCategoryButtonGoBack = styled(Button)`
  float: right;
`;

const NewSubCategoryButtonSave = styled(Button)`
  float: left;
  margin-right: 10px;
`;

const NewSubCategoryFooter = styled.footer`
  bottom: 24px;
  position: absolute;
  width: 95%;
`;

export {
  NewSubCategoryWrapper,
  NewSubCategoryForm,
  NewSubCategoryFooter,
  NewSubCategoryButtonSave,
  NewSubCategoryButtonGoBack,
  NewSubCategoryPhotoContent,
  NewSubCategoryPhotoTextContent,
  NewSubCategoryPhotoParagraph,
  NewSubCategoryPhotoArea,
  NewSubCategoryPhotoAreaContent,
  NewSubCategoryInputGroupWithToggle,
};
