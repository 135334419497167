import React from 'react';
import PropTypes from 'prop-types';
import {
  OrderButtonsIconArrowDown, OrderButtonsIconArrowUp, OrderButtonsWrapper,
} from './Styles';
import Tooltip from '../tooltip/Tooltip';

const OrderButtons = (props) => {
  const {
    className, tooltipUpText, tooltipDownText, onClickUp, onClickDown,
    isUpActive, isDownActive,
  } = props;
  return (
    <>
      <OrderButtonsWrapper className={className}>
        <Tooltip message={tooltipUpText} position="left">
          <OrderButtonsIconArrowUp isactive={isUpActive.toString()} onClick={onClickUp} />
        </Tooltip>
        <Tooltip message={tooltipDownText} position="left">
          <OrderButtonsIconArrowDown isactive={isDownActive.toString()} onClick={onClickDown} />
        </Tooltip>
      </OrderButtonsWrapper>
    </>
  );
};

OrderButtons.defaultProps = {
  className: '',
  tooltipUpText: '',
  tooltipDownText: '',
  isUpActive: false,
  isDownActive: false,
  onClickDown: () => {},
  onClickUp: () => {},
};

OrderButtons.propTypes = {
  tooltipUpText: PropTypes.string,
  tooltipDownText: PropTypes.string,
  isUpActive: PropTypes.bool,
  isDownActive: PropTypes.bool,
  className: PropTypes.string,
  onClickDown: PropTypes.func,
  onClickUp: PropTypes.func,
};

export default OrderButtons;
