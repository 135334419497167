import styled from "styled-components";
import {
  Paragraph16px300Light,
  Paragraph16px400Regular,
} from "../../../../../styles/style-guide";
import Button from "../../../../../components/button/Button";
import Card from "../../../../../components/styled/Card";

const DetailsQrCodeCard = styled(Card)`
  height: calc(100vh - 315px);
  min-height: calc(100vh - 293px);
`;

const DetailsQrCodeTitle = styled.h4`
  ${Paragraph16px400Regular}
  margin-bottom: 8px;
`;

const DetailsQrCodeParagraph = styled.p`
  ${Paragraph16px300Light};
  color: ${({ theme }) => theme.colors.textColor};
  margin-top: 8px;
`;

const DetailsQrCodeURL = styled.a`
  ${Paragraph16px300Light};
  margin: 16px;
  color: ${({ theme }) => theme.colors.mainColor};
  text-decoration: none;
`;

const DetailsQrCodeTextContent = styled.div``;

const DetailsQrCodeContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  text-align: center;
`;

const DetailsQrCodeContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DetailsQrCodeButtonsContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 12px 0px;
`;

const DetailsQrCodeButtonDownload = styled(Button)`
  margin: 0 8px;
  padding: 0 10.5px;
`;

const DetailsQrCodeSGVContent = styled.div`
  display: none;
`;

const DetailsQrCodeWrapper = styled.div`
  height: auto !important;
  width: 100% !important;
  max-width: 370px;
  max-height: 370px;

  & > svg {
    height: auto !important;
    width: 100% !important;
  }

  & > canvas {
    height: auto !important;
    width: 100% !important;
  }
`;

export {
  DetailsQrCodeTitle,
  DetailsQrCodeURL,
  DetailsQrCodeParagraph,
  DetailsQrCodeTextContent,
  DetailsQrCodeContent,
  DetailsQrCodeButtonsContent,
  DetailsQrCodeButtonDownload,
  DetailsQrCodeCard,
  DetailsQrCodeSGVContent,
  DetailsQrCodeWrapper,
  DetailsQrCodeContainer,
};
