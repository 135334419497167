import styled from "styled-components";

import { ReactComponent as IconAdd } from "../../../assets/icons/icon-add.svg";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.mainColor};
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconAddContainer = styled.div`
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconAddImage = styled(IconAdd)`
  path {
    fill: ${({ theme }) => theme.colors.mainColor};
  }
`;
