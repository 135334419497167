import React, {
  useMemo, useState, useContext,
  useCallback, createContext,
} from 'react';
import PropTypes from 'prop-types';

const PageContext = createContext({
  pageTitle: '',
  setPageTitle: () => { },
});

const usePageContext = () => (
  useContext(PageContext)
);

const PageProvider = ({ children }) => {
  const [pageTitle, _setPageTitle] = useState('');
  const setPageTitle = useCallback((e) => _setPageTitle(e), [_setPageTitle]);
  const value = useMemo(() => ({ pageTitle, setPageTitle }), [pageTitle, setPageTitle]);
  return (
    <PageContext.Provider value={value}>
      {children}
    </PageContext.Provider>
  );
};

PageProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.any,
  ]).isRequired,
};

export {
  PageContext,
  usePageContext,
};
export default PageProvider;
