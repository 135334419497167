export const currencyMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, ""); // permite digitar apenas numero
  v = v.replace(/(\d{1})(\d{15})$/, "$1.$2"); // coloca ponto antes dos ultimos digitos
  v = v.replace(/(\d{1})(\d{11})$/, "$1.$2"); // coloca ponto antes dos ultimos 13 digitos
  v = v.replace(/(\d{1})(\d{8})$/, "$1.$2"); // coloca ponto antes dos ultimos 10 digitos
  v = v.replace(/(\d{1})(\d{5})$/, "$1.$2"); // coloca ponto antes dos ultimos 7 digitos
  v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2"); // coloca virgula antes dos ultimos 4 digitos

  return `R$ ${v}`;
};

export const removeCurrencyMask = (value) => {
  return value.replace(/[^0-9,]*/g, "").replace(",", ".");
};

export function mask2(value, pattern, onlyNum) {
  let i = 0;
  let valueLen = value.length;
  const v = value.toString();
  if (onlyNum === true && (value.match(/[a-zA-Z]/) || []).length) valueLen--;
  let numero = pattern.length - (pattern.match(/#/) || []).length;
  let newPattern = pattern;
  while (numero > 0) {
    newPattern += "#";
    numero--;
  }
  newPattern = newPattern
    .replace(/#/g, () => {
      if (i <= valueLen && newPattern[i] !== "#") {
        valueLen++;
      }
      return v[i++] || "";
    })
    .slice(0, Math.min(valueLen, pattern.length));

  if (newPattern[newPattern.length - 1] === pattern[newPattern.length - 1])
    return "";
  else return newPattern;
  // return pattern.replace(/#/g, () => v[i++] || "");
}

export function unMask2(value, pattern) {
  // const v = value.toString();
  // if (value.length < pattern.length) {
  //   return "";
  // }
  const j = new RegExp(pattern);
  const newValue = value.split(j);
  const newString = newValue.join("");
  return newString;
}
export const percentFormatter = (value) => {
  // if (!Number(value)) return '';

  const amount = (value / 100).toFixed(2).toString().replace(".", ",");

  return `${amount}%`;
};

export const removeCharactersAndSpecialCharacter = (value) => {
  return value.replace(/[^0-9]/g, "");
};
