import React, { useEffect, useState } from "react";
import {
  OperationContainer,
  OperationContext,
  OperationCheckBox,
  OperationLabel,
  OperationLine,
} from "./Styles";
import PropTypes from "prop-types";
import Checkbox from "../../../../../components/checkbox/Checkbox";
import restOperation from "../../../../../api/operation/rest-operation";
import TranslatorFunction from "../../../../../components/I18n/Translator"

 


const Operation = ({ operation, getOperation, handleOnLoad }) => {
  const onSiteServiceVisualization = TranslatorFunction({ path: 'onSiteServiceVisualization.message' });
  const deliveryWhatsappOrders = TranslatorFunction({ path: 'deliveryWhatsappOrders.message' });
  const takeAway = TranslatorFunction({ path: 'takeAway.message' });
  const catalog = TranslatorFunction({ path: 'catalog.message' });
  const [form, setForm] = useState(operation);
  const labels = [
    onSiteServiceVisualization,
    deliveryWhatsappOrders,
    takeAway,
    catalog,
  ];
  const handleResponseSuccess = () => {
    getOperation();
    setForm(operation);
  };

  const handleOnChangeCheckBox = async (event) => {
    try {
      handleOnLoad(true);
      const { name, value } = event;

      const findedCatalogValue = form.find(
        (op) => op.operationName === "Catálogo"
      );

      if (findedCatalogValue) {
        if (
          name !== findedCatalogValue._id &&
          findedCatalogValue.active &&
          value
        ) {
          await restOperation.putOperations(findedCatalogValue._id, {
            active: false,
          });
        }

        if (name === findedCatalogValue._id && value) {
          const activeOperations = form.filter(
            (op) => op.operationName !== "Catálogo" && op.active
          );
          const updatedOperations = activeOperations.map(
            async (op) =>
              await restOperation.putOperations(op._id, { active: false })
          );

          await Promise.all(updatedOperations);
        }
      }

      await restOperation.putOperations(name, { active: value });
      handleResponseSuccess();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setForm(operation);
  }, [operation]);
  const typesOfService = TranslatorFunction({ path: 'typesOfService.message' });
  return (
    <>
      <OperationContainer>
        <OperationLabel>{typesOfService}</OperationLabel>
        <OperationContext>
          {form?.map((value, index) => (
            <OperationCheckBox key={index}>
              <Checkbox
                name={value._id}
                key={value.id}
                value={value.active}
                id={labels[value.id]}
                onChange={handleOnChangeCheckBox}
              >
                {labels[value.id]}
              </Checkbox>
            </OperationCheckBox>
          ))}
        </OperationContext>
        <OperationLine />
      </OperationContainer>
    </>
  );
};

Operation.defaultProps = {
  operation: [],
  getOperation: () => {},
};

Operation.propTypes = {
  operation: PropTypes.arrayOf(PropTypes.object),
  getOperation: PropTypes.func,
};

export default Operation;
