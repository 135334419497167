import styled, { css, keyframes } from "styled-components";
import {
  Paragraph16px300Light,
  Hint9px400Regular,
  Description12px600Semibold,
} from "../../../styles/style-guide";
import { IconError } from "../../../styles/icons";

const AnimationSpin = keyframes`
  0% {
     transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`;

const LoaderContainer = styled.div`
  display: ${({ haserror }) => (haserror ? "none" : "block")};
  position: absolute;
  float: right;
  top: 12px;
  right: 15.5px;
`;

const Loader = styled.i`
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: ${AnimationSpin} 3s infinite;

  &::after,
  &::before {
    ${({ isLoading }) => isLoading && 'content: ""'};
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.mainColor}
      ${({ theme }) => theme.colors.mainColor} transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: ${AnimationSpin} 3s ease-in-out infinite;
  }

  &::after {
    animation: ${AnimationSpin} 3s ease-in-out infinite;
    background: transparent;
  }
`;

const LabelOnTop = css`
  top: -47px;
  left: 10px;
  font-size: 12px;
  padding: 0 5px 0px 5px;
  pointer-events: none;
  ${Description12px600Semibold}
  color: ${({ theme, haserror }) =>
    haserror === "true"
      ? theme.colors.statusColorDanger
      : theme.colors.mainColor};
  background-color: ${({ theme }) => theme.colors.mainColorContrast};
`;

const HideLabelRiquired = css`
  display: none;
  opacity: 0;
  transition: 0.1s ease-in-out;
`;

const Label = styled.label`
  top: -29px;
  left: 0;
  right: 0;
  position: relative;
  z-index: 0;
  cursor: text;
  padding: 0 12px 8px 12px;
  transition: 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.textColor};
  ${() => Paragraph16px300Light}
`;

const CustomIconError = styled(IconError)`
  display: ${({ haserror }) => (haserror === "true" ? "block" : "none")};
  position: absolute;
  float: right;
  top: 12px;
  right: 15.5px;
  z-index: 1;
`;

const LabelRequired = styled.label`
  display: inline;
  opacity: 1;
  transition: 0.1s ease-in-out;
  color: ${({ theme, haserror }) =>
    haserror === "true"
      ? theme.colors.statusColorDanger
      : theme.colors.mainColor};
`;

const InputSpanCounter = styled.span`
  position: absolute;
  display: none;
  color: ${({ theme, haserror }) =>
    haserror === "true"
      ? theme.colors.statusColorDanger
      : theme.colors.mainColor};
  right: 10px;
  bottom: 2px;
  ${() => Hint9px400Regular};
`;

const InputFocus = css`
  height: 40px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border: 2px solid
    ${({ theme, haserror }) =>
      haserror === "true"
        ? theme.colors.statusColorDanger
        : theme.colors.mainColor};
`;

const InputErrorMessage = styled.span`
  display: ${({ haserror }) => (haserror === "true" ? "block" : "none")};
  position: absolute;
  color: ${({ theme }) => theme.colors.statusColorDanger};
  ${() => Hint9px400Regular};
  width: 100%;
  bottom: -15px;
  left: 12px;
`;

const Input = styled.input`
  outline: none;
  transition: 0.2s ease-in-out;
  box-sizing: border-box;
  padding: 8px 12px 8px 12px;
  width: 100%;
  height: 40px;
  color: ${({ theme }) => theme.colors.textColor};
  border: 1px solid
    ${({ theme, haserror }) =>
      haserror === "true"
        ? theme.colors.statusColorDanger
        : theme.colors.textColorDisabled};
  border-radius: 10px;
  font-size: 15px;
  ${({ theme, readOnly }) =>
    readOnly &&
    `
    background-color: ${theme.colors.mainColorDisabled};
    border: none;
  `};

  ${() => Paragraph16px300Light};

  &::placeholder {
    opacity: 0;
    transition: 0.2s ease-in-out;
  }

  &:focus {
    ${({ readOnly }) => !readOnly && InputFocus};

    &:empty {
      &::placeholder {
        opacity: 1;
        transition: 0.2s ease-in-out;
      }
    }
  }
`;

const InputCleave = styled.input`
  outline: none;
  transition: 0.2s ease-in-out;
  box-sizing: border-box;
  padding: 8px 12px 8px 12px;
  width: 100%;
  height: 40px;
  color: ${({ theme }) => theme.colors.textColor};
  border: 1px solid
    ${({ theme, haserror }) =>
      haserror === "true"
        ? theme.colors.statusColorDanger
        : theme.colors.textColorDisabled};
  border-radius: 10px;
  font-size: 15px;
  ${({ theme, readOnly }) =>
    readOnly &&
    `
    background-color: ${theme.colors.mainColorDisabled};
    border: none;
  `};

  ${() => Paragraph16px300Light};

  &::placeholder {
    opacity: 0;
    transition: 0.2s ease-in-out;
  }

  &:focus {
    ${({ readOnly }) => !readOnly && InputFocus};

    &:empty {
      &::placeholder {
        opacity: 1;
        transition: 0.2s ease-in-out;
      }
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 12px;
  width: 100%;
  height: 40px;
  padding: 4px 0 8px 0;

  ${Input}:read-only ~ ${Label} {
    ${LabelOnTop};

    ${LabelRequired} {
      ${HideLabelRiquired};
    }
  }

  ${Input}:disabled ~ ${Label} {
    ${LabelOnTop};

    ${LabelRequired} {
      ${HideLabelRiquired};
    }
  }

  ${Input}:focus ~ ${InputSpanCounter} {
    display: block;
  }

  ${Input}:valid ~ ${Label},
  ${Input}:focus ~ ${Label} {
    ${LabelOnTop};

    ${LabelRequired} {
      ${HideLabelRiquired};
    }
  }

  ${InputCleave}:read-only ~ ${Label} {
    ${LabelOnTop};

    ${LabelRequired} {
      ${HideLabelRiquired};
    }
  }

  ${InputCleave}:disabled ~ ${Label} {
    ${LabelOnTop};

    ${LabelRequired} {
      ${HideLabelRiquired};
    }
  }

  ${InputCleave}:focus ~ ${InputSpanCounter} {
    display: block;
  }

  ${InputCleave}:valid ~ ${Label},
  ${InputCleave}:focus ~ ${Label} {
    ${LabelOnTop};

    ${LabelRequired} {
      ${HideLabelRiquired};
    }
  }
`;

export {
  InputWrapper,
  InputErrorMessage,
  Input,
  InputCleave,
  Label,
  CustomIconError,
  LabelRequired,
  InputSpanCounter,
  LoaderContainer,
  Loader,
};
