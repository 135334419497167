import HttpClient from "../http-client";
import { id } from "../helper-client";

const putComplementGroup = async (complementGroupId, data) => {
  return HttpClient.put(
    `complementgroup/${complementGroupId}?userId=${id ?? ""}`,
    data
  );
};

const postComplementGroup = async (data) =>
  HttpClient.post(`complementgroup?userId=${id ?? ""}`, data);

const fetchComplementGroup = async () =>
  HttpClient.get(`complementgroup?userId=${id ?? ""}`);

export default {
  putComplementGroup,
  fetchComplementGroup,
  postComplementGroup,
};
