import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Table from "../../../../../components/table/Table";
import ListSchedulesTableWrapper from "./Styles";
import TranslatorFunction from '../../../../../components/I18n/Translator'

const ListSchedules = ({ schedules, onClickEditSchedules }) => {
  const schedulesMenu = TranslatorFunction({ path: 'Schedules.message' });
  const action = TranslatorFunction({ path: 'action.message' });
  const [data, setData] = useState(schedules);
  const colKeyAligns = { nameOperation: "left" };
  const colTitleKeysAlign = ["left", "center"];
  const schedulesKeys = ["nameOperation"];
  const listTitles = [schedulesMenu, action];

  useEffect(() => {
    setData(schedules);
  }, [schedules]);

  return (
    <ListSchedulesTableWrapper>
      <Table
        data={data}
        setData={setData}
        titles={listTitles}
        colKeysAlign={colKeyAligns}
        colTitleKeysAlign={colTitleKeysAlign}
        keys={schedulesKeys}
        onClickEdit={onClickEditSchedules}
        objectKey="id"
        withActions
      />
    </ListSchedulesTableWrapper>
  );
};

ListSchedules.defaultProps = {
  schedules: [],
  onClickEditSchedules: () => {},
};

ListSchedules.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.object),
  onClickEditSchedules: PropTypes.func,
};

export default ListSchedules;
