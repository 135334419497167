export default {
  translations: {
    language: {
      message: "ru",
    },
    LoginFormTitle: {
      message: "Авторизоваться"
    },
    LoginFormSubtitle: {
      message: "Здравствуйте, введите адрес электронной почты и пароль для входа:"
    },
    LoginFormErroMessage: {
      message: "Не удалось войти, попробуйте еще раз"
    },
    LoginFormButton: {
      message: "Авторизоваться"
    },
    LoginFormButtonForgotPassword: {
      message: "я забыл пароль"
    },
    password: {
      message: "Пароль"
    },
    confirmPassword: {
      message: "Подтвердить Пароль"
    },
    mail: {
      message: "Эл. адрес"
    },
    myEstablishment: {
      message: "мое заведение"
    },
    parameterization: {
      message: "Параметризация"
    },
    itemRegistration: {
      message: "Регистрация объекта"
    },
    orders: {
      message: "Запросы"
    },
    discountCoupons: {
      message: "Купоны на скидку"
    },
    logout: {
      message: "выходить"
    },
    establishmentDetails: {
      message: "сведения об учреждении"
    },
    subscriptions: {
      message: "Подписки"
    },
    aboutTheEstablishment: {
      message: "об учреждении"
    },
    mandatoryFilling: {
      message: "обязательное заполнение"
    },
    tradeName: {
      message: "Торговое название (название, под которым известно ваше заведение)"
    },
    companyCorporateName: {
      message: "социальная причина"
    },
    informTheCompanyCorporateName: {
      message: "Сообщите фирменное наименование компании"
    },
    pixel: {
      message: "пиксель"
    },
    informPixel: {
      message: "Сообщить о пикселях"
    },
    invalidCnpj: {
      message: "Недействительный CNPJ"
    },
    invalidCep: {
      message: "Неверный почтовый индекс"
    },
    state: {
      message: "государство"
    },
    city: {
      message: "Город"
    },
    cityUpper: {
      message: "ГОРОД"
    },
    neighborhoods: {
      message: "Район"
    },
    street: {
      message: "Дорога"
    },
    number: {
      message: "Число"
    },
    informTheNumber: {
      message: "Введите номерНомер"
    },
    complement: {
      message: "Дополнение"
    },
    complementUpper: {
      message: "ДОПОЛНЕНИЕ"
    },
    URLToYourMenu: {
      message: "URL вашего меню"
    },
    emailToLoginToTheManager: {
      message: "Электронная почта для входа к менеджеру"
    },
    name: {
      message: "Имя"
    },
    nameUpper: {
      message: "ИМЯ"
    },
    lastName: {
      message: "Фамилия"
    },
    contactEmail: {
      message: "Почта для связи"
    },
    cellphoneNumberWhatsApp: {
      message: "Мобильный/Ватсап"
    },
    thisFieldIsRequired: {
      message: "Это поле обязательно к заполнению."
    },
    enterYourCreditCardDetails: {
      message: "Введите данные своей кредитной карты:"
    },
    thisIsTheCurrentPaymentMethod: {
      message: "Это текущий способ оплаты:"
    },
    paymentMethod: {
      message: "Метод оплаты:"
    },
    paymentMethod2: {
      message: "Способы оплаты"
    },
    paymentMethod3: {
      message: "СПОСОБЫ ОПЛАТЫ"
    },
    cardNumber: {
      message: "Номер карты:"
    },
    enterAValidCardNumber: {
      message: "Пожалуйста, введите действующий номер карты."
    },
    enterAValidDate: {
      message: "Пожалуйста, введите правильную дату."
    },
    cardholderName: {
      message: "Имя владельца карты."
    },
    typeAsWrittenOnCard: {
      message: "Пишите как написано на карточке."
    },
    enterTheNameAsItIsWrittenOnTheCard: {
      message: "Введите имя так, как оно написано на карте"
    },
    bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable: {
      message: "Ao сохраняя свои платежные данные, вы разрешаете MyTripMenu автоматически списывать сумму плана каждый месяц, пока вы не отмените подписку. Условия доступны"
    },
    inThisLink: {
      message: "по этой ссылке."
    },
    savePaymentData: {
      message: "сохранить платежные данные"
    },
    addACreditCardToViewPlanInformationAndConfirmYourSubscription: {
      message: "Добавьте кредитную карту, чтобы просмотреть информацию о плане и подтвердить подписку"
    },
    deliveryConfiguration: {
      message: "конфигурация доставки"
    },
    schedules: {
      message: "расписания"
    },
    Schedules: {
      message: "РАСПИСАНИЯ"
    },
    add: {
      message: "добавлять"
    },
    onSiteServiceVisualization: {
      message: "Обслуживание на месте | визуализация"
    },
    deliveryWhatsappOrders: {
      message: "Доставка | Запросы по WhatsApp"
    },
    takeAway: {
      message: "вывод"
    },
    catalog: {
      message: "Каталог"
    },
    typesOfService: {
      message: "ВИДЫ УСЛУГ"
    },
    mandatoryDataForDelivery: {
      message: "ОБЯЗАТЕЛЬНЫЕ ДАННЫЕ ДЛЯ ПОСТАВКИ"
    },
    phone: {
      message: "телефон"
    },
    phoneUpper: {
      message: "ТЕЛЕФОН"
    },
    address: {
      message: "Адрес"
    },
    generalSettings: {
      message: "ОБЩИЕ НАСТРОЙКИ"
    },
    freeShippingForPurchasesAbove: {
      message: "БЕСПЛАТНАЯ ДОСТАВКА ДЛЯ ПОКУПОК ВЫШЕ"
    },
    noFreeShipping: {
      message: "Нет бесплатной доставки"
    },
    minimumValueForDeliveryOrders: {
      message: "МИНИМАЛЬНАЯ СТОИМОСТЬ ЗАКАЗА НА ДОСТАВКУ"
    },
    minimumDeliveryTime: {
      message: "МИНИМАЛЬНОЕ ВРЕМЯ ДОСТАВКИ"
    },
    noForecast: {
      message: "Нет прогноза"
    },
    maximumDeliveryTime: {
      message: "МАКСИМАЛЬНОЕ ВРЕМЯ ДОСТАВКИ"
    },
    orderResponseTimeInMinutes: {
      message: "ВРЕМЯ ОТВЕТА НА ЗАКАЗ В МИНУТАХ"
    },
    serviceNumberViaWhats: {
      message: "СЕРВИСНЫЙ НОМЕР ПО WHATSAPP"
    },
    servicePlaces: {
      message: "МЕСТА ОБСЛУЖИВАНИЯ"
    },
    action: {
      message: "ДЕЙСТВИЯ"
    },
    addEditCities: {
      message: "ДОБАВИТЬ/ИЗМЕНИТЬ ГОРОДА"
    },
    newCity: {
      message: "Новый город"
    },
    search: {
      message: "Поиск"
    },
    addNew: {
      message: "Добавить новое"
    },
    saveEditions: {
      message: "СОХРАНИТЬ ИЗДАНИЯ"
    },
    exitWithoutSaving: {
      message: 'ВЫХОД БЕЗ СОХРАНЕНИЯ'
    },
    saveEditionsAndExit: {
      message: 'СОХРАНИТЬ ИЗМЕНЕНИЯ И ВЫЙТИ'
    },
    back: {
      message: "ВЕРНИСЬ"
    },
    status: {
      message: "СТАТУС"
    },
    active: {
      message: "АКТИВНЫЙ"
    },
    inactive: {
      message: "НЕАКТИВНЫЙ"
    },
    category: {
      message: "Категория"
    },
    subcategory: {
      message: "Подкатегория"
    },
    categories: {
      message: "Категории"
    },
    subcategories: {
      message: "Подкатегории"
    },
    categoriesUpper: {
      message: "КАТЕГОРИИ"
    },
    subcategoriesUpper: {
      message: "ПОДКАТЕГОРИИ"
    },
    complements: {
      message: "надстройки"
    },
    complementsGroup: {
      message: "дополнительная группа"
    },
    complementsGroupUpper: {
      message: "КОМПЛЕКС ГРУП"
    },
    products: {
      message: "товары"
    },
    image: {
      message: "ИЗОБРАЖЕНИЕ"
    },
    yes: {
      message: "Да"
    },
    no: {
      message: "Нет"
    },
    theHighlightsCategoryCannotBeEdited: {
      message: "Категорию Highlights нельзя редактировать"
    },
    sortFromAZ: {
      message: "Сортировать от А до Я"
    },
    sortFromZA: {
      message: "Сортировать от Я до А"
    },
    sortByActive: {
      message: "Сортировать по активности"
    },
    sortByInactive: {
      message: "Сортировать по неактивным"
    },
    listActiveOnly: {
      message: "Список только активов"
    },
    addEditCategory: {
      message: "Добавить/изменить категорию"
    },
    addEditSubcategory: {
      message: "Добавить/изменить подкатегорию"
    },
    imageOptional: {
      message: "изображение (необязательно)" },
    addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive: {
      message: "Добавление иллюстративного изображения в категорию является необязательным действием, однако оно помогает сделать ваше меню более привлекательным"
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality: {
      message: "Выберите фотографию, которая иллюстрирует предметы, составляющие эту категорию, имеет хорошее освещение и реалистична по отношению к фактическому представлению продуктов. Избегайте использования стандартных, стоковых или низкокачественных фотографий"
    },
    theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB: {
      message: "Идеальное разрешение — 800x600 пикселей, а размер изображения не может превышать 1 МБ"
    },
    selectPhoto: {
      message: "ВЫБЕРИТЕ ФОТО"
    },
    selectCategory: {
      message: "ВЫБРАТЬ КАТЕГОРИЮ"
    },
    saveAndAddAnother: {
      message: "СОХРАНИТЕ И ДОБАВЬТЕ ДРУГОЕ"
    },
    highlightsSubcategoryCannotBeEdited: {
      message: "Подкатегория Highlights не может быть изменена"
    },
    categoryInWhichTheSubcategoryShouldAppear: {
      message: "Категория, в которой должна появиться подкатегория"
    },
    addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive: {
      message: "Добавление иллюстративного изображения в подкатегорию является необязательным действием, однако оно помогает сделать ваше меню более привлекательным"
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos: {
      message: "Выберите фотографию, которая иллюстрирует предметы, составляющие эту подкатегорию, имеет хорошее освещение и реалистична по отношению к фактическому представлению продуктов. Избегайте использования стандартных, стоковых или низкокачественных фотографий"
    },
    enterANameForTheSubcategory: {
      message: "Введите название подкатегории"
    },
    enterANameForTheCategory: {
      message: 'Введите название категории'
    },
    descriptionOfComplement: {
      message: "Описание дополнения"
    },
    descriptionOfComplementUpper: {
      message: "ДОПОЛНЕНИЕ ОПИСАНИЕ"
    },
    addComplementGroup: {
      message: "ДОБАВИТЬ ДОПОЛНИТЕЛЬНУЮ ГРУППУ"
    },
    complementGroupName: {
      message: "Название группы дополнений"
    },
    complementName: {
      message: "Название дополнения"
    },
    checkAllFields: {
      message: "Проверьте все поля."
    },
    complementGroupIsInactive: {
      message: "Группа дополнений неактивна."
    },
    value: {
      message: "Ценность"
    },
    valueUpper: {
      message: "Ценность"
    },
    addProduct: {
      message: "ДОБАВИТЬ ПРОДУКТ"
    },
    images: {
      message: "Картинки"
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos: {
      message: "Выберите фотографию, которая иллюстрирует элементы, из которых состоит этот продукт, имеет хорошее освещение и реалистична по отношению к вашей фактической презентации. Избегайте использования стандартных, стоковых или низкокачественных фотографий"
    },
    enableOn: {
      message: "Включить"
    },
    categorysInWhichTheProductShouldAppear: {
      message: "Категория(и), в которой должен появиться товар"
    },
    subCategorysInWhichTheProductShouldAppear: {
      message: "Подкатегории, в которых должен появиться товар"
    },
    categorization: {
      message: "категоризация"
    },
    shortDescriptionShownInProductDetail: {
      message: "Краткое описание (показано в деталях продукта)"
    },
    longDescriptionShownInProductDetail: {
      message: "Подробное описание (показано в деталях продукта)"
    },
    enterTheIngredientsThatMakeUpThisProductItsAccompanimentsAndThePortionSizes: {
      message: "Введите ингредиенты, из которых состоит этот продукт, его сопровождение(я) и размеры порций"
    },
    enterSomethingLikeSideDishesOrPortionSize: {
      message: "Введите что-нибудь, например, гарнир или размер порции"
    },
    principalInformation: {
      message: "Основная информация"
    },
    id: {
      message: "Я БЫ"
    },
    type: {
      message: "ТИП"
    },
    validity: {
      message: "Срок действия"
    },
    validityUpper: {
      message: "СРОК ДЕЙСТВИЯ"
    },
    useAvailable: {
      message: "ИСПОЛЬЗОВАНИЕ / ДОСТУПНОS"
    },
    filter: {
      message: "фильтр"
    },
    freeDelivery: {
      message: "БЕСПЛАТНАЯ ДОСТАВКА"
    },
    discount: {
      message: "СКИДКА"
    },
    aCouponWithThisCodeAlreadyExists: {
      message: "Купон с таким кодом уже существует."
    },
    dateTime: {
      message: "ДАТА/ВРЕМЯ"
    },
    change: {
      message: "ВЕЩЬ"
    },
    formOfPayment: {
      message: "ФОРМА ОПЛАТЫ"
    },
    customer: {
      message: "КЛИЕНТ"
    },
    uninformed: {
      message: "Неинформированный"
    },
    recordsFound: {
      message: "Найдены записи"
    },
    QRCodeOfYourDigitalMenu: {
      message: "QR-код вашего цифрового меню"
    },
    downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu: {
      message: "Загрузите свой QR-код и разместите его в своем заведении или в рекламных материалах, чтобы облегчить клиентам доступ к вашему цифровому меню"
    },
    areYouSureYouWantToDoThis: {
      message: "Вы уверены, что хотите это сделать?"
    },
    whenDisablingTheComplementAllPreviouslyLinkedComplementGroupsWillBeUnlinkedFromTheProduct: {
      message: "При отключении надстройки все ранее связанные группы надстроек будут отсоединены от продукта"
    },
    anErrorOccurredWhileFetchingProductCategories: {
      message: "Произошла ошибка при получении категорий товаров"
    },
    anErrorOccurredWhileFetchingProductOperations: {
      message: "Произошла ошибка при получении операций продукта"
    },
    anErrorOccurredWhileFetchingTheSubcategoriesForTheSelectedCategory: {
      message: "Произошла ошибка при получении подкатегорий для выбранной категории"
    },
    productImage: {
      message: "Изображение продукта"
    },
    whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus: {
      message: "Когда вы деактивируете продукт, он больше не будет отображаться в онлайн-меню"
    },
    RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary: {
      message: "Помните, что вы можете снова активировать его в другое время, если это необходимо"
    },
    inactivateTheProduct: {
      message: "ИНАКТИВИРОВАТЬ ПРОДУКТ"
    },
    keepActive: {
      message: "ПРОДОЛЖАЙТЕ АКТИВНОСТЬ"
    },
    simpleEasyFastDigital: {
      message: "Простой. Легко. Быстро. Цифровой"
    },
    errorIdentification: {
      message: "Идентификация ошибки"
    },
    tryAgain: {
      message: "ПОПРОБУЙТЕ ЕЩЕ РАЗ"
    },
    huhLooksLikeSomethingWentWrong: {
      message: "Хм! Кажется, что-то пошло не так..."
    },
    unableToSaveCreditCardDetails: {
      message: "Не удалось сохранить данные кредитной карты"
    },
    mmyy: {
      message: "ММ/ГГ"
    },
    removePhoto: {
      message: "удалить фото"
    },
    addEditCoupon: {
      message: "ДОБАВИТЬ/ИЗМЕНИТЬ КУПОН"
    },
    couponType: {
      message: "ТИП КУПОНА"
    },
    freeShipping: {
      message: "Бесплатная доставка"
    },
    discountByValue: {
      message: "Скидка по стоимости"
    },
    percentageDiscount: {
      message: "Скидка в процентах"
    },
    generateCoupon: {
      message: "СОЗДАТЬ КУПОН"
    },
    limitOfUse: {
      message: "ОГРАНИЧЕНИЕ ИСПОЛЬЗОВАНИЯ"
    },
    couponCode: {
      message: "КОД КУПОНА"
    },
    validFrom: {
      message: "ДЕЙСТВИТЕЛЬНО "
    },
    validUntil: {
      message: "ГОДЕН ДО"
    },
    highlightsSubcategoryCannotBeCopied: {
      message: "Подкатегория Highlights не может быть скопирована"
    },
    nothingHere: {
      message: "Похоже, здесь еще ничего нет"
    },
    from: {
      message: "В"
    },
    to: {
      message: "ВВЕРХ ДО"
    },
    sunday: {
      message: "Воскресенье"
    },
    monday: {
      message: "Второй"
    },
    tuesday: {
      message: "Вторник"
    },
    wesnesday: {
      message: "Четвертый"
    },
    thursday: {
      message: "Пятый"
    },
    friday: {
      message: "Пятница"
    },
    saturday: {
      message: "Суббота"
    },
    accessData: {
      message: "доступ к данным"
    },
    responsibleEstablishment: {
      message: "Ответственный за создание"
    },
    saveEditions2:{
      message: "Сохранить выпуски"
    },
    zeit:{
      message: "Время"
    },
    cardsAccepted:{
      message: "Отметьте карты, принимаемые вашим заведением"
    },
    pixKey:{
      message: "Зарегистрируйте свой ключ PIX здесь"
    },
    noOptions:{
      message: "нет вариантов"
    },
    neighborhoods2:{
      message: "РАЙОНЫ"
    },
    addEditNeighborhoods:{
      message: "ДОБАВИТЬ/РЕДАКТИРОВАТЬ ОКРЕСТНОСТИ"
    },
    newNeighborhoods:{
      message: "Новый район"
    },
    qtyMinimum:{
      message: "КОЛ-ВО МИНИМУМ"
    },
    qtymaximum:{
      message: "КОЛ-ВО максимум"
    },
    linkGroup:{
      message: "ГРУППА ПОСТАВОК ССЫЛКИ"
    },
    importantGroupComplements:{
      message: "ИМПОРТНАЯ ГРУППА КОМПЛЕКТУЮЩИХ"
    },
    people:{
      message: "Люди"
    },
    house:{
      message: "Дома"
    },
    subcategoryProducts:{
      message: "В этой подкатегории нет сопутствующих товаров"
    },
    productNotAdd:{
      message: "К этому продукту не добавлено никаких надстроек"
    },
    linkSupplements:{
      message: "ССЫЛКА ДОПОЛНЕНИЯ"
    },
    PRICE:{
      message: "ЦЕНА"
    },
    COMPLEMENTS:{
      message: "ДОПОЛНЕНИЯ"
    },
    DESCRIPTION:{
      message: "ОПИСАНИЕ"
    },
    action2:{
      message: "Действия"
    },
    pixKey2:{
      message: "Ваш PIX-ключ"
    },
    ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn:{
      message: "Если вы покинете страницу со сведениями об объекте до сохранения изменений, вы потеряете всю введенную ранее информацию."
    },
    couldNotLoadUserData:{
      message: "Не удалось загрузить данные пользователя"
    },
    termsOfUseAanServices:{
      message: "УСЛОВИЯ ИСПОЛЬЗОВАНИЯ И УСЛУГИ"
    },
    hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou:{
      message: "Привет! Рада вашему интересу! Перед использованием наших услуг, пожалуйста, найдите время, чтобы прочитать наши Условия использования и обслуживания и узнать о правилах, которые регулируют наши отношения с вами."
    },
    belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom:{
      message: "Ниже мы поясним некоторые моменты, которые считаем важными. Если у вас есть какие-либо сомнения по поводу каких-либо вопросов, обсуждаемых или не обсуждаемых в этом документе, не стесняйтесь обращаться к нам по адресу hello@mytripmenu.com."
    },
    definitions:{
      message: "1. ОПРЕДЕЛЕНИЯ:"
    },
    inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions: {
      message: "В этом документе мы понимаем приведенные ниже выражения в соответствии со следующими определениями:"
    },
    plataform: {
      message: "ПЛАТФОРМА"
    },
    webSystemAvailableThroughMyTripMenuComBrOfferedBy: {
      message: "веб-система, доступная через mytripmenu.com.br, предлагаемая"
    },
    andOwnershipOperationAndLiabilityOf: {
      message: " а также право собственности, деятельность и ответственность "
    },
    users: {
      message: "ПОЛЬЗОВАТЕЛИ"
    },
    user: {
      message: "ПОЛЬЗОВАТЕЛЬ"
    },
    where: {
      message: ", Где"
    },
    allThe: {
      message: "все"
    },
    canViewAndUseTheServicesOfferedBy: {
      message: "могут просматривать и использовать услуги, предлагаемые"
    },
    establishment: {
      message: "УЧРЕЖДЕНИЕ"
    },
    establishments: {
      message: "ЗАВЕДЕНИЯ"
    },
    legalEntityRegisteredInThe: {
      message: "юридическое лицо, зарегистрированное в г."
    },
    whichContractsTheServicesOfferedBy: {
      message: ", который заключает договор на услуги, предлагаемые"
    },
    client: {
      message: "КЛИЕНТ"
    },
    customers: {
      message: "КЛИЕНТЫ"
    },
    naturalPersonAccessingThe: {
      message: "физическое лицо, имеющее доступ к"
    },
    toViewTheMenu: {
      message: "для просмотра меню "
    },
    dataProcessing: {
      message: "ОБРАБОТКА ДАННЫХ"
    },
    pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy: {
      message: " В соответствии со статьей 5, пункт X, Закона 13,709 от 2018 года, каждая сделка, осуществляемая "
    },
    withPersonalDataOf: {
      message: " с персональными данными "
    },
    suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction: {
      message: " , такие как те, которые относятся к сбору, производству, получению, классификации, использованию, доступу, воспроизведению, передаче, распространению, обработке, архивированию, хранению, уничтожению, оценке или контролю информации, изменению, передаче, передаче, распространению или извлечению. "
    },
    admission: {
      message: " 2. ПРИЕМ: "
    },
    thisInstrumentRegulatesTheConditionsOfUseOfThe: {
      message: " Этот документ регулирует условия использования "
    },
    being: {
      message: "существование"
    },
    aContractBetweenThe: {
      message: " договор между "
    },
    andThe: {
      message: " и "
    },
    theUseOfTheServicesOfferedThrough: {
      message: " Использование услуг, предлагаемых через "
    },
    fromThe: {
      message: "от "
    },
    expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies: {
      message: " прямо указывает, что вы согласны со всеми положениями и условиями, содержащимися в этом документе, и с правовыми положениями, применимыми к видам. "
    },
    the: {
      message: "в "
    },
    understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy: {
      message: " понимает, что модель контракта на обслуживание будет заключаться в планах ежемесячной подписки с предоплатой, бюджет которых составляется и предлагается "
    },
    accordingToInformationAvailableInThePlansAreaOfTheWebsite: {
      message: "согласно информации, доступной в разделе планов на веб-сайте: "
    },
    forTheContractingOfCustomizedPlansThe: {
      message: "Для заключения индивидуальных планов,в "
    },
    shouldContactThe: {
      message: "следует связаться с"
    },
    throughItsChannelsForTheElaborationOfASpecificBudget: {
      message: "по своим каналам для разработки конкретного бюджета. "
    },
    youUnderstandAndAgreeThatThe: {
      message: "ВЫ ПОНИМАЕТЕ И СОГЛАШАЕТЕСЬ С ТЕМ, ЧТО "
    },
    useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies: {
      message: " ИСПОЛЬЗОВАНИЕ ПРЕДОСТАВЛЯЕМЫХ УСЛУГ БУДЕТ РАССМАТРИВАТЬСЯ ВАШИМ ПРИНЯТИЕМ НАСТОЯЩИХ УСЛОВИЙ И ВСЕХ ДРУГИХ ЗАКОННЫХ ПОЛОЖЕНИЙ, ОТНОСЯЩИХСЯ К ВИДАМ."
    },
    ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform: {
      message: "ПРИНИМАЯ УСЛОВИЯ ЭТОГО ИНСТРУМЕНТА, ПОЛЬЗОВАТЕЛЬ ЯВНО РАЗРЕШАЕТ НА ОБРАБОТКУ СВОИХ ДАННЫХ, ЧТОБЫ ГАРАНТИРОВАТЬ ОБСЛУЖИВАНИЕ И ХОРОШУЮ ФУНКЦИОНАЛЬНОСТЬ ФУНКЦИОНАЛЬНЫХ ВОЗМОЖНОСТЕЙ ПЛАТФОРМЫ"
    },
    theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy: {
      message: "НАСТОЯЩИМ ДЕЙСТВИЕМ ПОЛЬЗОВАТЕЛЬ ВЫЯВЛЯЕТ СВОЁ ПОЛНОЕ СОГЛАСИЕ НА ПЕРЕДАЧУ ДАННЫХ, СОБРАННЫХ И ОБРАБАТЫВАЕМЫХ "
    },
    pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders: {
      message: ", СОГЛАСНО ДАННОМУ ИНСТРУМЕНТУ, С ДРУГИМИ МЕНЮ MY TRIP, КОТОРЫЕ ЯВЛЯЮТСЯ ЧАСТЬЮ ВАШЕЙ ЭКОНОМИЧЕСКОЙ ГРУППЫ ИЛИ ЯВЛЯЮТСЯ ВАШИМИ ПОСТАВЩИКАМИ УСЛУГ."
    },

    caseYou: {
      message: "Если вы "
    },
    doNotAgree: {
      message: "НЕ СОГЛАШАТЬСЯ"
    },
    withTheProvisionsOfThisInstrument: {
      message: " с положениями этого документа, "
    },
    dont: {
      message: 'НЕТ'
    },
    accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom: {
      message: " получать доступ, просматривать, загружать или иным образом использовать любую страницу, контент, информацию или услугу с "
    },
    theseTermsAreAvailableForReadingAtAnyTimeOn: {
      message: "Эти условия доступны для ознакомления в любое время на "
    },
    inMessage: {
      message: "в "
    },
    whoWeAreAndWhatWeDo: {
      message: "3. КТО МЫ И ЧТО МЫ ДЕЛАЕМ:"
    },
    isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp: {
      message: "является поставщиком услуг, который разработал и лицензировал систему в форме SaaS (программное обеспечение как услуга) со специальными функциями, помогающими"
    },
    and: {
      message: "а также"
    },
    soThe: {
      message: "Итак"
    },
    offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe: {
      message: "предлагает цифровое меню, позволяющее повысить эффективность и опыт как для"
    },
    asForThe: {
      message: "Для"
    },
    generalConditionsOfUse: {
      message: "4. ОБЩИЕ УСЛОВИЯ ИСПОЛЬЗОВАНИЯ:"
    },
    onlyProvidesThe: {
      message: " обеспечивает только "
    },
    whichOffersDigitalMenuServicesForThe: {
      message: " который предлагает услуги цифрового меню для "
    },
    itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe: {
      message: " его ответственность ограничивается только правильным функционированием "
    },
    andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe: {
      message: "и его функциональные возможности, в соответствии с этим документом и планом, заключенным "
    },
    notStoppingThe: {
      message: ", не останавливая"
    },
    noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu: {
      message: "не несет ответственности за создание, настройку и распространение контента через цифровое меню."
    },
    justLicenseThe: {
      message: " просто лицензируй "
    },
    toThe: {
      message: " к "
    },
    toThe2: {
      message: " К "
    },
    andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe: {
      message: ", и нет никаких других отношений между этими сторонами, так что нельзя отнести к"
    },
    liabilityForAnyDamageCauseToOthers: {
      message: " ответственность за любой ущерб, причиненный другим "
    },
    orToThirdPartiesForActsArisingFrom: {
      message: " или третьим лицам за действия, вытекающие из "
    },
    whileUsingTheAvailableFeaturesOfThe: {
      message: " при использовании доступных функций "
    },
    declaresThatHeUnderstandsAndAcceptsThatThe: {
      message: "заявляет, что он понимает и принимает, что "
    },
    doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe: {
      message: "не намеревается заменить физическое меню, доступное в коммерческом подразделении "
    },
    beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween: {
      message: "является лишь дополнительным решением, чтобы облегчить связь между "
    },
    atTheTimeOfRegistrationThe: {
      message: "На момент регистрации,"
    },
    youCanUseAllTheServicesAvailableOnThe: {
      message: " Вы можете пользоваться всеми услугами, доступными на "
    },
    declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse: {
      message: ", заявляя, что они прочитали, поняли и приняли все положения, содержащиеся в настоящих Условиях использования."
    },
    expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom: {
      message: "прямо признает, что посредством этого документа он получает от "
    },
    theGarantingOfALicenseToUseThe: {
      message: " выдача лицензии на использование "
    },
    isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe: {
      message: ", не подлежит передаче, и сублицензирование запрещено для использования на национальной или иностранной территории до тех пор, пока действует присоединение к этому сроку, оставляя использование "
    },
    inDisagreementWithTheProvisionOfThisInstrument: {
      message: " в несогласии с положениями настоящего документа."
    },
    isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt: {
      message: " несет единоличную ответственность за безопасность вашего пароля и за использование вашей регистрации на "
    },
    thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr: {
      message: ", поэтому мы рекомендуем вам не делиться такой информацией с третьими лицами и, если эта информация по какой-либо причине утеряна или "
    },
    hacked: {
      message: "взломан"
    },
    mustImmediatelyInformThe: {
      message: " должен немедленно сообщить об "
    },
    throughHelloMyTripMenuComInOrderToResolveTheIssue: {
      message: ", через hello@mytripmenu.com, чтобы решить проблему."
    },
    itIsOnlyUpToThe: {
      message: "Это зависит только от "
    },
    liableForAnyDamagesCausedToThirdPartiesToOther: {
      message: " ответственность за любой ущерб, причиненный третьим лицам, другим "
    },
    orToItself: {
      message: " или себе "
    },
    resultingFromTheUseOfTheFeaturesOfThe: {
      message: ", в результате использования особенностей "
    },
    shouldNotUseTheServicesAvailableOnThe: {
      message: " не должны пользоваться услугами, доступными на "
    },
    forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe: {
      message: " за любое незаконное, клеветническое, дискриминационное, оскорбительное, оскорбительное, порнографическое, непристойное, агрессивное, оскорбительное, раздражающее, вводящее в заблуждение, клеветническое, насильственное, вульгарное или беспокоящее, угрожающее или использование ложной личности, т. е. любое теневое использование, цели или средства, которые может нанести вред "
    },
    others: {
      message: " другие"
    },
    orThirdParties: {
      message: " или третьи лица"
    },
    UnderNoCircumstancesWillThe: {
      message: "Ни при каких обстоятельствах не будет "
    },
    willBeLiableForAnyDamagesIncurredByThe: {
      message: "будет нести ответственность за любой ущерб, причиненный "
    },
    forPossibleTemporaryUnavailabilityOfThe: {
      message: " за возможную временную недоступность "
    },
    orOnyContentMadeAvailableByThe: {
      message: "или любой контент, предоставленный "
    },
    onTheDigitalMenu: {
      message: "в цифровом меню."
    },
    mustHaveAllNecessarySoftwareAndHardwareToAccessThe: {
      message: " должен иметь все необходимое программное и аппаратное обеспечение для доступа к "
    },
    includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe: {
      message: ", включая, помимо прочего, компьютер или мобильное устройство с доступом в Интернет, а также "
    },
    onlyToProvideThe: {
      message: ", только для того, чтобы обеспечить "
    },
    to2: {
      message: " к "
    },
    underThisInstrumentToThe: {
      message: "под этот инструмент. К "
    },
    accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable: {
      message: "получить доступ к цифровому меню, устройство, способное считывать QR-код, будет незаменимым."
    },
    byThe: {
      message: " посредством "
    },
    isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument: {
      message: "обусловлено его предварительной регистрацией, а также соблюдением положений, содержащихся в настоящем документе."
    },
    registration: {
      message: "5. РЕГИСТРАЦИЯ"
    },
    soThatThe: {
      message: "Таким образом "
    },
    registerInThe: {
      message: " зарегистрироваться в "
    },
    mustProvideTo: {
      message: "должен предоставить "
    },
    theFollowingData: {
      message: " следующие данные "
    },
    corporateName: {
      message: "фирменное наименование;"
    },
    unitAddressOf: {
      message: " адрес подразделения "
    },
    contactNumberInTheEventThatThe: {
      message: "Контактный номер. В случае, если"
    },
    hireThe: {
      message: "нанять"
    },
    forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest: {
      message: "для большего количества единиц вы должны зарегистрировать другие интересующие адреса."
    },
    forRegularUseOf: {
      message: "Для регулярного использования "
    },
    mustRegisterFillingInAllTheDataRequestedByThe: {
      message: " необходимо зарегистрироваться, заполнив все данные, запрошенные "
    },
    atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy: {
      message: " во время регистрации, а также выбрав тарифный план, предлагаемый "
    },
    itIsTheResponsibilityOf: {
      message: " Это ответственность "
    },
    identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting: {
      message: "указать в контрактном плане количество единиц, предусмотренных в его контракте."
    },
    itIsTheSoleResponsibilityOf: {
      message: "Это исключительная ответственность"
    },
    provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe: {
      message: "предоставлять, обновлять и гарантировать достоверность регистрационных данных, не попадая в"
    },
    anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy: {
      message: " гражданско-правовая и уголовная ответственность любого рода в связи с неверными, неверными или неполными данными, предоставленными "
    },
    reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe: {
      message: " оставляет за собой право использовать все допустимые и возможные средства для идентификации "
    },
    asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf: {
      message: " а также запрашивать дополнительные данные и документы, которые он считает уместными, для проверки сообщаемых данных. В этом случае использование "
    },
    isConditionedToTheSendingOfTheDocumentsEventuallyRequested: {
      message: " обусловливается отправкой запрошенных документов."
    },
    ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe: {
      message: "Если в регистрации есть подозрение, что она содержит ошибочные или недостоверные данные, "
    },
    reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe: {
      message: " оставляет за собой право приостановить, временно или постоянно, без предварительного уведомления, "
    },
    responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe: {
      message: " ответственный за регистрацию. В случае отстранения вы не будете присутствовать на "
    },
    rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages: {
      message: " право на любое возмещение убытков или компенсацию за убытки и ущерб, упущенную выгоду или моральный ущерб."
    },
    inTheEventThatThe: {
      message: "В случае, если"
    },
    carryOutTheCollectionOfPersonalDataFromThe: {
      message: "осуществлять сбор персональных данных с "
    },
    willAllowAccessToTheInformationCollectedAndAboutThe: {
      message: "позволит получить доступ к собранной информации и о "
    },
    carriedOutBy: {
      message: " осуществляется "
    },
    freeOfChargeByRequestingHelloMyTripMenuCom: {
      message: ", бесплатно, по запросу hello@mytripmenu.com"
    },
    orByRegisteringAt: {
      message: "или зарегистрировавшись на "
    },
    beingAbleToEditOrDeleteThemAtAnyTime: {
      message: " возможность редактировать или удалять их в любое время."
    },
    theCollectionOfDataFrom: {
      message: "Сбор данных из "
    },
    whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe: {
      message: ", когда это происходит, направлено на вашу идентификацию, а также на то, чтобы вы могли правильно использовать "
    },
    andWithThatThe: {
      message: ", и с этим, "
    },
    canEnsureTheGoodQualityOfTheLicensedServices: {
      message: " может гарантировать хорошее качество лицензированных услуг."
    },
    byConsentingToTheTermsOfThisInstrumentThe: {
      message: "Соглашаясь с условиями настоящего документа,"
    },
    expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe: {
      message: " прямо заявляет, что осознает, что сбор ваших данных необходим для надлежащего функционирования "
    },
    authorizingFromNowOnThe: {
      message: ", разрешает отныне "
    },
    forThe: {
      message: " для "
    },
    offersThePossibilityOf: {
      message: " предлагает возможность "
    },
    publicizeYourFigitalMenuThroughQRCodeToYour: {
      message: "опубликуйте свое цифровое меню через QR-код для вашего "
    },
    withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe: {
      message: "без необходимости регистрации, однако отсутствие регистрации не умаляет несоблюдения правил этого документа, который "
    },
    mustEnsureCompliance: {
      message: "должен обеспечить соблюдение."
    },
    willAccessYourRegistrationIn: {
      message: " получит доступ к вашей регистрации в "
    },
    through: {
      message: " через "
    },
    andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem: {
      message: " и пароль, обязуясь не сообщать эти данные третьим лицам, принимая на себя полную ответственность за их использование."
    },
    undertakesToNotifyThe: {
      message: " обязуется уведомить "
    },
    immediatelyThroughTheContactChannelsMaintainedBy: {
      message: " немедленно, по контактным каналам, поддерживаемым "
    },
    at: {
      message: " в "
    },
    regardingAnyUnauthorizedUseOfYourAccountThe: {
      message: ", в отношении любого несанкционированного использования вашей учетной записи."
    },
    willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou: {
      message: " будет нести единоличную ответственность за операции, проводимые с вашей учетной записью, поскольку доступ будет возможен только посредством использования пароля, известного исключительно вам."
    },
    regardingAnyKnowledgeOfWrongdoingByOther: {
      message: ", в отношении любой осведомленности о правонарушениях со стороны других"
    },
    thatMayCauseDamageToTheirOwn: {
      message: " которые могут нанести ущерб их собственному "
    },
    ofThe: {
      message: " принадлежащий "
    },
    toThisOnTo: {
      message: " к этому, к "
    },
    orToThirdParties: {
      message: " или третьим лицам."
    },
    undertakesToUsTheFeaturesOfThe: {
      message: "обязуется использовать возможности"
    },
    inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms: {
      message: " добросовестно, в соответствии с действующим законодательством, нравами и нравами."
    },
    underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe: {
      message: "Ни при каких обстоятельствах уступка, продажа, аренда или иная форма передачи"
    },
    atItsSoleDiscretion: {
      message: "По своему собственному усмотрению "
    },
    mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO: {
      message: " может исключать, отключать, устанавливать ограничения на использование сервиса, приостанавливать, блокировать, на неопределенный срок, без предварительного уведомления или компенсации, регистрации o "
    },
    thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce: {
      message: " которые считаются оскорбительными, нарушающими условия настоящего документа или действующего законодательства."
    },
    reservesTheRightNotToAllowNewRegistrationOf: {
      message: " оставляет за собой право не разрешать новую регистрацию "
    },
    thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom: {
      message: " которые уже были отменены, отключены, заблокированы, удалены или приостановлены "
    },
    theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe: {
      message: " Создание новых регистраций людьми, чьи первоначальные регистрации были отменены, заблокированы, отключены, исключены или приостановлены из-за нарушений политик "
    },
    orCurrentLegislation: {
      message: " или действующего законодательства."
    },
    reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA: {
      message: " оставляет за собой право в одностороннем порядке, без предварительного уведомления, согласия или компенсации, отказать в любом запросе на регистрацию "
    },
    asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration: {
      message: ", а также отменить, отключить, заблокировать, удалить или приостановить использование ранее принятой регистрации."
    },
    byAgreeingToThisInstrumentThe: {
      message: "Соглашаясь с этим документом, "
    },
    declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo: {
      message: " заявляет, что осознает, что несет единоличную ответственность за ее регистрацию, будучи уверенным, что любой ущерб, вызванный введением устаревшей, неточной или ложной информации, не может быть отнесен к "
    },
    orThe: {
      message: "Или"
    },
    functionalities: {
      message: "6. ФУНКЦИОНАЛЬНЫЕ ВОЗМОЖНОСТИ:"
    },
    offersTheFollowingFeatures: {
      message: " предлагает следующие возможности:"
    },
    aOnlineDigitalMenu: {
      message: "а) цифровое онлайн-меню;"
    },
    bViewingProductsWithPphotos: {
      message: "(б) Просмотр товаров с фотографиями;"
    },
    cOfferingAMultilingualMenuSubjectToAvailableConditions: {
      message: "c) предложение многоязычного меню при наличии условий;"
    },
    dActivationAndDeactivationOfItemsInRealTime: {
      message: "d) активация и деактивация элементов в режиме реального времени;"
    },
    eAccessToTheMenuByScanningAQRCode: {
      message: "(e) Доступ к меню путем сканирования QR-кода;"
    },
    fCreationOfHighlightsForPromotionalActions: {
      message: "(е) Создание основных моментов для рекламных акций."
    },
    whenUsingTheServicesProvidedByThe: {
      message: " при использовании услуг, предоставляемых "
    },
    canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe: {
      message: " можно выбрать URL (унифицированный указатель ресурса), чтобы определить электронный адрес, по которому будет расположено цифровое меню "
    },
    ToDefineTheURLThe: {
      message: "Чтобы определить URL-адрес, "
    },
    mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices: {
      message: "должен уважать интеллектуальную собственность третьих лиц, поэтому он не должен содержать выражений, которые могут вызвать путаницу или намекать на сторонние услуги."
    },
    understandThatThe: {
      message: " понять, что "
    },
    resourcesWereUsedToCauseConfusionAmongThe: {
      message: " ресурсы использовались, чтобы вызвать путаницу среди "
    },
    makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe: {
      message: "затрудняя правильную идентификацию услуг и вводя их в заблуждение, вы можете исключить URL-адрес "
    },
    asWellAsAdoptOtherMeasuresRelevantToTheCase: {
      message: "а также принять иные меры, имеющие значение для дела"
    },
    mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn: {
      message: " может изменить набор функций, доступных в соответствии с планом контракта, как это предусмотрено в "
    },
    mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe: {
      message: " может в любое время, направив предварительное уведомление, без необходимости компенсации редактировать и/или удалять существующие функции, а также включать новые функции в "
    },
    translations: {
      message: "7. ПЕРЕВОДЫ:"
    },
    youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe: {
      message: "Вы можете воспользоваться услугами переводчика, чтобы ваши меню были доступны на выбранных вами языках. При этом за услуги взимается плата, как это предусмотрено в п. "
    },
    forTheExecutionOfTranslationServicesThe: {
      message: "Для выполнения переводческих услуг,"
    },
    requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe: {
      message: "запрашивать не менее 72 часов (семьдесят два часа). В зависимости от длины текста, который необходимо перевести,"
    },
    withPriorNoticeTo: {
      message: "с предварительным уведомлением"
    },
    mayExtendTheServiceExecutionPeriodAtItsDiscretion: {
      message: "может продлить срок выполнения услуги по своему усмотрению."
    },
    declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions: {
      message: "заявляет, что он понимает и соглашается с тем, что некоторые выражения не могут быть переведены, учитывая, что они отражают определенные культуры или определенные регионы."
    },
    isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe: {
      message: " не несет ответственности за любой ущерб, возникший в результате неточных переводов в цифровом меню "
    },
    howeverInTheEventThatThe: {
      message: ". Однако в том случае, если"
    },
    identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe: {
      message: "определить любую ошибку или недоразумение в традиции, вы можете сообщить об этом "
    },
    whichMustRespondToTheRequestAsSoonAsPossible: {
      message: "который должен ответить на запрос как можно скорее."
    },
    suppertService: {
      message: "8. СЛУЖБА ПОДДЕРЖКИ"
    },
    willSupportThe: {
      message: "будет поддерживать "
    },
    throughthecontactchannelavailableviaemailhellomytripmenucom: {
      message: " через контактный канал, доступный по электронной почте hello@mytripmenu.com."
    },
    inSupportRequestsViaEmailTthe: {
      message: "В запросах на поддержку по электронной почте "
    },
    willHaveUpTo72SeventyTwoBusinessHoursToRespond: {
      message: "на ответ будет отведено до 72 (семидесяти двух) рабочих часов."
    },
    paymentMethod4: {
      message: "9. СПОСОБЫ ОПЛАТЫ:"
    },
    priorToAnyChargeBeingMadeByThe: {
      message: "До предъявления каких-либо обвинений со стороны "
    },
    willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf: {
      message: "будет предложен пробный период 07 (семь) дней, для бесплатной пробной версии инструментов "
    },
    ifThe: {
      message: "Если "
    },
    chooseToProceedWithTheContractingOfServicesThe: {
      message: " принять решение о заключении договора на оказание услуг, "
    },
    makesAvailableTo: {
      message: "делает доступным для "
    },
    oneOfTheFollowingPaymentMethods: {
      message: "одним из следующих способов оплаты: "
    },
    VISACreditCardOr: {
      message: "Кредитная карта VISA; или же,"
    },
    MASTERCARDCreditCardOr: {
      message: "Кредитная карта МАСТЕРКАРД; или же,"
    },
    AMERICANEXPRESSCreditCardOr: {
      message: "Кредитная карта АМЕРИКАН ЭКСПРЕСС; или же,"
    },
    DINNERSCLUBCreditCardOr: {
      message: "ОБЕДЫ КЛУБ Кредитная карта; или же,"
    },
    ELOCreditCardOr: {
      message: "Кредитная карта ELO или;"
    },
    DISCOVERCreditCard: {
      message: "ОТКРОЙТЕ для себя кредитную карту."
    },
    toProcessCreditCardPaymentsItWillBeNecessaryForThe: {
      message: "Для обработки платежей по кредитным картам необходимо, чтобы"
    },
    registerOn: {
      message: "зарегистрироваться на "
    },
    theFollowingDetailsOfTheChosenCreditCard: {
      message: "следующие реквизиты выбранной кредитной карты:"
    },
    NameOfTheCreditCardHolder: {
      message: "Имя держателя кредитной карты;"
    },
    CreditCardNumber: {
      message: "Номер кредитной карты;"
    },
    CreditCardFlag: {
      message: "Флаг кредитной карты;"
    },
    CreditCardExpirationMonthandYear: {
      message: "Срок действия кредитной карты (месяц и год);"
    },
    CreditCardsecuritynumber: {
      message: "Номер безопасности кредитной карты."
    },
    Paymentsforservicescontractedinthe: {
      message: "Платежи за услуги, заключенные в "
    },
    willBe: {
      message: " будет"
    },
    processedthroughtheGETNETplatform: {
      message: " обрабатывается через платформу GETNET"
    },
    beingnecessarythatall: {
      message: "необходимо, чтобы все "
    },
    alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform: {
      message: " также прочитать и принять условия использования и услуги платформы GETNET"
    },
    viaemailaddress: {
      message: "через адрес электронной почты: "
    },
    Whenhiringthe: {
      message: "При приеме на работу "
    },
    withpaymentbycreditcardorbankslipthe: {
      message: ", при оплате кредитной картой или банковским чеком,"
    },
    expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform: {
      message: "прямо указывает, что вы прочитали и приняли все условия, представленные в этом инструменте, а также в условиях использования и конкретных услуг платформы GETNET."
    },
    AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe: {
      message: "Любой спор о платежах, произведенных через платформу GETNET, должен решаться только между"
    },
    andGETNETnotfallingto: {
      message: " и GETNET, не попадая в "
    },
    anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform: {
      message: " любую ответственность за обработку платежей, сделанных на платформе GETNET."
    },
    TheGETNETplatform: {
      message: "Платформа GETNET"
    },
    maychargefeesforitsuseanditisonlyuptothe: {
      message: "может взимать плату за его использование, и это зависит только от "
    },
    informationaboutthevalueschargedbyGETNET: {
      message: " информация о значениях, взимаемых GETNET. "
    },
    isonlyresponsiblefordeletingthepaymentdataprovidedbythe: {
      message: " несет ответственность только за удаление платежных данных, предоставленных "
    },
    fromitsowndatabasedeclaringthe: {
      message: "из собственной базы данных, объявив"
    },
    beawarethatitisnotupto: {
      message: " знайте, что это не до "
    },
    promotetheexclusionofsaiddatafromtheGETNETdatabase: {
      message: " содействовать исключению указанных данных из базы данных GETNET."
    },
    PRIVACYANDDATAPROCESSINGPOLICY: {
      message: "10. ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ И ОБРАБОТКИ ДАННЫХ:"
    },
    Duringtheuseof: {
      message: "Во время использования "
    },
    willcollectandstoretheinformationprovidedbythe: {
      message: "будет собирать и хранить информацию, предоставленную"
    },
    underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof: {
      message: ", в соответствии с условиями этого документа вместе с автоматически сгенерированной информацией, такой как характеристики устройства доступа, браузера, журналы доступа к приложениям (IP, с указанием даты и времени), доступ к информации, доступ к экранам, данные геолокации, история приложений, среди другие, из"
    },
    whichwillbestoredinthedatabaseandalsointhebrowsercookie: {
      message: ", который будет храниться в базе данных, а также в файле cookie браузера."
    },
    Thedatacollectedfrom: {
      message: "Данные, собранные из "
    },
    throughtheuseof: {
      message: ", с помощью"
    },
    willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe: {
      message: ", будет использоваться для надлежащего предоставления услуг, с целью улучшения навигации по"
    },
    aswellasforadvertisingandstatisticalpurposes: {
      message: ", а также в рекламных и статистических целях."
    },
    declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato: {
      message: "заявляет, что понимает, принимает и соглашается с тем, что в случае отправки персональных данных"
    },
    mustdosounderthetermsofarticle5itemXofLaw13709of2018: {
      message: "должны сделать это в соответствии с пунктом X статьи 5 Закона № 13 709 от 2018 года."
    },
    Intheeventthatpersonaldataisprovidedbythe: {
      message: "В случае если персональные данные предоставляются "
    },
    willbeconsideredconfidentialbythe: {
      message: ", будет считаться конфиденциальной"
    },
    anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas: {
      message: ", и обязуется приложить все усилия для обеспечения безопасности своих систем при хранении таких данных в соответствии со стандартами безопасности, установленными в Указе № 8,771/2016, такими как:"
    },
    iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability: {
      message: "(i) Использование стандартных отраслевых методов шифрования собранных данных в дополнение к другим стандартным формам шифрования для обеспечения их неприкосновенности;"
    },
    iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments: {
      message: "(ii) использование высокотехнологичного программного обеспечения для защиты от несанкционированного доступа к системам, которые считаются контролируемой и защищенной средой;"
    },
    Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement: {
      message: "(iii) наличие контролируемого доступа к местам хранения персональных данных только для предварительно уполномоченных и аутентифицированных лиц, приверженных сохранению конфиденциальности таких данных, в том числе путем подписания соглашения о конфиденциальности;"
    },
    ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe: {
      message: "(iv) Применение механизмов аутентификации для доступа к записям, позволяющим индивидуализировать лицо, ответственное за обработку и доступ к данным, собранным в результате использования"
    },
    vAnonymizationofdatafromthe: {
      message: "(v) Анонимизация данных из"
    },
    whensharedwiththirdpartiesnotpartnerswith: {
      message: "при совместном использовании с третьими лицами, не являющимися партнерами"
    },
    viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016: {
      message: "(vi) Ведение реестра с указанием времени, продолжительности, личности сотрудника или лица, ответственного за доступ, и объектного файла на основе записей о подключении и доступе к приложению, как это определено в статье 13 Указа № 8,771/2016."
    },
    Ifdatacollectionfromthe: {
      message: "Если сбор данных из"
    },
    throughtheuse: {
      message: ", за счет использования"
    },
    thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow: {
      message: "они могут быть переданы третьим лицам только в случаях, перечисленных ниже:"
    },
    iFortheprotectionoftheinterestsof: {
      message: "(i) Для защиты интересов"
    },
    incaseofconflictincludinglegalclaims: {
      message: "в случае конфликта, включая судебные иски;"
    },
    iiIncaseoftransactionsandcorporatechangesinvolvingthe: {
      message: "(ii) В случае сделок и корпоративных изменений, затрагивающих"
    },
    inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe: {
      message: ", в этом случае передача данных будет необходима для непрерывности услуг, предлагаемых через"
    },
    iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest: {
      message: "(iii) По решению суда или по запросу административных органов, обладающих юридической компетенцией в отношении такого запроса."
    },
    guaranteesto: {
      message: "гарантии"
    },
    withregardtotheprocessingofpersonaldatathefollowingrights: {
      message: ", в отношении обработки персональных данных следующие права:"
    },
    iConfirmationoftheexistenceofprocessingofyourpersonaldata: {
      message: "(i) Подтверждение факта обработки ваших персональных данных;"
    },
    iiAccesstoyourdatacollectedby: {
      message: "(ii) Доступ к вашим данным, собранным"
    },
    throughyourownloginorbyrequestinghellomytripmenucom: {
      message: ", через собственный логин или по запросу hello@mytripmenu.com;"
    },
    iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate: {
      message: "(iii) Исправление ваших данных, если они являются неполными, неточными или устаревшими;"
    },
    ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation: {
      message: "(iv) Блокирование или удаление ненужных, чрезмерных или не соответствующих применимому бразильскому законодательству данных;"
    },
    vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe: {
      message: "(v) Переносимость персональных данных для вас или для третьей стороны по прямому запросу, сделанному"
    },
    viahellomytripmenucom: {
      message: ", через hello@mytripmenu.com;"
    },
    viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe: {
      message: "(vi) Удаление персональных данных, обрабатываемых с вашего согласия, при условии, что нет законного основания для их регистрации в"
    },
    viiObtaininginformationaboutpublicorprivateentitieswithwhichthe: {
      message: "(vii) Получение информации о государственных или частных организациях, с которыми"
    },
    sharedyourdataand: {
      message: "поделился своими данными; а также,"
    },
    viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe: {
      message: "(viii) Информация о возможности и последствиях непредоставления согласия"
    },
    youcansendemailto: {
      message: "вы можете отправить письмо на"
    },
    hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata: {
      message: "hello@mytripmenu.com, указывая на сомнения и/или требования, связанные с вашими личными данными."
    },
    maydeletethepersonaldatacollectedfrom: {
      message: "может удалить персональные данные, собранные с"
    },
    iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy: {
      message: "(i) Когда цель, для которой они были собраны, достигнута; или, когда они больше не нужны или не актуальны для цели, в соответствии с целями, описанными в настоящих Условиях использования и Политике конфиденциальности;"
    },
    iiWhenthe: {
      message: "(ii) Когда"
    },
    revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe: {
      message: "отозвать свое согласие, в случаях, когда это необходимо, запросив исключение из"
    },
    viahellomytripmenucomor: {
      message: "через hello@mytripmenu.com; или же,"
    },
    iiiIfdeterminedbycompetentauthority: {
      message: "(iii) Если это определено компетентным органом."
    },
    GENERALPROVISIONS: {
      message: "11. ОБЩИЕ ПОЛОЖЕНИЯ:"
    },
    AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent: {
      message: "Любой пункт или условие настоящего документа, которые по какой-либо причине будут признаны недействительными или недействительными любым судом или судом, не повлияют на действительность других положений настоящих Условий, которые останутся полностью действительными и обязательными, порождающими последствия для их самой полной степени."
    },
    Thefailureof: {
      message: "Провал"
    },
    inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines: {
      message: "требование каких-либо прав или положений настоящих Условий не будет означать отказ, и последние могут регулярно осуществлять свое право в установленные законом сроки."
    },
    Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe: {
      message: "Все материалы, патенты, товарные знаки, регистрации, домены, имена, привилегии, произведения, изображения и все смежные права, относящиеся к"
    },
    anddevelopedby: {
      message: "и разработан"
    },
    areandwillremainthesoleandexclusivepropertyof: {
      message: ", являются и останутся единоличной и исключительной собственностью"
    },
    agreeingwiththe: {
      message: ", соглашаясь с"
    },
    nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem: {
      message: "не совершать действий или фактов, которые каким-либо образом наносят ущерб правам, изложенным в настоящем документе, а также не требовать каких-либо прав или привилегий в отношении них."
    },
    maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreasonwestronglyrecommendthatyoualwaysvisitthissectionofourSiteandreaditperiodicallyButtocontributetothegoodrelationshipwewillalsosendanemailinformingyouaboutthesechanges: {
      message: "может изменить этот инструмент в любое время, просто опубликовав исправленную версию на нашем веб-сайте. По этой причине мы настоятельно рекомендуем вам всегда посещать этот раздел нашего Сайта и периодически его читать. Но, чтобы способствовать хорошим отношениям, мы также отправим вам электронное письмо с информацией об этих изменениях."
    },
    Thisinstrumentconstitutestheintegralunderstandingbetweenthe: {
      message: "Этот документ представляет собой целостное понимание между"
    },
    andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe: {
      message: "и регулируется бразильскими законами, при этом юрисдикция города Сан-Леопольдо/РС избирается в качестве единственного компетентного суда для решения вопросов, вытекающих из настоящего документа, с явным отказом от любой другой юрисдикции, какой бы привилегированной она ни была."
    },

    recoverPassword:{
      message: "ВОССТАНОВИТЬ ПАРОЛЬ"
    },
    receiveNewPassword:{
      message: "Вы получите новый пароль по электронной почте, не забудьте также проверить папку со спамом."
    },
    dontWorry:{
      message: "Не волнуйтесь, мы поможем вам вернуть его. Укажите адрес электронной почты для входа"
    },
    enterEmail:{
      message: "Введите адрес электронной почты"
    },
    notBeSent:{
      message: "Электронная почта не может быть отправлена, пожалуйста, попробуйте еще раз.."
    },
    pleasureToHaveYou:{
      message: "Очень приятно, что вы здесь, с нами!"
    },
    beforeStarting:{
      message: "Прежде чем начать включать свои продукты и увидеть, как ваше меню обретает форму, необходимо подписаться на план обслуживания в размере"
    },
    BRL99:{
      message: "99,90 бразильских реалов"
    },
    month:{
      message: "месяц"
    },
    includesFacilities:{
      message: "Он включает в себя такие объекты, как:"
    },
    menuWithPhotos:{
      message: "полное меню с фотографиями;"
    },
    priceUpdate:{
      message: "обновление цен в режиме реального времени;"
    },
    activateDeactivate:{
      message: "быстро активировать и деактивировать продукты;"
    },
    categorizeFilteReorder:{
      message: "классифицировать, фильтровать и переупорядочивать продукты;"
    },
    newFeatures:{
      message: "новые функции добавляются каждую неделю!"
    },
    thePlan:{
      message: "подписать план"
    },
    company:{
      message: "КОМПАНИЯ"
    },
    fantasyName:{
      message: "НАЗВАНИЕ ФАНТАЗИИ"
    },
    numberAccesses:{
      message: "КОЛИЧЕСТВО ПРОХОДОВ"
    },
    entryDate:{
      message: "ДАТА ПОСТУПЛЕНИЯ"
    },
    wantLeave:{
      message: "Теперь, когда вы зашли так далеко, вы уверены, что хотите уйти?"
    },
    cancelYourSubscription:{
      message: "Мы сожалеем, что вы хотите отменить подписку на план MyTripMenu."
    },
    beforeConfirming:{
      message: "Прежде чем подтвердить, я должен сообщить вам, что если вы уйдете, ваши клиенты больше не будут иметь доступа к меню вашего заведения, а доступ к URL-адресу и QRCode будет недоступен до тех пор, пока вы не подпишетесь на план повторно."
    },
    goAhead:{
      message: "Если вы в этом уверены, то вперед..."
    },
    unsubscribePlan:{
      message: "отписаться от плана"
    },
    dontCancel:{
      message: "Я больше не хочу отменять"
    },
    noChange:{
      message: "без изменений"
    },
    CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180: {
      message: "CAXA LABS SERVICOS DE DESENVOLVIMENTO DE SOFTWARE LTDA, компания с ограниченной ответственностью, регулярно зарегистрированная в CNPJ под № 34.177.633/0001-91, со штаб-квартирой по адресу Rua Felipe Dos Santos, nº 77, 1203 torre A, район Падре Реус, в городе Сан-Леопольдо/РС, CEP: 93.020-180."
    },
    Delivery: {
      message: "Доставка"
    },
    withdrawal: {
      message: "вывод"
    },
    Pickup: {
      message: "Забрать на месте"
    },
    Catalog: {
      message: "Каталог"
    },
    Enteremail: {
      message: "Введите адрес электронной почты"
    },
    emailinvalid: {
      message: "Этот адрес электронной почты недействителен"
    },
    Typepassword: {
      message: "Введите пароль"
    },
    least6digits: {
      message: "В этом поле должно быть не менее 6 цифр."
    },
     Cashpayment: {
      message: "Способ оплаты наличными нельзя изменить"
    },
    Checkpayment: {
      message: "Проверка способа оплаты не может быть изменена"
    },
    save: {
      message: "Сохранить"
    },
    logIn: {
      message: "АВТОРИЗОВАТЬСЯ"
    },
    logged: {
      message: "ВЫ ВОШЛИ В СИСТЕМУ"
    },
    enterAShortEasilyIdentifiableName: {
      message: "Введите короткое, легко узнаваемое имя"
    },
    highlightssubcategoryCannotBeCopied: {
      message: "Подкатегория Highlights не может быть скопирована"
    },
    aaaaaaaaaaaaaaaaaa: {
      message: "aaaaaaaaaaaaaaaaaaaaa"
    },
    unableToLoadCards : {
      message: "Не удалось загрузить карты"
    },
    invalidUser: {
      message: "недействительный пользователь"
    },
    invalidPassword: {
      message: "Неверный пароль"
    },
    inactiveUser: {
      message: "неактивный пользователь"
    }
  }
}
