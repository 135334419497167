import styled from 'styled-components';
import {
  Title24px400Regular,
  Paragraph16px300Light,
  Description12px400Regular,
} from '../../../styles/style-guide';

const ResetPasswordContent = styled.form`
  height: 100%;
  padding: 32px 24px;
  text-align: center;
`;

const ResetPasswordTitle = styled.h3`
  ${Title24px400Regular};
  color: ${({ theme }) => theme.colors.textColor};
  margin-bottom: 28px;
`;

const ResetPasswordParagraph = styled.p`
  ${Paragraph16px300Light};
  color: ${({ theme }) => theme.colors.textColor};
  margin-bottom: 24px;
`;

const ResetPasswordParagraphError = styled.p`
  ${Description12px400Regular};
  color: ${({ theme }) => theme.colors.statusColorDanger};
  display: ${({ showErrorOnSendEmail }) => (showErrorOnSendEmail ? 'block' : 'none')};
`;

const ResetPasswordEmailContent = styled.div`
  padding: 0 24px 20px 24px;
  min-height: 70px;
  text-align: initial;
`;

const ResetPasswordAdviceContent = styled.div`
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60px;
`;

const ResetPasswordFooterContent = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export {
  ResetPasswordContent,
  ResetPasswordTitle,
  ResetPasswordParagraph,
  ResetPasswordParagraphError,
  ResetPasswordEmailContent,
  ResetPasswordFooterContent,
  ResetPasswordAdviceContent,
};
