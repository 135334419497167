import styled from 'styled-components';
import { Description12px300Light, Description12px600Semibold } from '../../../../../../styles/style-guide';

const CreditCardFormContainerForm = styled.form`
  padding: 15px 30px;
`;

const CreditCardFormRow = styled.div`
  display: flex;
  margin: 12px 0;
  ${({ noMarginTop }) => noMarginTop && 'margin-top: 0;'};
`;

const CreditCardFormCol = styled.div`
  width: ${({ width }) => width || '100%'};
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
`;

const CreditCardFormFooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 35px;
  margin-top: 35px;
`;

const CreditCardFormSmallParagraph = styled.p`
  ${Description12px300Light}
  text-align: center;
  margin-bottom: 30px;
`;

const CreditCardFormLink = styled.a`
  ${Description12px600Semibold}
  color: ${({ theme }) => theme.colors.mainColor};
  cursor: pointer;
`;

const CreditCardFormLoaderContent = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
  align-items: center;
  justify-content: center;
  margin-top: 42px;
`;

export {
  CreditCardFormContainerForm,
  CreditCardFormRow,
  CreditCardFormCol,
  CreditCardFormFooterContent,
  CreditCardFormSmallParagraph,
  CreditCardFormLink,
  CreditCardFormLoaderContent,
};
