import HttpClient from "../http-client";
import { id } from "../helper-client";

const putDeliverySettings = async (deliverySettingsId, data) => {
  return HttpClient.put(
    `deliverysettings/${deliverySettingsId}?userId=${id ?? ""}`,
    data
  );
};

const fetchDeliverySettings = async () =>
  HttpClient.get(`deliverysettings?userId=${id ?? ""}`);

export default {
  putDeliverySettings,
  fetchDeliverySettings,
};
