import HttpClient from "../http-client";
import { id } from "../helper-client";

const postProduct = async (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "file") {
      data.file.forEach((file) => formData.append(key, file, file.name));
      return;
    }
    formData.append(key, data[key]);
  });

  return HttpClient.post(`products?userId=${id ?? ""}`, formData, {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  });
};

const putProduct = async (prodId, data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "file") {
      data.file.forEach((file) => formData.append(key, file, file.name));
      return;
    }
    formData.append(key, data[key]);
  });

  return HttpClient.put(`products/${prodId}?userId=${id ?? ""}`, formData, {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  });
};
const putProductImg = async (prodId, data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));

  return HttpClient.put(
    `products/images/${prodId}?userId=${id ?? ""}`,
    formData,
    {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    }
  );
};
const delProductImg = async (prodId, data) => {
  return HttpClient.delete(
    `products/images/${prodId}?userId=${id ?? ""}`,
    data
  );
};

const fetchProducts = async () => HttpClient.get(`products?userId=${id ?? ""}`);

const fetchProductsById = async (productId) =>
  HttpClient.get(`products/${productId}?userId=${id ?? ""}`);

const fetchProductsBySubCategory = async (id) => {
  const formData = new FormData();
  formData.append("subcategories", [id]);

  return HttpClient.post(`subcategories/products?userId=${id ?? ""}`, {
    subcategories: id,
  });
};

const putProductOrder = async (data) => {
  return HttpClient.put(`orderProduct?userId=${id ?? ""}`, data);
};

const putProductHighLight = async (highId) => {
  return HttpClient.put(
    `products/${highId}/highlightSubcategory?userId=${id ?? ""}`
  );
};
export default {
  postProduct,
  putProduct,
  putProductOrder,
  fetchProducts,
  fetchProductsBySubCategory,
  fetchProductsById,
  putProductHighLight,
  putProductImg,
  delProductImg,
};
