import styled from 'styled-components';
import { Hint9px400Regular } from '../../../styles/style-guide';

const Footer = styled.footer`
  position: relative;
  bottom: 0;
  height: fit-content;
  padding: 48px 0px 24px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.mainColorContrast};
`;

const FooterParagraph = styled.p`
  margin-top: 14px;
  ${() => Hint9px400Regular};
  color: ${({ theme }) => theme.colors.textColorLight};

`;

export {
  FooterParagraph,
  Footer,
};
