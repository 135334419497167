import styled from "styled-components";

export const Container = styled.div`
  padding: 32px 0 0px 16px;
  width: 100%;
  height: 95%;
  position: relative;
  overflow: scroll;
`;

export const ContainerFilter = styled.div`
  width: 30%;
`;

export const ContainerTable = styled.div`
  height: 87.5%;
  overflow: scroll;
`;

export const ContainerFooter = styled.div`
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 8%;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 8%);
`;
