import React, { useState, useEffect, useMemo } from "react";
import MuiAutoComplete from "@material-ui/lab/Autocomplete";
import { MuiTextFieldStyled } from "../../../../../../../components/inputs/mui-inputs/Styles";
import PropTypes from "prop-types";
import ModalMytm from "../../../../../../../components/modal-mytm/ModalMytm";
import {
  ModalWrapper,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "../../../../../../../components/modal-mytm/Styles";
import {
  ComplementGroupLoaderFooter,
  ComplementGroupLoaderForm,
  ComplementGroupLoaderButtonSave,
  ComplementGroupLoaderButtonGoBack,
  ComplementGroupLoaderGrid,
  ComplementGroupLoaderCell,
  ComplementGroupWithToggle,
  ComplementGroupFilterBox,
} from "./Styles";
import formUtils from "../../../../../../../utils/form-utils";
import Checkbox from "../../../../../../../components/checkbox/Checkbox";
import restComplementGroup from "../../../../../../../api/complementgroup/rest-complement-group";
import TranslatorFunction from '../../../../../../../components/I18n/Translator';

const ComplementGroupLoader = (props) => {
  const back = TranslatorFunction({ path: 'back.message' });
  const saveEditions = TranslatorFunction({ path: 'saveEditions.message' });
  const search = TranslatorFunction({ path: 'search.message' });
  const noOptions = TranslatorFunction({ path: 'noOptions.message' });
  const language = TranslatorFunction({ path: 'language.message' });
  const linkGroup = TranslatorFunction({ path: 'linkGroup.message' });
  const { open, onClose, data, setData } = props;
  const [form, setForm] = useState([]);
  const [openModal, setOpenModal] = useState(open);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [searchs, setSearchs] = useState({
    name: "",
  });

  const handleOnClickSave = () => {
    const newData = form.filter((group) => group.active);
    setData(newData);
    setOpenModal(false);
    onClose(true, newData);
  };

  const handleOnClickGoBack = () => {
    setOpenModal(false);
    onClose(false, data);
  };

  const handleOnChangeCheckBox = (event) => {
    const { name, value } = event;
    let newForm = form;

    newForm.map((group) => (group.name === name ? (group.active = value) : ""));
    setRefresh(!refresh);
    setForm(newForm);
  };

  const disableSubmitButton = () => !formUtils.isFormValid(form);

  const handleResponseCompGroupSuccess = (response) => {
    if (open === false) {
      return;
    }
    let newForm = [];

    newForm = response
      .filter((group) => group.active === true)
      .map(
        (group) =>
          (group = {
            ...group,
            showComp: false,
            min: 0,
            max: 0,
            complements: [],
            index: 0,
            complementGroupId: group._id,
          })
      );
    newForm.map((group) => (group.active = false));
    newForm.map((group) => (group = { ...group, showComp: false }));

    data.forEach((element) => {
      let complementIndex = newForm.findIndex((cat) => cat._id === element._id);
      newForm[complementIndex].active = true;
    });

    setForm(newForm);
    setIsLoading(false);
    setOpenModal(open);
  };

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      restComplementGroup
        .fetchComplementGroup()
        .then((res) => {
          handleResponseCompGroupSuccess(res.data);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

    // eslint-disable-next-line
  }, [open]);

  const renderComplementGroups = (dataObj) => {
    dataObj.map((umaData, index) => {
      return (
        <ComplementGroupLoaderCell>{umaData?.name}</ComplementGroupLoaderCell>
      );
    });
  };

  const filterComplementsByName = (form) => {
    if (!searchs.name) return true;
    return form.name
      .toLocaleLowerCase()
      .includes(searchs.name.toLocaleLowerCase());
  };

  const filteredComplements = useMemo(
    () => form?.filter(filterComplementsByName),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchs, form]
  );

  return (
    <ModalMytm open={openModal} isLoading={isLoading}>
      <ModalWrapper>
        <ModalTitle cColor={"#777"} cAlign={"center"} bold>
          {linkGroup}
        </ModalTitle>
        <ModalBody>
          <ComplementGroupLoaderForm
            noValidate
            onSubmit={(e) => e.preventDefault()}
          >
            <ComplementGroupFilterBox key={"search-popup"}>
              <MuiAutoComplete
                id="search"
                options={data}
                noOptionsText={noOptions}
                getOptionLabel={(option) => option?.name || ""}
                renderInput={(params) => (
                  <MuiTextFieldStyled
                    {...params}
                    margin="dense"
                    label={search}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
                onChange={(e) => {
                  setSearchs({ ...searchs, name: e.target.value });
                }}
                onSelect={(e) => {
                  setSearchs({ ...searchs, name: e.target.value });
                }}
              />
            </ComplementGroupFilterBox>
            <ComplementGroupLoaderGrid>
              {filteredComplements?.map((itens, index) => (
                <ComplementGroupWithToggle key={index}>
                  <Checkbox                  
                    key={itens.complementGroupId}
                    name={itens.name}
                    value={itens.active}
                    onChange={handleOnChangeCheckBox}
                  >
                    {Array.isArray(itens.translatedComplementGroup) && itens.translatedComplementGroup.length > 0 &&  itens.translatedComplementGroup[0].name[0][language] !== undefined ? itens.translatedComplementGroup[0].name[0][language] : itens.name}
                  </Checkbox>
                </ComplementGroupWithToggle>
              ))}

              {renderComplementGroups(data)}
            </ComplementGroupLoaderGrid>
            <ComplementGroupLoaderFooter></ComplementGroupLoaderFooter>
          </ComplementGroupLoaderForm>
        </ModalBody>
        <ModalFooter>
          <ComplementGroupLoaderButtonSave
            type="button"
            disabled={disableSubmitButton()}
            onClick={handleOnClickSave}
          >
            {saveEditions}
          </ComplementGroupLoaderButtonSave>
          <ComplementGroupLoaderButtonGoBack
            type="button"
            btnType="tertiary"
            onClick={handleOnClickGoBack}
          >
            {back}
          </ComplementGroupLoaderButtonGoBack>
        </ModalFooter>
      </ModalWrapper>
    </ModalMytm>
  );
};
ComplementGroupLoader.defaultProps = {
  subCategory: {},
  open: false,
  onClose: () => {},
};

ComplementGroupLoader.propTypes = {
  subCategory: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ComplementGroupLoader;
