import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import restUser from "../../../../../api/user/rest-user";
import Card from "../../../../../components/styled/Card";
import ModalGenericConfirmation from "../../../../../components/modal-generic-confirmation/ModalGenericConfirmation";
import {
  DetailsFormWrapper,
  DetailsFormContent,
  DetailsFormHeader,
  DetailsFormLeftLabel,
  DetailsFormRightLabel,
  DetailsFormIconMandatory,
  DetailsFormRow,
  DetailsFormColumn,
  DetailsFormButtonsContent,
  DetailsFormAccessDataContent,
  DetailsFormUserContent,
} from "./Styles";
import InputText from "../../../../../components/inputs/input-text/InputText";
import Button from "../../../../../components/button/Button";
import {
  CepOptions,
  CnpjOptions,
  NumericOptions,
  PhoneNumberOptions,
} from "../../../../../utils/input-options";
import detailsFormUtils from "../../../../../utils/details-form-utils";
import formUtils from "../../../../../utils/form-utils";
import { useBlockLoadingContext } from "../../../../../contexts/BlockLoaderContext";
import detailsForm from "./details-form";
import { useAPIErrorContext } from "../../../../../contexts/APIErrorContext";
import apiErrorHandler from "../../../../../utils/api-error-handler";
import { mask, unMask } from "remask";
import { unMask2, mask2 } from "../../../../../utils/masks";

import { Translator } from '../../../../../components/I18n';
import TranslatorFunction from '../../../../../components/I18n/Translator';
const defaultDetailsForm = { ...detailsForm };

const DetailsForm = () => {
  const history = useHistory();
  const modalError = useAPIErrorContext();
  const { setIsLoading } = useBlockLoadingContext();
  const [form, setForm] = useState(defaultDetailsForm);
  const [openModalOnLeave, setOpenModalOnLeave] = useState(false);
  const [isBlockingExit, setIsBlockingExit] = useState(false);
  const [lastPathname, setLastPathname] = useState("");
  const [isDisableButton, setIsDisableButton] = useState(true);

  const unblockHandle = useRef();

  const saveEditionsAndExit = TranslatorFunction({ path: "saveEditionsAndExit.message" });
  const exitWithoutSaving = TranslatorFunction({ path: "exitWithoutSaving.message" });
  const back = TranslatorFunction({ path: "back.message" });
  const aboutTheEstablishment = TranslatorFunction({ path: "aboutTheEstablishment.message" });
  const areYouSureYouWantToDoThis = TranslatorFunction({ path: "areYouSureYouWantToDoThis.message" });
  const ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn = TranslatorFunction({ path: "ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn.message" });
  const saveEditions = TranslatorFunction({ path: "saveEditions.message" });
  const mandatoryFilling = TranslatorFunction({ path: "mandatoryFilling.message" });
  const pixel = TranslatorFunction({ path: "pixel.message" });
  const accessData = TranslatorFunction({ path: "accessData.message" });
  const responsibleEstablishment = TranslatorFunction({ path: "responsibleEstablishment.message" });
  const couldNotLoadUserData = TranslatorFunction({ path: "couldNotLoadUserData.message" });

  const handleGetUserResponseSuccess = (response) => {
    setIsLoading(false);
    const userData = response.data;
    const newForm = detailsFormUtils.getFormFilledFromUser(
      defaultDetailsForm,
      userData
    );

    newForm.operation = newForm.operation.map((op) => op.id);
    newForm.subDomain.value = mask2(
      newForm.subDomain.value,
      `${process.env.REACT_APP_URL}############################`
    );

    // user.setDomainAndUser(userData);

    setForm(newForm);
  };

  const onClickGoBack = () => {
    modalError.setOpen(false);
    history.push("/home/cadastro-itens?tab=categories");
  };

  const tryAgainGetUser = () => {
    setIsLoading(true);
    modalError.setOpen(false);
    restUser
      .getUser()
      .then(handleGetUserResponseSuccess)
      .catch((err) => {
        setIsLoading(false);
        modalError.setMessage(couldNotLoadUserData);
        modalError.setError(apiErrorHandler.handleError(err));
        modalError.setOnClickTryAgainFunc(() => tryAgainGetUser());
        modalError.setOnClickGoBackFunc(() => onClickGoBack());
        modalError.setOpen(true);
      });
  };

  const handleGetUserResponseError = (err) => {
    setIsLoading(false);
    modalError.setMessage(couldNotLoadUserData);
    modalError.setError(apiErrorHandler.handleError(err));
    modalError.setOnClickTryAgainFunc(() => tryAgainGetUser());
    modalError.setOnClickGoBackFunc(() => onClickGoBack());
    modalError.setOpen(true);
  };

  const getUser = () => {
    setIsLoading(true);
    restUser
      .getUser()
      .then(handleGetUserResponseSuccess)
      .catch(handleGetUserResponseError);
  };

  const handleResponseSucess = () => {
    setIsBlockingExit(false);
    setIsDisableButton(true);
    unblockHandle.current();
    history.go(0);
    setIsLoading(false);

    // raw.CNPJ = detailsFormUtils.removeCNPJMask(raw.CNPJ);
    // raw.CEP = detailsFormUtils.removeCEPMask(raw.CEP);
    // raw.subDomain = unMask2(raw.subDomain, `${process.env.REACT_APP_URL}`);
    // raw.active = user.getStatus();
  };

  const handleResponseError = () => {
    setIsLoading(false);
  };

  const putUserData = () => {
    setIsLoading(true);
    const rawForm = formUtils.getFormRawValue(form);
    rawForm.CNPJ = detailsFormUtils.removeCNPJMask(rawForm.CNPJ);
    rawForm.CEP = detailsFormUtils.removeCEPMask(rawForm.CEP);
    if (rawForm.password !== rawForm.passwordConfirm) {
      rawForm.password = "";
    }

    rawForm.subDomain = unMask2(
      rawForm.subDomain,
      `${process.env.REACT_APP_URL}`
    );
    restUser
      .putUsers(rawForm)
      .then(handleResponseSucess)
      .catch(handleResponseError);
  };

  const handleOnMaskChange = (name, value) => {
    setIsBlockingExit(true);
    let maskera;

    switch (name) {
      case "CNPJ":
        maskera = ["99.999.999/9999-99"];
        break;
      case "CEP":
        maskera = ["99999-999"];
        break;
      case "userPhone":
        maskera = ["(99) 99999 9999"];
        break;
      default:
        maskera = ["99999999"];
        break;
    }

    setForm((oldForm) => {
      let newForm = { ...oldForm };
      newForm[name].value = unMask(value, maskera);

      newForm = formUtils.updateFieldValidationWithValidators(newForm, name);
      if (newForm.isValid) {
        setIsDisableButton(false);
      } else {
        setIsDisableButton(true);
      }
      return newForm;
    });
  };

  const handleOnChange = (name, value) => {
    setIsBlockingExit(true);
    setIsDisableButton(false);

    setForm((oldForm) => {
      let newForm = { ...oldForm };
      newForm[name].value =
        name === "subDomain"
          ? mask2(
            unMask2(value, `${process.env.REACT_APP_URL}`),
            `${process.env.REACT_APP_URL}############################`
          )
          : value;

      newForm = formUtils.updateFieldValidationWithValidators(newForm, name);

      if (newForm.isValid) {
        setIsDisableButton(false);
      }

      return newForm;
    });
  };

  const handleOnBlur = (event) => {
    const { name } = event.target;
    setForm((oldForm) => {
      const newForm = { ...oldForm };
      newForm[name].touched = true;
      return newForm;
    });
  };

  const handleSaveAndOut = async () => {
    setOpenModalOnLeave(false);
    setIsBlockingExit(false);
    await putUserData();
    unblockHandle.current();
    history.push(lastPathname);
  };

  const handleOutWithoutSave = async () => {
    setOpenModalOnLeave(false);
    setIsBlockingExit(false);
    unblockHandle.current();
    history.push(lastPathname);
  };

  const handleClose = () => {
    setOpenModalOnLeave(false);
  };

  const fieldHasErrors = (field) => {
    const keys = Object.keys(field.hasErrors);
    return keys.filter((key) => field.hasErrors[key] === true).length > 0;
  };

  const handleErrorShow = (field) => fieldHasErrors(field) && field.touched;

  const getErrorMessage = (field) => {
    const keys = Object.keys(field.hasErrors);
    const errorsKeys = keys.filter((key) => field.hasErrors[key] === true);
    const errorsMessages = errorsKeys.map((key) => field.errorsMsg[key]);
    return errorsMessages || "";
  };

  const RenderModalOnLeave = () => (
    <ModalGenericConfirmation
      open={openModalOnLeave}
      title={areYouSureYouWantToDoThis}
      message={ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn}
      onClickBtnOne={handleSaveAndOut}
      onClickBtnTwo={handleOutWithoutSave}
      onClickBtnThree={handleClose}
      btnOneText={saveEditionsAndExit}
      btnTwoText={exitWithoutSaving}
      btnThreeText={back}
    />
  );

  useEffect(() => {
    if (isBlockingExit && unblockHandle.current !== null) {
      unblockHandle.current = history.block((location) => {
        const { pathname } = location;
        if (!pathname.includes("/meu-estabelecimento")) {
          setLastPathname(pathname);
          setOpenModalOnLeave(isBlockingExit);
          return false;
        }
        return true;
      });
    }
  }, [isBlockingExit, history]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  return (
    <DetailsFormWrapper>
      <Card>
        <div>
          <DetailsFormHeader>
            <DetailsFormLeftLabel>{aboutTheEstablishment}</DetailsFormLeftLabel>
            <DetailsFormRightLabel>
              <DetailsFormIconMandatory />
              {mandatoryFilling}
            </DetailsFormRightLabel>
          </DetailsFormHeader>
          <DetailsFormContent>
            <DetailsFormRow>
              <DetailsFormColumn>
                <InputText
                  label={Translator({ path: form.fantasyName.label })}
                  isRequired={form.fantasyName.isRequired}
                  placeholder={form.fantasyName.placeholder}
                  value={form.fantasyName.value}
                  error={handleErrorShow(form.fantasyName)}
                  errorMessage={getErrorMessage(form.fantasyName)[0] || ""}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  name="fantasyName"
                  dataTestId="fantasyName"
                  maxLength={form.fantasyName.maxLength}
                />
              </DetailsFormColumn>
            </DetailsFormRow>
            <DetailsFormRow>
              <DetailsFormColumn widthPercentual="70" marginRight="23">
                <InputText
                  label={Translator({ path: form.companyName.label })}
                  isRequired={form.companyName.isRequired}
                  placeholder={form.companyName.placeholder}
                  value={form.companyName.value}
                  error={handleErrorShow(form.companyName)}
                  errorMessage={getErrorMessage(form.companyName)[0] || ""}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  name="companyName"
                  dataTestId="companyName"
                />
              </DetailsFormColumn>
              <DetailsFormColumn widthPercentual="30">
                <InputText
                  options={CnpjOptions}
                  label={Translator({ path: form.CNPJ.label })}
                  isRequired={form.CNPJ.isRequired}
                  placeholder={form.CNPJ.placeholder}
                  value={mask(form.CNPJ.value, ["99.999.999/9999-99"])}
                  error={handleErrorShow(form.CNPJ)}
                  errorMessage={getErrorMessage(form.CNPJ)[0] || ""}
                  onChange={handleOnMaskChange}
                  onBlur={handleOnBlur}
                  name="CNPJ"
                  dataTestId="CNPJ"
                  maxLength="18"
                  maxLengthShow={false}
                />
              </DetailsFormColumn>
            </DetailsFormRow>
            <DetailsFormRow>
              <DetailsFormColumn widthPercentual="25" marginRight="23">
                <InputText
                  label={Translator({ path: form.CEP.label })}
                  isRequired={form.CEP.isRequired}
                  placeholder={form.CEP.placeholder}
                  value={mask(form.CEP.value, ["99999-999"])}
                  error={handleErrorShow(form.CEP)}
                  errorMessage={getErrorMessage(form.CEP)[0] || ""}
                  onChange={handleOnMaskChange}
                  onBlur={handleOnBlur}
                  options={CepOptions}
                  name="CEP"
                  dataTestId="CEP"
                  maxLength="9"
                  maxLengthShow={false}
                />
              </DetailsFormColumn>
              <DetailsFormColumn widthPercentual="37.5" marginRight="23">
                <InputText
                  label={Translator({ path: form.state.label })}
                  isRequired={form.state.isRequired}
                  placeholder={form.state.placeholder}
                  value={form.state.value}
                  error={handleErrorShow(form.state)}
                  errorMessage={getErrorMessage(form.state)[0] || ""}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  name="state"
                  dataTestId="state"
                />
              </DetailsFormColumn>
              <DetailsFormColumn widthPercentual="37.5">
                <InputText
                  label={Translator({ path: form.city.label })}
                  isRequired={form.city.isRequired}
                  placeholder={form.city.placeholder}
                  error={handleErrorShow(form.city)}
                  errorMessage={getErrorMessage(form.city)[0] || ""}
                  value={form.city.value}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  name="city"
                  dataTestId="city"
                />
              </DetailsFormColumn>
            </DetailsFormRow>
            <DetailsFormRow>
              <DetailsFormColumn widthPercentual="51" marginRight="23">
                <InputText
                  label={Translator({ path: form.street.label })}
                  isRequired={form.street.isRequired}
                  placeholder={form.street.placeholder}
                  value={form.street.value}
                  error={handleErrorShow(form.street)}
                  errorMessage={getErrorMessage(form.street)[0] || ""}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  name="street"
                  dataTestId="street"
                />
              </DetailsFormColumn>
              <DetailsFormColumn widthPercentual="24.5" marginRight="23">
                <InputText
                  label={Translator({ path: form.addressNumber.label })}
                  isRequired={form.addressNumber.isRequired}
                  placeholder={Translator({ path: form.addressNumber.placeholder })}
                  value={mask(form.addressNumber.value, ["999999999"])}
                  error={handleErrorShow(form.addressNumber)}
                  errorMessage={getErrorMessage(form.addressNumber)[0] || ""}
                  onChange={handleOnMaskChange}
                  onBlur={handleOnBlur}
                  name="addressNumber"
                  dataTestId="addressNumber"
                  options={NumericOptions}
                  maxLength="9"
                  maxLengthShow={false}
                />
              </DetailsFormColumn>
              <DetailsFormColumn widthPercentual="24.5">
                <InputText
                  label={Translator({ path: form.addressComplement.label })}
                  isRequired={form.addressComplement.isRequired}
                  placeholder={form.addressComplement.placeholder}
                  value={form.addressComplement.value}
                  error={handleErrorShow(form.addressComplement)}
                  errorMessage={
                    getErrorMessage(form.addressComplement)[0] || ""
                  }
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  name="addressComplement"
                  dataTestId="addressComplement"
                />
              </DetailsFormColumn>
            </DetailsFormRow>
          </DetailsFormContent>
        </div>

        <div>
          <DetailsFormHeader>
            <DetailsFormLeftLabel>{pixel}</DetailsFormLeftLabel>
          </DetailsFormHeader>
          <InputText
            label={Translator({ path: form.facebookPixel.label })}
            isRequired={form.facebookPixel.isRequired}
            placeholder={form.facebookPixel.placeholder}
            value={form.facebookPixel.value}
            error={handleErrorShow(form.facebookPixel)}
            errorMessage={getErrorMessage(form.facebookPixel)[0] || ""}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            name="facebookPixel"
            dataTestId="facebookPixel"
          />
        </div>
        <DetailsFormAccessDataContent>
          <DetailsFormLeftLabel>{accessData}</DetailsFormLeftLabel>
          <DetailsFormColumn widthPercentual="100" marginRight="23">
            <DetailsFormRow>
              <DetailsFormColumn>
                <InputText
                  label={Translator({ path: form.subDomain.label })}
                  isRequired={form.subDomain.isRequired}
                  placeholder={form.subDomain.placeholder}
                  value={form.subDomain.value}
                  error={handleErrorShow(form.subDomain)}
                  errorMessage={getErrorMessage(form.subDomain)[0] || ""}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  name="subDomain"
                  dataTestId="subDomain"
                />
              </DetailsFormColumn>
            </DetailsFormRow>
            <DetailsFormRow>
              <DetailsFormColumn widthPercentual="45" marginRight="23">
                <InputText
                  label={Translator({ path: form.companyEmail.label })}
                  isRequired={form.companyEmail.isRequired}
                  placeholder={form.companyEmail.placeholder}
                  value={form.companyEmail.value}
                  error={handleErrorShow(form.companyEmail)}
                  errorMessage={getErrorMessage(form.companyEmail)[0] || ""}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  name="companyEmail"
                  dataTestId="companyEmail"
                  readonly
                />
              </DetailsFormColumn>
              <DetailsFormColumn widthPercentual="20" marginRight="23">
                <InputText
                  inputType="password"
                  label={Translator({ path: form.password.label })}
                  isRequired={form.password.isRequired}
                  placeholder={form.password.placeholder}
                  value={form.password.value}
                  error={handleErrorShow(form.password)}
                  errorMessage={getErrorMessage(form.password)[0] || ""}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  name="password"
                  dataTestId="password"
                />
              </DetailsFormColumn>
              <DetailsFormColumn widthPercentual="30">
                <InputText
                  inputType="password"
                  label={Translator({ path: form.passwordConfirm.label })}
                  isRequired={form.passwordConfirm.isRequired}
                  placeholder={form.passwordConfirm.placeholder}
                  value={form.passwordConfirm.value}
                  error={handleErrorShow(form.passwordConfirm)}
                  errorMessage={getErrorMessage(form.passwordConfirm)[0] || ""}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  name="passwordConfirm"
                  dataTestId="passwordConfirm"
                />
              </DetailsFormColumn>
            </DetailsFormRow>
          </DetailsFormColumn>
        </DetailsFormAccessDataContent>
        <DetailsFormUserContent>
          <DetailsFormLeftLabel>
            {responsibleEstablishment}
          </DetailsFormLeftLabel>
          <DetailsFormRow>
            <DetailsFormColumn widthPercentual="25" marginRight="33">
              <InputText
                label={Translator({ path: form.userFirstName.label })}
                isRequired={form.userFirstName.isRequired}
                placeholder={form.userFirstName.placeholder}
                value={form.userFirstName.value}
                error={handleErrorShow(form.userFirstName)}
                errorMessage={getErrorMessage(form.userFirstName)[0] || ""}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                name="userFirstName"
                dataTestId="userFirstName"
              />
            </DetailsFormColumn>
            <DetailsFormColumn widthPercentual="25" marginRight="33">
              <InputText
                label={Translator({ path: form.userLastName.label })}
                isRequired={form.userLastName.isRequired}
                placeholder={form.userLastName.placeholder}
                value={form.userLastName.value}
                error={handleErrorShow(form.userLastName)}
                errorMessage={getErrorMessage(form.userLastName)[0] || ""}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                name="userLastName"
                dataTestId="userLastName"
              />
            </DetailsFormColumn>
            <DetailsFormColumn widthPercentual="25" marginRight="33">
              <InputText
                label={Translator({ path: form.userEmail.label })}
                isRequired={form.userEmail.isRequired}
                placeholder={form.userEmail.placeholder}
                value={form.userEmail.value}
                error={handleErrorShow(form.userEmail)}
                errorMessage={getErrorMessage(form.userEmail)[0] || ""}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                name="userEmail"
                dataTestId="userEmail"
              />
            </DetailsFormColumn>
            <DetailsFormColumn widthPercentual="25">
              <InputText
                label={Translator({ path: form.userPhone.label })}
                isRequired={form.userPhone.isRequired}
                placeholder={form.userPhone.placeholder}
                value={mask(form.userPhone.value, ["(99) 99999 9999"])}
                error={handleErrorShow(form.userPhone)}
                errorMessage={getErrorMessage(form.userPhone)[0] || ""}
                onChange={handleOnMaskChange}
                onBlur={handleOnBlur}
                options={PhoneNumberOptions}
                name="userPhone"
                dataTestId="userPhone"
                maxLength="15"
                maxLengthShow={false}
              />
            </DetailsFormColumn>
          </DetailsFormRow>
        </DetailsFormUserContent>
      </Card>
      <DetailsFormButtonsContent>
        <Button disabled={isDisableButton} onClick={putUserData}>
          {saveEditions}
        </Button>
      </DetailsFormButtonsContent>
      {RenderModalOnLeave()}
    </DetailsFormWrapper>
  );
};

export default DetailsForm;
