import styled from "styled-components";

export const Container = styled.div`
  bottom: 0;
  position: fixed;
  right: 0;
  width: calc(100% - 275px);
  height: 8%;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 8%);
  background-color: #fff;
`;

export const CountResults = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 22px;
  color: #707070;
  font-weight: 400;
`;

export const ContainerInside = styled.div`
  width: 95%;
  height: 100%;

  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerPaginate = styled.div`
  float: right;
  .pagination {
    display: flex;
  }
  li {
    margin-right: 6px;
    cursor: pointer;
    border: 1px solid #ff876f;
    border-radius: 6px;
    color: #ff876f;
    a {
      padding: 4px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  li:hover,
  .active {
    color: #fff;
    background-color: #ff876f;
    transition: 0.2s;
  }

  .previous,
  .next {
    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
