import styled from 'styled-components';
import ModalMytm from '../modal-mytm/ModalMytm';

const ModalGenericConfirmationWrapper = styled(ModalMytm)`
  .modal_mytm {
    width: 736px;
    height: 376px;
    padding: 24px;
  }
`;

const ModalGenericConfirmationContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ModalGenericConfirmationContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const ModalGenericConfirmationDataContent = styled.div`
  width: 50%;
`;

const ModalGenericConfirmationImageContent = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalGenericConfirmationButtonsContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ModalGenericConfirmationButtonLeftContent = styled.div`
  display: flex;

  & :first-child {
    margin-right: 16px;
  }
`;

export {
  ModalGenericConfirmationWrapper,
  ModalGenericConfirmationContent,
  ModalGenericConfirmationContainer,
  ModalGenericConfirmationDataContent,
  ModalGenericConfirmationImageContent,
  ModalGenericConfirmationButtonsContent,
  ModalGenericConfirmationButtonLeftContent,
};
