import styled from "styled-components";
import { ReactComponent as ButtonAddIconSVG } from "../../assets/icons/icon-plus-square-fill.svg";

const ButtonAddIcon = styled(ButtonAddIconSVG)`
  align-items: center;
  display: flex;
  width: 28px;
  height: 28px;
  margin: 0px 0px 0px 25px;
`;

const Button = styled.div`
  cursor: pointer;
`;

export { ButtonAddIcon, Button };
