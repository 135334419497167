import React, { useState } from "react";
import Button from "../../../../components/button/Button";
import { ModalTitle } from "../../../../components/modal-mytm/Styles";
import onboarding from "../../../../utils/onboarding";
import user from "../../../../utils/user";
import { Translator } from '../../../../components/I18n';
import {
  ModalMytmOnboarding,
  ModalOnboardingContainer,
  ModalOnboardingContent,
  ModalOnboardingLeftContent,
  ModalOnboardingOpenStoreImage,
  ModalOnboardingRightContent,
  ModalOnboardingFooter,
  ModalOnboardingParagraph,
  ModalOnboardingColor,
} from "./Styles";

const ModalOnboarding = () => {
  const isFirstLogin = user.getIsFirstLogin();
  const [isOpen, setIsOpen] = useState(
    isFirstLogin && onboarding.canOpenOnboardingModal()
  );

  const handleOnClickFinishSignup = () => {
    onboarding.setAlreadyOnbording(true);
    setIsOpen(false);
  };

  return (
    <ModalMytmOnboarding open={isOpen}>
      <ModalOnboardingContainer>
        <ModalOnboardingContent>
          <ModalOnboardingLeftContent>
            <ModalTitle><Translator path="pleasureToHaveYou.message" /></ModalTitle>
            <ModalOnboardingParagraph>
              <Translator path="beforeStarting.message" />
              <b> <Translator path="BRL99.message" /> </b>
              <Translator path="month.message" />
              <br />
              <br />
              <Translator path="includesFacilities.message" />
              <br />
              <br />
              <ModalOnboardingColor>✓</ModalOnboardingColor>
              <Translator path="menuWithPhotos.message" />
              <br />
              <ModalOnboardingColor>✓</ModalOnboardingColor>
              <Translator path="priceUpdate.message" />
              <br />
              <ModalOnboardingColor>✓</ModalOnboardingColor>
              <Translator path="activateDeactivate.message" />
              <br />
              <ModalOnboardingColor>✓</ModalOnboardingColor>
              <Translator path="categorizeFilteReorder.message" />
              <br />
              <ModalOnboardingColor>✓</ModalOnboardingColor>
              <Translator path="newFeatures.message" />
              <br />
            </ModalOnboardingParagraph>
          </ModalOnboardingLeftContent>
          <ModalOnboardingRightContent>
            <ModalOnboardingOpenStoreImage />
          </ModalOnboardingRightContent>
        </ModalOnboardingContent>
        <ModalOnboardingFooter>
          <Button btnType="primary" onClick={handleOnClickFinishSignup}>
          <Translator path="thePlan.message" />
          </Button>
        </ModalOnboardingFooter>
      </ModalOnboardingContainer>
    </ModalMytmOnboarding>
  );
};

export default ModalOnboarding;
