import styled from "styled-components";

import Button from "../../../../components/button/Button";

export const Container = styled.div``;

export const ModalContainerFields = styled.div`
  padding-top: 8px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 20px;
  width: 95%;
  margin: 0 auto 0 0;
`;

export const ModalContainerType = styled.div`
  margin-top: 50px;
`;

export const TitleType = styled.h1`
  font-size: 14px;
  color: #ff5533;
  margin-bottom: 24px;
`;

export const ContainerSelects = styled.div``;

export const ContentSelects = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const SubtitleType = styled.h3`
  color: #6a6a6a;
  font-weight: 500;
`;

export const ContentDiscount = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  h3 {
    display: flex;
    align-items: center;
  }
  input::placeholder {
    color: #ff5533 !important;
  }

  input:-ms-input-placeholder {
    color: #ff5533 !important;
  }

  input::-ms-input-placeholder {
    color: #ff5533 !important;
  }
`;

export const ButtonSave = styled(Button)`
  float: left;
  margin-right: 10px;
`;

export const ButtonGoBack = styled(Button)`
  float: right;
`;
