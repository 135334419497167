export default {
  translations: {
    language: {
      message: 'es',
    },
    LoginFormTitle: {
      message: 'Acceso'
    },
    LoginFormSubtitle: {
      message: 'Hola, ingrese su correo electrónico y contraseña para iniciar sesión:'
    },
    LoginFormErroMessage: {
      message: 'No se puede iniciar sesión, inténtelo de nuevo.'
    },
    LoginFormButton: {
      message: 'Iniciar sesión'
    },
    LoginFormButtonForgotPassword: {
      message: 'Has olvidado tu contraseña'
    },
    password: {
      message: 'Pontraseña'
    },
    confirmPassword: {
      message: 'Confirmar contraseña'
    },
    mail: {
      message: 'Correo'
    },
    myEstablishment: {
      message: 'Mi Establecimiento'
    },
    parameterization: {
      message: 'Parametrización'
    },
    itemRegistration: {
      message: 'Registro de artículo'
    },
    orders: {
      message: 'Pedidos'
    },
    discountCoupons: {
      message: 'Cupones de descuento'
    },
    logout: {
      message: 'cerrar sesión'
    },
    establishmentDetails: {
      message: 'Detalles del establecimiento'
    },
    subscriptions: {
      message: 'Suscripciones'
    },
    aboutTheEstablishment: {
      message: 'Sobre el establecimiento'
    },
    mandatoryFilling: {
      message: 'llenado obligatorio'
    },
    tradeName: {
      message: 'Nombre comercial (nombre con el que se conoce su establecimiento)'
    },
    companyCorporateName: {
      message: 'Nombre de empresa'
    },
    informTheCompanyCorporateName: {
      message: 'Informar la razón social de la empresa'
    },
    pixel: {
      message: 'Pixel'
    },
    informPixel: {
      message: 'informar pixel'
    },
    invalidCnpj: {
      message: 'CNPJ no válido'
    },
    invalidCep: {
      message: 'CEP no válido'
    },
    state: {
      message: 'Estado'
    },
    city: {
      message: 'Ciudad'
    },
    cityUpper: {
      message: 'CIUDAD'
    },
    neighborhoods: {
      message: 'Barrios'
    },
    street: {
      message: 'Calle'
    },
    number: {
      message: 'Número'
    },
    informTheNumber: {
      message: 'informa el numero'
    },
    complement: {
      message: 'Complementar'
    },
    complementUpper: {
      message: 'COMPLEMENTAR'
    },
    URLToYourMenu: {
      message: 'URL de tu menú'
    },
    emailToLoginToTheManager: {
      message: 'Correo electrónico para iniciar sesión en el gerente'
    },
    name: {
      message: 'Nombre'
    },
    nameUpper: {
      message: 'NOMBRE'
    },
    lastName: {
      message: 'Apellido'
    },
    contactEmail: {
      message: 'Email de contacto'
    },
    cellphoneNumberWhatsApp: {
      message: 'celular/WhatsApp'
    },
    thisFieldIsRequired: {
      message: 'Este campo es requerido.'
    },
    enterYourCreditCardDetails: {
      message: 'Introduzca los datos de su tarjeta de crédito:'
    },
    thisIsTheCurrentPaymentMethod: {
      message: 'Este es el método de pago actual:'
    },
    paymentMethod: {
      message: 'Método de pago:'
    },
    paymentMethod2: {
      message: 'Método de pago'
    },
    paymentMethod3: {
      message: 'MÉTODOS DE PAGO'
    },
    cardNumber: {
      message: 'Número de tarjeta.'
    },
    enterAValidCardNumber: {
      message: 'Introduce un número de tarjeta válido.'
    },
    enterAValidDate: {
      message: 'Introduzca una fecha válida.'
    },
    cardholderName: {
      message: 'Nombre del titular de la tarjeta.'
    },
    typeAsWrittenOnCard: {
      message: 'Escriba como está escrito en la tarjeta.'
    },
    enterTheNameAsItIsWrittenOnTheCard: {
      message: 'Ingrese el nombre tal como está escrito en la tarjeta.'
    },
    bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable: {
      message: 'Al guardar los detalles de pago, autoriza a MyTripMenu a cargar el monto del plan automáticamente cada mes hasta que cancele la suscripción. Los términos y condiciones están disponibles '
    },
    inThisLink: {
      message: 'en este enlace.'
    },
    savePaymentData: {
      message: 'guardar datos de pago'
    },
    addACreditCardToViewPlanInformationAndConfirmYourSubscription: {
      message: 'Agrega una tarjeta de crédito para ver la información del plan y confirmar tu suscripción.'
    },
    deliveryConfiguration: {
      message: 'configuración de entrega'
    },
    schedules: {
      message: 'horarios'
    },
    Schedules: {
      message: 'HORARIOS'
    },
    add: {
      message: 'agregar'
    },
    onSiteServiceVisualization: {
      message: 'Servicio en sitio | visualización'
    },
    deliveryWhatsappOrders: {
      message: 'Entrega | pedidos de whatsapp'
    },
    takeAway: {
      message: 'Quitar'
    },
    catalog: {
      message: 'Catalogar'
    },
    typesOfService: {
      message: 'TIPOS DE SERVICIO'
    },
    mandatoryDataForDelivery: {
      message: 'DATOS OBLIGATORIOS PARA LA ENTREGA'
    },
    phone: {
      message: 'Teléfono'
    },
    phoneUpper: {
      message: 'TELÉFONO'
    },
    address: {
      message: 'Dirección'
    },
    generalSettings: {
      message: 'CONFIGURACIÓN GENERAL'
    },
    freeShippingForPurchasesAbove: {
      message: 'ENVÍO GRATIS PARA COMPRAS ARRIBA'
    },
    noFreeShipping: {
      message: 'Sin envío gratis'
    },
    minimumValueForDeliveryOrders: {
      message: 'VALOR MÍNIMO PARA PEDIDOS A ENTREGA'
    },
    minimumDeliveryTime: {
      message: 'TIEMPO MÍNIMO DE ENTREGA'
    },
    noForecast: {
      message: 'Sin pronóstico'
    },
    maximumDeliveryTime: {
      message: 'TIEMPO MÁXIMO DE ENTREGA'
    },
    orderResponseTimeInMinutes: {
      message: 'TIEMPO DE RESPUESTA DEL PEDIDO EN MINUTOS'
    },
    serviceNumberViaWhats: {
      message: 'NÚMERO DE SERVICIO A TRAVÉS DE WHATSAPP'
    },
    servicePlaces: {
      message: 'LUGARES DE SERVICIO'
    },
    action: {
      message: 'ACCIÓN'
    },
    addEditCities: {
      message: 'AÑADIR/EDITAR CIUDADES'
    },
    newCity: {
      message: 'Ciudad Nueva'
    },
    search: {
      message: 'Búsqueda'
    },
    addNew: {
      message: 'Agregar nuevo'
    },
    saveEditions2: {
      message: 'GUARDAR EDICIONES'
    },
    back: {
      message: 'ESPALDA'
    },
    status: {
      message: 'ESTADO'
    },
    active: {
      message: 'ACTIVO'
    },
    inactive: {
      message: 'INACTIVO'
    },
    category: {
      message: 'Categoría'
    },
    subcategory: {
      message: 'Subcategoría'
    },
    categories: {
      message: 'Categorías'
    },
    subcategories: {
      message: 'Subcategorías'
    },
    categoriesUpper: {
      message: 'CATEGORIAS'
    },
    subcategoriesUpper: {
      message: 'SUBCATEGORÍAS'
    },
    complements: {
      message: 'SUBCATEGORÍAS'
    },
    complementsGroup: {
      message: 'grupo de complementos'
    },
    complementsGroupUpper: {
      message: 'GRUPO COMPLEMENTOS'
    },
    products: {
      message: 'productos'
    },
    image: {
      message: 'IMAGEN'
    },
    yes: {
      message: 'Sí'
    },
    no: {
      message: 'No'
    },
    theHighlightsCategoryCannotBeEdited: {
      message: 'La categoría Destacados no se puede editar'
    },
    sortFromAZ: {
      message: 'ordenar de la A a la Z'
    },
    sortFromZA: {
      message: 'ordenar de Z-A'
    },
    sortByActive: {
      message: 'Ordenar por activo'
    },
    sortByInactive: {
      message: 'Ordenar por inactivo'
    },
    listActiveOnly: {
      message: 'Solo lista activa'
    },
    addEditCategory: {
      message: 'Añadir/editar categoría'
    },
    addEditSubcategory: {
      message: 'Añadir/editar subcategoría'
    },
    imageOptional: {
      message: 'Imagen (opcional) (optional)'
    },
    addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive: {
      message: 'Agregar una imagen ilustrativa a la categoría es una acción opcional, sin embargo, ayuda a que su menú sea más atractivo.'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality: {
      message: 'Elija una foto que ilustre los artículos que componen esta categoría, tenga buena iluminación y sea realista sobre la presentación real de los productos. Evite usar fotos genéricas, de stock o de baja calidad.'
    },
    theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB: {
      message: 'La resolución ideal es de 800x600 píxeles y la imagen no puede tener más de 1 MB.'
    },
    selectPhoto: {
      message: 'SELECCIONE FOTO'
    },
    selectCategory: {
      message: 'SELECCIONA UNA CATEGORÍA'
    },
    saveAndAddAnother: {
      message: 'GUARDAR Y AGREGAR OTRO'
    },
    highlightsSubcategoryCannotBeEdited: {
      message: 'La subcategoría Destacados no se puede editar'
    },
    anErrorOccurredWhileFetchingProductCategories: {
      message: 'Se produjo un error al obtener categorías de productos'
    },
    categoryInWhichTheSubcategoryShouldAppear: {
      message: 'Categoría en la que debe aparecer la subcategoría'
    },
    addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive: {
      message: 'Agregar una imagen ilustrativa a la subcategoría es una acción opcional, sin embargo, ayuda a que su menú sea más atractivo.'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos: {
      message: 'Elija una foto que ilustre los artículos que componen esta subcategoría, que tenga buena iluminación y que sea realista en relación con la presentación real de los productos. Evita usar fotos genéricas, de stock o de baja calidad.'
    },
    enterANameForTheSubcategory: {
      message: 'Introduce un nombre para la subcategoría'
    },
    enterANameForTheCategory: {
      message: 'Introduce un nombre para la categoría'
    },
    descriptionOfComplement: {
      message: 'descripción del complemento'
    },
    descriptionOfComplementUpper: {
      message: 'DESCRIPCIÓN DEL COMPLEMENTO'
    },
    addComplementGroup: {
      message: 'AÑADIR GRUPO COMPLEMENTO'
    },
    complementGroupName: {
      message: 'Nombre del grupo de complementos'
    },
    complementName: {
      message: 'Nombre del complemento'
    },
    checkAllFields: {
      message: 'Revisa todos los campos.'
    },
    complementGroupIsInactive: {
      message: 'ComplementGroup está inactivo.'
    },
    value: {
      message: 'Valor'
    },
    valueUpper: {
      message: 'VALOR'
    },
    addProduct: {
      message: 'AGREGAR PRODUCTO'
    },
    images: {
      message: 'Imágenes'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos: {
      message: 'Choose a phoElija una foto que ilustre los elementos que componen este producto, que tenga buena iluminación y que sea realista en relación con su presentación real. Evita usar fotos genéricas, de stock o de baja calidad.to that illustrates the items that make up this product, has good lighting, and is realistic in relation to your actual presentation. Avoid using generic, stock, or low-quality photos.'
    },
    enableOn: {
      message: 'Habilitar en'
    },
    categorysInWhichTheProductShouldAppear: {
      message: 'Categoría(s) en las que debe aparecer el producto'
    },
    subCategorysInWhichTheProductShouldAppear: {
      message: 'Subcategoría(s) en las que debe aparecer el producto'
    },
    categorization: {
      message: 'Categorización'
    },
    shortDescriptionShownInProductDetail: {
      message: 'Descripción breve (se muestra en el detalle del producto)'
    },
    longDescriptionShownInProductDetail: {
      message: 'Descripción larga (se muestra en el detalle del producto)'
    },
    enterTheIngredientsThatMakeUpThisProductItsAccompanimentsAndThePortionSizes: {
      message: 'Ingrese los ingredientes que componen este producto, su(s) acompañamiento(s) y los tamaños de las porciones'
    },
    enterSomethingLikeSideDishesOrPortionSize: {
      message: 'Ingrese algo como guarniciones o tamaño de la porción'
    },
    principalInformation: {
      message: 'Información principal'
    },
    id: {
      message: 'IDENTIFICACIÓN'
    },
    type: {
      message: 'ESCRIBE'
    },
    validity: {
      message: 'Validez'
    },
    validityUpper: {
      message: 'VALIDEZ'
    },
    useAvailable: {
      message: 'USO/DISPONIBLE'
    },
    filter: {
      message: 'Filtrar'
    },
    freeDelivery: {
      message: 'ENTREGA GRATIS'
    },
    discount: {
      message: 'DESCUENTO'
    },
    aCouponWithThisCodeAlreadyExists: {
      message: 'Ya existe un cupón con este código.'
    },
    dateTime: {
      message: 'FECHA Y HORA'
    },
    change: {
      message: 'CAMBIO'
    },
    formOfPayment: {
      message: 'FORMA DE PAGO'
    },
    customer: {
      message: 'CLIENTE'
    },
    uninformed: {
      message: 'No informado'
    },
    recordsFound: {
      message: 'Registros encontrados'
    },
    QRCodeOfYourDigitalMenu: {
      message: 'Código QR de tu carta digital'
    },
    downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu: {
      message: 'Descarga tu Código QR y ponlo a disposición en tu establecimiento o en materiales promocionales para facilitar el acceso de los clientes a tu carta digital'
    },
    areYouSureYouWantToDoThis: {
      message: "¿Estás segura de que quieres hacer esto?"
    },
    whenDisablingTheComplementAllPreviouslyLinkedComplementGroupsWillBeUnlinkedFromTheProduct: {
      message: 'Al deshabilitar el complemento, todos los grupos de complemento previamente vinculados se desvincularán del producto.'
    },
    anErrorOccurredWhileFetchingProductOperations: {
      message: 'Se produjo un error al obtener las operaciones del producto'
    },
    anErrorOccurredWhileFetchingTheSubcategoriesForTheSelectedCategory: {
      message: 'Se produjo un error al obtener las subcategorías para la categoría seleccionada'
    },
    productImage: {
      message: 'Imagen del producto'
    },
    whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus: {
      message: 'Cuando desactive un producto, ya no aparecerá en los menús en línea.'
    },
    RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary: {
      message: 'Recuerda que puedes volver a activarlo en otro momento si es necesario.'
    },
    inactivateTheProduct: {
      message: 'INACTIVAR EL PRODUCTO'
    },
    keepActive: {
      message: 'MANTENTE ACTIVO'
    },
    simpleEasyFastDigital: {
      message: 'Simple. Fácil. Rápido. Digital.'
    },
    errorIdentification: {
      message: 'Error de identificación'
    },
    tryAgain: {
      message: 'INTENTAR OTRA VEZ'
    },
    huhLooksLikeSomethingWentWrong: {
      message: '¡Eh! Parece que algo salió mal...'
    },
    unableToSaveCreditCardDetails: {
      message: 'No se pueden guardar los datos de la tarjeta de crédito.'
    },
    mmyy: {
      message: 'MM/AA'
    },
    removePhoto: {
      message: 'quitar foto'
    },
    addEditCoupon: {
      message: 'AÑADIR/EDITAR CUPÓN'
    },
    couponType: {
      message: 'TIPO DE CUPÓN'
    },
    freeShipping: {
      message: 'Envío gratis'
    },
    discountByValue: {
      message: 'Descuento por valor'
    },
    percentageDiscount: {
      message: 'Porcentaje de descuento'
    },
    generateCoupon: {
      message: 'GENERAR CUPÓN'
    },
    limitOfUse: {
      message: 'LÍMITE DE USO'
    },
    couponCode: {
      message: 'CÓDIGO PROMOCIONAL'
    },
    validFrom: {
      message: 'VÁLIDA DESDE'
    },
    validUntil: {
      message: 'VÁLIDO HASTA'
    },
    highlightsSubcategoryCannotBeCopied: {
      message: 'La subcategoría Destacados no se puede copiar'
    },
    nothingHere: {
      message: 'Parece que todavía no hay nada aquí..'
    },
    from: {
      message: "DE"
    },
    to: {
      message: "A"
    },
    sunday: {
      message: "Domingo"
    },
    monday: {
      message: "Lunes"
    },
    tuesday: {
      message: "Martes"
    },
    wesnesday: {
      message: "Miércoles"
    },
    thursday: {
      message: "Jueves "
    },
    friday: {
      message: "Viernes"
    },
    saturday: {
      message: "Sábado"
    },
    accessData: {
      message: "Datos de acesso"
    },
    responsibleEstablishment: {
      message: "Responsable del Establecimiento"
    },
    saveEditions: {
      message: "Guardar ediciones"
    },
    exitWithoutSaving: {
      message: 'SALIR SIN GUARDAR'
    },
    saveEditionsAndExit: {
      message: 'GUARDAR LOS CAMBIOS Y SALIR'
    },
    zeit: {
      message: "Tiempo"
    },
    cardsAccepted: {
      message: "Marca las tarjetas aceptadas por tu establecimiento"
    },
    pixKey: {
      message: "Registre su clave PIX aquí"
    },
    noOptions: {
      message: "sin opciones"
    },
    neighborhoods2: {
      message: "BARRIOS"
    },
    addEditNeighborhoods: {
      message: "AÑADIR/EDITAR BARRIOS"
    },
    newNeighborhoods: {
      message: "Nuevo Barrio"
    },
    qtyMinimum: {
      message: "CANTIDAD MÍNIMO"
    },
    qtymaximum: {
      message: "CANTIDAD MÁXIMO"
    },
    linkGroup: {
      message: "ENLACE GRUPO DE SUMINISTROS"
    },
    importantGroupComplements: {
      message: "GRUPO DE COMPLEMENTOS DE IMPORTACIÓN"
    },
    people: {
      message: "Gente"
    },
    house: {
      message: "casas"
    },
    subcategoryProducts: {
      message: "Esta SubCategoría no tiene Productos Asociados"
    },
    productNotAdd: {
      message: "Este producto no tiene complementos añadidos"
    },
    linkSupplements: {
      message: "ENLACE SUPLEMENTOS"
    },
    PRICE: {
      message: "PRECIO"
    },
    COMPLEMENTS: {
      message: "COMPLEMENTOS"
    },
    DESCRIPTION: {
      message: "DESCRIPCIÓN"
    },
    action2: {
      message: "Comportamiento"
    },
    pixKey2: {
      message: "Su clave PIX"
    },
    ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn: {
      message: "Si abandona la página de detalles de la propiedad antes de guardar los cambios, perderá toda la información que ya haya completado."
    },
    couldNotLoadUserData: {
      message: "No se pudieron cargar los datos del usuario"
    },
    termsOfUseAanServices:{
      message: "TÉRMINOS DE USO Y SERVICIOS"
    },
    hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou:{
      message: "¡Hola! ¡Me alegro de tener su interés! Antes de usar nuestros servicios, tómese el tiempo para leer nuestros Términos de uso y servicio y conozca las reglas que rigen nuestra relación con usted."
    },
    belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom:{
      message: "A continuación aclararemos algunos puntos que consideramos importantes. Si tiene alguna duda sobre algún punto tratado o no en este documento, no dude en ponerse en contacto con nosotros en hello@mytripmenu.com."
    },
    definitions:{
      message: "1. DEFINICIONES:"
    },
    inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions: {
      message: "En este instrumento, entendemos las siguientes expresiones de acuerdo con las siguientes definiciones:"
    },
    plataform: {
      message: "PLATAFORMA"
    },
    webSystemAvailableThroughMyTripMenuComBrOfferedBy: {
      message: "sistema web disponible a través de mytripmenu.com.br, ofrecido por"
    },
    andOwnershipOperationAndLiabilityOf: {
      message: " y propiedad, operación y responsabilidad de "
    },
    users: {
      message: "USUARIOS"
    },
    user: {
      message: "USUARIO"
    },
    where: {
      message: ", dónde"
    },
    allThe: {
      message: "todos los"
    },
    canViewAndUseTheServicesOfferedBy: {
      message: "puede ver y utilizar los servicios ofrecidos por"
    },
    establishment: {
      message: "ESTABLISHMENT"
    },
    establishments: {
      message: "ESTABLISHMENTS"
    },
    legalEntityRegisteredInThe: {
      message: "legal entity registered in the"
    },
    whichContractsTheServicesOfferedBy: {
      message: ", which contracts the services offered by"
    },
    client: {
      message: "CLIENT"
    },
    customers: {
      message: "CUSTOMERS"
    },
    naturalPersonAccessingThe: {
      message: "natural person accessing the"
    },
    toViewTheMenu: {
      message: "to view the menu "
    },
    dataProcessing: {
      message: "DATA PROCESSING"
    },
    pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy: {
      message: " Pursuant to article 5, item X, of Law 13,709 of 2018, every transaction carried out by "
    },
    withPersonalDataOf: {
      message: " with personal data of "
    },
    suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction: {
      message: " , such as those referring to the collection, production, reception, classification, use, access, reproduction, transmission, distribution, processing, archiving, storage, elimination, evaluation or control of information, modification, communication, transfer, dissemination or extraction. "
    },
    admission: {
      message: " 2. ADMISSION: "
    },
    thisInstrumentRegulatesTheConditionsOfUseOfThe: {
      message: " This instrument regulates the conditions of use of the "
    },
    being: {
      message: "being"
    },
    aContractBetweenThe: {
      message: " a contract between the "
    },
    andThe: {
      message: " and the "
    },
    theUseOfTheServicesOfferedThrough: {
      message: " The use of the services offered through "
    },
    fromThe: {
      message: "from the "
    },
    expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies: {
      message: " expressly indicates that you agree with all the terms and conditions contained in this instrument and with the legal provisions applicable to the species. "
    },
    the: {
      message: "The "
    },
    understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy: {
      message: " understands that the service contracting model will be through prepaid monthly subscription plans budgeted and offered by "
    },
    accordingToInformationAvailableInThePlansAreaOfTheWebsite: {
      message: "according to information available in the plans area of The website: "
    },
    forTheContractingOfCustomizedPlansThe: {
      message: "For the contracting of customized plans, the "
    },
    shouldContactThe: {
      message: "should contact the "
    },
    throughItsChannelsForTheElaborationOfASpecificBudget: {
      message: "through its channels, for the elaboration of a specific budget. "
    },
    youUnderstandAndAgreeThatThe: {
      message: "YOU UNDERSTAND AND AGREE THAT THE "
    },
    useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies: {
      message: " USE OF THE PROVIDED SERVICES WILL BE CONSIDERED YOUR ACCEPTANCE OF THESE TERMS AND ALL OTHER LEGAL PROVISIONS RELEVANT TO THE SPECIES."
    },
    ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform: {
      message: "BY ACCEPTING THE TERMS OF THIS INSTRUMENT, THE USER EXPRESSLY AUTHORIZES THE PROCESSING OF HIS DATA, IN ORDER TO GUARANTEE THE MAINTENANCE AND GOOD PERFORMANCE OF THE FUNCTIONALITIES OF THE PLATFORM"
    },
    theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy: {
      message: "THE USER, IN THIS ACT, MANIFESTS ITS COMPLETE CONSENT TO THE SHARING OF DATA COLLECTED AND PROCESSED BY "
    },
    pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders: {
      message: ", PURSUANT TO THIS INSTRUMENT, WITH OTHER MY TRIP MENUS THAT ARE PART OF YOUR ECONOMIC GROUP, OR ARE YOUR SERVICE PROVIDERS."
    },

    caseYou: {
      message: "Case you "
    },
    doNotAgree: {
      message: "DO NOT AGREE"
    },
    withTheProvisionsOfThisInstrument: {
      message: " with the provisions of this instrument, "
    },
    dont: {
      message: 'D\'ONT'
    },
    accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom: {
      message: " access, view, download or otherwise use any page, content, information or service from "
    },
    theseTermsAreAvailableForReadingAtAnyTimeOn: {
      message: "These terms are available for reading, at any time, on "
    },
    inMessage: {
      message: "in "
    },
    whoWeAreAndWhatWeDo: {
      message: "3. WHO WE ARE AND WHAT WE DO:"
    },
    isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp: {
      message: "is a service provider that has developed and licensed a system in the form of SaaS (Software as a Service), with specific features to help"
    },
    and: {
      message: "and"
    },
    soThe: {
      message: "So, the"
    },
    offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe: {
      message: "offers a digital menu, allowing an improvement in efficiency and experience for both the"
    },
    asForThe: {
      message: "as for the"
    },
    generalConditionsOfUse: {
      message: "4. GENERAL CONDITIONS OF USE:"
    },
    onlyProvidesThe: {
      message: " only provides the "
    },
    whichOffersDigitalMenuServicesForThe: {
      message: " which offers digital menu services for the "
    },
    itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe: {
      message: " its responsibility being restricted only to the correct functioning of the "
    },
    andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe: {
      message: "and its functionalities, according to this instrument and the plan contracted by the "
    },
    notStoppingThe: {
      message: ", not stopping the"
    },
    noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu: {
      message: "no responsibility for the creation, customization and dissemination of content through the digital menu."
    },
    justLicenseThe: {
      message: " just license the "
    },
    toThe: {
      message: " to the "
    },
    toThe2: {
      message: " To the "
    },
    andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe: {
      message: ", and there is no other relationship between these parties, so that it is not possible to attribute to the"
    },
    liabilityForAnyDamageCauseToOthers: {
      message: " liability for any damage caused to others "
    },
    orToThirdPartiesForActsArisingFrom: {
      message: " or to third parties, for acts arising from "
    },
    whileUsingTheAvailableFeaturesOfThe: {
      message: " while using the available features of the "
    },
    declaresThatHeUnderstandsAndAcceptsThatThe: {
      message: "declares that he understands and accepts that the "
    },
    doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe: {
      message: "does not intend to replace the physical menu available at the commercial unit of the "
    },
    beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween: {
      message: "being only a complementary solution, in order to facilitate communication between "
    },
    atTheTimeOfRegistrationThe: {
      message: "At the time of registration, the"
    },
    youCanUseAllTheServicesAvailableOnThe: {
      message: " You can use all the services available on the "
    },
    declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse: {
      message: ", declaring that they have read, understood and accepted all the provisions contained in these Terms of Use."
    },
    expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom: {
      message: "expressly acknowledges that, through this instrument, it receives from "
    },
    theGarantingOfALicenseToUseThe: {
      message: " the granting of a license to use the "
    },
    isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe: {
      message: ", is non-transferable, and sublicensing is prohibited, for use in national or foreign territory, for as long as the adhesion to this term lasts, leaving the use of the "
    },
    inDisagreementWithTheProvisionOfThisInstrument: {
      message: " in disagreement with the provisions of this instrument."
    },
    isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt: {
      message: " is solely responsible for the security of your password and for the use of your registration at "
    },
    thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr: {
      message: ", therefore, we recommend that you do not share such information with third parties and, if this information is, for any reason, lost or "
    },
    hacked: {
      message: "hacked"
    },
    mustImmediatelyInformThe: {
      message: " must immediately inform the "
    },
    throughHelloMyTripMenuComInOrderToResolveTheIssue: {
      message: ", through hello@mytripmenu.com, in order to resolve the issue."
    },
    itIsOnlyUpToThe: {
      message: "It is only up to the "
    },
    liableForAnyDamagesCausedToThirdPartiesToOther: {
      message: " liable for any damages caused to third parties, to other "
    },
    orToItself: {
      message: " or to itself "
    },
    resultingFromTheUseOfTheFeaturesOfThe: {
      message: ", resulting from the use of the features of the "
    },
    shouldNotUseTheServicesAvailableOnThe: {
      message: " should not use the services available on the "
    },
    forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe: {
      message: " for any unlawful, defamatory, discriminatory, abusive, offensive, pornographic, obscene, aggressive, injurious, vexatious, deceptive, slanderous, violent, vulgar, or harassing, threatening, or use of false identity, ie any shady use, purposes or means that could harm the "
    },
    others: {
      message: " others"
    },
    orThirdParties: {
      message: " or third parties"
    },
    UnderNoCircumstancesWillThe: {
      message: "Under no circumstances will the "
    },
    willBeLiableForAnyDamagesIncurredByThe: {
      message: "will be liable for any damages incurred by the "
    },
    forPossibleTemporaryUnavailabilityOfThe: {
      message: " for possible temporary unavailability of the "
    },
    orOnyContentMadeAvailableByThe: {
      message: "or any content made available by the "
    },
    onTheDigitalMenu: {
      message: "on the digital menu."
    },
    mustHaveAllNecessarySoftwareAndHardwareToAccessThe: {
      message: " must have all necessary software and hardware to access the "
    },
    includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe: {
      message: ", including, but not limited to, a computer or mobile device with Internet access, and the "
    },
    onlyToProvideThe: {
      message: ", only to provide the "
    },
    to2: {
      message: " to "
    },
    underThisInstrumentToThe: {
      message: "under this instrument. To the "
    },
    accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable: {
      message: "access the digital menu, a device capable of reading the QR Code will be indispensable."
    },
    byThe: {
      message: " by the "
    },
    isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument: {
      message: "is conditioned to its prior registration, as well as compliance with the provisions contained in this instrument."
    },
    registration: {
      message: "5. REGISTRATION"
    },
    soThatThe: {
      message: "So that the "
    },
    registerInThe: {
      message: " register in the "
    },
    mustProvideTo: {
      message: "must provide to "
    },
    theFollowingData: {
      message: " the following data "
    },
    corporateName: {
      message: "corporate name;"
    },
    unitAddressOf: {
      message: " unit address of "
    },
    contactNumberInTheEventThatThe: {
      message: "Contact number. In the event that the"
    },
    hireThe: {
      message: "hire the"
    },
    forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest: {
      message: "for more units, you must register the other addresses of interest."
    },
    forRegularUseOf: {
      message: "For regular use of "
    },
    mustRegisterFillingInAllTheDataRequestedByThe: {
      message: " must register, filling in all the data requested by the "
    },
    atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy: {
      message: " at the time of registration, as well as selecting the service plan offered by "
    },
    itIsTheResponsibilityOf: {
      message: " It is the responsibility of "
    },
    identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting: {
      message: "identify in the contracted plan the number of units contemplated in its contracting."
    },
    itIsTheSoleResponsibilityOf: {
      message: "It is the sole responsibility of"
    },
    provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe: {
      message: "provide, update and guarantee the veracity of the registration data, not falling to the"
    },
    anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy: {
      message: " any type of civil and criminal liability resulting from untrue, incorrect or incomplete data provided by "
    },
    reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe: {
      message: " reserves the right to use all valid and possible means to identify the "
    },
    asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf: {
      message: " as well as requesting additional data and documents that it deems relevant in order to verify the reported data. In this case, the use of "
    },
    isConditionedToTheSendingOfTheDocumentsEventuallyRequested: {
      message: " is conditioned to the sending of the documents eventually requested."
    },
    ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe: {
      message: "If a registration is considered suspected of containing erroneous or untrue data, the "
    },
    reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe: {
      message: " reserves the right to suspend, temporarily or permanently, without prior notice, the "
    },
    responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe: {
      message: " responsible for the registration. In the event of suspension, you will not attend the "
    },
    rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages: {
      message: " right to any type of indemnity or compensation for losses and damages, loss of profits or moral damages."
    },
    inTheEventThatThe: {
      message: "In the event that the"
    },
    carryOutTheCollectionOfPersonalDataFromThe: {
      message: "carry out the collection of personal data from the "
    },
    willAllowAccessToTheInformationCollectedAndAboutThe: {
      message: "will allow access to the information collected and about the "
    },
    carriedOutBy: {
      message: " carried out by "
    },
    freeOfChargeByRequestingHelloMyTripMenuCom: {
      message: ", free of charge, by requesting hello@mytripmenu.com"
    },
    orByRegisteringAt: {
      message: "or by registering at "
    },
    beingAbleToEditOrDeleteThemAtAnyTime: {
      message: " being able to edit or delete them at any time."
    },
    theCollectionOfDataFrom: {
      message: "The collection of data from "
    },
    whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe: {
      message: ", when it occurs, aims to identify you, as well as enable you to correctly use the "
    },
    andWithThatThe: {
      message: ", and with that, the "
    },
    canEnsureTheGoodQualityOfTheLicensedServices: {
      message: " can ensure the good quality of the licensed services."
    },
    byConsentingToTheTermsOfThisInstrumentThe: {
      message: "By consenting to the terms of this instrument, the"
    },
    expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe: {
      message: " expressly declares to be aware that the collection of your data is essential for the proper functioning of the "
    },
    authorizingFromNowOnThe: {
      message: ", authorizing, from now on, the "
    },
    forThe: {
      message: " for the "
    },
    offersThePossibilityOf: {
      message: " offers the possibility of "
    },
    publicizeYourFigitalMenuThroughQRCodeToYour: {
      message: "publicize your digital menu through QR Code to your "
    },
    withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe: {
      message: "without the need for registration, however, the absence of registration does not detract from the non-compliance with the rules of this instrument, which the "
    },
    mustEnsureCompliance: {
      message: "must ensure compliance."
    },
    willAccessYourRegistrationIn: {
      message: " will access your registration in "
    },
    through: {
      message: " through "
    },
    andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem: {
      message: " and password, pledging not to inform third parties of this data, taking full responsibility for the use made of them."
    },
    undertakesToNotifyThe: {
      message: " undertakes to notify the "
    },
    immediatelyThroughTheContactChannelsMaintainedBy: {
      message: " immediately, through the contact channels maintained by "
    },
    at: {
      message: " at "
    },
    regardingAnyUnauthorizedUseOfYourAccountThe: {
      message: ", regarding any unauthorized use of your account. The"
    },
    willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou: {
      message: " will be solely responsible for the operations carried out on your account, since access will only be possible through the use of a password known exclusively to you."
    },
    regardingAnyKnowledgeOfWrongdoingByOther: {
      message: ", regarding any knowledge of wrongdoing by other"
    },
    thatMayCauseDamageToTheirOwn: {
      message: " that may cause damage to their own "
    },
    ofThe: {
      message: " of the "
    },
    toThisOnTo: {
      message: " to this one, to "
    },
    orToThirdParties: {
      message: " or to third parties."
    },
    undertakesToUsTheFeaturesOfThe: {
      message: "undertakes to use the features of the"
    },
    inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms: {
      message: " in good faith, in accordance with the legislation in force, morals and good customs."
    },
    underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe: {
      message: "Under no circumstances will the assignment, sale, rent or other form of transfer of the"
    },
    atItsSoleDiscretion: {
      message: "At its sole discretion "
    },
    mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO: {
      message: " may exclude, disable, create limits on the use of the service, suspend, block, indefinitely, without prior notice or compensation, registrations o "
    },
    thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce: {
      message: " that are considered offensive, that violate the terms of this instrument or the legislation in force."
    },
    reservesTheRightNotToAllowNewRegistrationOf: {
      message: " reserves the right not to allow new registration of "
    },
    thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom: {
      message: " that have already been cancelled, disabled, blocked, deleted or suspended from "
    },
    theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe: {
      message: " The creation of new registrations by people whose original registrations have been cancelled, blocked, disabled, excluded or suspended due to violations of the policies of the "
    },
    orCurrentLegislation: {
      message: " or current legislation."
    },
    reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA: {
      message: " reserves the right, unilaterally, without prior notice, consent or compensation, to refuse any request for registration of a "
    },
    asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration: {
      message: ", as well as cancel, disable, block, delete or suspend the use of a previously accepted registration."
    },
    byAgreeingToThisInstrumentThe: {
      message: "By agreeing to this instrument, the "
    },
    declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo: {
      message: " declares to be aware that it is solely responsible for its registration, being certain that any damage caused by the insertion of outdated, inaccurate or untrue information cannot be attributed to "
    },
    orThe: {
      message: "Or the"
    },
    functionalities: {
      message: "6. FUNCTIONALITIES:"
    },
    offersTheFollowingFeatures: {
      message: " offers the following features:"
    },
    aOnlineDigitalMenu: {
      message: "(a) Online digital menu;"
    },
    bViewingProductsWithPphotos: {
      message: "(b) Viewing products with photos;"
    },
    cOfferingAMultilingualMenuSubjectToAvailableConditions: {
      message: "(c) Offering a multilingual menu, subject to available conditions;"
    },
    dActivationAndDeactivationOfItemsInRealTime: {
      message: "(d) Activation and deactivation of items in real time;"
    },
    eAccessToTheMenuByScanningAQRCode: {
      message: "(e) Access to the menu by scanning a QR Code;"
    },
    fCreationOfHighlightsForPromotionalActions: {
      message: "(f) Creation of highlights for promotional actions."
    },
    whenUsingTheServicesProvidedByThe: {
      message: " when using the services provided by the "
    },
    canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe: {
      message: " can select URL (Uniform Resource Locator), in order to determine the electronic address that will locate the digital menu of the "
    },
    ToDefineTheURLThe: {
      message: "To define the URL, the "
    },
    mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices: {
      message: "must respect the intellectual property of third parties, therefore, it must not include expressions that may cause confusion or allude to third-party services."
    },
    understandThatThe: {
      message: " understand that the "
    },
    resourcesWereUsedToCauseConfusionAmongThe: {
      message: " resources were used to cause confusion among the "
    },
    makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe: {
      message: "making it difficult to properly identify the services and misleading them, you may exclude the URL of the "
    },
    asWellAsAdoptOtherMeasuresRelevantToTheCase: {
      message: "as well as adopt other measures relevant to the case"
    },
    mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn: {
      message: " may change the range of features available according to the contract plan, as provided for in "
    },
    mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe: {
      message: " may at any time, by sending prior notice, without the need for compensation, edit and/or delete existing features, as well as include new features to the "
    },
    translations: {
      message: "7. TRANSLATIONS:"
    },
    youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe: {
      message: "You can use translation services in order to make your menus available in the languages of your choice. For this purpose, fees will be charged for the services, as provided in the "
    },
    forTheExecutionOfTranslationServicesThe: {
      message: "For the execution of translation services, the"
    },
    requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe: {
      message: "request at least a period of 72 hours (seventy-two hours). Depending on the length of the text to be translated, the"
    },
    withPriorNoticeTo: {
      message: "with prior notice to"
    },
    mayExtendTheServiceExecutionPeriodAtItsDiscretion: {
      message: "may extend the service execution period at its discretion."
    },
    declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions: {
      message: "declares that he understands and accepts that certain expressions cannot be translated, given that they reflect certain cultures or specific regions."
    },
    isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe: {
      message: " is not responsible for any damage resulting from inaccurate translations in the digital menu of the "
    },
    howeverInTheEventThatThe: {
      message: ". However, in the event that the"
    },
    identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe: {
      message: "identify any error or misunderstanding in the tradition, you can communicate to the "
    },
    whichMustRespondToTheRequestAsSoonAsPossible: {
      message: "which must respond to the request as soon as possible."
    },
    suppertService: {
      message: "8. SUPPORT SERVICE"
    },
    willSupportThe: {
      message: "will support the "
    },
    throughthecontactchannelavailableviaemailhellomytripmenucom: {
      message: " through the contact channel available via email hello@mytripmenu.com."
    },
    inSupportRequestsViaEmailTthe: {
      message: "In support requests via email, the "
    },
    willHaveUpTo72SeventyTwoBusinessHoursToRespond: {
      message: "will have up to 72 (seventy-two) business hours to respond."
    },
    paymentMethod4: {
      message: "9. PAYMENT METHODS:"
    },
    priorToAnyChargeBeingMadeByThe: {
      message: "Prior to any charge being made by the "
    },
    willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf: {
      message: "will be offered a trial period of 07 (seven) days, for a free trial of the tools of "
    },
    ifThe: {
      message: "If the "
    },
    chooseToProceedWithTheContractingOfServicesThe: {
      message: " choose to proceed with the contracting of services, the "
    },
    makesAvailableTo: {
      message: "makes available to "
    },
    oneOfTheFollowingPaymentMethods: {
      message: "one of the following payment methods: "
    },
    VISACreditCardOr: {
      message: "VISA Credit Card; or,"
    },
    MASTERCARDCreditCardOr: {
      message: "MASTERCARD Credit Card; or,"
    },
    AMERICANEXPRESSCreditCardOr: {
      message: "AMERICAN EXPRESS Credit Card; or,"
    },
    DINNERSCLUBCreditCardOr: {
      message: "DINNERS CLUB Credit Card; or,"
    },
    ELOCreditCardOr: {
      message: "ELO Credit Card, or;"
    },
    DISCOVERCreditCard: {
      message: "DISCOVER Credit Card."
    },
    toProcessCreditCardPaymentsItWillBeNecessaryForThe: {
      message: "To process credit card payments, it will be necessary for the"
    },
    registerOn: {
      message: "register on "
    },
    theFollowingDetailsOfTheChosenCreditCard: {
      message: "the following details of the chosen Credit Card:"
    },
    NameOfTheCreditCardHolder: {
      message: "Name of the Credit Card holder;"
    },
    CreditCardNumber: {
      message: "Credit card number;"
    },
    CreditCardFlag: {
      message: "Credit Card Flag;"
    },
    CreditCardExpirationMonthandYear: {
      message: "Credit Card Expiration (Month and Year);"
    },
    CreditCardsecuritynumber: {
      message: "Credit Card security number."
    },
    Paymentsforservicescontractedinthe: {
      message: "Payments for services contracted in the "
    },
    willBe: {
      message: " will be"
    },
    processedthroughtheGETNETplatform: {
      message: " processed through the GETNET platform"
    },
    beingnecessarythatall: {
      message: "being necessary that all "
    },
    alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform: {
      message: " also read and accept the terms of use and services of the GETNET platform"
    },
    viaemailaddress: {
      message: "via email address: "
    },
    Whenhiringthe: {
      message: "When hiring the "
    },
    withpaymentbycreditcardorbankslipthe: {
      message: ", with payment by credit card or bank slip, the"
    },
    expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform: {
      message: "expressly indicates that you have read and accepted all the conditions present in this instrument and in the terms of use and specific services of the GETNET platform."
    },
    AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe: {
      message: "Any dispute of payments made through the GETNET platform must be resolved only between the"
    },
    andGETNETnotfallingto: {
      message: " and GETNET, not falling to "
    },
    anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform: {
      message: " any responsibility for processing payments made on the GETNET platform."
    },
    TheGETNETplatform: {
      message: "The GETNET platform"
    },
    maychargefeesforitsuseanditisonlyuptothe: {
      message: "may charge fees for its use, and it is only up to the "
    },
    informationaboutthevalueschargedbyGETNET: {
      message: " information about the values charged by GETNET. "
    },
    isonlyresponsiblefordeletingthepaymentdataprovidedbythe: {
      message: " is only responsible for deleting the payment data provided by the "
    },
    fromitsowndatabasedeclaringthe: {
      message: "from its own database, declaring the"
    },
    beawarethatitisnotupto: {
      message: " be aware that it is not up to "
    },
    promotetheexclusionofsaiddatafromtheGETNETdatabase: {
      message: " promote the exclusion of said data from the GETNET database."
    },
    PRIVACYANDDATAPROCESSINGPOLICY: {
      message: "10. PRIVACY AND DATA PROCESSING POLICY:"
    },
    Duringtheuseof: {
      message: "During the use of "
    },
    willcollectandstoretheinformationprovidedbythe: {
      message: "will collect and store the information provided by the"
    },
    underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof: {
      message: ", under the terms of this instrument, together with automatically generated information, such as the characteristics of the access device, browser, application access logs (IP, with date and time), information accessed, screens accessed, geolocation data, history of applications, among others, of"
    },
    whichwillbestoredinthedatabaseandalsointhebrowsercookie: {
      message: ", which will be stored in the database and also in the browser cookie."
    },
    Thedatacollectedfrom: {
      message: "The data collected from "
    },
    throughtheuseof: {
      message: ", through the use of"
    },
    willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe: {
      message: ", will be used for the adequate provision of services, for the purpose of improving navigation of the"
    },
    aswellasforadvertisingandstatisticalpurposes: {
      message: ", as well as for advertising and statistical purposes."
    },
    declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato: {
      message: "declares to understand, accept and consent that in the event of sending personal data to"
    },
    mustdosounderthetermsofarticle5itemXofLaw13709of2018: {
      message: "must do so under the terms of article 5, item X, of Law 13,709 of 2018."
    },
    Intheeventthatpersonaldataisprovidedbythe: {
      message: "In the event that personal data is provided by the "
    },
    willbeconsideredconfidentialbythe: {
      message: ", will be considered confidential by the"
    },
    anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas: {
      message: ", and it undertakes to make every effort to preserve the security of its systems in the storage of such data, meeting the security standards established in Decree No. 8,771/2016, such as:"
    },
    iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability: {
      message: "(i) Use of industry standard methods to encrypt the collected data, in addition to other standard forms of encryption, to ensure its inviolability;"
    },
    iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments: {
      message: "(ii) Use of high-tech software to protect against unauthorized access to systems, which are considered controlled and security environments;"
    },
    Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement: {
      message: "(iii) Availability of controlled access to personal data storage locations only to previously authorized and authenticated persons, committed to the secrecy of such data, including by signing a confidentiality agreement;"
    },
    ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe: {
      message: "(iv) Application of authentication mechanisms for access to records capable of individualizing the person responsible for the treatment and access to the data collected as a result of the use of the"
    },
    vAnonymizationofdatafromthe: {
      message: "(v) Anonymization of data from the"
    },
    whensharedwiththirdpartiesnotpartnerswith: {
      message: "when shared with third parties not partners with"
    },
    viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016: {
      message: "(vi) Maintenance of the inventory indicating the time, duration, identity of the employee or person responsible for access and the object file, based on connection and application access records, as determined in article 13 of Decree No. 8,771/2016."
    },
    Ifdatacollectionfromthe: {
      message: "If data collection from the"
    },
    throughtheuse: {
      message: ", through the use"
    },
    thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow: {
      message: "these may only be shared with third parties, in the cases listed below:"
    },
    iFortheprotectionoftheinterestsof: {
      message: "(i) For the protection of the interests of"
    },
    incaseofconflictincludinglegalclaims: {
      message: "in case of conflict, including legal claims;"
    },
    iiIncaseoftransactionsandcorporatechangesinvolvingthe: {
      message: "(ii) In case of transactions and corporate changes involving the"
    },
    inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe: {
      message: ", in which case the transfer of data will be necessary for the continuity of the services offered through the"
    },
    iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest: {
      message: "(iii) By court order or by request of administrative authorities that have legal competence for its request."
    },
    guaranteesto: {
      message: "guarantees to"
    },
    withregardtotheprocessingofpersonaldatathefollowingrights: {
      message: ", with regard to the processing of personal data, the following rights:"
    },
    iConfirmationoftheexistenceofprocessingofyourpersonaldata: {
      message: "(i) Confirmation of the existence of processing of your personal data;"
    },
    iiAccesstoyourdatacollectedby: {
      message: "(ii) Access to your data collected by"
    },
    throughyourownloginorbyrequestinghellomytripmenucom: {
      message: ", through your own login or by requesting hello@mytripmenu.com;"
    },
    iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate: {
      message: "(iii) Correction of your data if it is incomplete, inaccurate or out of date;"
    },
    ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation: {
      message: "(iv) Blocking or deleting unnecessary, excessive or non-conforming data with applicable Brazilian legislation;"
    },
    vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe: {
      message: "(v) The portability of personal data, for you or for a third party, upon express request made by the"
    },
    viahellomytripmenucom: {
      message: ", via hello@mytripmenu.com;"
    },
    viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe: {
      message: "(vi) Deletion of personal data processed with your consent, provided that there is no legal determination to keep them registered with the"
    },
    viiObtaininginformationaboutpublicorprivateentitieswithwhichthe: {
      message: "(vii) Obtaining information about public or private entities with which the"
    },
    sharedyourdataand: {
      message: "shared your data; and,"
    },
    viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe: {
      message: "(viii) Information on the possibility and consequence of not providing the consent of the"
    },
    youcansendemailto: {
      message: "you can send email to"
    },
    hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata: {
      message: "hello@mytripmenu.com, pointing out doubts and/or requirements related to your personal data."
    },
    maydeletethepersonaldatacollectedfrom: {
      message: "may delete the personal data collected from"
    },
    iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy: {
      message: "(i) When the purpose for which they were collected is achieved; or, when they are no longer necessary or relevant for the scope of the purpose, according to the purposes described in these Terms of Use and Privacy Policy;"
    },
    iiWhenthe: {
      message: "(ii) When the"
    },
    revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe: {
      message: "revoke your consent, in cases where it is necessary, requesting the exclusion to the"
    },
    viahellomytripmenucomor: {
      message: "via hello@mytripmenu.com; or,"
    },
    iiiIfdeterminedbycompetentauthority: {
      message: "(iii) If determined by competent authority."
    },
    GENERALPROVISIONS: {
      message: "11. GENERAL PROVISIONS:"
    },
    AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent: {
      message: "Any clause or condition of this instrument that, for any reason, will be considered null or ineffective by any court or court, will not affect the validity of the other provisions of these Terms, which will remain fully valid and binding, generating effects to their fullest extent."
    },
    Thefailureof: {
      message: "The failure of"
    },
    inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines: {
      message: "in claiming any rights or provisions of these Terms will not constitute a waiver, and the latter may regularly exercise their right, within the legal deadlines."
    },
    Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe: {
      message: "All materials, patents, trademarks, registrations, domains, names, privileges, creations, images and all related rights relating to the"
    },
    anddevelopedby: {
      message: "and developed by"
    },
    areandwillremainthesoleandexclusivepropertyof: {
      message: ", are and will remain the sole and exclusive property of"
    },
    agreeingwiththe: {
      message: ", agreeing with the"
    },
    nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem: {
      message: "not to perform an act or fact that, in any way, harms the rights set forth herein, nor to claim any right or privilege over them."
    },
    maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreasonwestronglyrecommendthatyoualwaysvisitthissectionofourSiteandreaditperiodicallyButtocontributetothegoodrelationshipwewillalsosendanemailinformingyouaboutthesechanges: {
      message: "may change this instrument at any time, by simply publishing a revised version on our website. For this reason, we strongly recommend that you always visit this section of our Site and read it periodically. But, to contribute to the good relationship, we will also send an email informing you about these changes."
    },
    Thisinstrumentconstitutestheintegralunderstandingbetweenthe: {
      message: "This instrument constitutes the integral understanding between the"
    },
    andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe: {
      message: "and is governed by Brazilian Laws, with the jurisdiction of the city of São Leopoldo/RS being elected as the only competent court to resolve issues arising from this instrument, with express waiver of any other jurisdiction, however privileged it may be."
    },

    recoverPassword:{
      message: "RECUPERAR CONTRASEÑA"
    },
    receiveNewPassword:{
      message: "Recibirá una nueva contraseña en su correo electrónico, recuerde revisar también su carpeta de correo no deseado."
    },
    dontWorry:{
      message: "No te preocupes, te ayudaremos a recuperarlo. Proporcione su correo electrónico de inicio de sesión"
    },
    enterEmail:{
      message: "Ingrese correo electrónico"
    },
    notBeSent:{
      message: "No se pudo enviar el correo electrónico, inténtalo de nuevo."
    },
    pleasureToHaveYou:{
      message: "¡Es un placer tenerte aquí con nosotros!"
    },
    beforeStarting:{
      message: "Antes de empezar a incluir tus productos y ver tomar forma a tu menú, es necesario contratar un plan de mantenimiento por importe de"
    },
    BRL99:{
      message: "BRL 99,90"
    },
    month:{
      message: "por mes."
    },
    includesFacilities:{
      message: "Incluye instalaciones como:"
    },
    menuWithPhotos:{
      message: "menú completo con fotos;"
    },
    priceUpdate:{
      message: "actualización de precios en tiempo real;"
    },
    activateDeactivate:{
      message: "activar y desactivar productos rápidamente;"
    },
    categorizeFilteReorder:{
      message: "categorizar, filtrar y reordenar productos;"
    },
    newFeatures:{
      message: "¡Nuevas características añadidas cada semana!"
    },
    thePlan:{
      message: "firmar el plano"
    },
    company:{
      message: "EMPRESA"
    },
    fantasyName:{
      message: "NOMBRE DE FANTASÍA"
    },
    numberAccesses:{
      message: "NÚMERO DE ACCESOS"
    },
    entryDate:{
      message: "FECHA DE ENTRADA"
    },
    wantLeave:{
      message: "Ahora que has llegado hasta aquí, ¿estás seguro de que quieres irte?"
    },
    cancelYourSubscription:{
      message: "Lamentamos que desee cancelar su suscripción al plan MyTripMenu."
    },
    beforeConfirming:{
      message: "Antes de confirmar, debo decirte que si te vas, tus clientes ya no tendrán acceso al menú de tu establecimiento y el acceso a la URL y QRCode no estarán disponibles hasta que vuelvas a suscribirte al plan."
    },
    goAhead:{
      message: "Si estás seguro, adelante..."
    },
    unsubscribePlan:{
      message: "darse de baja del plan"
    },
    dontCancel:{
      message: "ya no quiero cancelar"
    },
    noChange:{
      message: "ningún cambio"
    },
    CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180:{
      message: "CAXA LABS SERVICOS DE DESENVOLVIMENTO DE SOFTWARE LTDA, Sociedad Limitada, registrada regularmente en el CNPJ bajo el Nro. 34.177.633/0001-91, con sede en Rua Felipe Dos Santos, nº 77, 1203 torre A, barrio Padre Reus, en la ciudad de São Leopoldo/RS, bajo CEP: 93.020-180."
    },
    Delivery: {
      message: "Entrega"
    },
    withdrawal: {
      message: "retiro"
    },
    Pickup: {
      message: "Recoger en el lugar"
    },
    Catalog: {
      message: "Catalogar"
    },

    Enteremail: {
      message: "Ingrese el correo electrónico"
    },
    emailinvalid: {
      message: "este email es invalido"
    },
    Typepassword: {
      message: "Escriba la contraseña"
    },
    least6digits: {
      message: "Este campo debe tener al menos 6 dígitos"
    },
     Cashpayment: {
      message: "No se puede editar el método de pago en efectivo"
    },
    Checkpayment: {
      message: "El método de pago con cheque no se puede editar"
    },
    save: {
      message: "Ahorrar"
    },
    logIn: {
      message: "INICIAR SESIÓN"
    },
    logged: {
      message: "USTED ESTÁ INICIADO SESIÓN"
    },
    enterAShortEasilyIdentifiableName: {
      message: "Introduzca un nombre corto y fácilmente identificable"
    },
    highlightssubcategoryCannotBeCopied: {
      message: "La subcategoría Destacados no se puede copiar"
    },
    aaaaaaaaaaaaaaaaaa: {
      message: "aaaaaaaaaaaaaaaaaaaaa"
    },
    unableToLoadCards : {
      message: "No se pueden cargar tarjetas"
    },
    invalidUser: {
      message: "Usuario invalido"
    },
    invalidPassword: {
      message: "Contraseña invalida"
    },
    inactiveUser: {
      message: "Usuario inactivo"
    }
  }
}
