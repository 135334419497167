import PropTypes from 'prop-types';
import React from 'react';
import ModalMytm from '../modal-mytm/ModalMytm';
import Terms from './terms/terms';
import {
  ModalTermsContent,
  ModalTermsTextContent,
  ModalTermsFooterContent,
  ModalTermsHeaderContent,
} from './Styles';
import Button from '../button/Button';
import { ModalTitle } from '../modal-mytm/Styles';
import TranslatorFunction from '../../components/I18n/Translator';

const ModalTerms = (props) => {

  const termsOfUseAanServices = TranslatorFunction({ path: "termsOfUseAanServices.message" })
  const back = TranslatorFunction({ path: "back.message" })

  const {
    isOpen, isLoading, onClose,
  } = props;
  return (
    <ModalMytm
      open={isOpen}
      isLoading={isLoading}
    >
      <ModalTermsContent>
        <ModalTermsHeaderContent>
          <ModalTitle>
            {termsOfUseAanServices}
          </ModalTitle>
        </ModalTermsHeaderContent>
        <ModalTermsTextContent>
          <Terms />
        </ModalTermsTextContent>
        <ModalTermsFooterContent>
          <Button onClick={onClose}>
            {back}
          </Button>
        </ModalTermsFooterContent>
      </ModalTermsContent>
    </ModalMytm>
  );
};

ModalTerms.defaultProps = {
  isLoading: false,
  onClose: () => { },
};

ModalTerms.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ModalTerms;
