import PropTypes from "prop-types";
import React from "react";
import {
  IconAmex,
  IconDiners,
  IconElo,
  IconMastercard,
  IconVisa,
} from "../../../../../../styles/icons";
import {
  CreditCardListFlagIcon,
  CreditCardListContent,
  CreditCardListParagraph,
  CreditCardListLink,
  CreditCardListSmallParagraph,
  CreditCardButtonContent,
  CreditCardListCardNumberText,
} from "./Styles";
import Button from "../../../../../../components/button/Button";

const defaultCardIcons = {
  mastercard: <IconMastercard />,
  visa: <IconVisa />,
  amex: <IconAmex />,
  elo: <IconElo />,
  diners: <IconDiners />,
  unknown: "",
};

const defaultBrandName = {
  mastercard: "Master Card",
  visa: "Visa",
  amex: "Amex",
  elo: "Elo",
  diners: "Diners",
};

const CreditCardList = ({ cards, onClickedTerms, onChangeCard }) => {
  const { brand, lastFourDigits } = cards[0];
  return (
    <CreditCardListContent>
      <CreditCardListParagraph>
        Cartão de Crédito {defaultBrandName[brand]}
      </CreditCardListParagraph>
      <CreditCardListCardNumberText>
        <CreditCardListFlagIcon>
          {defaultCardIcons[brand]}
        </CreditCardListFlagIcon>
        •••• •••• ••••
        {` ${lastFourDigits}`}
      </CreditCardListCardNumberText>
      <CreditCardListSmallParagraph>
        Os termos e condições estão disponíveis neste{" "}
        <CreditCardListLink onClick={onClickedTerms}>link.</CreditCardListLink>
      </CreditCardListSmallParagraph>
      <CreditCardButtonContent>
        <Button btnType="tertiary" onClick={onChangeCard}>
          trocar cartão de crédito
        </Button>
      </CreditCardButtonContent>
    </CreditCardListContent>
  );
};

CreditCardList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClickedTerms: PropTypes.func.isRequired,
  // onChangeCard: PropTypes.func.isRequired,
};

export default CreditCardList;
