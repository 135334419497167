import styled, { css } from "styled-components";

const colImageCss = css`
  width: ${({ withStar }) => (withStar ? "197px" : "132px")};
  min-width: ${({ withStar }) => (withStar ? "197px" : "132px")};
  padding: 0;
`;

export const Container = styled.div`
  padding-bottom: 80px !important;
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 24px;
  padding-bottom: 80px !important;
`;

export const TableColTitle = styled.th`
  text-align: ${({ textAlign }) => textAlign || "center"};
  padding-left: ${({ textAlign }) => (textAlign === "left" ? "18px" : "0px")};
  padding-right: 15px;
  vertical-align: middle;
  border-bottom: 1px solid ${({ theme }) => theme.colors.textColorDisabled};
  ${({ colImage }) => colImage && colImageCss};
`;

export const TableCol = styled.td`
  text-align: ${({ textAlign }) => textAlign || "center"};
  vertical-align: middle;
  padding: 0 15px;
  min-width: 150px;
  white-space: pre-wrap;

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  font-size: 18px;
  color: #6a6a6a;
  h1 {
    font-size: 18px;
  }
  .printer {
    cursor: pointer;
  }
`;

export const TableRow = styled.tr`
  height: 80px;
  /* display: block; */
  background-color: ${({ theme }) => theme.colors.mainColorContrast};

  &:nth-child(even) {
    background: rgba(230, 230, 230, 0.3);
  }

  ${TableCol} {
    height: 80px;
  }

  ${TableCol}, ${TableColTitle} {
    border-right: 1px solid ${({ theme }) => theme.colors.textColorDisabled};
  }

  &:last-of-type(${TableCol}),
  &:last-of-type(${TableColTitle}) {
    border-right: none;
  }
`;
