import styled from "styled-components";
import { IconAdd } from "../../styles/icons";
import Button from "../button/Button";

const TabContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TabUlContent = styled.ul`
  display: inline-flex;
  position: relative;
  list-style: none;
  margin: 16px 0;
  flex: 0 0 100%;
`;

const TabItemsContent = styled.div`
  min-width: 100%;
  width: 100%;
  height: 100%;
  flex: 0 0 100%;
`;

const TabButtonContent = styled.div`
  position: absolute;
  margin-top: 24px;
  right: 16px;
`;

const TabButton = styled(Button)`
  padding: 0 14px;
  display: flex;
`;

const TabButtonIconAdd = styled(IconAdd)`
  background-color: #ffffff;
  border-radius: 50%;
  padding: 5px;
  position: relative;
  bottom: 0;
  margin-right: 6px;
`;

export {
  TabButton,
  TabContent,
  TabUlContent,
  TabItemsContent,
  TabButtonContent,
  TabButtonIconAdd,
};
