import React from "react";

import Tag from "../../../tag/Tag";
import { TableCol, ColCategoriesWrapper } from "../../Styles";

const getTagOfCategory = (key, category) => {
  const CategoryTag = (
    <Tag key={`${key}-${category._id}`} withButton={false} type="secondary">
      {category.name.toUpperCase()}
    </Tag>
  );
  return CategoryTag;
};

const getTagsOfCategories = (key, categories) => {
  const arrCategoriesTags = categories.map((category) => (
    <Tag key={`${key}-${category._id}`} withButton={false} type="secondary">
      {category.name.toUpperCase()}
    </Tag>
  ));
  return arrCategoriesTags;
};

function CategoriesCol({ withCategories, obj, categoriesKey, objectKey }) {
  const renderCategories = (obj) => {
    const categories = obj[categoriesKey];
    const key = obj[objectKey];
    if (categories.length > 0) {
      return getTagsOfCategories(key, categories);
    }
    return getTagOfCategory(key, categories);
  };

  return (
    <>
      {withCategories ? (
        <TableCol colCategories>
          <ColCategoriesWrapper>{renderCategories(obj)}</ColCategoriesWrapper>
        </TableCol>
      ) : null}
    </>
  );
}

export default CategoriesCol;
