import React from "react";

import { TableColTitle, TableOrderButtons } from "../../Styles";

function TableHeadColumns({
  titles,
  colTitleKeysAlign,
  orderTitles,
  currentOrderArrowActive,
  currentOrderActive,
  handleOnClickOrder,
}) {
  return (
    <>
      {titles.map((title, index) => {
        const keyValue = `th-${index}`;
        const textAlign = colTitleKeysAlign[index];
        const currentOrderTitle = orderTitles.find(
          (obj) => obj.titleKeyIndex === index
        );
        return (
          <TableColTitle key={keyValue} textAlign={textAlign}>
            {title}
            {currentOrderTitle !== undefined &&
            currentOrderTitle.titleKey === title ? (
              <TableOrderButtons
                isUpActive={
                  title === currentOrderActive &&
                  currentOrderArrowActive === "up"
                }
                isDownActive={
                  title === currentOrderActive &&
                  currentOrderArrowActive === "down"
                }
                tooltipUpText={currentOrderTitle.tooltipUpText}
                tooltipDownText={currentOrderTitle.tooltipDownText}
                onClickUp={() => handleOnClickOrder(title, "up")}
                onClickDown={() => handleOnClickOrder(title, "down")}
              />
            ) : null}
          </TableColTitle>
        );
      })}
    </>
  );
}

export default TableHeadColumns;
