import HttpClient from "../http-client";
import { id } from "../helper-client";

const putFormPayment = async (paymentId, data) => {
  return HttpClient.put(`paymentmethods/${paymentId}?userId=${id ?? ""}`, data);
};

const fetchFormPayments = async () =>
  HttpClient.get(`paymentmethods?userId=${id ?? ""}`);

export default {
  putFormPayment,
  fetchFormPayments,
};
