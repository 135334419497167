import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Paragraph16px400Regular, SourceSansProFont } from '../../styles/style-guide';

const LinkActive = css`
  background-color: ${({ theme }) => theme.colors.mainColor};
  color: ${({ theme }) => theme.colors.mainColorContrast};
`;

const MenuLink = styled(Link)`
  ${() => SourceSansProFont};
  ${() => Paragraph16px400Regular};
  color: ${({ theme }) => theme.colors.mainColor};
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  height: 48px;
  text-decoration: none;
  line-height: 50px;
  padding-left: 18px;
  cursor: pointer;
  ${({ isactive }) => isactive === 'true' && LinkActive};
`;

export default MenuLink;
