import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import EmptyScreen from "../../../../components/empty-screen/EmptyScreen";
import ListFormPayment from "./list-payment-form/ListFormPayment";
import EditFormPayment from "./edit-payment-form/EditFormPayment";
import restFormPayment from "../../../../api/formpayment/rest-formpayment";

const FormPayment = ({ openNewFormPayment, onHideNewFormPayment, onLoad }) => {
  const [formPayment, setFormPayment] = useState({});
  const [formPayments, setFormPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openNewFormPaymentModal, setOpenNewFormPaymentModal] = useState(
    openNewFormPayment
  );

  const handleOnLoad = (value) => {
    onLoad(value);
    setIsLoading(value);
  };

  const handleResponseSuccess = (response) => {
    setFormPayments(response);
    handleOnLoad(false);
  };

  const handleOnModalClose = () => {
    setOpenNewFormPaymentModal(false);
    setFormPayment({});
    onHideNewFormPayment();
    fetchFormPayment();
  };

  const handleOnClickEditFormPayment = (selectedFormPayment) => {
    setFormPayment(selectedFormPayment);
    setOpenNewFormPaymentModal(true);
  };

  const fetchFormPayment = () => {
    handleOnLoad(true);
    restFormPayment
      .fetchFormPayments()
      .then((res) => handleResponseSuccess(res.data))
      .catch(() => handleOnLoad(false));
  };

  useEffect(() => {
    fetchFormPayment();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setOpenNewFormPaymentModal(openNewFormPayment);
  }, [openNewFormPayment]);

  const renderFormPayment = () =>
    !isLoading && formPayment.lenght === 0 ? (
      <EmptyScreen />
    ) : (
      <ListFormPayment
        formPayments={formPayments}
        onClickEditFormPayment={handleOnClickEditFormPayment}
      />
    );

  return (
    <>
      {renderFormPayment()}
      <EditFormPayment
        open={openNewFormPaymentModal}
        formPayment={formPayment}
        onClose={handleOnModalClose}
      />
    </>
  );
};

FormPayment.defaultProps = {
  openNewFormPayment: false,
  onHideNewFormPayment: () => {},
  onLoad: () => {},
};

FormPayment.propTypes = {
  openNewFormPayment: PropTypes.bool,
  onHideNewFormPayment: PropTypes.func,
  onLoad: PropTypes.func,
};

export default FormPayment;
