import styled from "styled-components";
import { Subtitle16px400RegularUppercase } from "../../../../../styles/style-guide";

const NewProducsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .MuiFormControl-root label {
    color: #818181 !important;
  }
`;

const ContentContainer = styled.div`
  height: 100%;
`;

const PageSubtitleContent = styled.div`
  display: flex;
  padding: 29px 0 20px 18px;
`;

const Subtitle = styled.h4`
  margin: 0 8px;
  height: 20px;
  ${() => Subtitle16px400RegularUppercase}
`;

export { NewProducsContainer, ContentContainer, PageSubtitleContent, Subtitle };
