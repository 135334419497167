import React from 'react';
import PropTypes from 'prop-types';
import Main from './Styles';

const PageContainer = (props) => {
  const { children } = props;
  return (<Main>{children}</Main>);
};

PageContainer.defaultProps = {
  children: '',
};

PageContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
  ]),
};

export default PageContainer;
