import React, { useState, useEffect, useMemo } from "react";
import { MuiTextFieldStyled } from "../../../../../components/inputs/mui-inputs/Styles";
import { toast } from "react-toastify";

import ModalMytm from "../../../../../components/modal-mytm/ModalMytm";
import {
  ModalWrapper,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "../../../../../components/modal-mytm/Styles";

import restComplement from "../../../../../api/complement/rest-complement";
import restComplementGroup from "../../../../../api/complementgroup/rest-complement-group";

import {
  NewComplementGroupInputContent,
  NewComplementGroupInputContext,
  NewComplementGroupDivision,
  NewComplementGroupForm,
  NewComplementGroupButtonSave,
  NewComplementGroupButtonGoBack,
  NewComplementGroupTableWrapper,
  NewComplementGroupTableContainer,
} from "./styles";

import ButtonAdd from "../../../../../components/button-add/button-add";
import Table from "../../../../../components/table/Table";

import ComplementGroupForm from "./complementGroupForm";
import TranslatorFunction from "../../../../../components/I18n/Translator"

export default function NewComplementGroup(props) {
  const complementUpper = TranslatorFunction({ path: 'complementUpper.message' });
  const descriptionOfComplementUpper = TranslatorFunction({ path: 'descriptionOfComplementUpper.message' });
  const statusS = TranslatorFunction({ path: 'status.message' });
  const complementGroupIsInactive = TranslatorFunction({ path: 'complementGroupIsInactive.message' });
  const addComplementGroup = TranslatorFunction({ path: 'addComplementGroup.message' });
  const complementGroupName = TranslatorFunction({ path: 'complementGroupName.message' });
  const complementName = TranslatorFunction({ path: 'complementName.message' });
  const descriptionOfComplement = TranslatorFunction({ path: 'descriptionOfComplement.message' });
  const checkAllFields = TranslatorFunction({ path: 'checkAllFields.message' });
  const saveEditions = TranslatorFunction({ path: 'saveEditions.message' });
  const back = TranslatorFunction({ path: 'back.message' });
  const language = TranslatorFunction({ path: 'language.message' });


  const { open, onClose, complementGroup } = props;
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //form que vem do backend + front
  console.log('complementGroup', complementGroup)
  const [form, setForm] = useState({ ...complementGroup });

  // array de todos os complementos
  const [complementArray, setComplementArray] = useState([]);

  //novos valores antes de mandar pro form
  const [oldForm, setOldForm] = useState([]);

  //valor do complement from
  const [values, setValues] = useState({
    name: "",
    description: "",
    id: 0,
    active: true,
  }); //valor do form

  //editando ou criando
  const [isEditMode, setIsEditMode] = useState(false);

  const colTitleKeysAlign = ["left", "center", "center"];
  const complementGroupKeys = ["name", "description"];
  const listTitles = [complementUpper, descriptionOfComplementUpper, statusS];
  const colKeyAligns = { name: "left", description: "left" };
  const configToast = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const handleOnClickGoBack = () => {
    setOpenModal(false);
    onClose();
    setForm({ ...complementGroup });
    setValues({ name: "", description: "", id: 0, active: true });
    setOldForm([]);
    setComplementArray([]);
  };

  const handleChangeValue = (event) => {
    const { value } = event.target;
    var oldValueForm = { ...form };
    if (Array.isArray(oldValueForm?.complementGroup.translatedComplementGroup) && oldValueForm.complementGroup.translatedComplementGroup.length > 0) {
      oldValueForm.complementGroup.translatedComplementGroup[0].name[0][language] = value;
      oldValueForm.complementGroup.name = value;
    } else {
      oldValueForm.complementGroup.name = value;
    }
    setForm(oldValueForm);
  };

  const handleChangeNewValue = (event) => {
    let name =  event.target.name;
    let value =  event.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleAddToList = () => {
    let oldForm_ = [...complementArray];
    let beforeForm = [...oldForm];
    beforeForm.push(values);
    oldForm_.push(values);
    setComplementArray(oldForm_);
    setOldForm(beforeForm);
    setValues({
      description: "",
      name: "",
      id: oldForm.length + 1,
      active: true,
    });
  };

  const putAndPostComplement = async () => {
    try {
      if (oldForm.length > 0) {
        await restComplement.postComplement(form.complementGroup._id, {
          complementArray: oldForm,
          from: language
        });
      }
      if (form.complement.length > 0) {
        await restComplement.putComplement({
          complementArray: form.complement,
          from: language
        });
      }
      handleResponseSuccess();
    } catch (error) {
      console.log(error);
      handleResponseError();
    }
    setIsLoading(false);
  };

  const putComplementGroup = () => {
    restComplementGroup
      .putComplementGroup(form.complementGroup._id, {
        complementGroup: form.complementGroup,
        from: language
      })
      .then(() => putAndPostComplement())
      .catch((err) => handleResponseError(err));
  };

  const handleResponseSuccess = () => {
    setIsLoading(false);
    setOpenModal(false);
    setForm({ ...ComplementGroupForm });
    setValues({ name: "", description: "", id: 0, active: true });
    setOldForm([]);
    setComplementArray([]);
    onClose();
  };

  const handleResponseError = () => {
    setIsLoading(false);
    setOpenModal(false);
    setForm({ ...ComplementGroupForm });
    setValues({ name: "", description: "", id: 0, active: true });
    setOldForm([]);
    setComplementArray([]);
    onClose();
  };

  const postComplement = async ({ _id }) => {
    try {
      await restComplement.postComplement(_id, {
        complementArray: oldForm,
        from: language
      });
      handleResponseSuccess();
    } catch (error) {
      console.log(error);
      handleResponseError(error);
    }
    setIsLoading(false);
  };

  const postComplementGroup = () => {
    restComplementGroup
      .postComplementGroup({
        complementGroup: form.complementGroup,
        from: language
      })
      .then(({ data }) => postComplement(data))
      .catch((res) => handleResponseError(res));
  };

  const handleOnClickSave = () => {
    setIsLoading(true);
    isEditMode ? putComplementGroup() : postComplementGroup();
  };

  const handleOnChangeActiveToggle = (selectedComplement) => {
    if (!form.complementGroup.active)
      toast.error(complementGroupIsInactive, configToast);

    const isNew = selectedComplement._id ? false : true;

    if (!isNew) {
      const complementIndex = form.complement.findIndex(
        (comp) => comp._id === selectedComplement._id
      );

      setForm((oldData) => {
        const newData = { ...oldData };
        if (newData.complementGroup.active) {
          newData.complement[complementIndex].active =
            !newData.complement[complementIndex].active;
        }
        return newData;
      });
      return;
    }

    const findedIndexComplement = oldForm.findIndex(
      (compl) => compl.id === selectedComplement.id
    );

    setOldForm((oldData) => {
      const newData = [...oldData];
      if (form.complementGroup.active) {
        newData[findedIndexComplement].active =
          !newData[findedIndexComplement].active;
      }
      return newData;
    });
  };

  const handleChangeTable = (data, event) => {
    const isNew = data._id ? false : true;
    const { value, name } = event.target;
    const valuesInArray = [...complementArray];

    const findedIndexInArray = complementArray.findIndex(
      (comp) => comp === data
    );

    if (isNew) {
      const values = [...oldForm];

      const findedIndexComplement = oldForm.findIndex(
        (compl) => compl.id === data.id
      );

      values[findedIndexComplement][name] = value;

      valuesInArray[findedIndexInArray] = values[findedIndexComplement];

      setOldForm(values);
      setComplementArray(valuesInArray);

      return;
    }

    const findedIndexComplement = form.complement.findIndex(
      (compl) => compl._id === data._id
    );

    const values = [...form.complement];
    values[findedIndexComplement][name] = value;

    valuesInArray[findedIndexInArray] = values[findedIndexComplement];
    setForm({ ...form, complement: values });
    setComplementArray(valuesInArray);

    return;
  };

  const isFilledComplementFields = useMemo(() => {
    return values.name !== "";
  }, [values]);

  const beSaved = useMemo(() => {
    const isFilledTable = complementArray.every((val) => val.name !== "");
    const isFilledForm = form?.complementGroup?.name !== "";

    return isFilledForm && isFilledTable;
  }, [form, complementArray]);

  useEffect(() => {
    const isEdit = Object.keys(complementGroup).length > 0;
    const { complement } = form;
    if (complementArray.length === 0) setComplementArray(complement ?? []);

    setIsEditMode(isEdit);
    setForm(Object.keys(form).length === 0 ? ComplementGroupForm : form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    setOpenModal(open);
    setForm(complementGroup);
    //eslint-disable-next-line
  }, [open]);

  return (
    <ModalMytm open={openModal} isLoading={isLoading}>
      <ModalWrapper>
        <ModalTitle>{addComplementGroup}</ModalTitle>
        <ModalBody noOverflow>
          <NewComplementGroupInputContent>
            <NewComplementGroupInputContext>
              {/* <NewComplementGroupInputTitle>
                NOME DO GRUPO DE COMPLEMENTO
              </NewComplementGroupInputTitle>
              <NewComplementGroupInput
                value={form?.complementGroup?.name}
                onChange={handleChangeValue}
              /> */}
              <MuiTextFieldStyled
                name="groupName"
                label={complementGroupName}
                variant="outlined"
                margin="dense"
                value={Array.isArray(form?.complementGroup?.translatedComplementGroup) && form?.complementGroup?.translatedComplementGroup.length > 0 ? (form?.complementGroup?.translatedComplementGroup[0].name[0][language] !== undefined ? form?.complementGroup?.translatedComplementGroup[0].name[0][language] : form?.complementGroup?.name): form?.complementGroup?.name}
                onChange={handleChangeValue}
              />
            </NewComplementGroupInputContext>
          </NewComplementGroupInputContent>
          <NewComplementGroupDivision />

          <NewComplementGroupForm>
            <NewComplementGroupInputContext>

              <MuiTextFieldStyled
                name="name"
                label={complementName}
                variant="outlined"
                margin="dense"
                value={values.name}
                onChange={handleChangeNewValue}
              />
            </NewComplementGroupInputContext>

            <NewComplementGroupInputContext>
              <MuiTextFieldStyled
                name="description"
                label={descriptionOfComplement}
                variant="outlined"
                margin="dense"
                value={values.description}
                onChange={handleChangeNewValue}
              />
            </NewComplementGroupInputContext>
            <ButtonAdd
              onClick={() => {
                if (isFilledComplementFields) handleAddToList();
              }}
              className="button"
            />
          </NewComplementGroupForm>
          <NewComplementGroupTableContainer>
            <NewComplementGroupTableWrapper>
              <Table
                data={complementArray ?? []}
                setData={setComplementArray}
                titles={listTitles}
                colKeysAlign={colKeyAligns}
                keys={complementGroupKeys}
                onClickToogle={handleOnChangeActiveToggle}
                colTitleKeysAlign={colTitleKeysAlign}
                statusKey="active"
                withStatus
                withEditMode
                objectKey="_id"
                withActions={false}
                onChangeInput={(data, event) => handleChangeTable(data, event)}
              />
            </NewComplementGroupTableWrapper>
          </NewComplementGroupTableContainer>
        </ModalBody>

        <ModalFooter>
          <NewComplementGroupButtonSave
            disabled={isLoading}
            onClick={() => {
              if (beSaved) {
                handleOnClickSave();
                return;
              }
              toast.error(checkAllFields, configToast);
            }}
          >
            {saveEditions}
          </NewComplementGroupButtonSave>
          <NewComplementGroupButtonGoBack
            type="button"
            btnType="tertiary"
            onClick={handleOnClickGoBack}
          >
            {back}
          </NewComplementGroupButtonGoBack>
        </ModalFooter>
      </ModalWrapper>
    </ModalMytm>
  );
}
