import Cropper from "react-easy-crop";
import styled from "styled-components";
import { IconAdd } from "../../styles/icons";
import { CTA16px600SemiboldUppercase } from "../../styles/style-guide";
import Button from "../button/Button";

const ImageCropInput = styled.input`
  display: none;
`;

const ImageCropOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  display: ${({ isCropActive, isLoading }) =>
    isCropActive || isLoading ? "none" : "block"};
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  background-color: ${({ theme }) => theme.colors.mainColor};
`;

const ImageCropIconAdd = styled(IconAdd)`
  background-color: #ffffff;
  border-radius: 50%;
  padding: 5px;
  position: relative;
  bottom: 0px;
  margin-right: 6px;
`;

const ImageCropLabelButton = styled.label`
  ${CTA16px600SemiboldUppercase};
  position: relative;
  width: 174px;
  height: 17.2px;
  border-radius: 8px;
  outline: none;
  padding: 10.5px 16px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.mainColor};
  border: 1px solid ${({ theme }) => theme.colors.mainColor};
  color: ${({ theme }) => theme.colors.textColorContrast};
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  display: ${({ isCropActive, isLoading }) =>
    isCropActive || isLoading ? "none" : "flex"};
  opacity: 0;
  height: 40px;
  width: 200px;
`;

const ImageCropEmptyContent = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.textColorDisabled};
`;

const ImageCropEmptytWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-top: 75%;
`;

const ImageCropContent = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  background: ${({ theme }) => theme.colors.mainColorContrast};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageCropContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 75%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
`;

const ImageCropSaveButton = styled(Button)`
  z-index: 1;
  position: absolute;
  left: 10px;
  bottom: 8px;
`;

const ImageCropPreviewImg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  border-radius: 8px;
  object-fit: cover;
  object-position: bottom;
  background-color: ${({ theme }) => theme.colors.mainColor};
  ${({ isDefaultImage, minHeight }) =>
    isDefaultImage && `min-height: ${minHeight}px`};
`;

const ImageCropWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    ${ImageCropEmptyContent} {
      background-color: ${({ theme }) => theme.colors.mainColorLight};
    }

    ${ImageCropLabelButton} {
      z-index: 2;
      opacity: 1;
      transition: 0.2s ease-in-out;
    }

    ${ImageCropContent} ${ImageCropOverlay} {
      opacity: 0.5;
      transition: 0.2s ease-in-out;
    }
  }
`;

const ImageCropLoaderContent = styled.div`
  position: absolute;
  z-index: 3;
  display: ${({ isLoading }) => (isLoading ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.mainColor};
`;

const ImageCropLoaderOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.mainColor};
`;

const CropperStyled = styled(Cropper)`
  .reactEasyCrop_Container {
    .reactEasyCrop_CropArea .reactEasyCrop_CropAreaGrid {
      color: ${({ theme }) => theme.colors.mainColor};
    }
  }
`;

export {
  ImageCropWrapper,
  ImageCropInput,
  ImageCropEmptyContent,
  ImageCropEmptytWrapper,
  ImageCropOverlay,
  ImageCropIconAdd,
  ImageCropLabelButton,
  ImageCropContent,
  ImageCropContainer,
  CropperStyled,
  ImageCropSaveButton,
  ImageCropPreviewImg,
  ImageCropLoaderContent,
  ImageCropLoaderOverlay,
};
