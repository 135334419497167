import formValidators from "../../../../../utils/form-validators";

const URL = process.env.REACT_APP_URL;

const detailsForm = {
  fantasyName: {
    maxLength: "25",
    value: "",
    isRequired: true,
    isValid: true,
    touched: false,
    label: "tradeName.message",
    placeholder: "",
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
    },
  },
  companyName: {
    value: "",
    isRequired: true,
    isValid: false,
    touched: false,
    label: "companyCorporateName.message",
    placeholder: "informTheCompanyCorporateName.message",
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: true,
    },
    validators: {
      required: formValidators.validateInputRequired,
    },
  },
  facebookPixel: {
    value: "",
    isRequired: false,
    isValid: false,
    touched: false,
    label: "pixel.message",
    placeholder: "informPixel.message",
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: false,
    },
    validators: {},
  },
  CNPJ: {
    value: "",
    isRequired: true,
    isValid: false,
    touched: false,
    label: "CNPJ",
    placeholder: "00.000.000/0000-00",
    errorsMsg: {
      required: "thisFieldIsRequired.message",
      cnpj: "invalidCnpj.message",
    },
    hasErrors: {
      required: true,
      cnpj: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
      cnpj: formValidators.validateCNPJ,
    },
  },
  CEP: {
    value: "",
    isRequired: true,
    isValid: false,
    touched: false,
    label: "CEP",
    placeholder: "00000-000",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
      cep: "invalidCep.message",
    },
    hasErrors: {
      required: true,
      cep: false,
      httpError: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
      cep: formValidators.validateCEP,
    },
  },
  state: {
    value: "",
    isRequired: true,
    isValid: false,
    touched: false,
    label: "state.message",
    placeholder: "",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: true,
    },
    validators: {
      required: formValidators.validateInputRequired,
    },
  },
  city: {
    value: "",
    isRequired: true,
    isValid: false,
    touched: false,
    label: "city.message",
    placeholder: "",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: true,
    },
    validators: {
      required: formValidators.validateInputRequired,
    },
  },
  street: {
    value: "",
    isRequired: true,
    isValid: false,
    touched: false,
    label: "street.message",
    placeholder: "",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: true,
    },
    validators: {
      required: formValidators.validateInputRequired,
    },
  },
  addressNumber: {
    value: "",
    isRequired: true,
    isValid: false,
    touched: false,
    label: "number.message",
    placeholder: "informTheNumber.message",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: true,
    },
    validators: {
      required: formValidators.validateInputRequired,
    },
  },
  addressComplement: {
    value: "",
    isrequired: false,
    isValid: true,
    touched: false,
    label: "complement.message",
    placeholder: "",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {},
    validators: {},
  },
  subDomain: {
    value: URL,
    isRequired: true,
    isValid: true,
    touched: false,
    label: "URLToYourMenu.message",
    placeholder: "",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
    },
  },
  companyEmail: {
    value: "",
    isRequired: true,
    isValid: true,
    touched: false,
    label: "emailToLoginToTheManager.message",
    placeholder: "",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
      email: "",
    },
    hasErrors: {
      required: false,
      email: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
      email: formValidators.validateEmail,
    },
  },
  password: {
    value: "",
    isRequired: false,
    isValid: true,
    touched: false,
    label: "password.message",
    placeholder: "",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
    },
  },
  passwordConfirm: {
    value: "",
    isRequired: false,
    isValid: false,
    touched: false,
    label: "confirmPassword.message",
    placeholder: "",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
    },
  },
  userFirstName: {
    value: "",
    isRequired: true,
    isValid: true,
    touched: false,
    label: "name.message",
    placeholder: "",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
    },
  },
  userLastName: {
    value: "",
    isRequired: true,
    isValid: true,
    touched: false,
    label: "lastName.message",
    placeholder: "",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
    },
  },
  userEmail: {
    value: "",
    isRequired: true,
    isValid: true,
    touched: false,
    label: "contactEmail.message",
    placeholder: "",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
      email: formValidators.validateEmail,
    },
  },
  userPhone: {
    value: "",
    isRequired: true,
    isValid: true,
    touched: false,
    label: "cellphoneNumberWhatsApp.message",
    placeholder: "",
    isLoading: false,
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
    hasErrors: {
      required: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
    },
  },
  isValid: false,
};

export default detailsForm;
