import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import EmptyScreen from "../../../../components/empty-screen/EmptyScreen";
import ListSchedules from "./list-schedules/ListSchedules";
import EditSchedules from "./edit-schedules/EditSchedules";
import restSchedules from "../../../../api/schedules/rest-schedules";

const Schedules = ({ openNewSchedule, onHideNewSchedule, onLoad }) => {
  const [schedules, setSchedules] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openNewScheduleModal, setOpenNewScheduleModal] = useState(
    openNewSchedule
  );
  const fecthSchedules = () => {
    handleOnLoad(true);
    restSchedules
      .fetchSchedules()
      .then((res) => handleResponseSuccess(res.data))
      .catch(() => handleOnLoad(false));
  };

  const handleResponseSuccess = (response) => {
    setSchedules(response);
    handleOnLoad(false);
  };

  const handleOnModalClose = () => {
    setOpenNewScheduleModal(false);
    setSchedule({});
    onHideNewSchedule();
  };

  const handleOnClickEditSchedule = (selectedSchedule) => {
    setSchedule(selectedSchedule);
    setOpenNewScheduleModal(true);
  };

  const handleOnLoad = (value) => {
    onLoad(value);
    setIsLoading(value);
  };

  useEffect(() => {
    setOpenNewScheduleModal(openNewSchedule);
  }, [openNewSchedule]);

  useEffect(() => {
    fecthSchedules();
    // eslint-disable-next-line
  }, []);

  const renderSchedules = () =>
    !isLoading && schedules.length === 0 ? (
      <EmptyScreen />
    ) : (
      <ListSchedules
        schedules={schedules}
        onClickEditSchedules={handleOnClickEditSchedule}
      />
    );

  return (
    <>
      {renderSchedules()}
      <EditSchedules
        open={openNewScheduleModal}
        schedule={schedule}
        onClose={handleOnModalClose}
      />
    </>
  );
};

Schedules.defaultProps = {
  openNewSchedule: false,
  onHideNewSchedule: () => {},
  onLoad: () => {},
};

Schedules.propTypes = {
  openNewSchedule: PropTypes.bool,
  onHideNewSchedule: PropTypes.func,
  onLoad: PropTypes.func,
};
export default Schedules;
