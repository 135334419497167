import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import ModalOnboarding from '../modal-onboarding/ModalOnboarding';
import DetailsForm from './details-form/DetailsForm';
import DetailsQrCode from './details-qrcode/DetailsQrCode';

const Details = () => (
  <Container fluid>
    <Row>
      <Col col={8} xs={12} sm={8}>
        <DetailsForm />
      </Col>
      <Col col={4} xs={12} sm={4}>
        <DetailsQrCode />
      </Col>
    </Row>
    <ModalOnboarding />
  </Container>
);

export default Details;
