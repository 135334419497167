import styled from "styled-components";

export const Container = styled.div`
  padding: 32px 0 0px 16px;
  width: 100%;
  height: 92%;
  position: relative;
`;

export const ContainerFilters = styled.div`
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  div:nth-child(1) {
    margin-right: 12px !important;
  }
  z-index: 1;
  position: inherit;
`;

export const InputFilters = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  > div:nth-child(2) {
    width: 100%;
  }
`;

export const ContainerButton = styled.div``;
