import styled, { css } from 'styled-components';
import { IconArrowUp, IconArrowDown } from '../../styles/icons';

const buttonsCss = css`
  path {
    fill: ${({ theme, isactive }) => (isactive === 'true' ? theme.colors.mainColor : theme.colors.textColorDisabled)};
  }

  &:hover {
    path {
      fill: ${({ theme, isactive }) => (isactive === 'true' ? theme.colors.textColorDisabled : theme.colors.mainColor)};
    }
  }
`;

const OrderButtonsWrapper = styled.div`
  width: 10px;
  height: 20px;
  position: relative;
`;

const OrderButtonsIconArrowUp = styled(IconArrowUp)`
  ${buttonsCss};
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
`;

const OrderButtonsIconArrowDown = styled(IconArrowDown)`
  ${buttonsCss};
  position: absolute;
  bottom: -15px;
  left: 0px;
  cursor: pointer;
`;

export {
  OrderButtonsWrapper,
  OrderButtonsIconArrowUp,
  OrderButtonsIconArrowDown,
};
