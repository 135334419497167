import React, { useState } from "react";
import PropTypes from "prop-types";
import Card from "../../../../../components/styled/Card";
import CardSubtitle from "../../../../../components/styled/CardSubtitle";
import CreditCardForm from "./credit-card-form/CreditCardForm";
import CreditCardList from "./credit-card-list/CreditCardList";
import {
  SignaturesCreditCardContent,
  SignaturesCreditCardParagraph,
} from "./Styles";

import  { Translator } from '../../../../../components/I18n';
const SignaturesCreditCard = ({ cards, onCreateCard, onClickedTerms }) => {
  const withoutCardText = <Translator path="enterYourCreditCardDetails.message" />;
  const withCardText = <Translator path="thisIsTheCurrentPaymentMethod.message" />;
  const paymentMethod = <Translator path="paymentMethod.message" />;

  const [onChangeCard, setOnChangeCard] = useState(false);

  const handleOnChangeCard = () => {
    setOnChangeCard(true);
  };

  const handleOnCreateSuccess = () => {
    setOnChangeCard(false);
    onCreateCard();
  };

  return (
    <Card>
      <SignaturesCreditCardContent>
        <CardSubtitle>{paymentMethod}</CardSubtitle>
        <SignaturesCreditCardParagraph>
          {cards.length > 0 ? withCardText : withoutCardText}
        </SignaturesCreditCardParagraph>
        {cards.length > 0 && !onChangeCard ? (
          <CreditCardList
            cards={cards}
            onChangeCard={handleOnChangeCard}
            onClickedTerms={onClickedTerms}
          />
        ) : (
          <CreditCardForm
            onSuccessCreateCard={handleOnCreateSuccess}
            onClickedTerms={onClickedTerms}
            onChangeCard={onChangeCard}
          />
        )}
      </SignaturesCreditCardContent>
    </Card>
  );
};

SignaturesCreditCard.defaultProps = {
  onCreateCard: () => {},
};

SignaturesCreditCard.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClickedTerms: PropTypes.func.isRequired,
  onCreateCard: PropTypes.func,
};

export default SignaturesCreditCard;
