import React, { useState } from "react";
import PropTypes from "prop-types";
import { TableRow, TableCustom, TableBody } from "./Styles";
import defaultNoProductPhoto from "../../assets/no-product-photo.svg";
import formatters from "../../utils/formatters";
import TableHead from "./components/TableHead";
import TableHeadColumns from "./components/TableHeadColumns";
import ImageCol from "./components/ImageCol";
import TableBodyColumns from "./components/TableBodyColumns";
import CityCol from "./components/CityCol";
import CategoriesCol from "./components/CategoriesCol";
import SubCategoriesCol from "./components/SubCategoriesCol";
import StatusCol from "./components/StatusCol";
import ActionsCol from "./components/ActionsCol";
import IDCol from "./components/IDCol/IDCol";
import TypeCol from "./components/TypeCol/TypeCol";
import ValidateCol from "./components/ValidateCol/ValidateCol";
import UsageCol from "./components/UsageCol/UsageCol";

const Table = (props) => {


  const {
    data,
    keys,
    titles,
    withActions,
    withEditMode,
    withImage,
    imageKey,
    onClickEdit,
    objectKey,
    withStatus,
    withStarCategory,
    statusKey,
    onClickToogle,
    colKeysAlign,
    colKeysCurrency,
    colTitleKeysAlign,
    withCategories,
    categoriesKey,
    withSubCategories,
    subCategoriesKey,
    colKeysMaxWidth,
    onClickStar,
    handleStarShow,
    orderTitles,
    onClickOrder,
    disabledKeys,
    onChangeInput,
    withCities,
    isCoupon,
  } = props;
  const [currentOrderActive, setCurrentOrderActive] = useState("");
  const [currentOrderArrowActive, setCurrentArrowActive] = useState("");

  const isDisabledObject = (obj) => {
    const filterResult = disabledKeys.find(
      (objKey) => objKey.key === obj[objectKey]
    );
    return !!(filterResult !== null && filterResult !== undefined);
  };

  const getDisabledObject = (obj) =>
    disabledKeys.find((objKey) => objKey.key === obj[objectKey]);

  const handleOnClickOrder = (headerTitle, arrow) => {
    setCurrentOrderActive(headerTitle);
    setCurrentArrowActive(arrow);
    onClickOrder(headerTitle, arrow);
  };

  const handleOnClickEdit = (obj) => {
    if (!isDisabledObject(obj)) {
      onClickEdit(obj);
    }
  };

  const handleOnClickToggle = (obj) => {
    onClickToogle(obj);
  };

  const handleOnClickStar = (obj) => {
    onClickStar(obj);
  };

  const formatColValue = (value, key) => {
    const isFormatCurrencyField = colKeysCurrency.includes(key);
    return isFormatCurrencyField
      ? formatters.formatToCurrencyWithMonetaryValue(value)
      : value;
  };

  return (
    <>
      <TableCustom>
        <TableHead>
          <TableHeadColumns
            colTitleKeysAlign={colTitleKeysAlign}
            currentOrderActive={currentOrderActive}
            currentOrderArrowActive={currentOrderArrowActive}
            orderTitles={orderTitles}
            titles={titles}
            handleOnClickOrder={handleOnClickOrder}
          />
        </TableHead>
        <TableBody>
          {data?.map((obj, index) => {
            const keyValue = `d-${obj._id ?? obj.id ?? index}`;
            return (
              <TableRow key={keyValue}>
                <ImageCol
                  withImage={withImage}
                  defaultNoProductPhoto={defaultNoProductPhoto}
                  handleOnClickStar={handleOnClickStar}
                  handleStarShow={handleStarShow}
                  imageKey={imageKey}
                  withStarCategory={withStarCategory}
                  obj={obj}
                />
                <IDCol obj={obj} isCoupon={isCoupon} />
                <TableBodyColumns
                  keys={keys}
                  colKeysAlign={colKeysAlign}
                  colKeysMaxWidth={colKeysMaxWidth}
                  dataObject={obj}
                  withEditMode={withEditMode}
                  formatColValue={formatColValue}
                  onChangeInput={onChangeInput}
                />
                <TypeCol obj={obj} isCoupon={isCoupon} />
                <ValidateCol obj={obj} isCoupon={isCoupon} />
                <UsageCol obj={obj} isCoupon={isCoupon} />
                <CityCol
                  withCities={withCities}
                  obj={obj}
                  objectKey={objectKey}
                />
                <CategoriesCol
                  categoriesKey={categoriesKey}
                  obj={obj}
                  objectKey={objectKey}
                  withCategories={withCategories}
                />
                <SubCategoriesCol
                  obj={obj}
                  objectKey={objectKey}
                  subCategoriesKey={subCategoriesKey}
                  withSubCategories={withSubCategories}
                />
                <StatusCol
                  handleOnClickToggle={handleOnClickToggle}
                  obj={obj}
                  statusKey={statusKey}
                  withStatus={withStatus}
                />
                <ActionsCol
                  obj={obj}
                  withActions={withActions}
                  getDisabledObject={getDisabledObject}
                  handleOnClickEdit={handleOnClickEdit}
                  isDisabledObject={isDisabledObject}
                />
              </TableRow>
            );
          })}
        </TableBody>
      </TableCustom>
    </>
  );
};

Table.defaultProps = {
  withEditMode: false,
  withActions: true,
  withStatus: false,
  withImage: false,
  withStarCategory: true,
  withCategories: false,
  withSubCategories: false,
  withCities: false,
  imageKey: "",
  categoriesKey: "",
  subCategoriesKey: "",
  schedulesKey: "",
  statusKey: "",
  objectKey: "",
  colKeysAlign: {},
  colKeysMaxWidth: {},
  colTitleKeysAlign: [],
  colKeysCurrency: [],
  onClickEdit: () => { },
  onClickToogle: () => { },
  onClickStar: () => { },
  handleStarShow: () => { },
  onClickOrder: () => { },
  orderTitles: [],
  disabledKeys: [],
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  colKeysAlign: PropTypes.objectOf(PropTypes.string),
  colKeysMaxWidth: PropTypes.objectOf(PropTypes.string),
  colTitleKeysAlign: PropTypes.arrayOf(PropTypes.string),
  colKeysCurrency: PropTypes.arrayOf(PropTypes.string),
  orderTitles: PropTypes.arrayOf(
    PropTypes.shape({
      colKey: PropTypes.string,
      titleKey: PropTypes.string,
      titleKeyIndex: PropTypes.number,
      tooltipUpText: PropTypes.string,
      tooltipDownText: PropTypes.string,
    })
  ),
  withActions: PropTypes.bool,
  withEditMode: PropTypes.bool,
  withImage: PropTypes.bool,
  withStatus: PropTypes.bool,
  withCategories: PropTypes.bool,
  withStarCategory: PropTypes.bool,
  withCities: PropTypes.bool,
  categoriesKey: PropTypes.string,
  subCategoriesKey: PropTypes.string,
  schedulesKey: PropTypes.string,
  imageKey: PropTypes.string,
  statusKey: PropTypes.string,
  objectKey: PropTypes.string,
  onClickEdit: PropTypes.func,
  onClickToogle: PropTypes.func,
  onClickStar: PropTypes.func,
  onClickOrder: PropTypes.func,
  handleStarShow: PropTypes.func,
  disabledKeys: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
