import React, {
  createContext, useCallback, useContext, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useFunction } from '../hooks/custom-hooks';
import ModalGenericError from '../components/modal-generic-error/ModalGenericError';

const APIErrorContext = createContext({
  open: false,
  setOpen: () => { },
  title: '',
  setTitle: () => { },
  message: '',
  setMessage: () => { },
  error: {},
  setError: () => { },
  onClickTryAgain: () => { },
  setOnClickTryAgainFunc: () => { },
  onClickGoBack: () => { },
  setOnClickGoBackFunc: () => { },
});

const useAPIErrorContext = () => (
  useContext(APIErrorContext)
);

const APIErrorProvider = ({ children }) => {
  const [open, _setOpen] = useState(false);
  const [title, _setTitle] = useState();
  const [message, _setMessage] = useState();
  const [error, _setError] = useState(null);
  const [onClickTryAgain, _setOnClickTryAgain] = useFunction(() => { });
  const [onClickGoBack, _setOnClickGoBack] = useFunction(() => { });

  const setOpen = useCallback((e) => _setOpen(e), [_setOpen]);
  const setTitle = useCallback((e) => _setTitle(e), [_setTitle]);
  const setMessage = useCallback((e) => _setMessage(e), [_setMessage]);
  const setError = useCallback((e) => _setError(e), [_setError]);
  const setOnClickTryAgainFunc = useCallback((e) => _setOnClickTryAgain(e), [_setOnClickTryAgain]);
  const setOnClickGoBackFunc = useCallback((e) => _setOnClickGoBack(e), [_setOnClickGoBack]);

  const formatError = () => (
    error !== null ? `${error.code} - ${error.message}` : ''
  );

  const contextValue = {
    open,
    setOpen,
    title,
    setTitle,
    message,
    setMessage,
    error,
    setError,
    onClickTryAgain,
    setOnClickTryAgainFunc,
    onClickGoBack,
    setOnClickGoBackFunc,
  };

  return (
    <APIErrorContext.Provider value={contextValue}>
      <ModalGenericError
        open={open}
        title={title}
        message={message}
        errorMessage={formatError()}
        onClickTryAgain={onClickTryAgain}
        onClickGoBack={onClickGoBack}
      />
      {children}
    </APIErrorContext.Provider>
  );
};

APIErrorProvider.defaultProps = {
  children: <></>,
};

APIErrorProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.any,
  ]),
};

export {
  useAPIErrorContext,
};
export default APIErrorProvider;
