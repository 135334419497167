import {
  isValidCEP, isValidCNPJ, isValidCPF, isValidEmail, isValidMobilePhone,
} from '@brazilian-utils/brazilian-utils';
import creditCardNumberValidator from './credit-card-number-validator';

const getNormalYear = (year) => {
  const MAX_YEAR_AHEAD = 20;
  const currYear = new Date().getFullYear();
  let newYear = year + (Math.floor(currYear / 100) * 100);
  if (newYear > (currYear + MAX_YEAR_AHEAD)) {
    newYear -= 100;
  } else if (newYear <= ((currYear - 100) + MAX_YEAR_AHEAD)) {
    newYear += 100;
  }
  return newYear;
};

const validateInputRequired = (field) => {
  if (typeof field.value === 'string') {
    return field.value.length > 0;
  }

  if (typeof field.value === 'boolean') {
    return true;
  }

  if (Array.isArray(field.value)) {
    return field.value.length > 0;
  }
  return false;
};

const validateEmail = (field) => (
  isValidEmail(field.value)
);

const validateMinLength = (field) => (
  field.value.length >= field.minLength
);

const validatePhoneMobile = (field) => {
  const phoneWithoutMask = field.value.toString().split('(').join().split(')')
    .join();
  return isValidMobilePhone(phoneWithoutMask);
};

const validateCNPJ = (field) => {
  const cnpjWithoutMask = field.value.toString().split('.').join('').split('-')
    .join('')
    .split('/')
    .join('');
  return isValidCNPJ(cnpjWithoutMask);
};

const validateCpfCnpj = (field) => {
  const cpfCnpjWithoutMask = field.value.toString().split('.').join('').split('-')
    .join('')
    .split('/')
    .join('');
  if (cpfCnpjWithoutMask.length > 11) {
    return isValidCNPJ(cpfCnpjWithoutMask);
  }
  return isValidCPF(cpfCnpjWithoutMask);
};

const validateCvvLength = (field) => {
  const value = field.value.toString();
  return value.length >= 3;
};

const validateCardNumber = (field) => {
  const value = field.value.toString().replaceAll(' ', '');
  return value.length >= 15 && creditCardNumberValidator.getCardFlag(value) !== 'unknow';
};

const validateCardExpDateMinDate = (field) => {
  const value = field.value.toString();
  if (value !== '' || value) {
    const month = parseInt(value.split('/')[0], 10) - 1;
    const year = getNormalYear(parseInt(value.split('/')[1], 10));
    const today = new Date();
    const currYear = today.getFullYear();
    const currMonth = today.getMonth();

    if (year > currYear) {
      return true;
    }

    if (year === currYear && month > currMonth) {
      return true;
    }
  }

  return false;
};

const validateCardNameHasTwoWords = (field) => {
  let isFirstWordMoreThan0 = false;
  let isSecondWordMoreThan0 = false;
  const value = field.value.toString();
  const arrWords = value.split(' ');
  if (arrWords.length >= 2) {
    if (arrWords[0].length > 0) {
      isFirstWordMoreThan0 = true;
    }

    if (arrWords[1].length > 0) {
      isSecondWordMoreThan0 = true;
    }
    return isFirstWordMoreThan0 && isSecondWordMoreThan0;
  }
  return false;
};

const validateCEP = (field) => {
  const cepWithoutMask = field.value
    .toString().split('-').join('');
  return isValidCEP(cepWithoutMask);
};

export default {
  validateInputRequired,
  validateEmail,
  validateCNPJ,
  validatePhoneMobile,
  validateMinLength,
  validateCEP,
  validateCpfCnpj,
  validateCardNumber,
  validateCvvLength,
  validateCardExpDateMinDate,
  validateCardNameHasTwoWords,
};
