import React, { useEffect, useState } from "react";
import {
  MandadoryDataContainer,
  MandadoryDataContext,
  MandadoryDataCheckBox,
  MandadoryDataLabel,
  MandadoryDataLine,
} from "./Styles";
import PropTypes from "prop-types";
import Checkbox from "../../../../../components/checkbox/Checkbox";
import restMandatoryData from "../../../../../api/mandatorydata/rest-mandatory-data";
import mandatoryDataForm from "./mandatoryDataForm";
import TranslatorFunction from "../../../../../components/I18n/Translator"

const MandadoryData = ({ mandatoryData, getMandatoryData }) => {
  const [form, setForm] = useState(mandatoryData);
  const mandatoryDataForDelivery = TranslatorFunction({ path: 'mandatoryDataForDelivery.message' });
  const namee = TranslatorFunction({ path: 'name.message' });
  const phone = TranslatorFunction({ path: 'phone.message' });
  const address = TranslatorFunction({ path: 'address.message' });
  const handleResponseSuccess = (res) => {
    getMandatoryData();
    setForm(mandatoryData);
  };
  const handleOnChangeCheckBox = (event) => {
    const { name, value } = event;

    let data = mandatoryDataForm;
    data.address = form.address.active;
    data.cpf = form.cpf.active;
    data.cep = form.cep.active;
    data.name = form.name.active;
    data.phone = form.phone.active;
    data[name] = value;

    restMandatoryData
      .putMandatoryData(form._id, data)
      .then((res) => handleResponseSuccess(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setForm(mandatoryData);
  }, [mandatoryData]);

  return (
    <>
      <MandadoryDataContainer>
        <MandadoryDataLabel>{mandatoryDataForDelivery}</MandadoryDataLabel>
        <MandadoryDataContext>
          <MandadoryDataCheckBox>
            <Checkbox
              name="name"
              key={form?.name?.id_}
              value={form?.name?.active}
              onChange={handleOnChangeCheckBox}
            >
              {form?.name?.name ? namee: ""}
            </Checkbox>
          </MandadoryDataCheckBox>
          <MandadoryDataCheckBox>
            <Checkbox
              name="cpf"
              key={form?.cpf?.id_}
              value={form?.cpf?.active}
              onChange={handleOnChangeCheckBox}
            >
              {form?.cpf?.name ?? ""}
            </Checkbox>
          </MandadoryDataCheckBox>
          <MandadoryDataCheckBox>
            <Checkbox
              name="phone"
              key={form?.phone?.id_}
              value={form?.phone?.active}
              onChange={handleOnChangeCheckBox}
            >
              {form?.phone?.name ? phone : ""}
            </Checkbox>
          </MandadoryDataCheckBox>
          <MandadoryDataCheckBox>
            <Checkbox
              name="address"
              key={form?.address?.id_}
              value={form?.address?.active}
              onChange={handleOnChangeCheckBox}
            >
              {form?.address?.name ? address : ""}
            </Checkbox>
          </MandadoryDataCheckBox>
          <MandadoryDataCheckBox>
            <Checkbox
              name="cep"
              key={form?.cep?.id_}
              value={form?.cep?.active}
              onChange={handleOnChangeCheckBox}
            >
              {form?.cep?.name ?? ""}
            </Checkbox>
          </MandadoryDataCheckBox>
        </MandadoryDataContext>
        <MandadoryDataLine />
      </MandadoryDataContainer>
    </>
  );
};

MandadoryData.defaultProps = {
  MandadoryData: [],
  getMandadoryData: () => {},
};

MandadoryData.propTypes = {
  MandadoryData: PropTypes.arrayOf(PropTypes.object),
  getMandadoryData: PropTypes.func,
};

export default MandadoryData;
