import styled, { css } from "styled-components";
import { IconEdit } from "../../styles/icons";
import { IconCopy } from "../../styles/icons";
import { Paragraph16px300Light } from "../../styles/style-guide";
import OrderButtons from "../order-buttons/OrderButtons";

const skewDeg = -16;

const TableFoodImage = styled.div`
  position: relative;
  height: 70px;
  width: 140px;
  border-top-left-radius: 16px;
  transform: skew(${skewDeg}deg);
  overflow: hidden;
  right: -12px;

  &:after {
    content: "";
    position: absolute;
    background-image: url(${({ img }) => img});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0px;
    left: -24px;
    min-width: 153px;
    width: 100%;
    height: 100%;
    transform: skew(${-skewDeg}deg);
  }
`;

const TableCustom = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);

  ::-webkit-scrollbar {
    width: 100px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: 0px;
  }
`;

const TableButtonStarContent = styled.div`
  margin-top: 24px;
  margin-left: 16px;
`;

const TableOrderButtons = styled(OrderButtons)`
  float: right;
`;

const colImageCss = css`
  width: ${({ withStar }) => (withStar ? "197px" : "132px")};
  min-width: ${({ withStar }) => (withStar ? "197px" : "132px")};
  padding: 0;

  ${TableButtonStarContent} {
    float: left;
  }

  ${TableFoodImage} {
    float: right;
  }
`;

const colStatusCss = css`
  width: 133px;
`;

const colActionsCss = css`
  width: 10%;
`;

const colCategoriesCss = css`
  width: 125px;
`;

const colSubCategoriesCss = css`
  width: 100%;
`;
const colSchedulesCss = css`
  width: 100%;
`;

const TableColDivCenter = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const TableCol = styled.td`
  text-align: ${({ textAlign }) => textAlign || "center"};
  vertical-align: middle;
  padding: 0 15px;
  min-width: 150px;
  white-space: pre-wrap;

  ${Paragraph16px300Light};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};

  ${({ colImage }) => colImage && colImageCss};
  ${({ colStatus }) => colStatus && colStatusCss};
  ${({ colActions }) => colActions && colActionsCss};
  ${({ colCategories }) => colCategories && colCategoriesCss};
  ${({ colSubCategories }) => colSubCategories && colSubCategoriesCss};
  ${({ colSchedules }) => colSchedules && colSchedulesCss};
`;

const ColCategoriesWrapper = styled.div`
  float: left;
  display: inline-flex;
  flex-wrap: wrap;
`;

const TableColTitle = styled.th`
  text-align: ${({ textAlign }) => textAlign || "center"};
  padding-left: ${({ textAlign }) => (textAlign === "left" ? "18px" : "0px")};
  padding-right: 15px;
  vertical-align: middle;
  border-bottom: 1px solid ${({ theme }) => theme.colors.textColorDisabled};
  ${({ colImage }) => colImage && colImageCss};
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
  height: 80px;
  /* display: block; */
  background-color: ${({ theme }) => theme.colors.mainColorContrast};

  &:nth-child(even) {
    background: rgba(230, 230, 230, 0.3);
  }

  ${TableCol} {
    height: 80px;
  }

  ${TableCol}, ${TableColTitle} {
    border-right: 1px solid ${({ theme }) => theme.colors.textColorDisabled};
  }

  &:last-of-type(${TableCol}),
  &:last-of-type(${TableColTitle}) {
    border-right: none;
  }
`;

const TableDrag = styled.div`
  display: flex;
`;

const TableEditIcon = styled(IconEdit)``;
const TableCopyIcon = styled(IconCopy)``;

const TableInput = styled.input`
  border: none;
  width: 100%;
  height: 50%;
  padding-left: 5px;
  border: 1px solid #ff5533;
  border-radius: 5px;
  outline: #ff5533;
  text-align: ${({ textAlign }) => textAlign || "center"};
  ${Paragraph16px300Light};

  background-color: transparent;

  &:nth-child(even) {
    background-color: rgba(230, 230, 230, 0.3);
  }
`;

export {
  TableCustom,
  TableRow,
  TableCol,
  TableColDivCenter,
  TableColTitle,
  TableEditIcon,
  TableCopyIcon,
  TableFoodImage,
  TableButtonStarContent,
  TableOrderButtons,
  TableDrag,
  ColCategoriesWrapper,
  TableInput,
  TableBody,
};
