export default {
  translations: {
    language: {
      message: "it",
    },
    LoginFormTitle: {
      message: "Login"
    },
    LoginFormSubtitle: {
      message: "Ciao, inserisci la tua email e password per accedere:"
    },
    LoginFormErroMessage: {
      message: "Impossibile accedere, riprova."
    },
    LoginFormButton: {
      message: "Accesso"
    },
    LoginFormButtonForgotPassword: {
      message: "Ho dimenticato la password"
    },
    password: {
      message: "Password"
    },
    confirmPassword: {
      message: "Conferma password"
    },
    mail: {
      message: "E-mail"
    },
    myEstablishment: {
      message: "Il mio stabilimento"
    },
    parameterization: {
      message: "Parametrizzazione"
    },
    itemRegistration: {
      message: "Registrazione dell'oggetto"
    },
    orders: {
      message: "Richieste"
    },
    discountCoupons: {
      message: "Buoni sconto"
    },
    logout: {
      message: "Esci"
    },
    establishmentDetails: {
      message: "Dettagli stabilimento"
    },
    subscriptions: {
      message: "Abbonamenti"
    },
    aboutTheEstablishment: {
      message: "Sulla struttura"
    },
    mandatoryFilling: {
      message: "Riempimento obbligatorio"
    },
    tradeName: {
      message: "Nome commerciale (nome con cui è noto il tuo stabilimento)"
    },
    companyCorporateName: {
      message: "Ragione sociale"
    },
    informTheCompanyCorporateName: {
      message: "Informare la ragione sociale della società"
    },
    pixel: {
      message: "Pixel"
    },
    informPixel: {
      message: "Segnala pixel"
    },
    invalidCnpj: {
      message: "CNPJ non valido"
    },
    invalidCep: {
      message: "Codice postale errato"
    },
    state: {
      message: "stato"
    },
    city: {
      message: "Città"
    },
    cityUpper: {
      message: "CITTÀ"
    },
    neighborhoods: {
      message: "Quartiere"
    },
    street: {
      message: "Strada"
    },
    number: {
      message: "Numero"
    },
    informTheNumber: {
      message: "Immettere il numero"
    },
    complement: {
      message: "Complemento"
    },
    complementUpper: {
      message: "COMPLEMENTO"
    },
    URLToYourMenu: {
      message: "URL al tuo menu"
    },
    emailToLoginToTheManager: {
      message: "Email per accedere al gestore"
    },
    name: {
      message: "Nome"
    },
    nameUpper: {
      message: "NOME"
    },
    lastName: {
      message: "Cognome"
    },
    contactEmail: {
      message: "Contatto email"
    },
    cellphoneNumberWhatsApp: {
      message: "Cellulare/WhatsApp"
    },
    thisFieldIsRequired: {
      message: "Questo campo è obbligatorio."
    },
    enterYourCreditCardDetails: {
      message: "Inserisci i dati della tua carta di credito:"
    },
    thisIsTheCurrentPaymentMethod: {
      message: "Questo è il metodo di pagamento corrente:"
    },
    paymentMethod: {
      message: "Metodo di pagamento:"
    },
    paymentMethod2: {
      message: "Modalità di pagamento"
    },
    paymentMethod3: {
      message: "MODALITÀ DI PAGAMENTO"
    },
    cardNumber: {
      message: "Numero di carta:"
    },
    enterAValidCardNumber: {
      message: "Per favore inserisci UN numero di carta valido."
    },
    enterAValidDate: {
      message: "Per favore, inserisci una data valida."
    },
    cardholderName: {
      message: "Nome del titolare."
    },
    typeAsWrittenOnCard: {
      message: "Scrivi come scritto sulla carta."
    },
    enterTheNameAsItIsWrittenOnTheCard: {
      message: "Inserisci il nome nello stesso modo in cui è scritto sulla carta."
    },
    bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable: {
      message: "Salvando i tuoi dettagli di pagamento, autorizzi MyTripMenu ad addebitare l'importo del piano automaticamente ogni mese fino all'annullamento dell'abbonamento. Termini e condizioni sono disponibili"
    },
    inThisLink: {
      message: "A questo link."
    },
    savePaymentData: {
      message: "salvare i dati di pagamento"
    },
    addACreditCardToViewPlanInformationAndConfirmYourSubscription: {
      message: "Aggiungi una carta di credito per visualizzare le informazioni sul piano e confermare l'abbonamento."
    },
    deliveryConfiguration: {
      message: "Configurazione di consegna"
    },
    schedules: {
      message: "Orari"
    },
    Schedules: {
      message: "ORARI"
    },
    add: {
      message: "Inserisci"
    },
    onSiteServiceVisualization: {
      message: "Servizio in loco | Visualizzazione"
    },
    deliveryWhatsappOrders: {
      message: "Consegna | Richieste di whatsapp"
    },
    takeAway: {
      message: "Ritiro"
    },
    catalog: {
      message: "Catalogare"
    },
    typesOfService: {
      message: "TIPI DI SERVIZIO"
    },
    mandatoryDataForDelivery: {
      message: "DATI OBBLIGATORI PER LA CONSEGNA"
    },
    phone: {
      message: "Telefono"
    },
    phoneUpper: {
      message: "TELEFONO"
    },
    address: {
      message: "Indirizzo"
    },
    generalSettings: {
      message: "IMPOSTAZIONI GENERALI"
    },
    freeShippingForPurchasesAbove: {
      message: "SPEDIZIONE GRATUITA PER GLI ACQUISTI DI CUI SOPRA"
    },
    noFreeShipping: {
      message: "Nessuna spedizione gratuita"
    },
    minimumValueForDeliveryOrders: {
      message: "VALORE MINIMO PER ORDINI DI CONSEGNA"
    },
    minimumDeliveryTime: {
      message: "TEMPO MINIMO DI CONSEGNA"
    },
    noForecast: {
      message: "Nessuna previsione"
    },
    maximumDeliveryTime: {
      message: "TEMPO MASSIMO DI CONSEGNA"
    },
    orderResponseTimeInMinutes: {
      message: "TEMPO DI RISPOSTA DELL'ORDINE IN MINUTI"
    },
    serviceNumberViaWhats: {
      message: "NUMERO DI SERVIZIO VIA WHATSAPP"
    },
    servicePlaces: {
      message: "LUOGHI DI SERVIZIO"
    },
    action: {
      message: "AZIONI"
    },
    addEditCities: {
      message: "AGGIUNGI/MODIFICA CITTÀ"
    },
    newCity: {
      message: "Nuova città"
    },
    search: {
      message: "Ricerca"
    },
    addNew: {
      message: "Aggiungere nuova"
    },
    saveEditions2: {
      message: "SALVA EDIZIONI"
    },
    back: {
      message: "RITORNO"
    },
    status: {
      message: "STATO"
    },
    active: {
      message: "ATTIVO"
    },
    inactive: {
      message: "NON ATTIVO"
    },
    category: {
      message: "Categoria"
    },
    subcategory: {
      message: "Sottocategoria"
    },
    categories: {
      message: "Categorie"
    },
    subcategories: {
      message: "Sottocategorie"
    },
    categoriesUpper: {
      message: "CATEGORIE"
    },
    subcategoriesUpper: {
      message: "SOTTOCATEGORIE"
    },
    complements: {
      message: "Componenti aggiuntivi"
    },
    complementsGroup: {
      message: "Gruppo aggiuntivo"
    },
    complementsGroupUpper: {
      message: "GRUPPO DI COMPLEMENTI"
    },
    products: {
      message: "Prodotti"
    },
    image: {
      message: "IMMAGINE"
    },
    yes: {
      message: "Sì"
    },
    no: {
      message: "No"
    },
    theHighlightsCategoryCannotBeEdited: {
      message: "La categoria In evidenza non può essere modificata"
    },
    sortFromAZ: {
      message: "Ordina dalla A alla Z"
    },
    sortFromZA: {
      message: "Ordina da Z-A"
    },
    sortByActive: {
      message: "Ordina per attivo"
    },
    sortByInactive: {
      message: "Ordina per inattivo"
    },
    listActiveOnly: {
      message: "Elenco solo risorse"
    },
    addEditCategory: {
      message: "Adicionar/editar categoria"
    },
    addEditSubcategory: {
      message: "Aggiungi/Modifica sottocategoria"
    },
    imageOptional: {
      message: "Immagine (opzionale)"
    },
    addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive: {
      message: "L'aggiunta di un'immagine illustrativa alla categoria è un'azione facoltativa, tuttavia aiuta a rendere il tuo menu più attraente."
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality: {
      message: "Scegli una foto che illustri gli articoli che compongono questa categoria, abbia una buona illuminazione e sia realistica in relazione alla presentazione effettiva dei prodotti. Evita di utilizzare foto generiche, stock o di bassa qualità."
    },
    theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB: {
      message: "La risoluzione ideale è 800x600 pixel e l'immagine non può essere più grande di 1 MB."
    },
    selectPhoto: {
      message: "SELEZIONA FOTO"
    },
    selectCategory: {
      message: "SELEZIONA CATEGORIA"
    },
    saveAndAddAnother: {
      message: "SALVA E AGGIUNGI UN ALTRO"
    },
    highlightsSubcategoryCannotBeEdited: {
      message: "La sottocategoria In evidenza non può essere modificata"
    },
    categoryInWhichTheSubcategoryShouldAppear: {
      message: "Categoria in cui dovrebbe apparire la sottocategoria"
    },
    addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive: {
      message: "L'aggiunta di un'immagine illustrativa alla sottocategoria è un'azione facoltativa, tuttavia aiuta a rendere il tuo menu più attraente."
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos: {
      message: "Scegli una foto che illustri gli articoli che compongono questa sottocategoria, abbia una buona illuminazione e sia realistica in relazione alla presentazione effettiva dei prodotti. Evita di utilizzare foto generiche, stock o di bassa qualità."
    },
    enterANameForTheSubcategory: {
      message: "Inserisci un nome per la sottocategoria"
    },
    enterANameForTheCategory: {
      message: 'Immettere un nome per la categoria'
    },
    descriptionOfComplement: {
      message: "Descrizione del componente aggiuntivo"
    },
    descriptionOfComplementUpper: {
      message: "DESCRIVERE PREGO"
    },
    addComplementGroup: {
      message: "AGGIUNGI GRUPPO COMPLEMENTO"
    },
    complementGroupName: {
      message: "Nome gruppo aggiuntivo"
    },
    complementName: {
      message: "Nome Aggiuntivo"
    },
    checkAllFields: {
      message: "Controlla tutti i campi."
    },
    complementGroupIsInactive: {
      message: "Il gruppo di componenti aggiuntivi è inattivo."
    },
    value: {
      message: "Valore"
    },
    valueUpper: {
      message: "VALORE"
    },
    addProduct: {
      message: "AGGIUNGI PRODOTTO"
    },
    images: {
      message: "Immagini"
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos: {
      message: "Scegli una foto che illustri gli elementi che compongono questo prodotto, abbia una buona illuminazione e sia realistica in relazione alla tua effettiva presentazione. Evita di utilizzare foto generiche, stock o di bassa qualità."
    },
    enableOn: {
      message: "Abilita su"
    },
    categorysInWhichTheProductShouldAppear: {
      message: "Categoria/e in cui dovrebbe apparire il prodotto"
    },
    subCategorysInWhichTheProductShouldAppear: {
      message: "Sottocategorie in cui dovrebbe apparire il prodotto"
    },
    categorization: {
      message: "Categorizzazione"
    },
    shortDescriptionShownInProductDetail: {
      message: "Breve descrizione (mostrata nei dettagli del prodotto)"
    },
    longDescriptionShownInProductDetail: {
      message: "Descrizione lunga (mostrata nei dettagli del prodotto)"
    },
    enterTheIngredientsThatMakeUpThisProductItsAccompanimentsAndThePortionSizes: {
      message: "Inserisci gli ingredienti che compongono questo prodotto, i suoi accompagnamenti e le dimensioni delle porzioni"
    },
    enterSomethingLikeSideDishesOrPortionSize: {
      message: "Digita qualcosa come contorni o porzioni"
    },
    principalInformation: {
      message: "Informazioni principali"
    },
    id: {
      message: "ID"
    },
    type: {
      message: "GENERE"
    },
    validity: {
      message: "Validità"
    },
    validityUpper: {
      message: "VALIDITÀ"
    },
    useAvailable: {
      message: "UTILIZZO / DISPONIBILE"
    },
    filter: {
      message: "Filtro"
    },
    freeDelivery: {
      message: "CONSEGNA GRATUITA"
    },
    discount: {
      message: "SCONTO"
    },
    aCouponWithThisCodeAlreadyExists: {
      message: "Esiste già un coupon con quel codice."
    },
    dateTime: {
      message: "APPUNTAMENTO"
    },
    change: {
      message: "COSA"
    },
    formOfPayment: {
      message: "FORMA DI PAGAMENTO"
    },
    customer: {
      message: "CLIENTE"
    },
    uninformed: {
      message: "Disinformato"
    },
    recordsFound: {
      message: "Record trovati"
    },
    QRCodeOfYourDigitalMenu: {
      message: "QR Code del tuo menù digitale"
    },
    downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu: {
      message: "Scarica il tuo QR Code e rendilo disponibile presso la tua struttura o nei materiali promozionali per facilitare l'accesso dei clienti al tuo menu digitale"
    },
    areYouSureYouWantToDoThis: {
      message: "Sei sicuro di volerlo fare?"
    },
    whenDisablingTheComplementAllPreviouslyLinkedComplementGroupsWillBeUnlinkedFromTheProduct: {
      message: "Disattivando il componente aggiuntivo, tutti i gruppi di componenti aggiuntivi collegati in precedenza verranno scollegati dal prodotto."
    },
    anErrorOccurredWhileFetchingProductCategories: {
      message: "Si è verificato un errore durante il recupero delle categorie di prodotti"
    },
    anErrorOccurredWhileFetchingProductOperations: {
      message: "Si è verificato un errore durante il recupero delle operazioni del prodotto"
    },
    anErrorOccurredWhileFetchingTheSubcategoriesForTheSelectedCategory: {
      message: "Si è verificato un errore durante il recupero delle sottocategorie per la categoria selezionata"
    },
    productImage: {
      message: "Immagine del prodotto"
    },
    whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus: {
      message: "Quando disattivi un prodotto, questo non apparirà più nei menu online"
    },
    RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary: {
      message: "Ricorda che puoi riattivarlo in un altro momento, se necessario."
    },
    inactivateTheProduct: {
      message: "INATTIVARE IL PRODOTTO"
    },
    keepActive: {
      message: "TENERE ATTIVO"
    },
    simpleEasyFastDigital: {
      message: "Semplice. Facile. Veloce. Digitale."
    },
    errorIdentification: {
      message: "Identificazione dell'errore"
    },
    tryAgain: {
      message: "RIPROVA"
    },
    huhLooksLikeSomethingWentWrong: {
      message: "Eh! Sembra che qualcosa sia andato storto..."
    },
    unableToSaveCreditCardDetails: {
      message: "Impossibile salvare i dettagli della carta di credito."
    },
    mmyy: {
      message: "MM/AA"
    },
    removePhoto: {
      message: "rimuovi foto"
    },
    addEditCoupon: {
      message: "AGGIUNGI/MODIFICA COUPON"
    },
    couponType: {
      message: "TIPO DI BUONO"
    },
    freeShipping: {
      message: "Spedizione gratuita"
    },
    discountByValue: {
      message: "Sconto per valore"
    },
    percentageDiscount: {
      message: "Sconto percentuale"
    },
    generateCoupon: {
      message: "GENERARE COUPON"
    },
    limitOfUse: {
      message: "LIMITE DI UTILIZZO"
    },
    couponCode: {
      message: "CODICE COUPON"
    },
    validFrom: {
      message: "VALIDO DI"
    },
    validUntil: {
      message: "VALIDO FINO A"
    },
    highlightsSubcategoryCannotBeCopied: {
      message: "Impossibile copiare la sottocategoria Highlights"
    },
    nothingHere: {
      message: "Sembra che non ci sia ancora niente qui."
    },
    from: {
      message: "IN"
    },
    to: {
      message: "FINO AL"
    },
    sunday: {
      message: "Domenica"
    },
    monday: {
      message: "Secondo"
    },
    tuesday: {
      message: "Martedì"
    },
    wesnesday: {
      message: "Il quarto"
    },
    thursday: {
      message: "Quinto"
    },
    friday: {
      message: "Venerdì"
    },
    saturday: {
      message: "Sabato"
    },
    accessData: {
      message: "Accedi ai dati"
    },
    responsibleEstablishment: {
      message: "responsabile dell'Istituzione"
    },
    saveEditions: {
      message: "Salva le edizioni"
    },
    exitWithoutSaving: {
      message: 'USCIRE SENZA SALVARE'
    },
    saveEditionsAndExit: {
      message: 'SALVA LE MODIFICHE ED ESCI'
    },
    zeit: {
      message: "Volta"
    },
    cardsAccepted: {
      message: "Segna le carte accettate dalla tua struttura"
    },
    pixKey: {
      message: "Registra qui la tua chiave PIX"
    },
    noOptions: {
      message: "nessuna opzione"
    },
    neighborhoods2: {
      message: "QUARTIERI"
    },
    addEditNeighborhoods: {
      message: "AGGIUNGI/MODIFICA QUARTIERI"
    },
    newNeighborhoods: {
      message: "Nuovo quartiere"
    },
    qtyMinimum: {
      message: "QTÀ MINIMO"
    },
    qtymaximum: {
      message: "QTÀ MASSIMO"
    },
    linkGroup: {
      message: "COLLEGARE IL GRUPPO FORNITURE"
    },
    importantGroupComplements: {
      message: "GRUPPO DI COMPLEMENTI D'IMPORTAZIONE"
    },
    people: {
      message: "Le persone"
    },
    house: {
      message: "Case"
    },
    subcategoryProducts: {
      message: "Questa sottocategoria non ha prodotti associati"
    },
    productNotAdd: {
      message: "Questo prodotto non ha alcun componente aggiuntivo aggiunto"
    },
    linkSupplements: {
      message: "SUPPLEMENTI DI COLLEGAMENTO"
    },
    PRICE: {
      message: "PREZZO"
    },
    COMPLEMENTS: {
      message: "COMPLEMENTI"
    },
    DESCRIPTION: {
      message: "DESCRIZIONE"
    },
    action2: {
      message: "Azioni"
    },
    pixKey2: {
      message: "La tua chiave PIX"
    },
    ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn: {
      message: "Se esci dalla pagina dei dettagli della struttura prima di salvare le modifiche, perderai tutte le informazioni che hai già inserito."
    },
    couldNotLoadUserData: {
      message: "Impossibile caricare i dati utente"
    },
    termsOfUseAanServices: {
      message: "TERMINI DI UTILIZZO E SERVIZI"
    },
    hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou: {
      message: "Ciao! Felice di avere il tuo interesse! Prima di utilizzare i nostri servizi, prenditi del tempo per leggere i nostri Termini di utilizzo e servizio e conoscere le regole che regolano il nostro rapporto con te."
    },
    belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom: {
      message: "Di seguito chiariremo alcuni punti che riteniamo importanti. In caso di dubbi su qualsiasi punto discusso o meno in questo documento, non esitate a contattarci all'indirizzo hello@mytripmenu.com."
    },
    definitions: {
      message: "1. DEFINIZIONI:"
    },
    inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions: {
      message: "In questo strumento, intendiamo le seguenti espressioni secondo le seguenti definizioni:"
    },
    plataform: {
      message: "PIATTAFORMA"
    },
    webSystemAvailableThroughMyTripMenuComBrOfferedBy: {
      message: "sistema web disponibile tramite mytripmenu.com.br, offerto da"
    },
    andOwnershipOperationAndLiabilityOf: {
      message: " e la proprietà, il funzionamento e la responsabilità di "
    },
    users: {
      message: "UTENTI"
    },
    user: {
      message: "UTENTE"
    },
    where: {
      message: ", dove"
    },
    allThe: {
      message: "tutti i"
    },
    canViewAndUseTheServicesOfferedBy: {
      message: "può visualizzare e utilizzare i servizi offerti da"
    },
    establishment: {
      message: "ISTITUZIONE"
    },
    establishments: {
      message: "STABILIMENTI"
    },
    legalEntityRegisteredInThe: {
      message: "persona giuridica iscritta al"
    },
    whichContractsTheServicesOfferedBy: {
      message: ", che contrae i servizi offerti da"
    },
    client: {
      message: "CLIENTE"
    },
    customers: {
      message: "CLIENTI"
    },
    naturalPersonAccessingThe: {
      message: "persona fisica che accede al"
    },
    toViewTheMenu: {
      message: "per visualizzare il menu "
    },
    dataProcessing: {
      message: "ELABORAZIONE DATI"
    },
    pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy: {
      message: " Ai sensi dell'articolo 5, comma X, della Legge 13.709 del 2018, ogni operazione effettuata da "
    },
    withPersonalDataOf: {
      message: " con dati personali di "
    },
    suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction: {
      message: " , come quelli riferiti alla raccolta, produzione, ricezione, classificazione, utilizzo, accesso, riproduzione, trasmissione, distribuzione, elaborazione, archiviazione, conservazione, eliminazione, valutazione o controllo di informazioni, modifica, comunicazione, trasferimento, diffusione o estrazione. "
    },
    admission: {
      message: " 2. AMMISSIONE: "
    },
    thisInstrumentRegulatesTheConditionsOfUseOfThe: {
      message: " Questo strumento regola le condizioni di utilizzo del "
    },
    being: {
      message: "essendo"
    },
    aContractBetweenThe: {
      message: " un contratto tra il "
    },
    andThe: {
      message: " e il "
    },
    theUseOfTheServicesOfferedThrough: {
      message: " L'utilizzo dei servizi offerti tramite "
    },
    fromThe: {
      message: "dal "
    },
    expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies: {
      message: " indica espressamente di essere d'accordo con tutti i termini e le condizioni contenuti in questo strumento e con le disposizioni legali applicabili alla specie. "
    },
    the: {
      message: "Il "
    },
    understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy: {
      message: " comprende che il modello di contratto del servizio sarà tramite piani di abbonamento mensili prepagati preventivati ​​e offerti da "
    },
    accordingToInformationAvailableInThePlansAreaOfTheWebsite: {
      message: "in base alle informazioni disponibili nell'area piani del sito Web: "
    },
    forTheContractingOfCustomizedPlansThe: {
      message: "Per la contrattazione di piani personalizzati, il "
    },
    shouldContactThe: {
      message: "dovrebbe contattare il "
    },
    throughItsChannelsForTheElaborationOfASpecificBudget: {
      message: "attraverso i suoi canali, per l'elaborazione di un budget specifico. "
    },
    youUnderstandAndAgreeThatThe: {
      message: "COMPRENDI E ACCETTI CHE IL "
    },
    useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies: {
      message: " L'UTILIZZO DEI SERVIZI FORNITI SARÀ CONSIDERATO ACCETTAZIONE DA PARTE DELL'UTENTE DI QUESTE CONDIZIONI E DI TUTTE LE ALTRE DISPOSIZIONI LEGALI RELATIVE ALLA SPECIE."
    },
    ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform: {
      message: "CON L'ACCETTAZIONE DEI TERMINI DEL PRESENTE STRUMENTO, L'UTENTE AUTORIZZA ESPRESSAMENTE IL TRATTAMENTO DEI SUOI ​​DATI, AL FINE DI GARANTIRE IL MANTENIMENTO E IL BUON PERFORMANCE DELLE FUNZIONALITÀ DELLA PIATTAFORMA"
    },
    theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy: {
      message: "L'UTENTE, CON QUESTO ATTO, MANIFESTA IL SUO COMPLETO CONSENSO ALLA CONDIVISIONE DEI DATI RACCOLTI ED ELABORATI DA "
    },
    pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders: {
      message: ", AI SENSI DI QUESTO STRUMENTO, CON ALTRI MENÙ DI VIAGGIO CHE FANNO PARTE DEL SUO GRUPPO ECONOMICO O CHE SONO I SUOI ​​FORNITORI DI SERVIZI."
    },

    caseYou: {
      message: "Caso tu "
    },
    doNotAgree: {
      message: "NON ESSERE D'ACCORDO"
    },
    withTheProvisionsOfThisInstrument: {
      message: " con le disposizioni del presente strumento, "
    },
    dont: {
      message: 'NON'
    },
    accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom: {
      message: " accedere, visualizzare, scaricare o utilizzare in altro modo qualsiasi pagina, contenuto, informazione o servizio da "
    },
    theseTermsAreAvailableForReadingAtAnyTimeOn: {
      message: "Questi termini sono disponibili per la lettura, in qualsiasi momento, su "
    },
    inMessage: {
      message: "nel "
    },
    whoWeAreAndWhatWeDo: {
      message: "3. CHI SIAMO E COSA FACCIAMO:"
    },
    isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp: {
      message: "è un fornitore di servizi che ha sviluppato e concesso in licenza un sistema sotto forma di SaaS (Software as a Service), con funzionalità specifiche per aiutare"
    },
    and: {
      message: "e"
    },
    soThe: {
      message: "Così la"
    },
    offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe: {
      message: "offers a digital menu, allowing an improvement in efficiency and experience for both the"
    },
    asForThe: {
      message: "offre un menu digitale, consentendo un miglioramento dell'efficienza e dell'esperienza sia per il"
    },
    generalConditionsOfUse: {
      message: "4. CONDIZIONI GENERALI D'USO:"
    },
    onlyProvidesThe: {
      message: " fornisce solo il "
    },
    whichOffersDigitalMenuServicesForThe: {
      message: " che offre servizi di menu digitale per il "
    },
    itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe: {
      message: " la sua responsabilità essendo limitata al solo corretto funzionamento del "
    },
    andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe: {
      message: "e le sue funzionalità, secondo questo strumento e il piano contratto dal "
    },
    notStoppingThe: {
      message: ", senza fermare il"
    },
    noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu: {
      message: "nessuna responsabilità per la creazione, personalizzazione e diffusione dei contenuti attraverso il menu digitale."
    },
    justLicenseThe: {
      message: " basta concedere in licenza il "
    },
    toThe: {
      message: " al "
    },
    toThe2: {
      message: " Al "
    },
    andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe: {
      message: ", e non sussistono altri rapporti tra detti soggetti, per cui non è possibile attribuire all'"
    },
    liabilityForAnyDamageCauseToOthers: {
      message: " responsabilità per eventuali danni causati ad altri "
    },
    orToThirdPartiesForActsArisingFrom: {
      message: " o a terzi, per atti derivanti da "
    },
    whileUsingTheAvailableFeaturesOfThe: {
      message: " durante l'utilizzo delle funzionalità disponibili di "
    },
    declaresThatHeUnderstandsAndAcceptsThatThe: {
      message: "dichiara di aver compreso e di accettare che il "
    },
    doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe: {
      message: "non intende sostituire il menù fisico disponibile presso l'unità commerciale del "
    },
    beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween: {
      message: "essendo solo una soluzione complementare, al fine di facilitare la comunicazione tra "
    },
    atTheTimeOfRegistrationThe: {
      message: "Al momento della registrazione, il"
    },
    youCanUseAllTheServicesAvailableOnThe: {
      message: " Puoi utilizzare tutti i servizi disponibili sul "
    },
    declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse: {
      message: ", dichiarando di aver letto, compreso e accettato tutte le disposizioni contenute nelle presenti Condizioni d'Uso."
    },
    expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom: {
      message: "riconosce espressamente che, attraverso tale strumento, riceve da "
    },
    theGarantingOfALicenseToUseThe: {
      message: " la concessione di una licenza per l'uso del "
    },
    isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe: {
      message: ", non è trasferibile, ed è vietata la sublicenza, per l'uso in territorio nazionale o estero, per tutto il periodo di durata dell'adesione a tale termine, lasciando l'uso del "
    },
    inDisagreementWithTheProvisionOfThisInstrument: {
      message: " in disaccordo con le disposizioni di questo strumento."
    },
    isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt: {
      message: " è l'unico responsabile della sicurezza della tua password e dell'uso della tua registrazione su "
    },
    thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr: {
      message: ", pertanto, ti consigliamo di non condividere tali informazioni con terzi e, se tali informazioni vengono, per qualsiasi motivo, perse o "
    },
    hacked: {
      message: "violato"
    },
    mustImmediatelyInformThe: {
      message: " deve informare immediatamente il "
    },
    throughHelloMyTripMenuComInOrderToResolveTheIssue: {
      message: ", tramite hello@mytripmenu.com, per risolvere il problema."
    },
    itIsOnlyUpToThe: {
      message: "Spetta solo al "
    },
    liableForAnyDamagesCausedToThirdPartiesToOther: {
      message: " responsabile degli eventuali danni cagionati a terzi, ad altri "
    },
    orToItself: {
      message: " o a se stesso "
    },
    resultingFromTheUseOfTheFeaturesOfThe: {
      message: ", risultante dall'utilizzo delle caratteristiche del "
    },
    shouldNotUseTheServicesAvailableOnThe: {
      message: " non dovrebbe utilizzare i servizi disponibili sul "
    },
    forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe: {
      message: " per qualsiasi uso illegale, diffamatorio, discriminatorio, offensivo, offensivo, pornografico, osceno, aggressivo, offensivo, vessatorio, ingannevole, calunnioso, violento, volgare o molesto, minaccioso o di falsa identità, vale a dire qualsiasi uso losco, scopi o mezzi che potrebbe danneggiare il "
    },
    others: {
      message: " altri"
    },
    orThirdParties: {
      message: " o terzi"
    },
    UnderNoCircumstancesWillThe: {
      message: "In nessun caso il "
    },
    willBeLiableForAnyDamagesIncurredByThe: {
      message: "sarà responsabile per eventuali danni subiti dal "
    },
    forPossibleTemporaryUnavailabilityOfThe: {
      message: " per eventuale temporanea indisponibilità del "
    },
    orOnyContentMadeAvailableByThe: {
      message: "o qualsiasi contenuto messo a disposizione dal "
    },
    onTheDigitalMenu: {
      message: "nel menu digitale."
    },
    mustHaveAllNecessarySoftwareAndHardwareToAccessThe: {
      message: " deve disporre di tutto il software e l'hardware necessari per accedere al "
    },
    includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe: {
      message: ", inclusi, a titolo esemplificativo, un computer o un dispositivo mobile con accesso a Internet e il "
    },
    onlyToProvideThe: {
      message: ", solo per fornire il "
    },
    to2: {
      message: " a "
    },
    underThisInstrumentToThe: {
      message: "sotto questo strumento. Al "
    },
    accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable: {
      message: "accedere al menù digitale, sarà indispensabile un dispositivo in grado di leggere il QR Code."
    },
    byThe: {
      message: " dal "
    },
    isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument: {
      message: "è condizionato alla sua preventiva registrazione, nonché al rispetto delle disposizioni contenute nel presente atto."
    },
    registration: {
      message: "5. ISCRIZIONE"
    },
    soThatThe: {
      message: "In modo che la "
    },
    registerInThe: {
      message: " registrarsi nel "
    },
    mustProvideTo: {
      message: "deve provvedere a "
    },
    theFollowingData: {
      message: " i seguenti dati "
    },
    corporateName: {
      message: "nome aziendale;"
    },
    unitAddressOf: {
      message: " indirizzo unità di "
    },
    contactNumberInTheEventThatThe: {
      message: "Numero di contatto. Nel caso in cui il"
    },
    hireThe: {
      message: "assumere il"
    },
    forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest: {
      message: "per più unità è necessario registrare gli altri indirizzi di interesse."
    },
    forRegularUseOf: {
      message: "Per un uso regolare di "
    },
    mustRegisterFillingInAllTheDataRequestedByThe: {
      message: " deve registrarsi, compilando tutti i dati richiesti dal "
    },
    atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy: {
      message: " al momento della registrazione, oltre a selezionare il piano dei servizi offerto da "
    },
    itIsTheResponsibilityOf: {
      message: " È responsabilità di "
    },
    identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting: {
      message: "individuare nel piano appaltato il numero di unità previste nella sua appalto."
    },
    itIsTheSoleResponsibilityOf: {
      message: "È responsabilità esclusiva di"
    },
    provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe: {
      message: "fornire, aggiornare e garantire la veridicità dei dati di registrazione, non rientrando nel"
    },
    anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy: {
      message: " ogni tipo di responsabilità civile e penale derivante da dati non veritieri, errati o incompleti forniti da "
    },
    reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe: {
      message: " si riserva il diritto di utilizzare tutti i mezzi validi e possibili per identificare il "
    },
    asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf: {
      message: " nonché richiedere ulteriori dati e documenti che ritenga rilevanti per la verifica dei dati comunicati. In questo caso, l'uso di "
    },
    isConditionedToTheSendingOfTheDocumentsEventuallyRequested: {
      message: " è condizionato all'invio dei documenti eventualmente richiesti."
    },
    ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe: {
      message: "Se si ritiene che una registrazione contenga dati errati o non veritieri, il "
    },
    reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe: {
      message: " si riserva il diritto di sospendere, temporaneamente o definitivamente, senza preavviso, il "
    },
    responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe: {
      message: " responsabile della registrazione. In caso di sospensione, non sarai presente al "
    },
    rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages: {
      message: " diritto a qualsiasi tipo di indennizzo o risarcimento per perdite e danni, mancato guadagno o danno morale."
    },
    inTheEventThatThe: {
      message: "Nel caso in cui il"
    },
    carryOutTheCollectionOfPersonalDataFromThe: {
      message: "effettuare la raccolta di dati personali presso il "
    },
    willAllowAccessToTheInformationCollectedAndAboutThe: {
      message: "consentirà l'accesso alle informazioni raccolte e relative al "
    },
    carriedOutBy: {
      message: " effettuato da "
    },
    freeOfChargeByRequestingHelloMyTripMenuCom: {
      message: ", gratuitamente, richiedendo hello@mytripmenu.com"
    },
    orByRegisteringAt: {
      message: "oppure registrandosi a "
    },
    beingAbleToEditOrDeleteThemAtAnyTime: {
      message: " poterli modificare o eliminare in qualsiasi momento."
    },
    theCollectionOfDataFrom: {
      message: "La raccolta dei dati da "
    },
    whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe: {
      message: ", quando ricorre, ha lo scopo di identificarti, nonché di consentirti di utilizzare correttamente i "
    },
    andWithThatThe: {
      message: ", e con ciò, il "
    },
    canEnsureTheGoodQualityOfTheLicensedServices: {
      message: " può garantire la buona qualità dei servizi concessi in licenza."
    },
    byConsentingToTheTermsOfThisInstrumentThe: {
      message: "Accettando i termini di questo strumento, il"
    },
    expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe: {
      message: " dichiara espressamente di essere consapevole che la raccolta dei Suoi dati è essenziale per il corretto funzionamento del "
    },
    authorizingFromNowOnThe: {
      message: ", autorizzando, d'ora in poi, il "
    },
    forThe: {
      message: " per il "
    },
    offersThePossibilityOf: {
      message: " offre la possibilità di "
    },
    publicizeYourFigitalMenuThroughQRCodeToYour: {
      message: "pubblicizza il tuo menu digitale tramite QR Code al tuo "
    },
    withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe: {
      message: "senza necessità di registrazione, tuttavia, l'assenza di registrazione non toglie l'inosservanza delle regole del presente strumento, che il "
    },
    mustEnsureCompliance: {
      message: "deve garantire la conformità."
    },
    willAccessYourRegistrationIn: {
      message: " accederà alla tua registrazione "
    },
    through: {
      message: " attraverso "
    },
    andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem: {
      message: " e password, impegnandosi a non informare terzi di tali dati, assumendosi la piena responsabilità dell'uso che ne viene fatto."
    },
    undertakesToNotifyThe: {
      message: " si impegna a notificare il "
    },
    immediatelyThroughTheContactChannelsMaintainedBy: {
      message: " immediatamente, attraverso i canali di contatto gestiti da "
    },
    at: {
      message: " a "
    },
    regardingAnyUnauthorizedUseOfYourAccountThe: {
      message: ", in merito a qualsiasi uso non autorizzato del tuo account. Il"
    },
    willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou: {
      message: " sarà l'unico responsabile delle operazioni effettuate sul tuo account, poiché l'accesso sarà possibile solo attraverso l'utilizzo di una password a te nota esclusivamente."
    },
    regardingAnyKnowledgeOfWrongdoingByOther: {
      message: ", per quanto riguarda qualsiasi conoscenza di illeciti da parte di altri"
    },
    thatMayCauseDamageToTheirOwn: {
      message: " che potrebbero causare danni ai propri "
    },
    ofThe: {
      message: " del "
    },
    toThisOnTo: {
      message: " a questo, a "
    },
    orToThirdParties: {
      message: " o a terzi."
    },
    undertakesToUsTheFeaturesOfThe: {
      message: "si impegna a utilizzare le caratteristiche del"
    },
    inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms: {
      message: " in buona fede, in conformità con la legislazione vigente, la morale e le buone consuetudini."
    },
    underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe: {
      message: "In nessun caso la cessione, vendita, affitto o altra forma di cessione del"
    },
    atItsSoleDiscretion: {
      message: "A sua discrezione "
    },
    mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO: {
      message: " può escludere, disabilitare, limitare l'utilizzo del servizio, sospendere, bloccare, a tempo indeterminato, senza preavviso o compenso, le registrazioni o "
    },
    thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce: {
      message: " che siano considerati offensivi, che violino i termini del presente strumento o della normativa vigente."
    },
    reservesTheRightNotToAllowNewRegistrationOf: {
      message: " si riserva il diritto di non consentire nuove registrazioni di "
    },
    thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom: {
      message: " che sono già stati cancellati, disabilitati, bloccati, cancellati o sospesi "
    },
    theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe: {
      message: " La creazione di nuove iscrizioni da parte di persone le cui iscrizioni originarie siano state cancellate, bloccate, disabilitate, escluse o sospese per violazione delle politiche del "
    },
    orCurrentLegislation: {
      message: " o normativa vigente."
    },
    reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA: {
      message: " si riserva il diritto, unilateralmente, senza preavviso, consenso o compenso, di rifiutare qualsiasi richiesta di registrazione di a "
    },
    asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration: {
      message: ", nonché annullare, disabilitare, bloccare, eliminare o sospendere l'utilizzo di una registrazione precedentemente accettata."
    },
    byAgreeingToThisInstrumentThe: {
      message: "Accettando questo strumento, il "
    },
    declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo: {
      message: " dichiara di essere consapevole di essere l'unico responsabile della sua registrazione, essendo certo che eventuali danni causati dall'inserimento di informazioni non aggiornate, inesatte o non veritiere non possono essere imputati a "
    },
    orThe: {
      message: "O il"
    },
    functionalities: {
      message: "6. FUNZIONALITÀ:"
    },
    offersTheFollowingFeatures: {
      message: " offre le seguenti caratteristiche:"
    },
    aOnlineDigitalMenu: {
      message: "(a) Menu digitale online;"
    },
    bViewingProductsWithPphotos: {
      message: "(b) Visualizzazione di prodotti con foto;"
    },
    cOfferingAMultilingualMenuSubjectToAvailableConditions: {
      message: "(c) offrire un menu multilingue, soggetto alle condizioni disponibili;"
    },
    dActivationAndDeactivationOfItemsInRealTime: {
      message: "(d) Attivazione e disattivazione degli articoli in tempo reale;"
    },
    eAccessToTheMenuByScanningAQRCode: {
      message: "(e) Accesso al menù tramite scansione di un QR Code;"
    },
    fCreationOfHighlightsForPromotionalActions: {
      message: "(f) Creazione di highlights per azioni promozionali."
    },
    whenUsingTheServicesProvidedByThe: {
      message: " quando si utilizzano i servizi forniti dal "
    },
    canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe: {
      message: " può selezionare l'URL (Uniform Resource Locator), al fine di determinare l'indirizzo elettronico che individuerà il menu digitale del "
    },
    ToDefineTheURLThe: {
      message: "Per definire l'URL, il "
    },
    mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices: {
      message: "deve rispettare la proprietà intellettuale di terzi, pertanto non deve contenere espressioni che possano creare confusione o alludere a servizi di terzi."
    },
    understandThatThe: {
      message: " capire che il "
    },
    resourcesWereUsedToCauseConfusionAmongThe: {
      message: " risorse sono state utilizzate per creare confusione tra i "
    },
    makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe: {
      message: "rendendo difficile l'identificazione corretta dei servizi e fuorvianti, è possibile escludere l'URL del "
    },
    asWellAsAdoptOtherMeasuresRelevantToTheCase: {
      message: "nonché adottare altre misure pertinenti al caso"
    },
    mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn: {
      message: " potrà modificare la gamma delle funzionalità disponibili in base al piano contrattuale, come previsto dall'art "
    },
    mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe: {
      message: " può in qualsiasi momento, mediante invio di preavviso, senza necessità di compenso, modificare e/o eliminare funzionalità esistenti, nonché includere nuove funzionalità nel "
    },
    translations: {
      message: "7. TRADUZIONI:"
    },
    youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe: {
      message: "Puoi utilizzare i servizi di traduzione per rendere disponibili i tuoi menu nelle lingue di tua scelta. A tal fine verranno addebitate le tariffe per i servizi, come previsto dall'art "
    },
    forTheExecutionOfTranslationServicesThe: {
      message: "Per l'esecuzione dei servizi di traduzione, il"
    },
    requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe: {
      message: "richiedere almeno un periodo di 72 ore (settantadue ore). A seconda della lunghezza del testo da tradurre, il"
    },
    withPriorNoticeTo: {
      message: "con preavviso a"
    },
    mayExtendTheServiceExecutionPeriodAtItsDiscretion: {
      message: "può estendere il periodo di esecuzione del servizio a sua discrezione."
    },
    declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions: {
      message: "dichiara di comprendere e di accettare che alcune espressioni non possono essere tradotte, poiché riflettono determinate culture o regioni specifiche."
    },
    isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe: {
      message: " non è responsabile per eventuali danni derivanti da traduzioni inesatte nel menu digitale del "
    },
    howeverInTheEventThatThe: {
      message: ". Tuttavia, nel caso in cui il"
    },
    identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe: {
      message: "identificare eventuali errori o incomprensioni nella tradizione, è possibile comunicare al "
    },
    whichMustRespondToTheRequestAsSoonAsPossible: {
      message: "che deve rispondere alla richiesta nel più breve tempo possibile."
    },
    suppertService: {
      message: "8. SERVIZIO DI ASSISTENZA"
    },
    willSupportThe: {
      message: "sosterrà il "
    },
    throughthecontactchannelavailableviaemailhellomytripmenucom: {
      message: " tramite il canale di contatto disponibile via email hello@mytripmenu.com."
    },
    inSupportRequestsViaEmailTthe: {
      message: "Nelle richieste di supporto via e-mail, il "
    },
    willHaveUpTo72SeventyTwoBusinessHoursToRespond: {
      message: "avrà fino a 72 (settantadue) ore lavorative per rispondere."
    },
    paymentMethod4: {
      message: "9. MODALITÀ DI PAGAMENTO:"
    },
    priorToAnyChargeBeingMadeByThe: {
      message: "Prima di qualsiasi addebito da parte del "
    },
    willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf: {
      message: "verrà offerto un periodo di prova di 07 (sette) giorni, per una prova gratuita degli strumenti di "
    },
    ifThe: {
      message: "Se la "
    },
    chooseToProceedWithTheContractingOfServicesThe: {
      message: " scegliere di procedere con l'appalto dei servizi, il "
    },
    makesAvailableTo: {
      message: "mette a disposizione "
    },
    oneOfTheFollowingPaymentMethods: {
      message: "uno dei seguenti metodi di pagamento: "
    },
    VISACreditCardOr: {
      message: "Carta di credito VISA; o,"
    },
    MASTERCARDCreditCardOr: {
      message: "Carta di Credito MASTERCARD; o,"
    },
    AMERICANEXPRESSCreditCardOr: {
      message: "Carta di credito AMERICAN EXPRESS; o,"
    },
    DINNERSCLUBCreditCardOr: {
      message: "CENE CLUB Carta di Credito; o,"
    },
    ELOCreditCardOr: {
      message: "Carta di credito ELO, o;"
    },
    DISCOVERCreditCard: {
      message: "SCOPRI Carta di credito."
    },
    toProcessCreditCardPaymentsItWillBeNecessaryForThe: {
      message: "Per elaborare i pagamenti con carta di credito, sarà necessario il"
    },
    registerOn: {
      message: "registrati su "
    },
    theFollowingDetailsOfTheChosenCreditCard: {
      message: "i seguenti dettagli della Carta di Credito scelta:"
    },
    NameOfTheCreditCardHolder: {
      message: "Nome del titolare della Carta di Credito;"
    },
    CreditCardNumber: {
      message: "Numero di carta di credito;"
    },
    CreditCardFlag: {
      message: "Bandiera della carta di credito;"
    },
    CreditCardExpirationMonthandYear: {
      message: "Scadenza della carta di credito (mese e anno);"
    },
    CreditCardsecuritynumber: {
      message: "Numero di sicurezza della carta di credito."
    },
    Paymentsforservicescontractedinthe: {
      message: "Pagamenti per servizi appaltati nel "
    },
    willBe: {
      message: " sarà"
    },
    processedthroughtheGETNETplatform: {
      message: " elaborati attraverso la piattaforma GETNET"
    },
    beingnecessarythatall: {
      message: "essendo necessario che tutto "
    },
    alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform: {
      message: " inoltre leggere e accettare i termini di utilizzo e servizi della piattaforma GETNET"
    },
    viaemailaddress: {
      message: "tramite indirizzo email: "
    },
    Whenhiringthe: {
      message: "Quando si assume il "
    },
    withpaymentbycreditcardorbankslipthe: {
      message: ", con pagamento con carta di credito o bollettino bancario, il"
    },
    expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform: {
      message: "indica espressamente di aver letto e accettato tutte le condizioni presenti in questo strumento e nei termini di utilizzo e servizi specifici della piattaforma GETNET."
    },
    AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe: {
      message: "Qualsiasi contestazione di pagamenti effettuati tramite la piattaforma GETNET deve essere risolta solo tra i"
    },
    andGETNETnotfallingto: {
      message: " e GETNET, senza cadere "
    },
    anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform: {
      message: " qualsiasi responsabilità per l'elaborazione dei pagamenti effettuati sulla piattaforma GETNET."
    },
    TheGETNETplatform: {
      message: "La piattaforma GETNET"
    },
    maychargefeesforitsuseanditisonlyuptothe: {
      message: "può addebitare commissioni per il suo utilizzo, ed è solo fino al "
    },
    informationaboutthevalueschargedbyGETNET: {
      message: " informazioni sui valori addebitati da GETNET. "
    },
    isonlyresponsiblefordeletingthepaymentdataprovidedbythe: {
      message: " è responsabile esclusivamente della cancellazione dei dati di pagamento forniti dal "
    },
    fromitsowndatabasedeclaringthe: {
      message: "dal proprio database, dichiarando il"
    },
    beawarethatitisnotupto: {
      message: " essere consapevoli che non è all'altezza "
    },
    promotetheexclusionofsaiddatafromtheGETNETdatabase: {
      message: " promuovere l'esclusione di detti dati dal database GETNET."
    },
    PRIVACYANDDATAPROCESSINGPOLICY: {
      message: "10. INFORMATIVA PRIVACY E TRATTAMENTO DEI DATI:"
    },
    Duringtheuseof: {
      message: "Durante l'uso di "
    },
    willcollectandstoretheinformationprovidedbythe: {
      message: "raccoglierà e conserverà le informazioni fornite dal"
    },
    underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof: {
      message: ", ai sensi del presente strumento, insieme a informazioni generate automaticamente, come le caratteristiche del dispositivo di accesso, browser, log di accesso alle applicazioni (IP, con data e ora), informazioni a cui si accede, schermate a cui si accede, dati di geolocalizzazione, cronologia delle applicazioni, tra gli altri, di"
    },
    whichwillbestoredinthedatabaseandalsointhebrowsercookie: {
      message: ", che verranno memorizzati nel database e anche nel cookie del browser."
    },
    Thedatacollectedfrom: {
      message: "I dati raccolti da "
    },
    throughtheuseof: {
      message: ", attraverso l'uso di"
    },
    willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe: {
      message: ", saranno utilizzati per l'adeguata erogazione dei servizi, al fine di migliorare la navigazione del"
    },
    aswellasforadvertisingandstatisticalpurposes: {
      message: ", nonché per scopi pubblicitari e statistici."
    },
    declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato: {
      message: "dichiara di comprendere, accettare e acconsentire che in caso di invio di dati personali a"
    },
    mustdosounderthetermsofarticle5itemXofLaw13709of2018: {
      message: "deve farlo ai sensi dell'articolo 5, comma X, della Legge 13.709 del 2018."
    },
    Intheeventthatpersonaldataisprovidedbythe: {
      message: "Nel caso in cui i dati personali siano forniti dal "
    },
    willbeconsideredconfidentialbythe: {
      message: ", sarà considerato riservato dal"
    },
    anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas: {
      message: ", e si impegna a compiere ogni sforzo per preservare la sicurezza dei propri sistemi nella conservazione di tali dati, rispondendo agli standard di sicurezza stabiliti dal D.Lgs. n. 8.771/2016, quali:"
    },
    iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability: {
      message: "(i) Utilizzo di metodi standard del settore per crittografare i dati raccolti, oltre ad altre forme standard di crittografia, per garantirne l'inviolabilità;"
    },
    iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments: {
      message: "(ii) Utilizzo di software ad alta tecnologia per la protezione dall'accesso non autorizzato ai sistemi, che sono considerati ambienti controllati e di sicurezza;"
    },
    Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement: {
      message: "(iii) disponibilità di un accesso controllato ai luoghi di conservazione dei dati personali solo a persone preventivamente autorizzate e autenticate, impegnate alla segretezza di tali dati, anche attraverso la sottoscrizione di un accordo di riservatezza;"
    },
    ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe: {
      message: "(iv) Applicazione di meccanismi di autenticazione per l'accesso a registrazioni in grado di individuare il responsabile del trattamento e l'accesso ai dati raccolti a seguito dell'utilizzo del"
    },
    vAnonymizationofdatafromthe: {
      message: "(v) Anonimizzazione dei dati dal"
    },
    whensharedwiththirdpartiesnotpartnerswith: {
      message: "quando condiviso con terze parti non partner"
    },
    viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016: {
      message: "(vi) Tenuta dell'inventario indicante l'ora, la durata, l'identità del dipendente o del responsabile dell'accesso e il fascicolo oggetto, sulla base delle registrazioni di connessione e di accesso all'applicazione, come determinato dall'articolo 13 del D.Lgs. 8.771/2016."
    },
    Ifdatacollectionfromthe: {
      message: "Se la raccolta dei dati dal"
    },
    throughtheuse: {
      message: ", attraverso l'uso"
    },
    thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow: {
      message: "questi potranno essere condivisi solo con soggetti terzi, nei casi di seguito elencati:"
    },
    iFortheprotectionoftheinterestsof: {
      message: "(i) Per la tutela degli interessi di"
    },
    incaseofconflictincludinglegalclaims: {
      message: "in caso di conflitto, comprese le rivendicazioni legali;"
    },
    iiIncaseoftransactionsandcorporatechangesinvolvingthe: {
      message: "(ii) In caso di operazioni e modifiche societarie che coinvolgono il"
    },
    inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe: {
      message: ", in tal caso il conferimento dei dati sarà necessario per la continuità dei servizi offerti tramite il"
    },
    iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest: {
      message: "(iii) Con provvedimento del tribunale o su richiesta delle autorità amministrative competenti per legge per la sua richiesta."
    },
    guaranteesto: {
      message: "garanzie a"
    },
    withregardtotheprocessingofpersonaldatathefollowingrights: {
      message: ", in relazione al trattamento dei dati personali, i seguenti diritti:"
    },
    iConfirmationoftheexistenceofprocessingofyourpersonaldata: {
      message: "(i) Conferma dell'esistenza del trattamento dei tuoi dati personali;"
    },
    iiAccesstoyourdatacollectedby: {
      message: "(ii) Accesso ai tuoi dati raccolti da"
    },
    throughyourownloginorbyrequestinghellomytripmenucom: {
      message: ", tramite il proprio login o richiedendo hello@mytripmenu.com;"
    },
    iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate: {
      message: "(iii) la rettifica dei tuoi dati se incompleti, inesatti o non aggiornati;"
    },
    ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation: {
      message: "(iv) Blocco o cancellazione di dati non necessari, eccessivi o non conformi alla legislazione brasiliana applicabile;"
    },
    vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe: {
      message: "(v) La portabilità dei dati personali, per Lei o per terzi, previa espressa richiesta avanzata dal"
    },
    viahellomytripmenucom: {
      message: ", tramite ciao@mytripmenu.com;"
    },
    viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe: {
      message: "(vi) Cancellazione dei dati personali trattati con il Suo consenso, a condizione che non vi sia determinazione legale a mantenerli registrati presso il"
    },
    viiObtaininginformationaboutpublicorprivateentitieswithwhichthe: {
      message: "(vii) Ottenere informazioni su soggetti pubblici o privati ​​con i quali il"
    },
    sharedyourdataand: {
      message: "condiviso i tuoi dati; e,"
    },
    viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe: {
      message: "(viii) Informativa sulla possibilità e conseguenza del mancato conferimento del consenso del"
    },
    youcansendemailto: {
      message: "puoi inviare e-mail a"
    },
    hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata: {
      message: "hello@mytripmenu.com, segnalando dubbi e/o esigenze relative ai tuoi dati personali."
    },
    maydeletethepersonaldatacollectedfrom: {
      message: "può cancellare i dati personali raccolti da"
    },
    iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy: {
      message: "(i) When the purpo(i) quando viene raggiunto lo scopo per il quale sono stati raccolti; oppure, quando non sono più necessari o rilevanti per l'ambito della finalità, secondo le finalità descritte nelle presenti Condizioni d'uso e Informativa sulla privacy;se for which they were collected is achieved; or, when they are no longer necessary or relevant for the scope of the purpose, according to the purposes described in these Terms of Use and Privacy Policy;"
    },
    iiWhenthe: {
      message: "(ii) Quando il"
    },
    revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe: {
      message: "revocare il consenso, nei casi in cui sia necessario, chiedendone l'esclusione dall'art"
    },
    viahellomytripmenucomor: {
      message: "via ciao@mytripmenu.com; o,"
    },
    iiiIfdeterminedbycompetentauthority: {
      message: "(iii) Se determinato dall'autorità competente."
    },
    GENERALPROVISIONS: {
      message: "11. DISPOSIZIONI GENERALI:"
    },
    AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent: {
      message: "Qualsiasi clausola o condizione di questo strumento che, per qualsiasi motivo, sarà considerata nulla o inefficace da qualsiasi tribunale o tribunale, non pregiudicherà la validità delle altre disposizioni delle presenti Condizioni, che rimarranno pienamente valide e vincolanti, generando effetti al loro misura massima."
    },
    Thefailureof: {
      message: "Il fallimento di"
    },
    inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines: {
      message: "nel far valere qualsiasi diritto o disposizione delle presenti Condizioni non costituirà rinuncia, e queste ultime potranno esercitare regolarmente il proprio diritto, nei termini di legge."
    },
    Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe: {
      message: "Tutti i materiali, brevetti, marchi, registrazioni, domini, nomi, privilegi, creazioni, immagini e tutti i diritti correlati relativi al"
    },
    anddevelopedby: {
      message: "e sviluppato da"
    },
    areandwillremainthesoleandexclusivepropertyof: {
      message: ", sono e rimarranno di sola ed esclusiva proprietà di"
    },
    agreeingwiththe: {
      message: ", d'accordo con il"
    },
    nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem: {
      message: "non compiere un atto o fatto che, in qualsiasi modo, leda i diritti qui enunciati, né rivendicare su di essi alcun diritto o privilegio."
    },
    maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreasonwestronglyrecommendthatyoualwaysvisitthissectionofourSiteandreaditperiodicallyButtocontributetothegoodrelationshipwewillalsosendanemailinformingyouaboutthesechanges: {
      message: "può modificare questo strumento in qualsiasi momento, semplicemente pubblicando una versione rivista sul nostro sito web. Per questo motivo consigliamo vivamente di visitare sempre questa sezione del nostro Sito e di leggerla periodicamente. Ma, per contribuire al buon rapporto, invieremo anche un'e-mail per informarti di questi cambiamenti."
    },
    Thisinstrumentconstitutestheintegralunderstandingbetweenthe: {
      message: "Questo strumento costituisce l'intesa integrale tra i"
    },
    andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe: {
      message: "ed è regolato dalle leggi brasiliane, con la giurisdizione della città di São Leopoldo/RS eletta come unico tribunale competente per risolvere le questioni derivanti da questo strumento, con espressa rinuncia a qualsiasi altra giurisdizione, per quanto privilegiata possa essere."
    },

    recoverPassword: {
      message: "RECUPERA LA PASSWORD"
    },
    receiveNewPassword: {
      message: "Riceverai una nuova password nella tua email, ricordati di controllare anche la tua cartella spam."
    },
    dontWorry: {
      message: "Non preoccuparti, ti aiuteremo a recuperarlo. Fornisci la tua email di accesso"
    },
    enterEmail: {
      message: "Inserisci l'email"
    },
    notBeSent: {
      message: "Impossibile inviare l'e-mail, riprova."
    },
    pleasureToHaveYou: {
      message: "È un piacere averti qui con noi!"
    },
    beforeStarting: {
      message: "Prima di iniziare a inserire i tuoi prodotti e vedere il tuo menù prendere forma, è necessario sottoscrivere un piano di manutenzione dell'importo di"
    },
    BRL99: {
      message: "BRL 99.90"
    },
    month: {
      message: "un mese."
    },
    includesFacilities: {
      message: "Comprende strutture come:"
    },
    menuWithPhotos: {
      message: "menù completo con foto;"
    },
    priceUpdate: {
      message: "aggiornamento dei prezzi in tempo reale;"
    },
    activateDeactivate: {
      message: "attivare e disattivare rapidamente i prodotti;"
    },
    categorizeFilteReorder: {
      message: "categorizzare, filtrare e riordinare i prodotti;"
    },
    newFeatures: {
      message: "categorizzare, filtrare e riordinare i prodotti;"
    },
    thePlan: {
      message: "firmare il piano"
    },
    company: {
      message: "AZIENDA"
    },
    fantasyName: {
      message: "NOME DI FANTASIA"
    },
    numberAccesses: {
      message: "NUMERO DI ACCESSI"
    },
    entryDate: {
      message: "DATA DI INGRESSO"
    },
    wantLeave:{
      message: "Ora che sei arrivato così lontano, sei sicuro di voler partire?"
    },
    cancelYourSubscription:{
      message: "Siamo spiacenti che tu voglia cancellare la tua iscrizione al piano MyTripMenu."
    },
    beforeConfirming:{
      message: "Prima di confermare, devo dirti che se te ne vai, i tuoi clienti non avranno più accesso al menu della tua struttura e l'accesso all'URL e al QRCode non sarà disponibile fino a quando non ti abboni nuovamente al piano."
    },
    goAhead:{
      message: "Se ne sei sicuro, vai avanti..."
    },
    unsubscribePlan:{
      message: "cancellarsi dal piano"
    },
    dontCancel:{
      message: "Non voglio più cancellare"
    },
    noChange:{
      message: "nessun cambiamento"
    },
    CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180: {
      message: "CAXA LABS SERVICOS DE DESENVOLVIMENTO DE SOFTWARE LTDA, Società a responsabilità limitata, regolarmente registrata presso il CNPJ con il n. 34.177.633/0001-91, con sede in Rua Felipe Dos Santos, nº 77, 1203 torre A, quartiere Padre Reus, nella città di São Leopoldo/RS, sotto CEP: 93.020-180."
    },
    Delivery: {
      message: "Consegna"
    },
    withdrawal: {
      message: "ritiro"
    },
    Pickup: {
      message: "Ritiro sul posto"
    },
    Catalog: {
      message: "Catalogare"
    },
    Enteremail: {
      message: "Inserisci l'e-mail"
    },
    emailinvalid: {
      message: "Questa email non è valida"
    },
    Typepassword: {
      message: "Digita la password"
    },
    least6digits: {
      message: "Questo campo deve contenere almeno 6 cifre"
    },
     Cashpayment: {
      message: "Il metodo di pagamento in contanti non può essere modificato"
    },
    Checkpayment: {
      message: "Il metodo di pagamento tramite assegno non può essere modificato"
    },
    save: {
      message: "Salvare"
    },
    logIn: {
      message: "ACCESSO"
    },
    logged: {
      message: "TI SEI AUTENTICATO"
    },
    enterAShortEasilyIdentifiableName: {
      message: "Immettere un nome breve e facilmente identificabile"
    },
    highlightssubcategoryCannotBeCopied: {
      message: "La sottocategoria In evidenza non può essere copiata"
    },
    aaaaaaaaaaaaaaaaaa: {
      message: "aaaaaaaaaaaaaaaaaaaaa"
    },
    unableToLoadCards : {
      message: "Impossibile caricare le carte"
    },
    invalidUser: {
      message: "Utente non valido"
    },
    invalidPassword: {
      message: "Password non valida"
    },
    inactiveUser: {
      message: "Utente inattivo"
    }
  }
}
