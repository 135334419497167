import styled from 'styled-components';
import ModalMytm from '../../../../../../../components/modal-mytm/ModalMytm';
import { ReactComponent as LaunchSVG } from '../../../../../../../assets/launch.svg';
import { Paragraph16px300Light, Paragraph16px600Medium } from '../../../../../../../styles/style-guide';

const SignaturesPlanSignInModalWrapper = styled(ModalMytm)`
  .modal_mytm {
    width: 736px;
    height: 450px;
    padding: 24px;
  };
  
`;

const SignaturesPlanSignInModalImage = styled(LaunchSVG)``;

const SignaturesPlanSignInModalContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const SignaturesPlanSignInModalLeftContent = styled.div`
  width: 55%;
`;

const SignaturesPlanSignInModalRightContent = styled.div`
  width: 45%;
`;

const SignaturesPlanSignInModalSpanMainColor = styled.span`
  ${Paragraph16px600Medium}
  color: ${({ theme }) => theme.colors.mainColor};
`;

const SignaturesPlanSignInModalParagraph = styled.p`
  ${Paragraph16px300Light};
`;

const SignaturesPlanSignInModalLink = styled.a`
  ${Paragraph16px600Medium}
  color: ${({ theme }) => theme.colors.mainColor};
`;

const SignaturesPlanSignInModalCardFlagIcon = styled.label`
  position: relative;
  top: 5px;
  margin-right: 8px;
`;

const SignaturesPlanSignInModalCardNumberText = styled.p`
  ${Paragraph16px300Light};
  margin-top: 5px;
  margin-bottom: 5px;
`;

const SignaturesPlanSignInModalCardContainer = styled.div`
  margin: 20px 0; 
`;

export {
  SignaturesPlanSignInModalWrapper,
  SignaturesPlanSignInModalContent,
  SignaturesPlanSignInModalLeftContent,
  SignaturesPlanSignInModalRightContent,
  SignaturesPlanSignInModalImage,
  SignaturesPlanSignInModalParagraph,
  SignaturesPlanSignInModalLink,
  SignaturesPlanSignInModalSpanMainColor,
  SignaturesPlanSignInModalCardNumberText,
  SignaturesPlanSignInModalCardFlagIcon,
  SignaturesPlanSignInModalCardContainer,
};
