import styled from "styled-components";

const MandadoryDataContainer = styled.div`
  /* width: 100px; */
  margin-left: 30px;
  margin-right: 50px;
  margin-top: 30px;
`;

const MandadoryDataLine = styled.div`
  border-top: 1px;
  border-color: #cccccc;
  border-width: 2px;
  border-top-style: solid;
  margin-right: auto;
  margin-left: auto;
  width: calc(80vw - 100px);
  margin-top: 30px;
`;

const MandadoryDataContext = styled.div`
  display: flex;
`;

const MandadoryDataCheckBox = styled.div`
  margin-right: 80px;
`;

const MandadoryDataLabel = styled.h4`
  margin-bottom: 30px;
  color: #000000;
`;

export {
  MandadoryDataContainer,
  MandadoryDataContext,
  MandadoryDataCheckBox,
  MandadoryDataLabel,
  MandadoryDataLine,
};
