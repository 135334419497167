import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import {
  TabContent,
  TabUlContent,
  TabItemsContent,
  TabButtonContent,
  TabButtonIconAdd,
  TabButton,
} from "./Styles";
import TabItem from "../tab-item/TabItem";
import BlockLoader from "../block-loader/BlockLoader";
import { useQuery } from "../../hooks/custom-hooks";

const Tab = (props) => {
  const {
    tabsConfig,
    selectedTabNumber,
    buttonText,
    onClickTab,
    isLoadingContent,
  } = props;
  const [selectedTabItemIndex, setSelectedTabItemIndex] = useState(
    selectedTabNumber
  );
  const queryParams = useQuery();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const tabTextFromQuery = queryParams.get("tab");
    tabsConfig.forEach((tab, tabIndex) => {
      if (
        tab.path === tabTextFromQuery &&
        tabIndex !== setSelectedTabItemIndex
      ) {
        setSelectedTabItemIndex(tabIndex);
      }
    });
  });

  const onClickTabItem = (tabIndex, tabPath) => {
    onClickTab(tabIndex, tabPath);
    setSelectedTabItemIndex(tabIndex);
    const { pathname: pageUrl } = location;
    history.push(`${pageUrl}?tab=${tabPath}`);
  };

  const renderButtonContent = () => {
    const { onClickButton } =
      selectedTabItemIndex !== -1 ? tabsConfig[selectedTabItemIndex] : () => {};
    return onClickButton ? (
      <TabButtonContent>
        <TabButton onClick={onClickButton}>
          <TabButtonIconAdd />
          {buttonText}
        </TabButton>
      </TabButtonContent>
    ) : (
      ""
    );
  };

  const renderChildContent = () => (
    <TabItemsContent>
      <BlockLoader blocking={isLoadingContent}>
        {selectedTabItemIndex !== -1 && tabsConfig
          ? tabsConfig[selectedTabItemIndex].component
          : ""}
      </BlockLoader>
    </TabItemsContent>
  );

  const renderTabItems = () =>
    tabsConfig.map(({ text, path }, index) => {
      return(
        <TabItem
          text={text}
          key={index}
          onClick={() => onClickTabItem(index, path)}
          selected={index === selectedTabItemIndex}
        />
      );
    });

  return (
    <TabContent>
      <TabUlContent>{renderTabItems()}</TabUlContent>
      {renderButtonContent()}
      {renderChildContent()}
    </TabContent>
  );
};

Tab.defaultProps = {
  buttonText: "",
  selectedTabNumber: -1,
  onClickTab: () => {},
  isLoadingContent: false,
};

Tab.propTypes = {
  buttonText: PropTypes.string,
  selectedTabNumber: PropTypes.number,
  onClickTab: PropTypes.func,
  isLoadingContent: PropTypes.bool,
  tabsConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Tab;
