import React from 'react';
import MytmTheme from '../../layouts/mytm/mytm-theme/MytmTheme';
import DomainRoutes from '../../routes/sub-routes/DomainRoutes';

const Domain = () => (
  <MytmTheme>
    <DomainRoutes />
  </MytmTheme>
);

export default Domain;
