import React from "react";
import CurrencyMask from "react-currency-format";
import dayjs from "dayjs";
import { TableCol, TableInput } from "../../Styles";
import TranslatorFunction from '../../../I18n/Translator';

function TableBodyColumns({
  keys,
  colKeysAlign,
  colKeysMaxWidth,
  dataObject,
  withEditMode,
  onChangeInput,
  formatColValue,
}) {

  const language = TranslatorFunction({ path: "language.message" });

  return (
    <>
      {keys.map((objKey, index) => {
        const keyValue = `c-${index}`;
        const textAlign = colKeysAlign[objKey];
        const maxWidth = colKeysMaxWidth[objKey];
        let field = '';

        if(dataObject.translatedComplementGroup !== undefined  && dataObject.translatedComplementGroup.length){
          field = "translatedComplementGroup";
        }else if(dataObject.translatedComplement!== undefined  && dataObject.translatedComplement.length){
          field = "translatedComplement";
        }else if(dataObject.translatedName!== undefined  && dataObject.translatedName.length){
          field = "translatedName";
        }else if(dataObject.description!== undefined  && dataObject.description.length){
          field = "description";
        }

        let nameField = '';
        if(field === "translatedName"){
          nameField = field ? (dataObject[field][0][language]!== undefined ? dataObject[field][0][language]: dataObject[objKey] ): dataObject[objKey];
        }else{
          nameField = field 
            && dataObject?.[field] 
            && typeof dataObject?.[field] !== "string"
            && dataObject?.[field]?.length ?( dataObject[field][0][objKey][0][language] !== undefined?  dataObject[field][0][objKey][0][language]:  dataObject[objKey]) : dataObject[objKey];
        }

        const name = nameField;
        const fomattedValue = formatColValue(name, objKey);

        if (objKey === "value" || objKey === "price")
          return (
            <TableCol key={keyValue} maxWidth={maxWidth} textAlign={textAlign}>
              <CurrencyMask
                placeholder="R$ 0,00"
                prefix="R$ "
                displayType={dataObject.edit || withEditMode ? "input" : "text"}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                name={objKey}
                value={fomattedValue}
                onValueChange={(event) => onChangeInput(dataObject, event)}
                customInput={(props) => {
                  return <TableInput textAlign="left" {...props} />;
                }}
              />
            </TableCol>
          );

        if (objKey === "createdAt") {
          return (
            <TableCol key={keyValue} maxWidth={maxWidth} textAlign={textAlign}>
              {dayjs(fomattedValue).format("DD/MM/YYYY")}
            </TableCol>
          );
        }

        if (objKey === "fantasyName") {
          return (
            <TableCol key={keyValue} maxWidth={maxWidth} textAlign={textAlign}>
              {fomattedValue ?? "Não informado"}
            </TableCol>
          );
        }
        return (
          <TableCol key={keyValue} maxWidth={maxWidth} textAlign={textAlign}>
            {dataObject.edit || withEditMode ? (
              <TableInput
                onBlur={(event) => onChangeInput(dataObject, event)}
                name={objKey}
                textAlign={textAlign}
                defaultValue={fomattedValue}
              />
            ) : (
              fomattedValue
            )}
          </TableCol>
        );
      })}
    </>
  );
}

export default React.memo(TableBodyColumns);
