import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/button/Button';
import {
  ResetPasswordSuccessContent,
  ResetPasswordSuccessTitle,
  ResetPasswordSuccessImageContent,
  ResetPasswordSuccessImage,
  ResetPasswordSuccessParagraph,
  ResetPasswordSuccessFooterContent,
} from './Styles';

const ResetPasswordSuccess = () => {
  const history = useHistory();
  const onClickGoLogin = () => (
    history.push('/login')
  );

  return (
    <ResetPasswordSuccessContent>
      <ResetPasswordSuccessTitle>
        Tudo certo!
      </ResetPasswordSuccessTitle>
      <ResetPasswordSuccessImageContent>
        <ResetPasswordSuccessImage />
        <ResetPasswordSuccessParagraph>
          Você receberá uma nova senha em seu e-mail, lembre-se de verificar também a caixa de spam.
        </ResetPasswordSuccessParagraph>
      </ResetPasswordSuccessImageContent>
      <ResetPasswordSuccessFooterContent>
        <Button type="button" onClick={onClickGoLogin}>
          voltar para o login
        </Button>
      </ResetPasswordSuccessFooterContent>
    </ResetPasswordSuccessContent>
  );
};

export default ResetPasswordSuccess;
