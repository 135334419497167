import React, { useState, useEffect, useMemo } from "react";
import MuiAutoComplete from "@material-ui/lab/Autocomplete";
import { MuiTextFieldStyled } from "../../../../../../../components/inputs/mui-inputs/Styles";
import PropTypes from "prop-types";
import TableDoubleDnD from "../../../../../../../components/table/double-dnd/TableDoubleDnD";
import Button from "../../../../../../../components/button/Button";

import ComplementTableWrapper, {
  ContainerFilter,
  EmptySpace,
  ContainerButtons,
  FilterLine,
} from "./Styles";

import ComplementGroupImport from "../complement-group-import/ComplementGroupImport";
import ComplementGroupLoader from "../complement-group-loader/ComplementGroupLoader";
import ComplementLoader from "../complement-loader/ComplementLoader";
import TranslatorFunction from "../../../../../../../components/I18n/Translator"
const ListComplement = ({
  complements,
  setComplements,
  categories,
  setDnDOrder,
  subcat,
  myId,
}) => {
  const [data, setData] = useState([]);
  const [lastVisitedGroup, setLastVisitedGroup] = useState("");
  const [openCompGroupLoaderModal, setOpenCompGroupLoaderModal] =
    useState(false);
  const [openCompLoaderModal, setOpenCompLoaderModal] = useState(false);
  const [openCompImportModal, setOpenCompImportModal] = useState(false);
  const [searchs, setSearchs] = useState({
    name: "",
    category: "",
  });
  const linkGroup = TranslatorFunction({ path: 'linkGroup.message' });
  const importantGroupComplements = TranslatorFunction({ path: 'importantGroupComplements.message' });
  const qtymaximum = TranslatorFunction({ path: 'qtymaximum.message' });
  const qtyMinimum = TranslatorFunction({ path: 'qtyMinimum.message' });
  const complementsGroupUpper = TranslatorFunction({ path: 'complementsGroupUpper.message' });
  const status = TranslatorFunction({ path: 'status.message' });
  const action = TranslatorFunction({ path: 'action.message' });
  const search = TranslatorFunction({ path: 'search.message' });
  const filter = TranslatorFunction({ path: 'filter.message' });
  const linkSupplements = TranslatorFunction({ path: 'linkSupplements.message' });
  const noOptions = TranslatorFunction({ path: 'noOptions.message' });
  const colTitleKeysAlign = ["left", "left", "left", "left", "left", "left"];
  const colKeyAligns = { name: "left", min: "left", max: "left" };
  const complementKeys = ["translatedComplementGroup", "min", "max"];
  const complementKeys2 = ["name", "min", "max"];
  const colEditable = [false, true, true];
  const listTitles = [
    complementsGroupUpper,
    qtyMinimum,
    qtymaximum,
    status,
    action,
  ];

  const handleOnChangeActiveToggle = (selectedComplement) => {
    const complementIndex = data.findIndex(
      (cat) => cat._id === selectedComplement._id
    );
    setData((oldData) => {
      const newData = [...oldData];
      newData[complementIndex].active = !newData[complementIndex].active;
      return newData;
    });
  };

  const handleOnModalClose = (change, newData) => {
    if (change) {
      setData(newData);
      setComplements(newData, true);
    }

    setOpenCompGroupLoaderModal(false);
    setOpenCompImportModal(false);
  };

  const handleOnInsideModalClose = (change, newData) => {
    if (change) {
      const complementGroupIndex = data.findIndex(
        (cat) => cat._id === newData._id
      );
      if (complementGroupIndex >= 0) {
        let finalData = { ...newData };
        finalData.complements.forEach((each) => {
          const complementIndex = data[
            complementGroupIndex
          ].complements.findIndex((cat) => cat._id === each._id);
          if (complementIndex >= 0)
            each.price =
              data[complementGroupIndex].complements[complementIndex].price;
        });

        setComplements(newData, true, true);
      } else {
        setComplements(newData, true, true);
      }
    }

    setOpenCompLoaderModal(false);
  };

  const handleOnClickGetGroups = () => {
    setOpenCompGroupLoaderModal(true);
  };

  const handleOnClickImportGroups = () => {
    setOpenCompImportModal(true);
  };

  const handleOnClickExpand = (result) => {
    setLastVisitedGroup(result);
    setOpenCompLoaderModal(true);
  };

  const handleOnDragStart = (result) => {};

  const handleOnClickShowOpen = (selectComplementGroup) => {
    let newData = [...data];

    newData.map((dat) =>
      dat.complementGroupId === selectComplementGroup.complementGroupId
        ? (dat.showComp = !dat.showComp)
        : ""
    );

    setData(newData);
  };

  const filterComplementsByName = (subcategory) => {
    if (!searchs.name) return true;
    return subcategory.name
      .toLocaleLowerCase()
      .includes(searchs.name.toLocaleLowerCase());
  };

  const filteredComplements = useMemo(
    () => data?.filter(filterComplementsByName),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchs, data]
  );

  useEffect(() => {
    let newData = [...data];
    newData.forEach((dat, newpos) => {
      dat.index = newpos;
    });
    if (newData.length) {
      setDnDOrder(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setData(complements);
  }, [complements]);

  return (
    <>
      <ComplementTableWrapper>
        <FilterLine>
          <ContainerFilter>
            {data && (
              <>
                <MuiAutoComplete
                  id="search"
                  options={data}
                  noOptionsText={noOptions}
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <MuiTextFieldStyled
                      {...params}
                      margin="dense"
                      label={search}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                  onChange={(e) => {
                    setSearchs({ ...searchs, name: e.target.value });
                  }}
                  onSelect={(e) => {
                    setSearchs({ ...searchs, name: e.target.value });
                  }}
                />
                <MuiAutoComplete
                  id="filter"
                  options={categories}
                  noOptionsText={noOptions}
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <MuiTextFieldStyled
                      {...params}
                      margin="dense"
                      label={filter}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </>
            )}
          </ContainerFilter>
          <EmptySpace />
          <ContainerButtons>
            <Button
              type="button"
              btnType="primary"
              onClick={handleOnClickGetGroups}
              cWidth={"auto"}
              cHeight={"35px"}
              cFontSize={"11px"}
            >
              {linkGroup}
            </Button>
            <Button
              type="button"
              btnType="primary"
              onClick={handleOnClickImportGroups}
              cWidth={"auto"}
              cHeight={"35px"}
              cBg={"#6959CD"}
              cFontSize={"11px"}
            >
              {importantGroupComplements}
            </Button>
          </ContainerButtons>
        </FilterLine>
        <TableDoubleDnD
          className="complement"
          data={filteredComplements}
          setData={setData}
          titles={listTitles}
          colKeysAlign={colKeyAligns}
          colTitleKeysAlign={colTitleKeysAlign}
          keys={filteredComplements[0]?.translatedComplementGroup === undefined  ?  complementKeys2  :  complementKeys }
          withStatus
          withActions={false}
          withStarSubCategory={false}
          statusKey="active"
          withCustomButton
          buttonText={linkSupplements}
          onClickButton={handleOnClickExpand}
          onDrag={handleOnDragStart}
          onClickToogle={handleOnChangeActiveToggle}
          onClickShowOpen={handleOnClickShowOpen}
          colEditableField={colEditable}
          objectKey="_id"
        />
      </ComplementTableWrapper>
      <ComplementGroupLoader
        open={openCompGroupLoaderModal}
        data={data}
        setData={setData}
        onClose={handleOnModalClose}
      />
      <ComplementLoader
        open={openCompLoaderModal}
        data={data}
        group={lastVisitedGroup}
        setGroup={setLastVisitedGroup}
        onClose={handleOnInsideModalClose}
      />
      <ComplementGroupImport
        open={openCompImportModal}
        data={data}
        myId={myId}
        subcat={subcat}
        setData={setData}
        onClose={handleOnModalClose}
      />
    </>
  );
};

ListComplement.defaultProps = {
  complements: [],
  categories: [],
  complList: [],
  lastVisitedGroup: {},
};

ListComplement.propTypes = {
  complements: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.object),
};

export default ListComplement;
