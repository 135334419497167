import React from 'react';
import PropTypes from 'prop-types';
import SignaturesEmpty from './signatures-empty/SignaturesEmpty';
import SignaturesPlansList from './signatures-plans-list/SignaturesPlansList';

const SignaturesPlans = ({ cards, onClickedTerms }) => (
  <>
    { cards.length > 0
      ? (
        <SignaturesPlansList
          cards={cards}
          onClickedTerms={onClickedTerms}
        />
      )
      : <SignaturesEmpty />}
  </>
);

SignaturesPlans.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClickedTerms: PropTypes.func.isRequired,
};

export default SignaturesPlans;
