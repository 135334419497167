import React from "react";
import styled from "styled-components";
import MUITextField from "@material-ui/core/TextField";
import { BsSearch } from "react-icons/bs";
import { withStyles } from "@material-ui/core/styles";

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
  svg {
    position: absolute;
    top: 11px;
    right: 16px;
  }
  .MuiSvgIcon-root {
    display: none;
  }
  .MuiFormLabel-root {
    padding-right: 8px;
    background-color: #fff;
  }
`;

const MuiTextFieldSearchStyled = withStyles({
  root: {
    width: "100%",
    margin: "0px",
    "& label.Mui-focused": {
      color: "#FF5533",
    },
    "& label": { color: "#FF5533" },

    "& MuiSvgIcon-root": { display: "none" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      "& fieldset": {
        borderColor: "#c6c6c6",
      },
      "&:hover fieldset": {
        borderColor: "#FF5533",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FF5533",
      },
    },
  },
})(MUITextField);

const InputSearch = (props) => {
  return (
    <SearchContainer>
      <MuiTextFieldSearchStyled {...props} />
      <BsSearch />
    </SearchContainer>
  );
};

export default InputSearch;
