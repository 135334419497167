import React from "react";
import { Radio } from "@material-ui/core";

import { Container } from "./styles";

function RadioButton({ name, value, checked, onChange }) {
  return (
    <Container>
      <Radio
        checked={checked}
        onChange={onChange}
        value={value}
        name={name}
        inputProps={{ "aria-label": name }}
      />
    </Container>
  );
}

export default RadioButton;
