export const newCouponForm = {
  couponCod: "",
  startedAt: new Date(),
  endendAt: new Date(),
  useLimite: "",
  type: "",
  discountValue: "",
  discountPercent: "",
  active: true,
};
