import React from "react";
import { PropTypes } from "prop-types";
import ButtonStyled from "./Styles";

const Button = (props) => {
  const {
    children,
    btnType,
    disabled,
    onClick,
    className,
    type,
    cWidth,
    cHeight,
    cBg,
    cFontSize,
  } = props;
  return (
    <ButtonStyled
      className={className}
      btnType={btnType}
      disabled={disabled}
      onClick={onClick}
      type={type}
      cWidth={cWidth}
      cHeight={cHeight}
      cBg={cBg}
      cFontSize={cFontSize}
    >
      {children}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  btnType: "primary",
  children: "",
  disabled: false,
  type: "button",
  className: "",
  onClick: () => {},
  large: false,
};

Button.propTypes = {
  btnType: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.any,
    PropTypes.number,
  ]),
};

export default Button;
