import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Row, Col } from 'styled-bootstrap-grid';
import restCards from '../../../../api/cards/rest-cards';
import { useAPIErrorContext } from '../../../../contexts/APIErrorContext';
import SignaturesCreditCard from './signatures-credit-card/SignaturesCreditCard';
import SignaturesPlans from './signatures-plans/SignaturesPlans';
import { useBlockLoadingContext } from '../../../../contexts/BlockLoaderContext';
import apiErrorHandler from '../../../../utils/api-error-handler';
import ModalTerms from '../../../../components/modal-terms/modal-terms';
import { Translator } from '../../../../components/I18n';

const Signatures = () => {
  const [cards, setCards] = useState([]);
  const modalError = useAPIErrorContext();
  const { setIsLoading } = useBlockLoadingContext();
  const [openModalTerms, setOpenModalTerms] = useState(false);
  const history = useHistory();

  const unableToLoadCards = <Translator path="unableToLoadCards.message" />;

  const onClickGoBack = () => {
    modalError.setOpen(false);
    history.push('/home/meu-estabelecimento?tab=subscriptions');
  };

  const handlesResponseSuccess = (response) => {
    setIsLoading(false);
    const { card } = response.data;
    const newCards = card !== null ? [card] : [];
    setCards(newCards);
  };

  const tryAgainGetCards = () => {
    setIsLoading(true);
    modalError.setOpen(false);
    restCards.getCard()
      .then(handlesResponseSuccess)
      .catch((err) => {
        setIsLoading(false);
        console.log(unableToLoadCards)
        modalError.setMessage(unableToLoadCards);
        modalError.setError(apiErrorHandler.handleError(err));
        modalError.setOnClickTryAgainFunc(() => tryAgainGetCards());
        modalError.setOnClickGoBackFunc(() => onClickGoBack());
        modalError.setOpen(true);
      });
  };

  const handleResponseError = (err) => {
    setIsLoading(false);
    console.log(unableToLoadCards)
    modalError.setMessage(unableToLoadCards);
    modalError.setError(apiErrorHandler.handleError(err));
    modalError.setOnClickTryAgainFunc(() => tryAgainGetCards());
    modalError.setOnClickGoBackFunc(() => onClickGoBack());
    modalError.setOpen(true);
  };

  const getCards = () => {
    setIsLoading(true);
    restCards.getCard()
      .then(handlesResponseSuccess)
      .catch(handleResponseError);
  };

  const handleOnCreateNewCard = () => {
    getCards();
  };

  useEffect(() => {
    getCards();
    // eslint-disable-next-line
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col sm="12" md="5" lg="5" xl="4">
          <SignaturesCreditCard
            cards={cards}
            onCreateCard={handleOnCreateNewCard}
            onClickedTerms={() => setOpenModalTerms(true)}
          />
        </Col>
        <Col sm="12" md="7" lg="7" xl="8">
          <SignaturesPlans
            cards={cards}
            onClickedTerms={() => setOpenModalTerms(true)}
          />
        </Col>
      </Row>
      <ModalTerms
        isOpen={openModalTerms}
        onClose={() => setOpenModalTerms(false)}
      />
    </Container>
  );
};

export default Signatures;
