/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";

import restCoupons from "../../../api/coupons/rest-coupons";

import {
  Container,
  ContainerFilters,
  InputFilters,
  ContainerButton,
} from "./styles";

import InputSearch from "../../../components/inputs/mui-input-search/input-search";
import { MuiTextFieldStyled } from "../../../components/inputs/mui-inputs/Styles";
import { Autocomplete } from "@material-ui/lab";
import ButtonAddTab from "../../../components/button-add-tab/ButtonAddTab";
// import apiErrorHandler from "../../../utils/api-error-handler";
import { useAPIErrorContext } from "../../../contexts/APIErrorContext";
import BlockLoader from "../../../components/block-loader/BlockLoader";
import EmptyScreen from "../../../components/empty-screen/EmptyScreen";
import ListCoupons from "./list-coupons/ListCoupons";
import NewCoupons from "./new-coupons/NewCoupons";
import { newCouponForm } from "./new-coupons/NewCouponForm";
import { useHistory } from "react-router-dom";
import TranslatorFunction from '../../../components/I18n/Translator';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const Coupons = () => {
  
  const aCouponWithThisCodeAlreadyExists = TranslatorFunction({ path: 'aCouponWithThisCodeAlreadyExists.message' });
  const search = TranslatorFunction({ path: 'search.message' });
  const filter = TranslatorFunction({ path: 'filter.message' });
  const add = TranslatorFunction({ path: 'add.message' });
  const modalError = useAPIErrorContext();
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    name: "",
    type: "",
  });
  const [couponsData, setCouponsData] = useState();
  const [editMode, setEditMode] = useState(false);
  const [form, setForm] = useState({ ...newCouponForm });
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const query = useQuery();

  const page = query.get("page");
  const name = query.get("name");
  const type = query.get("type");

  const menuItems = [
    { value: "shipping", label: TranslatorFunction({ path: "freeDelivery.message" }) },
    { value: "discount", label: TranslatorFunction({ path: "discount.message" }) },
  ];

  const onClickGoBack = () => {
    modalError.setOpen(false);
    onClose();
  };

  const tryAgain = async () => {
    setIsLoading(true);
    editMode ? await putCoupons() : await postCoupon();
  };

  const handleError = (err) => {
    setIsLoading(false);
    modalError.setMessage(aCouponWithThisCodeAlreadyExists);
    // modalError.setError(apiErrorHandler.handleError(err));
    modalError.setOnClickTryAgainFunc(() => tryAgain());
    modalError.setOnClickGoBackFunc(() => onClickGoBack());
    modalError.setOpen(true);
  };

  const handleResponseSuccess = (data) => {
    setCouponsData(data);
    setIsLoading(false);
  };

  const putCoupons = async (id, data) => {
    try {
      await restCoupons.putCoupons(id, data);
    } catch (error) {
      handleError(error);
      console.log(error);
    }
  };

  const postCoupon = async (data) => {
    try {
      setIsLoading(true);
      await restCoupons.postCoupons(data);
    } catch (error) {
      handleError(error);
      console.log(error);
    }
  };

  const fetchCoupons = async () => {
    try {
      const { data } = await restCoupons.fetchCoupons(
        filters.page,
        filters.name,
        filters.type
      );
      handleResponseSuccess(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnClickAdd = () => {
    setEditMode(false);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setIsLoading(true);
    setEditMode(false);
    setForm({ ...newCouponForm });
    fetchCoupons();
  };

  const handleOnClickEdit = (selectedCoupon) => {
    setEditMode(true);
    setForm({ ...selectedCoupon });
    setOpen(true);
  };

  const handleChangeNameFilter = (event) => {
    const { value } = event.target;
    setFilters({ ...filters, name: value });

    if (query.get("name")) {
      query.delete("name");
    }

    if (value !== "") query.append("name", value);

    history.push({ search: query.toString() });
  };

  const onChangeActualPage = (obj) => {
    setFilters({ ...filters, page: parseInt(obj.selected) + 1 });

    if (query.get("page")) {
      query.delete("page");
    }

    query.append("page", parseInt(obj.selected) + 1);

    history.push({ search: query.toString() });
  };

  const handleChangeType = (event, value) => {
    setFilters({ ...filters, type: value?.value ?? "" });

    if (query.get("type")) {
      query.delete("type");
    }

    query.append("type", value?.value);

    history.push({ search: query.toString() });
  };

  useEffect(() => {
    fetchCoupons();
    setFilters({ name: name ?? "", page: page ?? 1, type: type ?? "" });
  }, []);

  useEffect(() => {
    fetchCoupons();
  }, [filters]);

  return (
    <BlockLoader blocking={isLoading}>
      <Container>
        <ContainerFilters>
          <InputFilters>
            <InputSearch
              margin="dense"
              label={search}
              variant="outlined"
              onBlur={handleChangeNameFilter}
            />
            <Autocomplete
              options={menuItems}
              getOptionLabel={(option) => option.label}
              onChange={handleChangeType}
              renderInput={(props) => (
                <MuiTextFieldStyled
                  margin="dense"
                  variant="outlined"
                  label={filter}
                  {...props}
                />
              )}
            />
          </InputFilters>
          <ContainerButton>
            <ButtonAddTab
              buttonText={add}
              isTab={false}
              onClickButton={handleOnClickAdd}
            />
          </ContainerButton>
        </ContainerFilters>
        {!isLoading && couponsData?.coupons.length > 0 ? (
          <ListCoupons
            data={couponsData}
            setData={setCouponsData}
            putCoupons={putCoupons}
            handleOnClickEdit={handleOnClickEdit}
            page={filters.page}
            onChangePage={onChangeActualPage}
          />
        ) : (
          <EmptyScreen />
        )}
      </Container>
      <NewCoupons
        open={open}
        onClose={onClose}
        createCoupon={postCoupon}
        setOpen={setOpen}
        form={form}
        setForm={setForm}
        edit={editMode}
        updateCoupon={putCoupons}
      />
    </BlockLoader>
  );
};

export default Coupons;
