const sortFunction = (a, b, key) => {
  const valA = a[key].toUpperCase();
  const valB = b[key].toUpperCase();
  if (valA < valB) return -1;
  if (valA > valB) return 1;
  return 0;
};

const sortFunctionReverse = (a, b, key) => {
  const valA = a[key].toUpperCase();
  const valB = b[key].toUpperCase();
  if (valA > valB) return -1;
  if (valA < valB) return 1;
  return 0;
};

const sortByStatusFunction = (a, b, key, status) => {
  const valA = a[key];
  const valB = b[key];
  if (valA === status && valB !== status) return -1;
  if (valA !== status && valB === status) return 1;
  return 0;
};

const orderAlphabetical = (list = [], key, order) => (
  order === 'ASC'
    ? list.sort((a, b) => sortFunction(a, b, key))
    : list.sort((a, b) => sortFunctionReverse(a, b, key))
);

const orderByStatus = (list = [], key, status) => (
  list.sort((a, b) => sortByStatusFunction(a, b, key, status))
);

export {
  orderAlphabetical,
  orderByStatus,
};
