import styled from "styled-components";
import Button from "../../../../../../../components/button/Button";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiTypography from "@material-ui/core/Typography";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";

const ComplementGroupImportWrapper = styled.div`
  width: 65vw;
  height: 60vh;
  min-width: 40vw;
  padding: 4px;
`;

const ComplementGroupImportForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const ComplementGroupImportAcWrapper = styled.div`
  overflow-y: scroll;
  height: 42vh;
  max-height: 42vh;
  display: flex;
  flex-direction: column;
`;

const ComplementGroupImportSubAcWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &:nth-child(even) {
    background-color: black;
  }
  &:nth-child(odd) {
    background-color: azure;
  }
`;

const ComplementGroupImportCell = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const ComplementGroupImportButtonGoBack = styled(Button)`
  float: right;
`;

const ComplementGroupImportButtonSave = styled(Button)`
  float: left;
`;

const ComplementGroupImportFooter = styled.footer`
  height: 10vh;
  bottom: 0px;
  width: 95%;
  position: absolute;
`;

const Empty = styled.footer`
  height: 10%;
  bottom: 0px;
  width: 95%;
`;

const ComplementGroupImportLastLevel = styled.div`
  /* width: "100%"; */
  /* min-height: "200px"; */
  padding: 15px;
  /* padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px; */

  /* padding: 10px */

  /* border: "1px solid rgba(0, 0, 0, .125)"; */

  background-color: ${({ id }) => {
    if (id % 2 === 0) return "#dedede";
    else return "#d5d5d5";
  }};

  /* .even {
    background-color: black;
  }
  .odd {
    background-color: azure;
  } */
`;

const ComplementGroupWithToggle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0px;

  .thirdLevel {
    background-color: "#000";
  }
`;

const ComplementGroupBarText = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0px;
  align-items: center;
`;

const ComplementGroupFilterBox = styled.div`
  padding-top: 2px;
  width: 44%;
  display: grid;
  gap: 2%;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 16px;
`;

const ComplementGroupInput = styled.input`
  width: 30%;
`;

const CustomAccordion = withStyles({
  root: {
    width: "100%",
    padding: "0px",
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    backgroundColor: "#fff",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "0px",
      // width: "65vw",
    },
  },
  expanded: {},
})(MuiAccordion);

// const CAccordeon = {
//   root: {
//     border: "1px solid rgba(0, 0, 0, 0.125)",
//     "&:not(:last-child)": {
//       borderBottom: 0,
//     },
//     "&:before": {
//       display: "none",
//     },
//     "&$expanded": {
//       margin: "0px",
//       // width: "65vw",
//     },
//   },
//   expanded: {},
// };
// const CustomAccordion = withStyles(CAccordeon)(MuiAccordion);

const CustomAccordionDisabled = withStyles({
  root: {
    width: "100%",
    padding: "0px",
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    backgroundColor: "#fff",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "0px",
      // width: "65vw",
    },
  },
  expanded: {},
})(MuiAccordion);

const CustomAccordionSummary = withStyles({
  root: {
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    font: "arial",

    fontWeight: "fontWeightBold",
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const CustomAccordionDetails = withStyles({
  root: {
    // backgroundColor: "#000",
    width: "100%",
    backgroundColor: "#bbb",
    padding: "0px",
    marginBottom: -1,
    minHeight: 56,
  },
})(MuiAccordionDetails);

const CustomAccordionMsgDetails = withStyles({
  root: {
    width: "100%",
    backgroundColor: "#bbb",
    marginBottom: -1,
    minHeight: 56,
  },
})(MuiAccordionDetails);

const CustomTypography = withStyles({
  root: {
    color: "#818181",
    fontWeight: "bold",
  },
})(MuiTypography);

const CustomFormControlLabel = withStyles({
  root: {
    color: "#6e6e6e",
    fontWeight: "bold",
    // width: "3%",
  },
})(MuiFormControlLabel);

export {
  ComplementGroupImportWrapper,
  ComplementGroupImportForm,
  ComplementGroupImportFooter,
  ComplementGroupImportButtonSave,
  ComplementGroupImportButtonGoBack,
  ComplementGroupImportAcWrapper,
  ComplementGroupImportSubAcWrapper,
  ComplementGroupImportCell,
  ComplementGroupImportLastLevel,
  ComplementGroupWithToggle,
  ComplementGroupBarText,
  ComplementGroupFilterBox,
  ComplementGroupInput,
  Empty,
  CustomAccordion,
  CustomAccordionDisabled,
  CustomAccordionSummary,
  CustomAccordionDetails,
  CustomAccordionMsgDetails,
  CustomTypography,
  CustomFormControlLabel,
};
