import styled, { css } from 'styled-components';
import { Description12px400Regular } from '../../styles/style-guide';

const topPos = css`
  top: -8px;
  left: 50%;
  transform: translate(-50%, -100%);
`;

const bottomPos = css`
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, 100%);
`;

const leftPos = css`
  top: 50%;
  left: -8px;
  transform: translate(-100%, -50%);
`;

const rightPos = css`
  top: 50%;
  right: -8px;
  transform: translate(100%, -50%);
`;

const getPositionCss = (position) => {
  switch (position) {
    case 'top': return topPos;
    case 'bottom': return bottomPos;
    case 'left': return leftPos;
    case 'right': return rightPos;
    default: return topPos;
  }
};

const TooltipMessage = styled.p`
  z-index: 1000;
  width: max-content;
  padding: 5px 8px;
  position: absolute;
  display: none;

  border-radius: 2px;

  color: ${({ theme }) => theme.colors.textColorContrast};
  background-color: ${({ theme }) => theme.colors.textColor};
  opacity: 0.8;

  ${Description12px400Regular}
  ${({ position }) => getPositionCss(position)}
`;

const TooltipContainer = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
  display: flex;

  &:hover {
    cursor: ${({ changeCursor }) => (changeCursor ? 'pointer' : 'default')};
    ${TooltipMessage} {
      display: block;
    }
  }
`;

export {
  TooltipContainer,
  TooltipMessage,
};
