import styled from "styled-components";
import { InputGroupWithToggle } from "../../../../../components/styled/Forms";
import Button from "../../../../../components/button/Button";
import {
  Paragraph16px300Light,
  Title24px400Regular,
} from "../../../../../styles/style-guide";

const NewFormPaymentWrapper = styled.div`
  max-height: 60vh;
  height: 100%;
  padding: 14px;
  border-radius: 30px;
  /* width: 60vw; */
`;

const NewFormPaymentTitle = styled.h4`
  ${Title24px400Regular};
  margin-bottom: 10px;
  text-align: center;
  color: #9a9a9a;
`;

const NewFormPaymentSubTitle = styled.h4`
  margin-bottom: 50px;
  text-align: center;
  font-weight: 400;
  color: #6a6a6a;
`;

const NewFormPaymentInputTitle = styled.label`
  position: absolute;
  background: white;
  top: -7px;
  left: 25px;
  font-size: 11px;
  font-weight: 600;
  color: #ff5533;
`;
const NewFormPaymentInput = styled.input`
  /* margin-left: 12px; */
  border-width: 1px;
  border-radius: 10px;
  border-color: #9a9a9a;
  width: ${({ inputPix }) => (inputPix ? "600px" : "100%")};
  height: 35px;
  padding-left: 10px;
  :focus {
    border-radius: 10px;
    outline: 0;
  }
`;
const NewFormPaymentInputContent = styled.div`
  height: 35px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;

const NewFormPaymentCheckBox = styled.div`
  display: flex;
  flex: 0;
  min-width: 100px;
`;

const NewFormPaymentGrid = styled.div`
  max-height: 300px;
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 3fr 3fr;
`;

const NewFormPaymentFormContent = styled.div`
  height: 300px;
`;

const NewFormPaymentForm = styled.form`
  /* display: flex; */
  /* flex-direction: column; */
  /* height: 40vh; */
  justify-content: space-between;
`;

const NewFormPaymentPhotoContent = styled.div`
  margin-top: 40px;
  display: flex;
`;

const NewFormPaymentPhotoTextContent = styled.div`
  width: 40%;
  height: 320px;
`;

const NewFormPaymentPhotoArea = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  height: 320px;
`;

const NewFormPaymentPhotoAreaContent = styled.div`
  margin-left: 35px;
  align-self: flex-end;
  height: 100%;
  width: 100%;
  max-width: 426px;
`;

const NewFormPaymentPhotoParagraph = styled.p`
  margin-top: 16px;
  ${Paragraph16px300Light};
  color: ${({ theme }) => theme.colors.textColor};
`;

const NewFormPaymentInputGroupWithToggle = styled(InputGroupWithToggle)`
  margin-top: 15px;
  margin-bottom: 11px;
  justify-content: "flex-start";

  justify-content: ${({ index }) => (index % 3 === 0 ? "flex-start" : "")};
  justify-content: ${({ index }) => (index % 3 === 1 ? "flex-start" : "")};
  /* justify-content: ${({ index }) => (index % 3 === 2 ? "flex-end" : "")}; */

  width: 100%;
  margin-left: ${({ index }) => index % 3 === 2 && "auto"};
`;

const NewFormPaymentButtonGoBack = styled(Button)`
  float: right;
`;

const NewFormPaymentButtonSave = styled(Button)`
  float: left;
`;

const NewFormPaymentFooter = styled.footer`
  margin-top: 30px;
  bottom: 20px;
  width: 100%;
`;
const NewFormPaymentInputGroup = styled.div`
  display: flex;
  flex: 0;
  min-width: 100px;
`;

export {
  NewFormPaymentWrapper,
  NewFormPaymentForm,
  NewFormPaymentFooter,
  NewFormPaymentButtonSave,
  NewFormPaymentButtonGoBack,
  NewFormPaymentPhotoContent,
  NewFormPaymentPhotoTextContent,
  NewFormPaymentPhotoParagraph,
  NewFormPaymentPhotoArea,
  NewFormPaymentPhotoAreaContent,
  NewFormPaymentInputGroupWithToggle,
  NewFormPaymentInput,
  NewFormPaymentCheckBox,
  NewFormPaymentInputGroup,
  NewFormPaymentTitle,
  NewFormPaymentInputTitle,
  NewFormPaymentInputContent,
  NewFormPaymentSubTitle,
  NewFormPaymentGrid,
  NewFormPaymentFormContent,
};
