const { default: formUtils } = require('./form-utils');

const formatFormRaw = (form) => {
  const rawForm = formUtils.getFormRawValue({ ...form });
  const expirationDateArr = rawForm.expirationDate.split('/');
  const { 0: first, 1: second } = expirationDateArr;
  rawForm.cardNumber = rawForm.cardNumber.toString().split(' ').join('');
  rawForm.expirationMonth = first;
  rawForm.expirationYear = second;
  delete rawForm.expirationDate;
  return rawForm;
};

export default formatFormRaw;
