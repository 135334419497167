export default {
  translations: {
    language: {
      message: 'zh-Hant',
    },
    LoginFormTitle: {
      message: '登錄'
    },
    LoginFormSubtitle: {
      message: '您好，請輸入您的郵箱和密碼登錄：'
    },
    LoginFormErroMessage: {
      message: '無法登錄，請重試。'
    },
    LoginFormButton: {
      message: '登錄'
    },
    LoginFormButtonForgotPassword: {
      message: '我忘記密碼了'
    },
    password: {
      message: '密碼'
    },
    confirmPassword: {
      message: '確認密碼'
    },
    mail: {
      message: '電子郵件'
    },
    myEstablishment: {
      message: '我的機構'
    },
    parameterization: {
      message: '參數化'
    },
    itemRegistration: {
      message: '物品登記'
    },
    orders: {
      message: '要求'
    },
    discountCoupons: {
      message: '優惠券'
    },
    logout: {
      message: '出去'
    },
    establishmentDetails: {
      message: '成立詳情'
    },
    subscriptions: {
      message: '訂閱'
    },
    aboutTheEstablishment: {
      message: '關於成立'
    },
    mandatoryFilling: {
      message: '強制的'
    },
    tradeName: {
      message: '商品名稱（您的機構所用的名稱）'
    },
    companyCorporateName: {
      message: '社會理性'
    },
    informTheCompanyCorporateName: {
      message: '告知公司法人名稱'
    },
    pixel: {
      message: '像素'
    },
    informPixel: {
      message: '報告像素'
    },
    invalidCnpj: {
      message: '無效的 CNPJ'
    },
    invalidCep: {
      message: '無效的郵政編碼'
    },
    state: {
      message: '狀態'
    },
    city: {
      message: '城市'
    },
    cityUpper: {
      message: '城市'
    },
    neighborhoods: {
      message: '鄰里w'
    },
    street: {
      message: '路'
    },
    number: {
      message: '數字'
    },
    informTheNumber: {
      message: '輸入號碼號碼'
    },
    complement: {
      message: '補充'
    },
    complementUpper: {
      message: '補充ww'
    },
    URLToYourMenu: {
      message: '您的菜單的 URL'
    },
    emailToLoginToTheManager: {
      message: '用於登錄經理的電子郵件'
    },
    name: {
      message: '姓名'
    },
    nameUpper: {
      message: '姓名'
    },
    lastName: {
      message: '姓'
    },
    contactEmail: {
      message: '聯繫電子郵件'
    },
    cellphoneNumberWhatsApp: {
      message: '手機/WhatsApp'
    },
    thisFieldIsRequired: {
      message: '這是必填欄。'
    },
    enterYourCreditCardDetails: {
      message: '輸入您的信用卡詳細信息：'
    },
    thisIsTheCurrentPaymentMethod: {
      message: '這是當前的付款方式：'
    },
    paymentMethod: {
      message: '付款方式：'
    },
    paymentMethod2: {
      message: '支付方式'
    },
    paymentMethod3: {
      message: '支付方式'
    },
    cardNumber: {
      message: '卡號：'
    },
    enterAValidCardNumber: {
      message: '請輸入有效的卡號。'
    },
    enterAValidDate: {
      message: '請輸入一個有效的日期。'
    },
    cardholderName: {
      message: '持卡人姓名。'
    },
    typeAsWrittenOnCard: {
      message: '輸入卡片上的內容。'
    },
    enterTheNameAsItIsWrittenOnTheCard: {
      message: '輸入姓名的方式與寫在卡片上的方式相同。'
    },
    bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable: {
      message: '通過保存您的付款詳細信息，您授權 MyTripMenu 每月自動收取計劃金額，直到您取消訂閱。 條款和條件可用'
    },
    inThisLink: {
      message: '在這個鏈接上。'
    },
    savePaymentData: {
      message: '保存付款數據'
    },
    addACreditCardToViewPlanInformationAndConfirmYourSubscription: {
      message: '添加信用卡以查看計劃信息並確認您的訂閱。'
    },
    deliveryConfiguration: {
      message: '交貨配置'
    },
    schedules: {
      message: '時間表'
    },
    Schedules: {
      message: '時間表'
    },
    add: {
      message: '添加'
    },
    onSiteServiceVisualization: {
      message: '上門服務 | 可視化'
    },
    deliveryWhatsappOrders: {
      message: '交貨 | WhatsApp的請求'
    },
    takeAway: {
      message: '退出'
    },
    catalog: {
      message: '目錄'
    },
    typesOfService: {
      message: '服務類型'
    },
    mandatoryDataForDelivery: {
      message: '交付的強制性數據'
    },
    phone: {
      message: '電話'
    },
    phoneUpper: {
      message: '電話'
    },
    address: {
      message: '地址'
    },
    generalSettings: {
      message: '通用設置'
    },
    freeShippingForPurchasesAbove: {
      message: '購買以上免費送貨'
    },
    noFreeShipping: {
      message: '沒有免費送貨'
    },
    minimumValueForDeliveryOrders: {
      message: '交貨訂單的最低價值'
    },
    minimumDeliveryTime: {
      message: '最短交貨時間'
    },
    noForecast: {
      message: '沒有預測'
    },
    maximumDeliveryTime: {
      message: '最長交貨時間'
    },
    orderResponseTimeInMinutes: {
      message: '以分鐘為單位的訂單響應時間'
    },
    serviceNumberViaWhats: {
      message: '通過 WHATSAPP 獲得服務號碼'
    },
    servicePlaces: {
      message: '服務場所'
    },
    action: {
      message: '行動'
    },
    addEditCities: {
      message: '添加/編輯城市'
    },
    newCity: {
      message: '新城市'
    },
    search: {
      message: '搜索'
    },
    addNew: {
      message: '添新'
    },
    saveEditions: {
      message: '保存版本'
    },
    exitWithoutSaving: {
      message: '退出而不保存'
    },
    saveEditionsAndExit: {
      message: '保存更改並退出'
    },
    back: {
      message: '回來'
    },
    status: {
      message: '地位'
    },
    active: {
      message: '積極的'
    },
    inactive: {
      message: '不活動'
    },
    category: {
      message: '類別'
    },
    subcategory: {
      message: '子類別'
    },
    categories: {
      message: '類別'
    },
    subcategories: {
      message: '子類別'
    },
    categoriesUpper: {
      message: '類別'
    },
    subcategoriesUpper: {
      message: '子類別'
    },
    complements: {
      message: '附加組件'
    },
    complementsGroup: {
      message: '附加組'
    },
    complementsGroupUpper: {
      message: '補語組'
    },
    products: {
      message: '產品'
    },
    image: {
      message: '圖片'
    },
    yes: {
      message: '是的'
    },
    no: {
      message: '不'
    },
    theHighlightsCategoryCannotBeEdited: {
      message: '亮點類別無法編輯'
    },
    sortFromAZ: {
      message: '從 A-Z 排序'
    },
    sortFromZA: {
      message: '按 Z-A 排序'
    },
    sortByActive: {
      message: '按活動排序'
    },
    sortByInactive: {
      message: '按非活動排序'
    },
    listActiveOnly: {
      message: '僅資產清單'
    },
    addEditCategory: {
      message: '添加/編輯類別'
    },
    addEditSubcategory: {
      message: '添加/編輯子類別'
    },
    imageOptional: {
      message: '圖片（可選）'
    },
    addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive: {
      message: '向類別添加說明性圖像是一項可選操作，但是，它有助於使您的菜單更具吸引力。'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality: {
      message: '選擇一張照片，說明構成該類別的項目，具有良好的照明並且與產品的實際展示相關。 避免使用通用、庫存或低質量的照片。'
    },
    theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB: {
      message: '理想的分辨率是 800x600 像素，圖像不能大於 1 MB。'
    },
    selectPhoto: {
      message: '選擇照片'
    },
    selectCategory: {
      message: '選擇類別'
    },
    saveAndAddAnother: {
      message: '保存並添加另一個'
    },
    highlightsSubcategoryCannotBeEdited: {
      message: '亮點子類別無法編輯'
    },
    categoryInWhichTheSubcategoryShouldAppear: {
      message: '子類別應出現的類別'
    },
    addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive: {
      message: '向子類別添加說明性圖像是一項可選操作，但它有助於使您的菜單更具吸引力。'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos: {
      message: '選擇一張能說明構成該子類別的項目、具有良好照明並且與產品的實際展示相關的照片。 避免使用通用、庫存或低質量的照片。'
    },
    enterANameForTheSubcategory: {
      message: '輸入子類別的名稱'
    },
    enterANameForTheCategory: {
      message: '輸入類別的名稱'
    },
    descriptionOfComplement: {
      message: '補充說明'
    },
    descriptionOfComplementUpper: {
      message: '補充說明'
    },
    addComplementGroup: {
      message: '添加補組'
    },
    complementGroupName: {
      message: '附加組名稱'
    },
    complementName: {
      message: '附加名稱'
    },
    checkAllFields: {
      message: '檢查所有字段。'
    },
    complementGroupIsInactive: {
      message: '附加組件組處於非活動狀態。'
    },
    value: {
      message: '價值'
    },
    valueUpper: {
      message: '價值'
    },
    addProduct: {
      message: '添加產品'
    },
    images: {
      message: '圖片'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos: {
      message: '選擇一張照片，說明構成該產品的物品，具有良好的照明，並且與您的實際演示相關。 避免使用通用、庫存或低質量的照片。'
    },
    enableOn: {
      message: '啟用'
    },
    categorysInWhichTheProductShouldAppear: {
      message: '產品應出現的類別'
    },
    subCategorysInWhichTheProductShouldAppear: {
      message: '產品應出現的子類別'
    },
    categorization: {
      message: '分類'
    },
    shortDescriptionShownInProductDetail: {
      message: '簡要說明（顯示在產品詳細信息中）'
    },
    longDescriptionShownInProductDetail: {
      message: '詳細描述（顯示在產品詳細信息中）'
    },
    enterTheIngredientsThatMakeUpThisProductItsAccompanimentsAndThePortionSizes: {
      message: '輸入構成該產品的成分、其伴奏和份量'
    },
    enterSomethingLikeSideDishesOrPortionSize: {
      message: '輸入諸如配菜或份量之類的內容'
    },
    principalInformation: {
      message: '主要信息'
    },
    id: {
      message: 'ID'
    },
    type: {
      message: '類型'
    },
    validity: {
      message: '有效性'
    },
    validityUpper: {
      message: '有效性'
    },
    useAvailable: {
      message: '使用/可用'
    },
    filter: {
      message: '篩選'
    },
    freeDelivery: {
      message: '免費送貨'
    },
    discount: {
      message: '折扣'
    },
    aCouponWithThisCodeAlreadyExists: {
      message: '帶有此代碼的優惠券已存在。'
    },
    dateTime: {
      message: '約會時間'
    },
    change: {
      message: '事物'
    },
    formOfPayment: {
      message: '付款方式'
    },
    customer: {
      message: '客戶'
    },
    uninformed: {
      message: '不知情'
    },
    recordsFound: {
      message: '找到記錄'
    },
    QRCodeOfYourDigitalMenu: {
      message: '您的數字菜單的二維碼'
    },
    downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu: {
      message: '下載您的二維碼並在您的機構或宣傳材料中提供，以方便客戶訪問您的數字菜單'
    },
    areYouSureYouWantToDoThis: {
      message: '你確定要這麼做嗎？'
    },
    whenDisablingTheComplementAllPreviouslyLinkedComplementGroupsWillBeUnlinkedFromTheProduct: {
      message: '通過禁用附加組件，所有以前鏈接的附加組件組都將與產品取消鏈接。'
    },
    anErrorOccurredWhileFetchingProductCategories: {
      message: '獲取產品類別時出錯'
    },
    anErrorOccurredWhileFetchingProductOperations: {
      message: '提取產品操作時出錯'
    },
    anErrorOccurredWhileFetchingTheSubcategoriesForTheSelectedCategory: {
      message: '獲取所選類別的子類別時出錯'
    },
    productImage: {
      message: '產品圖片'
    },
    whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus: {
      message: '當您停用產品時，它將不再出現在在線菜單上。'
    },
    RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary: {
      message: '請記住，如有必要，您可以在其他時間再次激活它。'
    },
    inactivateTheProduct: {
      message: '停用產品'
    },
    keepActive: {
      message: '保持活躍'
    },
    simpleEasyFastDigital: {
      message: '簡單的。 簡單的。 快速地。 數字的。'
    },
    errorIdentification: {
      message: '錯誤識別'
    },
    tryAgain: {
      message: '再試一次'
    },
    huhLooksLikeSomethingWentWrong: {
      message: '哼！ 好像出了什麼問題……'
    },
    unableToSaveCreditCardDetails: {
      message: '無法保存信用卡詳細信息。'
    },
    mmyy: {
      message: '月/年'
    },
    removePhoto: {
      message: '刪除照片'
    },
    addEditCoupon: {
      message: '添加/編輯優惠券'
    },
    couponType: {
      message: '優惠券類型'
    },
    freeShipping: {
      message: '免運費'
    },
    discountByValue: {
      message: '按價值打折'
    },
    percentageDiscount: {
      message: '按百分比折扣'
    },
    generateCoupon: {
      message: '生成優惠券'
    },
    limitOfUse: {
      message: '使用限制'
    },
    couponCode: {
      message: '優惠券代碼'
    },
    validFrom: {
      message: '有效自'
    },
    validUntil: {
      message: '有效期至'
    },
    highlightsSubcategoryCannotBeCopied: {
      message: '亮點子類別無法複製'
    },
    nothingHere: {
      message: '好像這裡還是什麼都沒有.'
    },
    from: {
      message: "在"
    },
    to: {
      message: "直到......為止"
    },
    sunday: {
      message: "星期日"
    },
    monday: {
      message: "第二"
    },
    tuesday: {
      message: "週二"
    },
    wesnesday: {
      message: "第四w"
    },
    thursday: {
      message: "第五"
    },
    friday: {
      message: "星期五"
    },
    saturday: {
      message: "週六"
    },
    accessData: {
      message: "訪問數據"
    },
    responsibleEstablishment: {
      message: "負責編制"
    },
    saveEditions2:{
      message: "保存版本"
    },
    zeit:{
      message: "時間"
    },
    cardsAccepted:{
      message: "標記您的機構接受的卡"
    },
    pixKey:{
      message: "在此處註冊您的 PIX 密鑰"
    },
    noOptions:{
      message: "沒有選項"
    },
    neighborhoods2:{
      message: "鄰里"
    },
    addEditNeighborhoods:{
      message: "添加/編輯社區"
    },
    newNeighborhoods:{
      message: "新社區"
    },
    qtyMinimum:{
      message: "數量 最低限度"
    },
    qtymaximum:{
      message: "數量 最大"
    },
    linkGroup:{
      message: "鏈接用品集團"
    },
    importantGroupComplements:{
      message: "進口補品組"
    },
    people:{
      message: "人們"
    },
    house:{
      message: "人們"
    },
    subcategoryProducts:{
      message: "此子類別沒有關聯產品"
    },
    productNotAdd:{
      message: "此子類別沒有關聯產品"
    },
    linkSupplements:{
      message: "鏈接補充"
    },
    PRICE:{
      message: "價格"
    },
    COMPLEMENTS:{
      message: "補充"
    },
    DESCRIPTION:{
      message: "描述"
    },
    action2:{
      message: "行動"
    },
    pixKey2:{
      message: "您的 PIX 密鑰"
    },
    ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn:{
      message: "如果您在保存更改之前離開屬性詳細信息頁面，您將丟失已填寫的所有信息。"
    },
    couldNotLoadUserData:{
      message: "無法加載用戶數據"
    },
    termsOfUseAanServices:{
      message: "使用和服務條款"
    },
    hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou:{
      message: "你好！很高興有您的興趣！在使用我們的服務之前，請花時間閱讀我們的使用和服務條款，並了解管理我們與您的關係的規則。"
    },
    belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom:{
      message: "下面我們將澄清一些我們認為重要的點。如果您對本文檔中討論或未討論的任何要點有任何疑問，請隨時通過 hello@mytripmenu.com 與我們聯繫。"
    },
    definitions:{
      message: "1. 定義："
    },
    inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions: {
      message: "在本工具中，我們根據以下定義理解以下表達式："
    },
    plataform: {
      message: "平台"
    },
    webSystemAvailableThroughMyTripMenuComBrOfferedBy: {
      message: "網絡系統可通過 mytripmenu.com.br 獲得，由"
    },
    andOwnershipOperationAndLiabilityOf: {
      message: " 和所有權、經營和責任 "
    },
    users: {
      message: "用戶"
    },
    user: {
      message: "用戶"
    },
    where: {
      message: "， 在哪裡"
    },
    allThe: {
      message: "所有"
    },
    canViewAndUseTheServicesOfferedBy: {
      message: "可以查看和使用由"
    },
    establishment: {
      message: "機構"
    },
    establishments: {
      message: "機構"
    },
    legalEntityRegisteredInThe: {
      message: "註冊在"
    },
    whichContractsTheServicesOfferedBy: {
      message: ", 它與提供的服務簽訂合同"
    },
    client: {
      message: "客戶"
    },
    naturalPersonAccessingThe: {
      message: "自然人訪問"
    },
    toViewTheMenu: {
      message: "查看菜單 "
    },
    dataProcessing: {
      message: "數據處理"
    },
    pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy: {
      message: " 根據 2018 年第 13,709 號法第 5 條第 X 項，每筆交易由 "
    },
    withPersonalDataOf: {
      message: " 與個人資料 "
    },
    suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction: {
      message: " ，例如指收集、生產、接收、分類、使用、訪問、複製、傳輸、分發、處理、歸檔、存儲、消除、評估或控制信息、修改、通信、傳輸、傳播或提取的那些。 "
    },
    admission: {
      message: " 2. 錄取： "
    },
    thisInstrumentRegulatesTheConditionsOfUseOfThe: {
      message: " 本儀器規定了使用條件 "
    },
    being: {
      message: "存在"
    },
    aContractBetweenThe: {
      message: " 之間的合同 "
    },
    andThe: {
      message: " 和 "
    },
    theUseOfTheServicesOfferedThrough: {
      message: " 使用通過提供的服務 "
    },
    fromThe: {
      message: "來自 "
    },
    expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies: {
      message: " 明確表示您同意本文​​件中包含的所有條款和條件以及適用於該物種的法律規定。 "
    },
    the: {
      message: "這 "
    },
    understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy: {
      message: " 了解服務合同模式將通過預付月度訂閱計劃進行預算和提供 "
    },
    accordingToInformationAvailableInThePlansAreaOfTheWebsite: {
      message: "根據網站計劃區域提供的信息： "
    },
    forTheContractingOfCustomizedPlansThe: {
      message: "對於定制計劃的承包， "
    },
    shouldContactThe: {
      message: "應該聯繫 "
    },
    throughItsChannelsForTheElaborationOfASpecificBudget: {
      message: "通過其渠道，制定具體預算。 "
    },
    youUnderstandAndAgreeThatThe: {
      message: " 您理解並同意 "
    },
    useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies: {
      message: " 使用所提供的服務將被視為您接受這些條款以及與該物種相關的所有其他法律規定。"
    },
    ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform: {
      message: "通過接受本儀器的條款，用戶明確授權處理他的數據，以保證平台功能的維護和良好性能"
    },
    theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy: {
      message: "用戶在本法案中表示完全同意共享由以下機構收集和處理的數據 "
    },
    pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders: {
      message: "，根據本文件，與屬於您的經濟集團或您的服務提供商的其他我的旅行菜單一起使用。"
    },

    caseYou: {
      message: " 案例你 "
    },
    doNotAgree: {
      message: "不同意"
    },
    withTheProvisionsOfThisInstrument: {
      message: " 根據本文書的規定， "
    },
    dont: {
      message: '不'
    },
    accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom: {
      message: " 訪問、查看、下載或以其他方式使用來自 "
    },
    theseTermsAreAvailableForReadingAtAnyTimeOn: {
      message: "這些條款隨時可供閱讀 "
    },
    inMessage: {
      message: "在 "
    },
    whoWeAreAndWhatWeDo: {
      message: "3. 我們是誰以及我們做什麼："
    },
    isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp: {
      message: "是一家服務提供商，以 SaaS（軟件即服務）的形式開發和許可系統，具有特定的功能來幫助"
    },
    and: {
      message: "和"
    },
    soThe: {
      message: "所以"
    },
    offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe: {
      message: "提供數字菜單，提高效率和體驗"
    },
    asForThe: {
      message: "至於"
    },
    generalConditionsOfUse: {
      message: "4. 一般使用條件："
    },
    onlyProvidesThe: {
      message: " 只提供 "
    },
    whichOffersDigitalMenuServicesForThe: {
      message: " 提供數字菜單服務 "
    },
    itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe: {
      message: " 其責任僅限於正確運行 "
    },
    andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe: {
      message: "及其功能，根據該文書和由合同簽訂的計劃 "
    },
    notStoppingThe: {
      message: "，不停止"
    },
    noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu: {
      message: "對通過數字菜單創建、​​定制和傳播內容不承擔任何責任。"
    },
    justLicenseThe: {
      message: " 只需許可 "
    },
    toThe: {
      message: " 到 "
    },
    toThe2: {
      message: " 到 "
    },
    andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe: {
      message: "，並且這些當事人之間沒有其他關係，因此無法歸因於"
    },
    liabilityForAnyDamageCauseToOthers: {
      message: " 對他人造成的任何損害承擔責任 "
    },
    orToThirdPartiesForActsArisingFrom: {
      message: " 或第三方，對於由以下行為引起的行為 "
    },
    whileUsingTheAvailableFeaturesOfThe: {
      message: " 同時使用的可用功能 "
    },
    declaresThatHeUnderstandsAndAcceptsThatThe: {
      message: "聲明他理解並接受 "
    },
    doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe: {
      message: "不打算替換在商業單位可用的物理菜單 "
    },
    beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween: {
      message: "只是一個補充解決方案，以促進之間的溝通 "
    },
    atTheTimeOfRegistrationThe: {
      message: "在註冊時，"
    },
    youCanUseAllTheServicesAvailableOnThe: {
      message: " 您可以使用所有可用的服務 "
    },
    declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse: {
      message: "，聲明他們已閱讀、理解並接受本使用條款中包含的所有規定。"
    },
    expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom: {
      message: "明確承認，通過該文書，它從 "
    },
    theGarantingOfALicenseToUseThe: {
      message: " 授予使用許可 "
    },
    isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe: {
      message: ", 是不可轉讓的，並且禁止再許可，用於在國家或外國領土上使用，只要遵守本條款，留下使用 "
    },
    inDisagreementWithTheProvisionOfThisInstrument: {
      message: " 不同意本文書的規定。"
    },
    isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt: {
      message: " 對您的密碼安全和使用您在 "
    },
    thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr: {
      message: "，因此，我們建議您不要與第三方共享此類信息，如果此信息出於任何原因丟失或 "
    },
    hacked: {
      message: "被黑"
    },
    mustImmediatelyInformThe: {
      message: " 必須立即通知 "
    },
    throughHelloMyTripMenuComInOrderToResolveTheIssue: {
      message: "，通過hello@mytripmenu.com，以解決問題。"
    },
    itIsOnlyUpToThe: {
      message: "這僅取決於 "
    },
    liableForAnyDamagesCausedToThirdPartiesToOther: {
      message: " 對第三方造成的任何損害負責，其他 "
    },
    orToItself: {
      message: " 或對自己 "
    },
    resultingFromTheUseOfTheFeaturesOfThe: {
      message: "，由於使用了 "
    },
    shouldNotUseTheServicesAvailableOnThe: {
      message: " 不應使用網站上提供的服務 "
    },
    forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe: {
      message: " 用於任何非法、誹謗、歧視、辱罵、冒犯、色情、淫穢、攻擊性、傷害性、無理取鬧、欺騙性、誹謗、暴力、粗俗或騷擾、威脅或使用虛假身份，即任何不正當使用、目的或手段可能會傷害 "
    },
    others: {
      message: " 其他"
    },
    orThirdParties: {
      message: " 或第三方"
    },
    UnderNoCircumstancesWillThe: {
      message: "在任何情況下都不會 "
    },
    willBeLiableForAnyDamagesIncurredByThe: {
      message: "將承擔由此造成的任何損失 "
    },
    forPossibleTemporaryUnavailabilityOfThe: {
      message: " 可能暫時無法使用 "
    },
    orOnyContentMadeAvailableByThe: {
      message: "或由 "
    },
    onTheDigitalMenu: {
      message: "在數字菜單上。"
    },
    mustHaveAllNecessarySoftwareAndHardwareToAccessThe: {
      message: " 必須擁有所有必要的軟件和硬件才能訪問 "
    },
    includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe: {
      message: "，包括但不限於具有互聯網訪問權限的計算機或移動設備，以及 "
    },
    onlyToProvideThe: {
      message: ", 只提供 "
    },
    to2: {
      message: " 敖 "
    },
    underThisInstrumentToThe: {
      message: "根據本文書。到 "
    },
    accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable: {
      message: "訪問數字菜單，能夠讀取二維碼的設備將是必不可少的。"
    },
    byThe: {
      message: " 由 "
    },
    isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument: {
      message: "以事先註冊為條件，並遵守本文書中的規定。"
    },
    registration: {
      message: "5. 註冊"
    },
    soThatThe: {
      message: "所以這樣 "
    },
    registerInThe: {
      message: " 在註冊 "
    },
    mustProvideTo: {
      message: "必須提供給 "
    },
    theFollowingData: {
      message: " 以下數據 "
    },
    corporateName: {
      message: "公司名稱;"
    },
    unitAddressOf: {
      message: " 單位地址 "
    },
    contactNumberInTheEventThatThe: {
      message: "聯繫電話。如果發生"
    },
    hireThe: {
      message: "僱用"
    },
    forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest: {
      message: "對於更多單位，您必須註冊其他感興趣的地址。"
    },
    forRegularUseOf: {
      message: "對於經常使用 "
    },
    mustRegisterFillingInAllTheDataRequestedByThe: {
      message: " 必須註冊，填寫所有要求的數據 "
    },
    atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy: {
      message: " 在註冊時，以及選擇提供的服務計劃 "
    },
    itIsTheResponsibilityOf: {
      message: " 這是責任 "
    },
    identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting: {
      message: "在合同計劃中確定合同中預期的單位數量。"
    },
    itIsTheSoleResponsibilityOf: {
      message: "這是唯一的責任"
    },
    provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe: {
      message: "提供、更新和保證註冊數據的真實性，不屬於"
    },
    anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy: {
      message: " 因提供的不真實、不正確或不完整的數據而導致的任何類型的民事和刑事責任 "
    },
    reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe: {
      message: " 保留使用所有有效和可能的方式來識別 "
    },
    asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf: {
      message: " 以及要求其認為相關的其他數據和文件，以驗證報告的數據。在這種情況下，使用 "
    },
    isConditionedToTheSendingOfTheDocumentsEventuallyRequested: {
      message: " 以最終要求的文件的發送為條件。"
    },
    ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe: {
      message: "如果一個註冊被認為包含錯誤或不真實的數據， "
    },
    reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe: {
      message: " 保留暫時或永久暫停的權利，恕不另行通知， "
    },
    responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe: {
      message: " 負責註冊。如果停課，您將不會參加 "
    },
    rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages: {
      message: " 對損失和損害、利潤損失或精神損害獲得任何類型的賠償或補償的權利。"
    },
    inTheEventThatThe: {
      message: "如果發生"
    },
    carryOutTheCollectionOfPersonalDataFromThe: {
      message: "從 "
    },
    willAllowAccessToTheInformationCollectedAndAboutThe: {
      message: "將允許訪問收集的信息以及關於 "
    },
    carriedOutBy: {
      message: " 通過開展 "
    },
    freeOfChargeByRequestingHelloMyTripMenuCom: {
      message: "，免費，通過請求 hello@mytripmenu.com"
    },
    orByRegisteringAt: {
      message: "或通過註冊 "
    },
    beingAbleToEditOrDeleteThemAtAnyTime: {
      message: " 可以隨時編輯或刪除它們。"
    },
    theCollectionOfDataFrom: {
      message: "數據的收集來自 "
    },
    whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe: {
      message: "，當它發生時，旨在識別您，以及使您能夠正確使用 "
    },
    andWithThatThe: {
      message: "，因此， "
    },
    canEnsureTheGoodQualityOfTheLicensedServices: {
      message: " 可以保證許可服務的質量。"
    },
    byConsentingToTheTermsOfThisInstrumentThe: {
      message: "通過同意本文書的條款，"
    },
    expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe: {
      message: " 明確聲明了解您的數據的收集對於正常運行至關重要 "
    },
    authorizingFromNowOnThe: {
      message: ", 授權, 從現在開始, "
    },
    forThe: {
      message: " 為了 "
    },
    offersThePossibilityOf: {
      message: " 提供了可能性 "
    },
    publicizeYourFigitalMenuThroughQRCodeToYour: {
      message: "通過二維碼將您的數字菜單發佈到您的 "
    },
    withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe: {
      message: "然而，無需登記，不登記並不減損不遵守本文書的規則， "
    },
    mustEnsureCompliance: {
      message: "必須確保合規。"
    },
    willAccessYourRegistrationIn: {
      message: " 將訪問您的註冊 "
    },
    through: {
      message: " 通過 "
    },
    andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem: {
      message: " 和密碼，承諾不將這些數據通知第三方，並對這些數據的使用承擔全部責任。"
    },
    undertakesToNotifyThe: {
      message: " 承諾通知 "
    },
    immediatelyThroughTheContactChannelsMaintainedBy: {
      message: " 立即，通過由維護的聯繫渠道 "
    },
    at: {
      message: " 在 "
    },
    regardingAnyUnauthorizedUseOfYourAccountThe: {
      message: "，關於任何未經授權使用您的帳戶的行為。這"
    },
    willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou: {
      message: " 將對您的帳戶進行的操作全權負責，因為只有通過使用您專有的密碼才能訪問。"
    },
    regardingAnyKnowledgeOfWrongdoingByOther: {
      message: ", 關於其他人的不法行為的任何知識"
    },
    thatMayCauseDamageToTheirOwn: {
      message: " 可能會對自己造成傷害 "
    },
    ofThe: {
      message: " 的 "
    },
    toThisOnTo: {
      message: " 到這個，到 "
    },
    orToThirdParties: {
      message: " 或第三方。"
    },
    undertakesToUsTheFeaturesOfThe: {
      message: "承諾使用的功能"
    },
    inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms: {
      message: " 真誠地，按照現行法律、道德和良好習俗。"
    },
    underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe: {
      message: "在任何情況下，不得轉讓、出售、出租或其他形式的轉讓"
    },
    atItsSoleDiscretion: {
      message: "自行決定 "
    },
    mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO: {
      message: " 可能會在不事先通知或補償的情況下無限期地排除、禁用、限制服務的使用、暫停、阻止、註冊 o "
    },
    thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce: {
      message: " 被認為是冒犯性的，違反本文書條款或現行法律的。"
    },
    reservesTheRightNotToAllowNewRegistrationOf: {
      message: " 保留不允許新註冊的權利 "
    },
    thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom: {
      message: " 已被取消、禁用、阻止、刪除或暫停的 "
    },
    theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe: {
      message: " 原註冊因違反政策而被取消、屏蔽、禁用、排除或暫停的人創建新註冊 "
    },
    orCurrentLegislation: {
      message: " 或現行立法。"
    },
    reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA: {
      message: " 保留在不事先通知、同意或補償的情況下單方面拒絕任何註冊申請的權利 "
    },
    asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration: {
      message: "，以及取消、禁用、阻止、刪除或暫停使用先前接受的註冊。"
    },
    byAgreeingToThisInstrumentThe: {
      message: "通過同意本文書， "
    },
    declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo: {
      message: " 聲明知道它對其註冊負全部責任，並確定因插入過時、不准確或不真實的信息而造成的任何損害不能歸因於 "
    },
    orThe: {
      message: "或者"
    },
    functionalities: {
      message: "6. 功能："
    },
    offersTheFollowingFeatures: {
      message: " 提供以下功能："
    },
    aOnlineDigitalMenu: {
      message: "(a) 在線數字菜單；"
    },
    bViewingProductsWithPphotos: {
      message: "(b) 查看帶有照片的產品；"
    },
    cOfferingAMultilingualMenuSubjectToAvailableConditions: {
      message: "(c) 根據可用條件提供多語種菜單；"
    },
    dActivationAndDeactivationOfItemsInRealTime: {
      message: "(d) 實時啟用和停用項目；"
    },
    eAccessToTheMenuByScanningAQRCode: {
      message: "(e) 通過掃描二維碼訪問菜單；"
    },
    fCreationOfHighlightsForPromotionalActions: {
      message: "(f) 為促銷活動創建亮點。"
    },
    whenUsingTheServicesProvidedByThe: {
      message: " 在使用本站提供的服務時 "
    },
    canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe: {
      message: " 可以選擇 URL（統一資源定位器），以確定將定位數字菜單的電子地址 "
    },
    ToDefineTheURLThe: {
      message: "要定義 URL， "
    },
    mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices: {
      message: "必須尊重第三方的知識產權，因此，不得包含可能引起混淆或暗示第三方服務的表述。"
    },
    understandThatThe: {
      message: " 明白 "
    },
    resourcesWereUsedToCauseConfusionAmongThe: {
      message: " 資源被用來造成混亂 "
    },
    makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe: {
      message: "難以正確識別服務並誤導他們，您可以排除服務的 URL "
    },
    asWellAsAdoptOtherMeasuresRelevantToTheCase: {
      message: "以及採取與案件有關的其他措施"
    },
    mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn: {
      message: " 可以根據合同計劃更改可用的功能範圍，如 "
    },
    mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe: {
      message: " 可以隨時通過發送事先通知而無需補償、編輯和/或刪除現有功能，以及將新功能添加到 "
    },
    translations: {
      message: "7. 翻譯："
    },
    youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe: {
      message: "您可以使用翻譯服務以您選擇的語言提供菜單。為此，將對服務收取費用，如 "
    },
    forTheExecutionOfTranslationServicesThe: {
      message: "對於翻譯服務的執行，"
    },
    requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe: {
      message: "要求至少 72 小時（七十二小時）。根據要翻譯的文本的長度，"
    },
    withPriorNoticeTo: {
      message: "事先通知"
    },
    mayExtendTheServiceExecutionPeriodAtItsDiscretion: {
      message: "可酌情延長服務執行期限。"
    },
    declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions: {
      message: "聲明他理解並接受某些表達不能被翻譯，因為它們反映了某些文化或特定地區。"
    },
    isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe: {
      message: " 對於因數字菜單中的不准確翻譯而造成的任何損害，概不負責 "
    },
    howeverInTheEventThatThe: {
      message: ".但是，如果"
    },
    identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe: {
      message: "發現傳統中的任何錯誤或誤解，您可以與 "
    },
    whichMustRespondToTheRequestAsSoonAsPossible: {
      message: "它必須盡快響應請求。"
    },
    suppertService: {
      message: "8. 支持服務"
    },
    willSupportThe: {
      message: "將支持 "
    },
    throughthecontactchannelavailableviaemailhellomytripmenucom: {
      message: " 通過電子郵件 hello@mytripmenu.com 提供的聯繫渠道。"
    },
    inSupportRequestsViaEmailTthe: {
      message: "在通過電子郵件提出的支持請求中， "
    },
    willHaveUpTo72SeventyTwoBusinessHoursToRespond: {
      message: "將有多達 72（七十二）個工作時間來回复。"
    },
    paymentMethod4: {
      message: "9. 付款方式："
    },
    priorToAnyChargeBeingMadeByThe: {
      message: "在任何收費之前 "
    },
    willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf: {
      message: "將提供 07（七）天的試用期，免費試用 "
    },
    ifThe: {
      message: "如果 "
    },
    chooseToProceedWithTheContractingOfServicesThe: {
      message: " 選擇繼續簽訂服務合同， "
    },
    makesAvailableTo: {
      message: "提供給 "
    },
    oneOfTheFollowingPaymentMethods: {
      message: "以下付款方式之一： "
    },
    VISACreditCardOr: {
      message: "維薩信用卡；或者，"
    },
    MASTERCARDCreditCardOr: {
      message: "萬事達信用卡；或者，"
    },
    AMERICANEXPRESSCreditCardOr: {
      message: "美國運通信用卡；或者，"
    },
    DINNERSCLUBCreditCardOr: {
      message: "晚餐俱樂部信用卡；或者，"
    },
    ELOCreditCardOr: {
      message: "ELO 信用卡，或；"
    },
    DISCOVERCreditCard: {
      message: "發現信用卡。"
    },
    toProcessCreditCardPaymentsItWillBeNecessaryForThe: {
      message: "要處理信用卡付款，需要"
    },
    registerOn: {
      message: "註冊 "
    },
    theFollowingDetailsOfTheChosenCreditCard: {
      message: "所選信用卡的以下詳細信息："
    },
    NameOfTheCreditCardHolder: {
      message: "信用卡持有人姓名；"
    },
    CreditCardNumber: {
      message: "信用卡號;"
    },
    CreditCardFlag: {
      message: "信用卡標誌；"
    },
    CreditCardExpirationMonthandYear: {
      message: "信用卡到期（月和年）；"
    },
    CreditCardsecuritynumber: {
      message: "信用卡安全號碼。"
    },
    Paymentsforservicescontractedinthe: {
      message: "合同中籤訂的服務的付款 "
    },
    willBe: {
      message: " 將會"
    },
    processedthroughtheGETNETplatform: {
      message: " 通過GETNET平台處理"
    },
    beingnecessarythatall: {
      message: "有必要讓所有 "
    },
    alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform: {
      message: " 還閱讀並接受 GETNET 平台的使用和服務條款"
    },
    viaemailaddress: {
      message: "通過電子郵件地址： "
    },
    Whenhiringthe: {
      message: "招聘時 "
    },
    withpaymentbycreditcardorbankslipthe: {
      message: "，以信用卡或銀行單據付款，"
    },
    expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform: {
      message: "明確表示您已閱讀並接受本儀器以及 GETNET 平台的使用條款和特定服務中的所有條件。"
    },
    AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe: {
      message: "通過 GETNET 平台支付的任何爭議只能在"
    },
    andGETNETnotfallingto: {
      message: " 和 GETNET，不落於 "
    },
    anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform: {
      message: " 處理在 GETNET 平台上進行的付款的任何責任。"
    },
    TheGETNETplatform: {
      message: "GETNET 平台"
    },
    maychargefeesforitsuseanditisonlyuptothe: {
      message: "可能會收取使用費，並且僅由 "
    },
    informationaboutthevalueschargedbyGETNET: {
      message: " 有關 GETNET 收取的價值的信息。 "
    },
    isonlyresponsiblefordeletingthepaymentdataprovidedbythe: {
      message: " 僅負責刪除由 "
    },
    fromitsowndatabasedeclaringthe: {
      message: "從它自己的數據庫中，聲明"
    },
    beawarethatitisnotupto: {
      message: " 請注意，這不符合 "
    },
    promotetheexclusionofsaiddatafromtheGETNETdatabase: {
      message: " 促進將上述數據從 GETNET 數據庫中排除。"
    },
    PRIVACYANDDATAPROCESSINGPOLICY: {
      message: "10. 隱私和數據處理政策："
    },
    Duringtheuseof: {
      message: "在使用過程中 "
    },
    willcollectandstoretheinformationprovidedbythe: {
      message: "將收集和存儲由"
    },
    underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof: {
      message: ", 根據本工具的條款，連同自動生成的信息，例如訪問設備的特徵、瀏覽器、應用程序訪問日誌（IP，帶有日期和時間）、訪問的信息、訪問的屏幕、地理位置數據、應用程序的歷史記錄，其中，"
    },
    whichwillbestoredinthedatabaseandalsointhebrowsercookie: {
      message: "，它將存儲在數據庫和瀏覽器 cookie 中。"
    },
    Thedatacollectedfrom: {
      message: "從收集的數據 "
    },
    throughtheuseof: {
      message: "，通過使用"
    },
    willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe: {
      message: "，將用於提供充分的服務，以改善航行"
    },
    aswellasforadvertisingandstatisticalpurposes: {
      message: "，以及用於廣告和統計目的。"
    },
    declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato: {
      message: "聲明理解、接受並同意在將個人數據發送至"
    },
    mustdosounderthetermsofarticle5itemXofLaw13709of2018: {
      message: "必鬚根據 2018 年第 13,709 號法律第 5 條第 X 項的條款這樣做。"
    },
    Intheeventthatpersonaldataisprovidedbythe: {
      message: "如果個人數據由 "
    },
    willbeconsideredconfidentialbythe: {
      message: ", 將被視為機密"
    },
    anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas: {
      message: "，並承諾盡一切努力保護其係統在存儲此類數據時的安全性，符合第 8,771/2016 號法令規定的安全標準，例如："
    },
    iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability: {
      message: "(i) 除其他標準加密形式外，使用行業標準方法對收集的數據進行加密，以確保其不可侵犯性；"
    },
    iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments: {
      message: "(ii) 使用高科技軟件防止未經授權訪問被視為受控和安全環境的系統；"
    },
    Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement: {
      message: "(iii) 個人數據存儲位置的受控訪問權限僅限於先前授權和認證的人員，承諾對此類數據保密，包括簽署保密協議；"
    },
    ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe: {
      message: "(iv) 應用認證機制來訪問能夠使負責處理的人員個性化的記錄以及訪問因使用"
    },
    vAnonymizationofdatafromthe: {
      message: "(v) 匿名數據來自"
    },
    whensharedwiththirdpartiesnotpartnerswith: {
      message: "與非合作夥伴的第三方共享時"
    },
    viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016: {
      message: "(vi) 根據第 8,771/2016 號法令第 13 條確定的連接和應用程序訪問記錄，維護表明負責訪問的員工或人員的時間、持續時間、身份和目標文件的清單。"
    },
    Ifdatacollectionfromthe: {
      message: "如果從數據收集"
    },
    throughtheuse: {
      message: ", 通過使用"
    },
    thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow: {
      message: "這些只能在下列情況下與第三方共享："
    },
    iFortheprotectionoftheinterestsof: {
      message: "（一）為了保護當事人的利益"
    },
    incaseofconflictincludinglegalclaims: {
      message: "如果發生衝突，包括法律索賠；"
    },
    iiIncaseoftransactionsandcorporatechangesinvolvingthe: {
      message: "(ii) 如果交易和公司變更涉及"
    },
    inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe: {
      message: "，在這種情況下，數據傳輸對於通過"
    },
    iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest: {
      message: "(iii) 法院命令或對其請求具有法律權限的行政當局的請求。"
    },
    guaranteesto: {
      message: "保證"
    },
    withregardtotheprocessingofpersonaldatathefollowingrights: {
      message: "，關於個人數據的處理，以下權利："
    },
    iConfirmationoftheexistenceofprocessingofyourpersonaldata: {
      message: "(i) 確認存在處理您的個人數據；"
    },
    iiAccesstoyourdatacollectedby: {
      message: "(ii) 訪問您收集的數據"
    },
    throughyourownloginorbyrequestinghellomytripmenucom: {
      message: "，通過您自己的登錄或通過請求 hello@mytripmenu.com；"
    },
    iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate: {
      message: "(iii) 如果您的數據不完整、不准確或過時，則更正您的數據；"
    },
    ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation: {
      message: "(iv) 根據適用的巴西法律阻止或刪除不必要、過多或不符合要求的數據；"
    },
    vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe: {
      message: "(v) 個人數據的可移植性，對於您或第三方，應由政府提出的明確要求"
    },
    viahellomytripmenucom: {
      message: ", 通過 hello@mytripmenu.com;"
    },
    viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe: {
      message: "(vi) 刪除在您同意的情況下處理的個人數據，前提是沒有法律決定讓這些數據在"
    },
    viiObtaininginformationaboutpublicorprivateentitieswithwhichthe: {
      message: "(vii) 獲取有關公共或私人實體的信息"
    },
    sharedyourdataand: {
      message: "共享您的數據；和，"
    },
    viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe: {
      message: "(viii) 關於不提供同意的可能性和後果的信息"
    },
    youcansendemailto: {
      message: "您可以發送電子郵件至"
    },
    hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata: {
      message: "hello@mytripmenu.com，指出與您的個人數據相關的疑問和/或要求。"
    },
    maydeletethepersonaldatacollectedfrom: {
      message: "可能會刪除從收集的個人數據"
    },
    iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy: {
      message: "(i) 達到收集目的時；或者，當它們不再需要或與目的範圍相關時，根據這些使用條款和隱私政策中描述的目的；"
    },
    iiWhenthe: {
      message: "(ii) 當"
    },
    revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe: {
      message: "撤銷您的同意，在必要的情況下，請求排除"
    },
    viahellomytripmenucomor: {
      message: "通過 hello@mytripmenu.com；或者，"
    },
    iiiIfdeterminedbycompetentauthority: {
      message: "(iii) 如果由主管當局確定。"
    },
    GENERALPROVISIONS: {
      message: "11. 一般規定："
    },
    AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent: {
      message: "本文書的任何條款或條件，無論出於何種原因，將被任何法院或法院視為無效或無效，不會影響這些條款的其他條款的有效性，這些條款將保持完全有效和具有約束力，對其產生影響最大程度。"
    },
    Thefailureof: {
      message: "的失敗"
    },
    inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines: {
      message: "主張本條款的任何權利或規定不構成棄權，後者可在法定期限內定期行使其權利。"
    },
    Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe: {
      message: "所有材料、專利、商標、註冊、域名、名稱、特權、創作、圖像和與"
    },
    anddevelopedby: {
      message: "並由"
    },
    areandwillremainthesoleandexclusivepropertyof: {
      message: ", 是並且將繼續是"
    },
    agreeingwiththe: {
      message: ", 同意"
    },
    nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem: {
      message: "不執行以任何方式損害此處規定的權利的行為或事實，也不要求對它們提出任何權利或特權。"
    },
    maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreasonwestronglyrecommendthatyoualwaysvisitthissectionofourSiteandreaditperiodicallyButtocontributetothegoodrelationshipwewillalsosendanemailinformingyouaboutthesechanges: {
      message: "可以隨時更改此工具，只需在我們的網站上發布修訂版即可。因此，我們強烈建議您始終訪問我們網站的這一部分並定期閱讀。但是，為了促進良好的關係，我們還將發送一封電子郵件，通知您這些變化。"
    },
    Thisinstrumentconstitutestheintegralunderstandingbetweenthe: {
      message: "該文書構成了雙方之間的整體理解"
    },
    andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe: {
      message: "並受巴西法律管轄，聖萊奧波爾多市/RS 市的司法管轄區被選為解決由本文書引起的問題的唯一主管法院，並明確放棄任何其他司法管轄區，無論其享有何種特權。"
    },

    recoverPassword:{
      message: "恢復密碼"
    },
    receiveNewPassword:{
      message: "您將在電子郵件中收到一個新密碼，請記得檢查您的垃圾郵件文件夾。"
    },
    dontWorry:{
      message: "別擔心，我們會幫你找回來的。 提供您的登錄電子郵件"
    },
    enterEmail:{
      message: "輸入電子郵件"
    },
    notBeSent:{
      message: "電子郵件無法發送，請重試。"
    },
    pleasureToHaveYou:{
      message: "很高興有你和我們在一起！"
    },
    beforeStarting:{
      message: "在開始包含您的產品並看到您的菜單成型之前，有必要訂閱一份維護計劃，金額為"
    },
    BRL99:{
      message: "99.90 巴西雷亞爾"
    },
    month:{
      message: "一個月。"
    },
    includesFacilities:{
      message: "它包括以下設施："
    },
    menuWithPhotos:{
      message: "帶照片的完整菜單；"
    },
    priceUpdate:{
      message: "實時價格更新；"
    },
    activateDeactivate:{
      message: "快速激活和停用產品；"
    },
    categorizeFilteReorder:{
      message: "對產品進行分類、過濾和重新排序；"
    },
    newFeatures:{
      message: "每週都會添加新功能！"
    },
    thePlan:{
      message: "簽署計劃"
    },
    company:{
      message: "公司"
    },
    fantasyName:{
      message: "幻想名稱"
    },
    numberAccesses:{
      message: "訪問次數"
    },
    entryDate:{
      message: "入學日期"
    },
    customers:{
      message: "顧客"   
    }, 
    wantLeave:{
      message: "既然已經走到這一步了，確定要離開嗎？"
    },
    cancelYourSubscription:{
      message: "很抱歉您要取消訂閱 MyTripMenu 計劃。"
    },
    beforeConfirming:{
      message: "在確認之前，我需要告訴您，如果您離開，您的客戶將無法再訪問您的機構的菜單，並且在您重新訂閱該計劃之前將無法訪問 URL 和 QRCode。"
    },
    goAhead:{
      message: "如果你確定，那就繼續吧……"
    },
    unsubscribePlan:{
      message: "取消訂閱該計劃"
    },
    dontCancel:{
      message: "取消訂閱該計劃"
    },
    noChange:{
      message: "沒變"
    },
    CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180: {
      message: "CAXA LABS SERVICOS DE DESENVOLVIMENTO DE SOFTWARE LTDA 有限公司，定期在 CNPJ 註冊，編號為34.177.633/0001-91，總部位於 Rua Felipe Dos Santos, nº 77, 1203 torre A, Padre Reus 社區，在聖萊奧波爾多/RS 市，在 CEP 下：93.020-180。"
    },
    Delivery: {
      message: "送貨"
    },
    withdrawal: {
      message: "退出"
    },
    Pickup: {
      message: "現場領取"
    },
    Catalog: {
      message: "目錄"
    },
    Enteremail: {
      message: "輸入郵箱"
    },
    emailinvalid: {
      message: "此電子郵件無效"
    },
    Typepassword: {
      message: "輸入密碼"
    },
    least6digits: {
      message: "此字段必須至少有 6 位數字"
    },
     Cashpayment: {
      message: "現金支付方式不可修改"
    },
    Checkpayment: {
      message: "無法編輯支票付款方式"
    },
    save: {
      message: "保存"
    },
    logIn: {
      message: "登錄"
    },
    logged: {
      message: "您已登錄"
    },
    enterAShortEasilyIdentifiableName: {
      message: "輸入一個簡短、易於識別的名稱"
    },
    highlightssubcategoryCannotBeCopied: {
      message: "亮點子類別無法複製"
    },
    aaaaaaaaaaaaaaaaaa: {
      message: "aaaaaaaaaaaaaaaaaaaaa"
    },
    unableToLoadCards : {
      message: "無法加載卡片"
    },
    invalidUser: {
      message: "無效的用戶"
    },
    invalidPassword: {
      message: "無效的密碼"
    },
    inactiveUser: {
      message: "非活躍用戶"
    }
  }
}
