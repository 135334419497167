import styled, { css } from "styled-components";
import {
  Paragraph16px300Light,
  Description12px600Semibold,
  Description12px300Light,
} from "../../styles/style-guide";

const cssPrimary = css`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.textColorDisabled : theme.colors.mainColorDisabled};
`;

const cssSecondary = css`
  ${({ bold }) =>
    bold ? Description12px600Semibold : Description12px300Light};
  height: 13px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.mainColorContrast : theme.colors.textColorDisabled};
`;

const cssTertiary = css`
  background-color: ${({ theme }) => theme.colors.mainColorContrast};
`;

const CustomTag = styled.label`
  ${Paragraph16px300Light};
  border-radius: 16px;
  height: 23px;
  background-color: ${({ theme }) => theme.colors.mainColorDisabled};
  padding: 3.3px;
  min-width: 25px;
  margin: 1px 3px;
  word-wrap: normal;
  height: 100% !important;
  ${({ type }) =>
    (type === "primary" && cssPrimary) ||
    (type === "secondary" && cssSecondary) ||
    (type === "tertiary" && cssTertiary)}
`;

const ButtonClose = styled.span`
  display: ${({ show }) => (show ? "inline" : "none")};
  border-radius: 50%;
  border: none;
  outline: none;
  height: 15px;
  width: 15px;
  font-size: 12px;
  position: relative;
  z-index: 1;
  padding: 1.5px 5px;
  top: -2px;
  cursor: pointer;
  margin-left: 1.33px;
  background-color: ${({ theme }) => theme.colors.mainColorLight};

  svg {
    cursor: pointer;
    height: 7px;
    width: 7px;
  }
`;

export { CustomTag, ButtonClose };
