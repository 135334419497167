import React, { useState, useEffect, useMemo } from "react";
import MuiAutoComplete from "@material-ui/lab/Autocomplete";
import { MuiTextFieldStyled } from "../../../../../../../components/inputs/mui-inputs/Styles";
import PropTypes from "prop-types";
import ModalMytm from "../../../../../../../components/modal-mytm/ModalMytm";
import {
  ModalWrapper,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "../../../../../../../components/modal-mytm/Styles";
import {
  ComplementLoaderForm,
  ComplementLoaderButtonSave,
  ComplementLoaderButtonGoBack,
  ComplementLoaderGrid,
  ComplementGroupWithToggle,
  ComplementFilterBox,
} from "./Styles";
import formUtils from "../../../../../../../utils/form-utils";
import Checkbox from "../../../../../../../components/checkbox/Checkbox";
import restComplement from "../../../../../../../api/complement/rest-complement";
import TranslatorFunction from "../../../../../../../components/I18n/Translator"
const ComplementLoader = (props) => {
  const { open, onClose, data, group, setGroup } = props;
  const [form, setForm] = useState([]);
  const [openModal, setOpenModal] = useState(open);
  const [isLoading, setIsLoading] = useState(false);
  const [searchs, setSearchs] = useState({
    name: "",
  });
  const linkSupplements = TranslatorFunction({ path: 'linkSupplements.message' });
  const back = TranslatorFunction({ path: 'back.message' });
  const saveEditions = TranslatorFunction({ path: 'saveEditions.message' });
  const noOptions = TranslatorFunction({ path: 'noOptions.message' });
  const search = TranslatorFunction({ path: 'search.message' });
  const language = TranslatorFunction({ path: 'language.message' });
  const handleOnClickSave = () => {
    let newData = group;
    let oldGroup = { ...group };

    newData.complements = [];

    // eslint-disable-next-line
    form.groupList.map((complement) => {
      if (complement.active === true) {
        const complementAux = { ...complement, price: 0 };
        const complementIndex = oldGroup.complements.findIndex(
          (cat) => cat._id === complementAux._id
        );

        if (complementIndex >= 0) {
          complementAux.price = oldGroup.complements[complementIndex].price;
        }

        newData.complements.push(complementAux);
      }
    });
    setGroup(newData);
    setOpenModal(false);
    newData.complements.length > 0
      ? onClose(true, newData)
      : onClose(false, data);
  };

  const handleOnClickGoBack = () => {
    setOpenModal(false);
    onClose(false, data);
  };

  const handleOnChangeCheckBox = (event) => {
    const { name, value } = event;
    const newForm = { ...form };

    newForm.groupList.map((group, index) =>
      group.name === name ? (group.active = value) : ""
    );
    setForm(newForm);
  };

  const disableSubmitButton = () => !formUtils.isFormValid(form);

  const handleResponseFetchComplement = (response, gID) => {
    let newForm = { ...form };
    newForm.groupList = response
      .filter((group) => group.active)
      .map(
        (group) =>
          (group = {
            ...group,
            price: 0,
            index: 0,
            complementId: group._id,
          })
      );

    newForm.groupList.map((e) => (e.active = false));

    let groupIndex = data.findIndex((cat) => cat._id === gID);

    data[groupIndex].complements.forEach((element) => {
      let complementIndex = newForm.groupList.findIndex(
        (cat) => cat._id === element._id
      );
      newForm.groupList[complementIndex].active = true;
      newForm.groupList[complementIndex].price = element.price;
    });

    setForm(newForm);
    setIsLoading(false);
    setOpenModal(open);
  };

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      restComplement
        .fetchComplementByGroup(group?._id)
        .then((res) => {
          handleResponseFetchComplement(res.data, group?._id);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

    // eslint-disable-next-line
  }, [open]);

  const filterComplementsByName = (form) => {
    if (!searchs.name) return true;
    return form.name
      .toLocaleLowerCase()
      .includes(searchs.name.toLocaleLowerCase());
  };

  const filteredComplements = useMemo(
    () => form?.groupList?.filter(filterComplementsByName),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchs, form]
  );
  function nameTraslated(form) {
      let variavel = form;
      if(variavel.translatedComplementGroup !== undefined && variavel.translatedComplementGroup[0].name[0][language] !== undefined){
        return  variavel.translatedComplementGroup[0].name[0][language]?.toUpperCase();
      }
  };
  return (
    <ModalMytm open={openModal} isLoading={isLoading}>
      <ModalWrapper>
        <ModalTitle cColor={"#777"} cAlign={"center"} bold>
          {linkSupplements}: {nameTraslated(group)}
        </ModalTitle>
        <ModalBody>
          <ComplementLoaderForm noValidate onSubmit={(e) => e.preventDefault()}>
            <ComplementFilterBox>
              <MuiAutoComplete
                id="search"
                options={form?.groupList}
                getOptionLabel={(option) => option?.name || ""}
                renderInput={(params) => (
                  <MuiTextFieldStyled
                    {...params}
                    noOptions = {noOptions}
                    margin="dense"
                    label= {search}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
                onChange={(e) => {
                  setSearchs({ ...searchs, name: e.target.value });
                }}
                onSelect={(e) => {
                  setSearchs({ ...searchs, name: e.target.value });
                }}
              />
            </ComplementFilterBox>
            <ComplementLoaderGrid>
              {filteredComplements?.map((itens, index) => (
                <ComplementGroupWithToggle key={index}>
                  <Checkbox
                    key={itens?._id}
                    name={itens.name}
                    value={itens.active}
                    onChange={handleOnChangeCheckBox}
                  >
                    {itens.name}
                  </Checkbox>
                </ComplementGroupWithToggle>
              ))}
            </ComplementLoaderGrid>
          </ComplementLoaderForm>
        </ModalBody>
        <ModalFooter>
          <ComplementLoaderButtonSave
            type="button"
            disabled={disableSubmitButton()}
            onClick={handleOnClickSave}
          >
            {saveEditions}
          </ComplementLoaderButtonSave>
          <ComplementLoaderButtonGoBack
            type="button"
            btnType="tertiary"
            onClick={handleOnClickGoBack}
          >
            {back}
          </ComplementLoaderButtonGoBack>
        </ModalFooter>
      </ModalWrapper>
    </ModalMytm>
  );
};
ComplementLoader.defaultProps = {
  subCategory: {},
  group: {},
  open: false,
  onClose: () => {},
};

ComplementLoader.propTypes = {
  subCategory: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ComplementLoader;
