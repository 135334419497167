import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import { BaseCSS, GridThemeProvider } from "styled-bootstrap-grid";
import BloackLoadingProvider from "./contexts/BlockLoaderContext";
import Routes from "./routes/Routes";
import GlobalStyle from "./styles/global";
import restTheme from "./api/theme/rest-theme";
import APIErrorProvider from "./contexts/APIErrorContext";
import ModalConfirmationContextProvider from "./contexts/ModalConfirmationContext";
import PageProvider from "./contexts/PageContext";
import UserContext from "./contexts/useGlobalState";
import gridTheme from "./styles/grid-theme";
import I18n from './components/I18n'
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [theme] = useState(restTheme.getDefaultTheme);
  return (
    <>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <GridThemeProvider gridTheme={gridTheme}>
          <Router>
            <BaseCSS />
            <GlobalStyle />
            <UserContext>
              <PageProvider>
                <APIErrorProvider>
                  <ModalConfirmationContextProvider>
                    <BloackLoadingProvider>
                      <I18n/>
                      <Routes />
                    </BloackLoadingProvider>
                  </ModalConfirmationContextProvider>
                </APIErrorProvider>
              </PageProvider>
            </UserContext>
          </Router>
        </GridThemeProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
