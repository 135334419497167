import React from "react";

import { TableCol } from "../../Styles";

function UsageCol({ obj, isCoupon }) {
  return isCoupon ? (
    <TableCol>
      {obj?.useActual}/{obj?.useLimite}
    </TableCol>
  ) : null;
}

export default UsageCol;
