import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  LoginWrapper, LoginContent, LoginOverlay, LoginLogoMyTripMenu,
} from './Styles';
import LoginForm from './login-form/LoginForm';
import Auth from '../../utils/auth';

const Login = () => {
  const history = useHistory();

  useEffect(() => {
    if (Auth.isAuthenticated()) {
      history.push('/home/cadastro-itens?tab=subcategories');
    }
    // eslint-disable-next-line
  }, []);
  return (
    <LoginWrapper>
      <LoginOverlay />
      <LoginLogoMyTripMenu />
      <LoginContent>
        <LoginForm />
      </LoginContent>
    </LoginWrapper>
  );
};

export default Login;
