import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import restComplementgroup from "../../../../api/complementgroup/rest-complement-group";
import restComplement from "../../../../api/complement/rest-complement";
import EmptyScreen from "../../../../components/empty-screen/EmptyScreen";
import ListComplementGroup from "./list/ListComplementGroup";
import NewComplementGroup from "./new-complement-group/NewComplementGroup";

export default function ComplementGroup({
  onLoad,
  openModalNewComplementGroup,
  onHideNewComplementGroup,
}) {
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [complementGroup, setComplementGroup] = useState([]);
  const [complementGroupForm, setComplementGroupForm] = useState({});
  const [complement, setComplement] = useState([{}]);
  const [openNewComplementGroupModal, setOpenNewComplementGroupModal] =
    useState(openModalNewComplementGroup);

  const history = useHistory();

  const handleOnClickEditComplementGroup = (selectedComplementGroup) => {
    const findedComplement = complement.filter(
      (compl) => compl.complementGroupId === selectedComplementGroup._id
    );

    setComplementGroupForm({
      complementGroup: selectedComplementGroup,
      complement: findedComplement ?? [],
    });

    setOpenNewComplementGroupModal(true);
  };

  const handleOnLoad = (value) => {
    onLoad(value);
    setIsLoading(value);
  };

  const handleOnModalClose = () => {
    setOpenNewComplementGroupModal(false);
    setComplementGroupForm({});
    onHideNewComplementGroup();
    fetchComplementGroup();
    fetchComplement();
    history.go(0);
  };

  const handleOnClickEmptyScreenButton = () => {};

  const handleResponseComplementGroupSuccess = (response) => {
    setComplementGroup(response);
    handleOnLoad(false);
  };

  const handleResponseComplementSuccess = (response) => {
    setComplement(response);
    handleOnLoad(false);
  };

  const fetchComplementGroup = () => {
    handleOnLoad(true);
    restComplementgroup
      .fetchComplementGroup()
      .then(({ data }) => handleResponseComplementGroupSuccess(data))
      .catch(() => handleOnLoad(false));
  };

  const fetchComplement = () => {
    handleOnLoad(true);
    restComplement
      .fetchComplement()
      .then(({ data }) => handleResponseComplementSuccess(data))
      .catch(() => handleOnLoad(false));
  };

  useEffect(() => {
    fetchComplementGroup();
    fetchComplement();
    return () => {
      setComplementGroup([]);
      setComplement([]);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => setOpenNewComplementGroupModal(openModalNewComplementGroup),
    [openModalNewComplementGroup]
  );

  const renderSubCategories = () =>
    complementGroup?.length === 0 ? (
      <EmptyScreen onClickButton={handleOnClickEmptyScreenButton} />
    ) : (
      <ListComplementGroup
        complementGroup={complementGroup}
        onClickEditComplementGroup={handleOnClickEditComplementGroup}
      />
    );
  return (
    <>
      {renderSubCategories()}
      <NewComplementGroup
        open={openNewComplementGroupModal}
        onClose={handleOnModalClose}
        complementGroup={complementGroupForm}
      />
    </>
  );
}
