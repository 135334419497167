import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ModalMytm from "../../../../../../../components/modal-mytm/ModalMytm";
import { ModalTitle } from "../../../../../../../components/modal-mytm/Styles";
import {
  ComplementGroupImportWrapper,
  ComplementGroupImportFooter,
  ComplementGroupImportForm,
  ComplementGroupImportButtonSave,
  ComplementGroupImportButtonGoBack,
  ComplementGroupImportAcWrapper,
  ComplementGroupImportSubAcWrapper,
  ComplementGroupWithToggle,
  ComplementGroupBarText,
  Empty,
  CustomAccordionSummary,
  CustomAccordionDetails,
  CustomAccordionMsgDetails,
  CustomAccordion,
  ComplementGroupImportLastLevel,
  CustomTypography,
  CustomFormControlLabel,
} from "./Styles";
import formUtils from "../../../../../../../utils/form-utils";
import Checkbox from "../../../../../../../components/checkbox/Checkbox";
// import restSubcat from "../../../../../../../api/subcategories/rest-subcategories";
import restProduct from "../../../../../../../api/products/rest-products";
import TranslatorFunction from '../../../../../../../components/I18n/Translator';

const ComplementGroupImport = (props) => {
  const back = TranslatorFunction({ path: 'back.message' });
  const saveEditions = TranslatorFunction({ path: 'saveEditions.message' });
  const subcategoryProducts = TranslatorFunction({ path: 'subcategoryProducts.message' });
  const productNotAdd = TranslatorFunction({ path: 'productNotAdd.message' });
  const importantGroupComplements = TranslatorFunction({ path: 'importantGroupComplements.message' });
  const { open, onClose, data, setData, subcat, myId } = props;
  const [form, setForm] = useState([]);
  const [openModal, setOpenModal] = useState(open);
  const [isLoading, setIsLoading] = useState(false);
  const [subInd, setSubInd] = useState(-1);
  const [prodInd, setProdInd] = useState(-1);

  const handleResponseSingleProduct = (prod) => {
    let newData = prod.complementGroup.filter((group) => group.active);
    // eslint-disable-next-line
    newData.map((compG) => {
      compG.name = compG.complementGroupId.name;
      // eslint-disable-next-line
      compG.complements.map((comp) => {
        comp.name = comp.complementId.name;
        comp.description = comp.complementId.description;
      });
    });
    setData(newData);
    setOpenModal(false);
    onClose(false, data);
    setIsLoading(false);
    return;
  };

  const fetchProduct = (id) => {
    if (id === undefined) return;
    setIsLoading(true);
    restProduct
      .fetchProductsById(id)
      .then((res) => handleResponseSingleProduct(res.data.products))
      .catch((err) => setIsLoading(false));
  };

  const handleOnClickSave = () => {
    fetchProduct(form[subInd].products[prodInd]._id);

    if (subInd + prodInd < 0) {
      setOpenModal(false);
      onClose(false, data);
      return;
    }
  };

  const handleOnClickGoBack = () => {
    setOpenModal(false);
    onClose(false, data);
  };

  const disableSubmitButton = () => !formUtils.isFormValid(form);

  const handleResponseProducts = (response, it) => {
    let newForm = form;
    const subIndex = form.findIndex((sub) => sub.key === it.key);

    newForm[subIndex].products = response[0].products.filter(
      (item) => item._id !== myId
    );
    // eslint-disable-next-line
    newForm[subIndex].products.map((product) => {
      product.active = false;
      product.complementGroup.forEach((compGroup) => {
        compGroup.active = false;
      });
    });
    setForm(newForm);
    setIsLoading(false);
  };

  const handleOpenSub = (it, value) => {
    if (value) {
      if (it.products.length > 0) return;
      setIsLoading(true);
      restProduct
        .fetchProductsBySubCategory([it.key])
        .then((res) => {
          handleResponseProducts(res.data, it);
        })
        .catch(() => setIsLoading(false));
    }
  };

  const ProductCheckBoxHandler = (ev, subcatIndex, index) => {
    setIsLoading(true);
    setSubInd(subcatIndex);
    setProdInd(index);

    let { value } = ev;
    let newForm = [...form];

    newForm[subcatIndex].products.forEach((product, id) => {
      if (id === index) {
        product.active = value;
        product.complementGroup.forEach((compGroup) => {
          compGroup.active = value;
        });
      } else {
        if (product.active) {
          product.active = false;
          product.complementGroup.forEach((compGroup) => {
            compGroup.active = false;
          });
        }
      }
    });
    setForm(newForm);
    setIsLoading(false);
  };

  const CompGroupCheckBoxHandler = (ev, subcatIndex, prodIndex, index) => {
    setIsLoading(true);
    let { value } = ev;
    let newForm = [...form];

    if (newForm[subcatIndex].products[prodIndex].active === false) {
      ProductCheckBoxHandler(ev, subcatIndex, prodIndex);
      newForm[subcatIndex].products[prodIndex].complementGroup.forEach(
        (compGroup) => {
          compGroup.active = false;
        }
      );
    }

    newForm[subcatIndex].products[prodIndex].complementGroup.forEach(
      (element, id) => {
        if (id === index) element.active = value;
      }
    );
    setForm(newForm);
    setIsLoading(false);
  };

  useEffect(() => {
    let newForm = subcat.map((element) => {
      return { ...element, products: [] };
    });

    setForm(newForm);
    setOpenModal(open);

    // eslint-disable-next-line
  }, [open]);

  const renderComplementGroups = (dataObj, subCatIndex, prodIndex) => {
    if (dataObj.length < 1) return <></>;

    if (dataObj.complementGroup.length < 1) {
      return (
        <CustomAccordionMsgDetails key={prodIndex}>
          {productNotAdd}
        </CustomAccordionMsgDetails>
      );
    } else {
      return (
        <ComplementGroupImportSubAcWrapper>
          {dataObj?.complementGroup?.map((itens, index) => (
            <ComplementGroupImportLastLevel key={index} id={index}>
              <ComplementGroupWithToggle key={itens.key}>
                <ComplementGroupBarText width={50}>
                  <hr
                    style={{
                      color: "#818181",
                      backgroundColor: "#818181",
                      height: 1,
                      width: 100,
                      borderWidth: 0,
                      verticalAlign: "middle",
                      marginRight: "10px",
                    }}
                  />
                  <CustomTypography>{itens.name}</CustomTypography>
                </ComplementGroupBarText>
                <CustomFormControlLabel
                  aria-label="T2"
                  // value={itens.name}
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      value={itens.active}
                      onChange={(ev) => {
                        CompGroupCheckBoxHandler(
                          ev,
                          subCatIndex,
                          prodIndex,
                          index
                        );
                      }}
                      name="checkedA"
                      blackBorder={true}
                    />
                  }
                />
              </ComplementGroupWithToggle>
            </ComplementGroupImportLastLevel>
          ))}
        </ComplementGroupImportSubAcWrapper>
      );
    }
  };

  const renderProducts = (dataObj, subcatIndex) => {
    if (dataObj.products === undefined) return <></>;
    if (dataObj.products.length === undefined) return <></>;
    if (dataObj.products.length < 1) {
      return (
        <CustomAccordionMsgDetails key={subcatIndex}>
          {subcategoryProducts}
        </CustomAccordionMsgDetails>
      );
    } else {
      return (
        <ComplementGroupImportSubAcWrapper>
          {dataObj?.products?.map((itens, index) => (
            <CustomAccordion key={index}>
              <CustomAccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="panel1d-content"
                id={itens.key}
                key={itens.key}
              >
                <ComplementGroupWithToggle key={itens.key}>
                  <ComplementGroupBarText width={50}>
                    <hr
                      style={{
                        color: "#818181",
                        backgroundColor: "#818181",
                        height: 1,
                        width: 50,
                        borderWidth: 0,
                        verticalAlign: "middle",
                        marginRight: "10px",
                      }}
                    />
                    <CustomTypography>{itens.name}</CustomTypography>
                  </ComplementGroupBarText>
                  <CustomFormControlLabel
                    aria-label="T2"
                    value={itens.name}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <Checkbox
                        value={itens.active}
                        onChange={(ev) => {
                          ProductCheckBoxHandler(ev, subcatIndex, index);
                        }}
                        name="checkedA"
                        blackBorder={true}
                      />
                      // <input
                      //   type="checkbox"
                      //   value={itens.active}
                      //   onChange={(ev) => {
                      //     ProductCheckBoxHandler(ev, subcatIndex, index);
                      //   }}
                      //   name="checkedA"
                      // />
                    }
                  />
                </ComplementGroupWithToggle>
              </CustomAccordionSummary>

              <CustomAccordionDetails>
                {renderComplementGroups(itens, subcatIndex, index)}
                {/* <CustomTypography>ABC</CustomTypography> */}
              </CustomAccordionDetails>
            </CustomAccordion>
          ))}
        </ComplementGroupImportSubAcWrapper>
      );
    }
  };

  if (openModal === false) return null;
  else
    return (
      <ModalMytm open={openModal} isLoading={isLoading}>
        <ComplementGroupImportWrapper>
          <ModalTitle cColor={"#777"} cAlign={"center"} bold>
            {importantGroupComplements}
          </ModalTitle>
          <ComplementGroupImportForm
            noValidate
            onSubmit={(e) => e.preventDefault()}
          >
            <ComplementGroupImportAcWrapper>
              {form?.map((itens, index) => (
                <CustomAccordion
                  key={index}
                  onChange={(ev, value) => handleOpenSub(itens, value)}
                >
                  <CustomAccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    key={itens._id}
                  >
                    <CustomTypography>
                      {itens?.label?.toUpperCase()}
                    </CustomTypography>
                  </CustomAccordionSummary>
                  <CustomAccordionDetails>
                    {renderProducts(itens, index)}
                    {/* <CustomTypography>ABC</CustomTypography> */}
                  </CustomAccordionDetails>
                </CustomAccordion>
              ))}
            </ComplementGroupImportAcWrapper>
            <ComplementGroupImportFooter>
              <ComplementGroupImportButtonSave
                type="button"
                disabled={disableSubmitButton()}
                onClick={handleOnClickSave}
              >
                {saveEditions}
              </ComplementGroupImportButtonSave>
              <ComplementGroupImportButtonGoBack
                type="button"
                btnType="tertiary"
                onClick={handleOnClickGoBack}
              >
                {back}
              </ComplementGroupImportButtonGoBack>
            </ComplementGroupImportFooter>
            <Empty />
          </ComplementGroupImportForm>
        </ComplementGroupImportWrapper>
      </ModalMytm>
    );
};
ComplementGroupImport.defaultProps = {
  data: {},
  setData: () => {},
  subcat: [{}],
  open: false,
  onClose: () => {},
};

ComplementGroupImport.propTypes = {
  data: PropTypes.array,
  setData: PropTypes.func,
  subcat: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ComplementGroupImport;
