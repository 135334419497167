import React, { useEffect, useMemo, useState } from "react";
import ListUsersTableWrapper, { ContainerFilter } from "./Styles";
import Table from "../../../components/table/Table";
import MuiAutoComplete from "@material-ui/lab/Autocomplete";
import InputSearch from "../../../components/inputs/mui-input-search/input-search";
import restUsers from "../../../api/user/rest-user";
import TranslatorFunction from "../../../components/I18n/Translator"

const Admin = () => {
  const company = TranslatorFunction({ path: 'company.message' });
  const fantasyName = TranslatorFunction({ path: 'fantasyName.message' });
  const numberAccesses = TranslatorFunction({ path: 'numberAccesses.message' });
  const entryDate = TranslatorFunction({ path: 'entryDate.message' });
  const status = TranslatorFunction({ path: 'status.message' });
  const search = TranslatorFunction({ path: 'search.message' });
  const noOptions = TranslatorFunction({ path: 'noOptions.message' });

  const [data, setData] = useState([]);
  const [searchs, setSearchs] = useState({
    name: "",
  });
  const colKeyAligns = { companyName: "left" };
  const colTitleKeysAlign = ["left", "center", "center", "center", "center"];
  const usersKeys = ["companyName", "fantasyName", "numberAccess", "createdAt"];
  const listTitles = [
    company,
    fantasyName,
    numberAccesses,
    entryDate,
    status,
  ];

  const handleOnChangeActiveToggle = (selectedUser) => {
    const userIndex = data.findIndex((user) => user._id === selectedUser._id);
    let newData = [...data];

    newData[userIndex].active = !newData[userIndex].active;
    restUsers.putUsersActive(selectedUser._id, newData[userIndex].active);

    setData(newData);
  };

  const filterUsersByName = (user) => {
    if (!searchs.name) return true;
    return user.companyName
      .toLocaleLowerCase()
      .includes(searchs.name.toLocaleLowerCase());
  };

  const filteredUsers = useMemo(
    () => data?.filter(filterUsersByName),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchs, data]
  );

  const handleSuccess = (users) => {
    setData(users);
  };

  const fetchUsers = () => {
    restUsers
      .getAllUser()
      .then((res) => handleSuccess(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ListUsersTableWrapper>
        <ContainerFilter>
          {data && (
            <>
              <MuiAutoComplete
                id="search"
                options={data}
                noOptionsText ={noOptions}
                getOptionLabel={(option) => option?.companyName || ""}
                renderInput={(params) => (
                  <InputSearch
                    {...params}
                    margin="dense"
                    label={search}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
                onChange={(e) => {
                  setSearchs({ ...searchs, name: e.target.value });
                }}
                onSelect={(e) => {
                  setSearchs({ ...searchs, name: e.target.value });
                }}
              />
            </>
          )}
        </ContainerFilter>
        <Table
          data={filteredUsers}
          setData={setData}
          titles={listTitles}
          colKeysAlign={colKeyAligns}
          colTitleKeysAlign={colTitleKeysAlign}
          statusKey="active"
          withStatus
          withActions={false}
          keys={usersKeys}
          onClickToogle={handleOnChangeActiveToggle}
          objectKey="_id"
        />
      </ListUsersTableWrapper>
    </>
  );
};

export default Admin;
