import styled from "styled-components";
import { InputGroupWithToggle } from "../../../../../components/styled/Forms";
import Button from "../../../../../components/button/Button";
import { Paragraph16px300Light } from "../../../../../styles/style-guide";

const NewCategoryWrapper = styled.div`
  width: 945px;
  height: 60vh;
  min-width: 700px;
  min-height: 550px;
  padding: 4px;
`;

const NewCategoryForm = styled.form`
  margin-right: 5px;
  display: flex;
  flex-direction: column;
`;

const NewCategoryPhotoContent = styled.div`
  margin-top: 40px;
  display: flex;
`;

const NewCategoryPhotoTextContent = styled.div`
  width: 40%;
  height: 320px;
`;

const NewCategoryPhotoArea = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  height: 320px;
`;

const NewCategoryPhotoAreaContent = styled.div`
  margin-left: 35px;
  align-self: flex-end;
  height: 100%;
  width: 100%;
  max-width: 426px;
`;

const NewCategoryPhotoParagraph = styled.p`
  margin-top: 16px;
  ${Paragraph16px300Light};
  color: ${({ theme }) => theme.colors.textColor};
`;

const NewCategoryInputGroupWithToggle = styled(InputGroupWithToggle)`
  margin-right: 32px;
  margin-top: 0;
  div {
    margin-top: 0;
    padding: 0;
  }
`;

const NewCategoryButtonGoBack = styled(Button)`
  float: right;
`;

const NewCategoryButtonSave = styled(Button)`
  float: left;
  margin-right: 10px;
`;

const NewCategoryFooter = styled.footer`
  bottom: 24px;
  position: absolute;
  width: 95%;
`;

export {
  NewCategoryWrapper,
  NewCategoryForm,
  NewCategoryFooter,
  NewCategoryButtonSave,
  NewCategoryButtonGoBack,
  NewCategoryPhotoContent,
  NewCategoryPhotoTextContent,
  NewCategoryPhotoParagraph,
  NewCategoryPhotoArea,
  NewCategoryPhotoAreaContent,
  NewCategoryInputGroupWithToggle,
};
