import styled from 'styled-components';
import {
  Title24px400Regular,
  Description12px400Regular,
  Description12px300Light,
} from '../../../styles/style-guide';
import { IconLogout, IconAvatar } from '../../../styles/icons';

const Header = styled.header`
  position: absolute;
  height: 60px;
  width: 100%;
  min-width: 700px;
  background-color: ${({ theme }) => theme.colors.mainColorContrast};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  vertical-align: middle;
  z-index: 1;
`;

const BreadcrumbsContent = styled.div`
  float: left;
  display: flex;
  align-items: center;
  margin: 15px 0 0 300px;
`;

const UserContent = styled.div`
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 16px;

  ${IconAvatar} {
    margin-left: 9px;
    width: 42px;
    height: 42px;
  }

  ${IconLogout} {
    cursor: pointer;
  }
`;

const UserTextInfo = styled.div`
  margin: 0px 16px;
`;

const UserName = styled.span`
  ${() => Description12px400Regular};
  color: ${({ theme }) => theme.colors.textColor};
  float: right;
`;

const UserEmail = styled.span`
  ${() => Description12px300Light};
  color: ${({ theme }) => theme.colors.textColorLight};
  float: right;
`;

const Breadcrumb = styled.span`
  ${() => Title24px400Regular};
  color: ${({ theme }) => theme.colors.textColor};
`;

export {
  Header,
  UserContent,
  UserTextInfo,
  UserName,
  UserEmail,
  Breadcrumb,
  BreadcrumbsContent,
};
