import styled from 'styled-components';

const Main = styled.div`
  overflow-y: auto;
  margin-top: 60px;
  height: calc(100% - 60px);
  width: 100%;
  min-width: calc(100vw - 325px);
`;

export default Main;
