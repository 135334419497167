import React from 'react';
import PropTypes from 'prop-types';
import { CustomTag, ButtonClose } from './Styles';
import { IconClose } from '../../styles/icons';

const Tag = (props) => {
  const {
    children, onCloseTag, withButton, type, bold,
  } = props;
  const onClickClose = () => {
    onCloseTag();
  };
  return (
    <CustomTag type={type} bold={bold}>
      { children }
      <ButtonClose show={withButton} onClick={() => onClickClose()}>
        <IconClose />
      </ButtonClose>
    </CustomTag>
  );
};

Tag.defaultProps = {
  type: 'primary',
  children: '',
  bold: false,
  withButton: true,
  onCloseTag: () => {},
};

Tag.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  onCloseTag: PropTypes.func,
  withButton: PropTypes.bool,
  bold: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.any,
    PropTypes.number,
  ]),
};

export default Tag;
