import HttpClient from "../http-client";
import { id } from "../helper-client";

const postSubCategory = async (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return HttpClient.post(`subcategory?userId=${id ?? ""}`, formData, {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  });
};

const putSubCategory = async (SubcatId, data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));

  return HttpClient.put(
    `subcategory/${SubcatId}?userId=${id ?? ""}`,
    formData,
    {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    }
  );
};

const fetchSubCategories = async () => {
  return HttpClient.get(`subcategories?userId=${id ?? ""}`);
};

const fetchSubCategoriesByCategory = async (catId) => {
  return HttpClient.get(`subcategories/${catId}?userId=${id ?? ""}`);
};

const putSubCategoryOrder = async (data) => {
  return HttpClient.put(`orderSubCategory?userId=${id ?? ""}`, data);
};

export default {
  postSubCategory,
  putSubCategory,
  fetchSubCategories,
  fetchSubCategoriesByCategory,
  putSubCategoryOrder,
};
