
const SubCategoryForm = {
  name: {
    value: "",
    isRequired: true,
    isValid: false,
    placeholder: "enterANameForTheSubcategory.message",
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
  },
  active: {
    value: true,
    isRequired: true,
    isValid: true,
  },
  file: {
    value: "",
    isRequired: false,
    isValid: true,
  },
  from: {
    value: '',
  },
  categoryId: {
    value: "",
    name: "",
    isRequired: true,
    isValid: false,
    placeholder: "selectCategory.message",
    errorsMsg: {
      required: "thisFieldIsRequired.message",
    },
  },
  touched: false,
};

export default SubCategoryForm;
