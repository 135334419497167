import React from "react";

import { Container, IconAddImage, IconAddContainer } from "./styles";

function DotsAdd() {
  return (
    <Container>
      <IconAddContainer>
        <IconAddImage />
      </IconAddContainer>
    </Container>
  );
}

export default DotsAdd;
