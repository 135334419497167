import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import { useForm, useController } from "react-hook-form";
import { useDeb, usePriorityDeb } from "../../../../../../hooks/custom-hooks";
import MuiAutoComplete from "@material-ui/lab/Autocomplete";
import MuiCheckbox from "@material-ui/core/Checkbox";
import { MuiTextFieldStyled } from "../../../../../../components/inputs/mui-inputs/Styles";
import ModalGenericConfirmation from "../../../../../../components/modal-generic-confirmation/ModalGenericConfirmation";
import restOperation from "../../../../../../api/operation/rest-operation";
import RestCategories from "../../../../../../api/categories/rest-categories";
import RestSubCategories from "../../../../../../api/subcategories/rest-subcategories";
import defaultNoProductPhoto from "../../../../../../assets/productDefaultImg.png";
import Button from "../../../../../../components/button/Button";
import Checkbox from "../../../../../../components/checkbox/Checkbox";
import ImageCrop from "../../../../../../components/image-crop/ImageCrop";
import { ModalParagraph } from "../../../../../../components/modal-mytm/Styles";
import CurrencyMask from "react-currency-format";
import Card from "../../../../../../components/styled/Card";
import CardSubtitle from "../../../../../../components/styled/CardSubtitle";
import {
  InputGroup,
  InputGroupWithToggle,
  InputToggleContent,
} from "../../../../../../components/styled/Forms";
import Toggle from "../../../../../../components/toggle/Toggle";
import { useModalConfirmationContext } from "../../../../../../contexts/ModalConfirmationContext";
import FormUtils from "../../../../../../utils/form-utils";
import formatters from "../../../../../../utils/formatters";
import ProductForm from "./ProductForm";

import {
  CheckBoxContainer,
  CheckBoxLine,
  DataCardContent,
  DataContent,
  FormContainer,
  InputNameContent,
  InputValorContent,
  LabelDescription,
  LabelMainColor,
  LabelSubtitle,
  NewProductFormBsCol,
  NewProductFormBSContainer,
  NewProductFormBSRow,
  NewProductFormButtonsBar,
  NewProductFormPhotoArea,
  NewProductFormPhotoAreaContent,
  ParagraphPhoto,
  PhotoCardContent,
  InputSpanCounter,
  InputDescriptionContent,
} from "./Styles";

import ListComplement from "./list-complement/ListComplement";
import CarrouselPictures from "../../../../../../components/carrousel-pictures/CarrouselPictures";
import DotsAdd from "../../../../../../components/dots-carousel/dots-add/DotsAdd";
import TranslatorFunction from '../../../../../../components/I18n/Translator';
const NewProductForm = (props) => {
  const yes = TranslatorFunction({ path: "yes.message" });
  const no = TranslatorFunction({ path: "no.message" });
  const selectPhoto = TranslatorFunction({ path: "selectPhoto.message" });
  const productImage = TranslatorFunction({ path: "productImage.message" });
  const language = TranslatorFunction({ path: "language.message" });
  const noOptions = TranslatorFunction({ path: "noOptions.message" });
  const onSiteServiceVisualization = TranslatorFunction({ path: 'onSiteServiceVisualization.message' });
  const deliveryWhatsappOrders = TranslatorFunction({ path: 'deliveryWhatsappOrders.message' });
  const takeAway = TranslatorFunction({ path: 'takeAway.message' });
  const catalog = TranslatorFunction({ path: 'catalog.message' });
  const whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus = TranslatorFunction({ path: 'whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus.message' });
  const RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary = TranslatorFunction({ path: 'RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary.message' });
  const principalInformation = TranslatorFunction({ path: 'principalInformation.message' });
  const mandatoryFilling = TranslatorFunction({ path: 'mandatoryFilling.message' });
  const value = TranslatorFunction({ path: 'value.message' });
  const shortDescriptionShownInProductDetail = TranslatorFunction({ path: 'shortDescriptionShownInProductDetail.message' });
  const longDescriptionShownInProductDetail = TranslatorFunction({ path: 'longDescriptionShownInProductDetail.message' });
  const categorization = TranslatorFunction({ path: 'categorization.message' });
  const categories = TranslatorFunction({ path: 'categories.message' });
  const subcategories = TranslatorFunction({ path: 'subcategories.message' });
  const enableOn = TranslatorFunction({ path: 'enableOn.message' });
  const complements = TranslatorFunction({ path: 'complements.message' });
  const namee = TranslatorFunction({ path: 'name.message' });
  const images = TranslatorFunction({ path: 'images.message' });
  const chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos = TranslatorFunction({ path: 'chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos.message' });
  const theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB = TranslatorFunction({ path: 'theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB.message' });
  const saveEditions = TranslatorFunction({ path: 'saveEditions.message' });
  const saveAndAddAnother = TranslatorFunction({ path: 'saveAndAddAnother.message' });
  const enterAShortEasilyIdentifiableName = TranslatorFunction({ path: 'enterAShortEasilyIdentifiableName.message' });
  const back = TranslatorFunction({ path: 'back.message' });

  ProductForm.from = language;

  const { onSubmit, onChange, product, onRedirectToProducts } = props;
  const [fileURL, setFileURL] = useState([]);
  const [form, setForm] = useState({ ...ProductForm });
  const [complementGroup, setComplementGroup] = useState(form?.complementGroup);
  const [refresh, setRefresh] = useState(true);
  const [operation, setOperation] = useState([]);
  const [formKey, setFormKey] = useState(1);
  const [canResetForm, setCanResetForm] = useState(true);
  const [selectOptions, setMultSelectOptions] = useState([]);
  const [selectOptionsSub, setMultSelectOptionsSub] = useState([]);
  const [operationFlag, setOperationFlag] = useState(true);
  const [priceTag, setPriceTag] = useState("");
  const [nameCategory, setNameCategory] = useState("");
  const [openModalOnLeave, setOpenModalOnLeave] = useState(false);
  const [carouselPicture, setCarouselPicture] = useState({
    thumb: [],
    slides: [],
  });
  const [files, setFiles] = useState([]);
  const debounceFunc = useDeb(setPriceTag, 2000);
  const { handleSubmit, getValues, setValue, control } = useForm();

  const { field: controlNameInput } = useController({
    name: "Name",
    defaultValue: "",
    control: control,
  });

  const { field: controlSDInput } = useController({
    name: "ShortDescription",
    defaultValue: "",
    control: control,
  });

  const { field: controlLDInput } = useController({
    name: "LongDescription",
    defaultValue: "",
    control: control,
  });

  const YesNoOptions = [yes, no];

  const labelsOperation = [
    onSiteServiceVisualization,
    deliveryWhatsappOrders,
    takeAway,
    catalog,
  ];

  const {
    setMessage,
    setBtnOneText,
    setBtnTwoText,
    setOnClickBtnOneFunc,
    setOnClickBtnTwoFunc,
    setOpen,
    setOnClickBtnThreeFunc,
  } = useModalConfirmationContext();

  const verify = (finalForm) => {
    if (controlNameInput.value === "" || controlNameInput.value === undefined)
      return false;
    if (priceTag === "" || priceTag === undefined) return false;

    if (controlSDInput.value === "" || controlSDInput.value === undefined)
      return false;

    if (controlLDInput.value === "" || controlLDInput.value === undefined)
      return false;

    if (form && form.subcategories && form.subcategories.length === 0)
      return false;

    let operationsActive = operation.filter(
      (x) => x.checkedId !== undefined && x.checkedId
    );

    if (!operation || (operation && operationsActive.length === 0))
      return false;
    return true;
  };

  const handleSaveAndOut = async () => {
    setOpenModalOnLeave(false);
    onSubmitSecond(getValues());
  };

  const handleOutWithoutSave = async () => {
    setOpenModalOnLeave(false);
    onRedirectToProducts();
  };

  const handleClose = () => {
    setOpenModalOnLeave(false);
  };

  const RenderModalOnLeave = () => (
    <ModalGenericConfirmation
      open={openModalOnLeave}
      title={TranslatorFunction({ path: "areYouSureYouWantToDoThis.message" })}
      message={TranslatorFunction({ path: "whenDisablingTheComplementAllPreviouslyLinkedComplementGroupsWillBeUnlinkedFromTheProduct.message" })}
      onClickBtnOne={handleSaveAndOut}
      onClickBtnTwo={handleOutWithoutSave}
      onClickBtnThree={handleClose}
    />
  );

  const onSubmitSecond = (finalForm, next) => {
    let newForm = { ...form, removedFiles: "" };
    if (verify(finalForm)) {
      newForm.name = finalForm.Name;
      newForm.value = priceTag;
      newForm.shortDescription = finalForm.ShortDescription;
      newForm.longDescription = finalForm.LongDescription;

      if (newForm && newForm.subcategories && newForm.subcategories[0]?.key) {
        newForm.subcategories = newForm.subcategories.map(
          (subCategory) => subCategory.key
        );
      }

      if (operation) {
        let operationsActive = operation.filter(
          (x) => x.checkedId !== undefined && x.checkedId
        );
        let operationMapActives = operationsActive.map((x) => x._id);
        delete form.attendance;
        newForm.enableIn = operationMapActives.toString();
      }

      if (
        newForm &&
        newForm.complementGroup &&
        newForm.complementGroupActive &&
        newForm.complementGroupActive === yes
      ) {
        let complementGroupString = JSON.stringify(newForm.complementGroup);
        newForm.complementGroup = complementGroupString;
      } else {
        newForm.complementGroup = "";
      }

      const removedFiles = product.imageURL
        ? product.imageURL
          // eslint-disable-next-line
          .map((file) => {
            const existValue = carouselPicture.thumb.find(
              (url) => url === file
            );
            if (!existValue) return file;
          })
          .filter((url) => url)
        : "[]";

      newForm.file = files;
      newForm.removedFiles = removedFiles.toString();

      onSubmit(newForm, next);
    }
  };

  const onSubmitFirst = (finalForm, next) => {
    let newForm = { ...form };
    newForm.complementGroup.forEach((compG) => {
      if (compG.active === false) {
        compG.complements.forEach((comp) => {
          comp.active = false;
        });
      }
    });
    if (
      newForm.complementGroupActive === no &&
      newForm.complementGroup.length > 0
    ) {
      setOpenModalOnLeave(true);
    } else {
      onSubmitSecond(finalForm, next);
    }
  };

  const handleCategoriesResponseSuccess = (categories) => {
    setMultSelectOptions(
      formatters.formatCategoriesToMultSelectOptions(categories,language)
    );
  };

  const handleOperationResponseSuccess = (response) => {
    let operations = response.filter((x) => x.active === true);
    let newOperation = [];
    operation.map((x) => (x.checkedId = false));

    operations.forEach((o) => {
      if (product && product.enableIn) {
        let enableIdsIn = product.enableIn.map((x) => x.idEnableIn);
        if (enableIdsIn && enableIdsIn.includes(o._id)) {
          o.checkedId = true;
        }
      }
    });

    newOperation = [...operations];
    setOperation(newOperation);
    setOperationFlag(true);
    setRefresh(!refresh);
  };

  const fetchCategories = async () => {
    RestCategories.fetchCategories()
      .then((res) => handleCategoriesResponseSuccess(res.data.categories))
      .catch(() => alert(TranslatorFunction({ path: "anErrorOccurredWhileFetchingProductCategories.message" })));
  };

  const fecthOperation = () => {
    restOperation
      .fetchOperations()
      .then((res) => handleOperationResponseSuccess(res.data))
      .catch(() => alert(TranslatorFunction({ path: "anErrorOccurredWhileFetchingProductOperations.message" })));
  };

  const debounceFetchOp = usePriorityDeb(fecthOperation, 100);

  const handleOnChangeCheckBox = (event) => {
    const { name } = event;

    let newOperation = [];

    operation.forEach((o) => {
      if (o._id === name) {
        if (o._id === name) {
          if (!o.checkedId) {
            o.checkedId = true;
          } else {
            o.checkedId = !o.checkedId;
          }
        }
      }
    });

    newOperation = [...operation];
    setOperation(newOperation);
  };

  const handleSubCategoriesResponseSuccess = (subCategories) => {
    setMultSelectOptionsSub(
      formatters.formatSubCategoriesToMultSelectOptions(subCategories,language),
      );
    };
    
    const fetchSubCategories = async (categories) => {
      RestSubCategories.fetchSubCategoriesByCategory(categories)
      .then((res) => handleSubCategoriesResponseSuccess(res.data.subCategories))
      .catch(() =>
        alert(TranslatorFunction({ path: "anErrorOccurredWhileFetchingTheSubcategoriesForTheSelectedCategory.message" }))
      );
  };

  const resetForm = useCallback(() => {
    setForm(ProductForm);
    setFormKey(formKey + 1);
    setCanResetForm(false);

    //eslint-disable-next-line
  }, [formKey, setFormKey, setForm]);

  const fetchAllExpandedTables = (obj) => {
    let existGroup = form.complementGroup;
    obj.forEach((o, index) => {
      let filteredComplements = existGroup.filter((x) => x._id === o._id);
      if (filteredComplements && filteredComplements.length > 0) {
        let filterComp = filteredComplements[0];
        obj[index].min = filterComp.min;
        obj[index].max = filterComp.max;
        obj[index].active = true;
        obj[index].index = index;
        obj[index].complements = filterComp.complements;
        obj[index].showComp =
          filterComp &&
            filterComp.complements &&
            filterComp.complements.length > 0
            ? true
            : false;
      }
    });

    return obj;
  };

  const setComplementGroupsOnProduct = (
    Obj,
    inClosedPopup,
    isInside = false
  ) => {
    let newForm = { ...form };
    if (Obj) {
      if (inClosedPopup) {
        if (isInside) {
          let objIdx = newForm.complementGroup.findIndex(
            (x) => x._id === Obj._id
          );
          if (objIdx !== -1) {
            if (
              newForm.complementGroup[objIdx] &&
              newForm.complementGroup[objIdx].complements &&
              newForm.complementGroup[objIdx].complements.length > 0
            ) {
              newForm.complementGroup[objIdx].showComp = true;
            } else {
              newForm.complementGroup[objIdx].showComp = false;
            }
          }
        } else {
          newForm.complementGroup = fetchAllExpandedTables(Obj);
        }
        setForm(newForm);
      }
    }
  };

  const handleRemoveURLPictures = (imgURL) => {
    const urlImages = [...fileURL];
    const findIndexURLImage = urlImages.findIndex((url) => url === imgURL);

    urlImages.splice(findIndexURLImage, 1);

    setFileURL(urlImages);

    if (urlImages.length === 0) {
      const newPictures = { thumb: [], slides: [] };

      setCarouselPicture(newPictures);

      return;
    }

    const componentsImages = urlImages.map((url, idx) => (
      <img
        src={url}
        alt={productImage}
        width={300}
        data-index={idx}
        key={url}
      />
    ));

    setCarouselPicture({
      slides: [
        ...componentsImages,
        <ImageCrop
          isProduct
          value={defaultNoProductPhoto}
          withIconAdd
          onChange={handleOnChangeInputImage}
          buttonText={selectPhoto}
          isDefaultImage={true}
        />,
      ],
      thumb: [...urlImages, <DotsAdd />],
    });
  };

  const initEditMode = () => {
    setOperationFlag(false);
    let newForm = { ...form };
    const selectedSubcategories = product?.subcategories.map((p) => ({
      label: p.translatedName && p?.translatedName?.length > 0 ? p?.translatedName?.[0]?.name?.[0]?.[language] : `${p.name} (Não traduzido)`,
      key: p._id,
      isMultSelected: true,
    }));

    setValue("Name", product.name);
    setValue("Value", product.value);
    setValue("ShortDescription", product.description && product?.description?.length > 0 && product?.description?.[0]?.shortDescription?.[0]?.[language] !== undefined ? product?.description?.[0]?.shortDescription?.[0]?.[language] : product.shortDescription);
    setValue("LongDescription", product.description && product?.description?.length > 0 && product?.description?.[0]?.longDescription?.[0]?.[language] !== undefined? product?.description?.[0]?.longDescription?.[0]?.[language] : product.longDescription);
    
    setMultSelectOptionsSub(selectedSubcategories);
    
    newForm.name = product.name;
    setPriceTag(product.value);
    newForm.active = product.active;
    newForm.longDescription = product.longDescription;
    newForm.shortDescription = product.shortDescription;

    newForm.subcategories =
      formatters.formatProductsSubCategoriesToEdit(product) || undefined;
    newForm = FormUtils.updateFormValidation(newForm);
    newForm.subcategories.selecteds = selectedSubcategories;
    setNameCategory({
      label: product?.subcategories[0]?.category ? product?.subcategories[0]?.category.name  : '',
      key: product?.subcategories[0]?.category ? product?.subcategories[0]?.category._id  : '',
      isMultSelected: false,
      value: product?.subcategories[0]?.category ? product?.subcategories[0]?.category._id  : ''
    })

    newForm.complementGroupActive =
      product && product.complementGroup && product.complementGroup.length > 0
        ? yes
        : no;
    newForm.touched = true;

    if (product && product.complementGroup) {
      newForm.complementGroup = product.complementGroup;
    }

    setForm(newForm);
    if (
      Array.isArray(product.imageURL) &&
      product.imageURL.length > 0 &&
      product.imageURL.length < 5
    ) {
      const componentsImages = product.imageURL.map((url) => (
        <img src={url} alt={productImage} width={300} key={url} />
      ));

      setCarouselPicture({
        thumb: [...product.imageURL, <DotsAdd />],
        slides: [
          ...componentsImages,
          <ImageCrop
            isProduct
            value={defaultNoProductPhoto}
            withIconAdd
            onChange={handleOnChangeInputImage}
            buttonText={selectPhoto}
            isDefaultImage={true}
          />,
        ],
      });

      Array.isArray(product.imageURL)
        ? setFileURL(product.imageURL)
        : setFileURL([product.imageURL]);
    } else {
      const componentsImages = product.imageURL.map((url) => (
        <img src={url} alt={productImage} width={300} key={url} />
      ));

      setCarouselPicture({
        thumb: [...product.imageURL],
        slides: [...componentsImages],
      });

      Array.isArray(product.imageURL)
        ? setFileURL(product.imageURL)
        : setFileURL([product.imageURL]);
    }

    debounceFetchOp(2);
  };

  useEffect(() => {
    // initEditMode();
    let newForm = { ...form };

    setValue("ShortDescription", product.description && product?.description?.length > 0  && product.description?.[0]?.shortDescription?.[0]?.[language] !== undefined? product.description?.[0]?.shortDescription?.[0]?.[language] : product.shortDescription);
    setValue("LongDescription", product.description && product?.description?.length > 0 && product.description?.[0]?.longDescription?.[0]?.[language] !==undefined? product.description?.[0]?.longDescription?.[0]?.[language] : product.longDescription);    
    newForm.complementGroupActive =
      product && product.complementGroup && product.complementGroup.length > 0
        ? yes
        : no;
    
    setForm(newForm);
    fetchCategories();
    // eslint-disable-next-line
  }, [language]);

  const handleOnChangeInputImage = (eventFile) => {
    const url = URL.createObjectURL(eventFile.file);
    setFiles([...files, eventFile.file]);

    setCarouselPicture((oldCarousel) => {
      const lengthValue = oldCarousel.slides.length - 1;
      let newCarousel = { ...oldCarousel };

      newCarousel.slides.splice(lengthValue, 1);
      newCarousel.thumb.splice(lengthValue, 1);

      if (fileURL.length >= 3) {
        newCarousel.slides = [
          ...newCarousel.slides,
          <img src={url} alt={productImage} width={300} key={url} />,
        ];

        newCarousel.thumb = [...newCarousel.thumb, url];
      } else {
        newCarousel.slides = [
          ...newCarousel.slides,
          <img src={url} alt={productImage} width={300} key={url} />,
          <ImageCrop
            isProduct
            value={defaultNoProductPhoto}
            withIconAdd
            onChange={handleOnChangeInputImage}
            buttonText={selectPhoto}
            isDefaultImage={true}
          />,
        ];

        newCarousel.thumb = [...newCarousel.thumb, url, <DotsAdd />];
      }

      return newCarousel;
    });

    setForm((oldForm) => {
      let newform = { ...oldForm };
      if (!newform.file) {
        newform.file = [eventFile.file];
      } else {
        newform.file.push(eventFile.file);
      }
      newform.touched = true;
      return newform;
    });

    setFileURL((oldFilesURL) => {
      let newFilesURL = [...oldFilesURL, url];
      return newFilesURL;
    });
  };

  const handleOnMultSelectChangeCategories = (ev, categories) => {
    setNameCategory(categories);
    form.categoryId = categories.key;
    fetchSubCategories(categories.key);
  };

  const handleOnMultSelectChangeSubCategories = (ev, subcategories) => {
    form.subcategories =
      formatters.formatProductsMultiSelectSubCategoriesToEdit({
        subcategories,
      }) || undefined;
    form.subcategories.selecteds = subcategories;
    setRefresh(!refresh);
  };

  const handleOnChangeSelectComplement = (e, nv) => {
    form.complementGroupActive = nv;
    setRefresh(!refresh);
  };

  const setFormInputField = (name, value) => {
    setForm((oldForm) => {
      let newform = { ...oldForm };
      newform[name] = value;
      return newform;
    });
  };

  const handlerInputCurrencyChange = (e) => {
    debounceFunc(e.floatValue);
  };

  const handleModalOnConfirmInactive = (e) => {
    setOpen(false);
    setFormInputField(e);
  };

  const getInactiveModalMessage = () => (
    <ModalParagraph>
      {whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus}
      <br />
      <br />
      {RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary}
    </ModalParagraph>
  );

  const renderMaxLength = (inputValue, maxLength) =>
    inputValue && inputValue.length > 0 && maxLength !== "" ? (
      <InputSpanCounter>
        {inputValue.length}/{maxLength}
      </InputSpanCounter>
    ) : (
      ""
    );

  const initModalConfimationData = (message, btnText, btnText2, onConfirm) => {
    setBtnOneText(btnText);
    setBtnTwoText(btnText2);
    setMessage(message);
    setOnClickBtnOneFunc(onConfirm);
    setOnClickBtnTwoFunc(() => setOpen(false));
    setOnClickBtnThreeFunc(() => setOpen(false));
  };

  const handleDnDOrder = (compGroup) => {
    let newForm = { ...form };
    newForm.complementGroup = compGroup;

    setForm(newForm);
  };

  const handleActiveOnChange = (event) => {
    if (!event.target) {
      initModalConfimationData(
        getInactiveModalMessage(),
        TranslatorFunction({path:"inactivateTheProduct.message"}),
        TranslatorFunction({path:"keepActive.message"}),
        () => handleModalOnConfirmInactive(event)
      );
      setOpen(true);
      return;
    }
    let newForm = { ...form };
    newForm.active = event.target.value;

    setForm(newForm);
    setFormInputField(event);
  };

  useLayoutEffect(() => {
    if (canResetForm) {
      resetForm();
    }
    if (Object.keys(product).includes("name")) {
      initEditMode();
    }

    // eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    if (
      carouselPicture.slides?.length > 0 &&
      carouselPicture.slides?.length < 5
    ) {
      const componentsImages = fileURL.map((url) => (
        <img src={url} alt={productImage} width={300} key={url} />
      ));

      setCarouselPicture({
        thumb: [...fileURL, <DotsAdd />],
        slides: [
          ...componentsImages,
          <ImageCrop
            isProduct
            value={defaultNoProductPhoto}
            withIconAdd
            onChange={handleOnChangeInputImage}
            buttonText={selectPhoto}
            isDefaultImage={true}
          />,
        ],
      });
    }
    // eslint-disable-next-line
  }, [fileURL]);

  useEffect(() => {
    fetchCategories();
    onChange(form);
    if (operationFlag) {
      debounceFetchOp(1);
    }

    setForm({ ...ProductForm, subcategories: [] });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (complementGroup.length !== form.complementGroup.length) {
      setComplementGroup(form.complementGroup);
    }
    // eslint-disable-next-line
  }, [form]);

  return (
    <>
      <FormContainer key={formKey}>
        <NewProductFormBSContainer fluid>
          <NewProductFormBSRow>
            <NewProductFormBsCol sm="12" md="7" lg="8" noPadding>
              <Card>
                <DataCardContent>
                  <DataContent marginBottom>
                    <LabelSubtitle>{principalInformation}:</LabelSubtitle>
                    <LabelDescription>
                      <LabelMainColor>* </LabelMainColor>
                      {mandatoryFilling}
                    </LabelDescription>
                    <InputGroup>
                      <InputGroupWithToggle>
                        <InputNameContent>
                          <MuiTextFieldStyled
                            name="name"
                            label={namee}
                            variant="outlined"
                            margin="dense"
                            onChange={(e) => {
                              form.name = e.target.value;
                            }}
                            placeholder={enterAShortEasilyIdentifiableName}
                            InputProps={controlNameInput}
                            inputProps={{ maxLength: 40 }}
                          />
                          {renderMaxLength(controlNameInput.value, 40)}
                        </InputNameContent>
                        <InputValorContent>
                          <CurrencyMask
                            label={value}
                            name="value"
                            value={priceTag}
                            placeholder="R$ 0,00"
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            customInput={(p) => {
                              return (
                                <MuiTextFieldStyled
                                  {...p}
                                  margin="dense"
                                  variant="outlined"
                                />
                              );
                            }}
                            onValueChange={handlerInputCurrencyChange}
                          />
                        </InputValorContent>
                        <InputToggleContent>
                          <Toggle
                            onChange={handleActiveOnChange}
                            name="active"
                            textLeft={TranslatorFunction({ path: "active.message" })}
                            textRight={TranslatorFunction({ path: "inactive.message" })}
                            value={form.active}
                          />
                        </InputToggleContent>
                      </InputGroupWithToggle>
                    </InputGroup>
                    <InputGroup>
                      <InputDescriptionContent>
                        <MuiTextFieldStyled
                          name="shortDesc"
                          label={shortDescriptionShownInProductDetail}
                          variant="outlined"
                          margin="dense"
                          placeholder={TranslatorFunction({ path: "enterSomethingLikeSideDishesOrPortionSize.message" })}
                          InputProps={controlSDInput}
                          inputProps={{ maxLength: 70 }}
                        />
                        {renderMaxLength(controlSDInput.value, 70)}
                      </InputDescriptionContent>
                    </InputGroup>
                    <InputGroup>
                      <InputDescriptionContent>
                        <MuiTextFieldStyled
                          label={longDescriptionShownInProductDetail}
                          name="longDescription"
                          variant="outlined"
                          margin="dense"
                          placeholder={TranslatorFunction({ path: "enterTheIngredientsThatMakeUpThisProductItsAccompanimentsAndThePortionSizes.message" })}
                          InputProps={controlLDInput}
                          inputProps={{ maxLength: 1500 }}
                          multiline
                        />
                        {renderMaxLength(controlLDInput.value, 1500)}
                      </InputDescriptionContent>
                    </InputGroup>
                  </DataContent>
                  <DataContent marginBottom>
                    <LabelSubtitle>{categorization}:</LabelSubtitle>
                    <InputGroup>
                      <MuiAutoComplete
                        id="single"
                        value={nameCategory}
                        options={selectOptions.map((option) => ({...option, value: option.key}))}                           
                        getOptionLabel={(option) => option.label}                       
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <MuiTextFieldStyled
                            {...params}
                            margin="dense"
                            label={categories}
                            placeholder={TranslatorFunction({ path: "categorysInWhichTheProductShouldAppear.message" })}
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                        onChange={handleOnMultSelectChangeCategories}
                      />
                    </InputGroup>
                    <InputGroup>
                      <MuiAutoComplete
                        id="multiple"
                        multiple
                        disableCloseOnSelect
                        noOptionsText={noOptions}
                        options={selectOptionsSub}

                        getOptionLabel={(option) => option?.label ? option?.label : option?.label || ""}
                        getOptionSelected={(option, value) =>  option?.label === value?.label }
                        style={{ width: "100%" }}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <MuiCheckbox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option?.label}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <MuiTextFieldStyled
                            {...params}
                            margin="dense"
                            label={subcategories}
                            placeholder={TranslatorFunction({ path: "subCategorysInWhichTheProductShouldAppear.message" })}
                            variant="outlined"
                          />
                        )}
                        value={
                          form &&
                            form.subcategories &&
                            form.subcategories.selecteds
                            ? form?.subcategories.selecteds
                            : []
                        }
                        onChange={handleOnMultSelectChangeSubCategories}
                      />
                    </InputGroup>
                  </DataContent>
                  <DataContent marginBottom>
                    <LabelSubtitle>{enableOn}:</LabelSubtitle>
                    <CheckBoxLine>
                      {operation?.map((value, index) => {
                        return (
                          <CheckBoxContainer key={index}>
                            <Checkbox
                              name={value._id}
                              key={value.id}
                              value={value?.checkedId}
                              onChange={handleOnChangeCheckBox}
                            >
                              {labelsOperation[value.id]}
                            </Checkbox>
                          </CheckBoxContainer>
                        );
                      })}
                    </CheckBoxLine>
                  </DataContent>
                  <DataContent>
                    <LabelSubtitle>{complements}:</LabelSubtitle>
                    <MuiAutoComplete
                      id="complementGroupActive"
                      options={YesNoOptions}
                      getOptionLabel={(option) => option || ""}
                      getOptionSelected={(option, value) => {
                        return option === value;
                      }}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <MuiTextFieldStyled
                          {...params}
                          margin="dense"
                          label={complements}
                          variant="outlined"
                        />
                      )}
                      value={form?.complementGroupActive}
                      onChange={handleOnChangeSelectComplement}
                    />
                  </DataContent>
                </DataCardContent>
              </Card>
            </NewProductFormBsCol>
            <NewProductFormBsCol sm="12" md="5" lg="4" noPadding>
              <Card>
                <PhotoCardContent>
                  <CardSubtitle>{images}</CardSubtitle>
                  <ParagraphPhoto>
                    {chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos}
                    <br />
                    <br />
                    {theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB}
                  </ParagraphPhoto>
                  <NewProductFormPhotoArea>
                    <NewProductFormPhotoAreaContent>
                      <CarrouselPictures
                        slides={carouselPicture.slides}
                        thumbnails={carouselPicture.thumb}
                        test={product.imageURL}
                        handleRemove={handleRemoveURLPictures}
                      />
                      {!fileURL?.length && (
                        <ImageCrop
                          value={defaultNoProductPhoto}
                          withIconAdd
                          onChange={handleOnChangeInputImage}
                          buttonText={selectPhoto}
                          isDefaultImage={true}
                        />
                      )}
                    </NewProductFormPhotoAreaContent>
                  </NewProductFormPhotoArea>
                </PhotoCardContent>
              </Card>
            </NewProductFormBsCol>
          </NewProductFormBSRow>
          {form.complementGroupActive === yes && (
            <NewProductFormBSRow>
              <NewProductFormBsCol sm="12" md="12" lg="12" noPadding>
                <Card>
                  <ListComplement
                    subcat={form.subcategories.selecteds}
                    myId={product._id}
                    complements={complementGroup}
                    setComplements={setComplementGroupsOnProduct}
                    setDnDOrder={handleDnDOrder}
                  />
                </Card>
              </NewProductFormBsCol>
            </NewProductFormBSRow>
          )}

          <NewProductFormBSRow>
            <NewProductFormBsCol sm="12" md="12" lg="12" noPadding>
              <NewProductFormButtonsBar>
                <div>
                  <Button
                    disabled={!verify(getValues)}
                    type="button"
                    btnType="primary"
                    onClick={handleSubmit((data) => onSubmitFirst(data, false))}
                  >
                    {saveEditions}
                  </Button>
                  <Button
                    disabled={!verify(getValues)}
                    type="button"
                    btnType="primary"
                    onClick={handleSubmit((data) => onSubmitFirst(data, true))}
                  >
                  {saveAndAddAnother}
                  </Button>
                </div>

                <Button
                  type="button"
                  btnType="tertiary"
                  onClick={onRedirectToProducts}
                >
                  {back                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                </Button>
              </NewProductFormButtonsBar>
            </NewProductFormBsCol>
          </NewProductFormBSRow>
        </NewProductFormBSContainer>
      </FormContainer>
      {RenderModalOnLeave()}
    </>
  );
};

NewProductForm.defaultProps = {
  product: {},
  submitted: false,
  onSubmit: () => { },
  onRedirectToProducts: () => { },
  onChange: () => { },
};

NewProductForm.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
  submitted: PropTypes.bool,
  onSubmit: PropTypes.func,
  onRedirectToProducts: PropTypes.func,
  onChange: PropTypes.func,
};

export default NewProductForm;
