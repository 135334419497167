export default {
  translations: {
    language: {
      message: 'pt-PT',
    },
    LoginFormTitle: {
      message: 'Login'
    },
    LoginFormSubtitle: {
      message: 'Olá, informe o seu e-mail e senha para entrar:'
    },
    LoginFormErroMessage: {
      message: 'Não foi possível realizar o login, tente novamente.'
    },
    LoginFormButton: {
      message: 'Entrar'
    },
    LoginFormButtonForgotPassword: {
      message: 'Esqueci a senha'
    },
    password: {
      message: 'Senha'
    },
    confirmPassword: {
      message: 'Confirmar senha'
    },
    mail: {
      message: 'E-mail'
    },
    myEstablishment: {
      message: 'Meu estabelecimento'
    },
    parameterization: {
      message: 'Parametrização'
    },
    itemRegistration: {
      message: 'Cadastro de itens'
    },
    orders: {
      message: 'Pedidos'
    },
    discountCoupons: {
      message: 'Cupons de Desconto'
    },
    logout: {
      message: 'sair'
    },
    establishmentDetails: {
      message: 'Detalhes do estabelecimento'
    },
    subscriptions: {
      message: 'Assinaturas'
    },
    aboutTheEstablishment: {
      message: 'Sobre o estabelecimento'
    },
    mandatoryFilling: {
      message: 'preenchimento obrigatório'
    },
    tradeName: {
      message: 'Nome fantasia (nome pelo qual o seu estabelecimento é conhecido)'
    },
    companyCorporateName: {
      message: 'Razão social'
    },
    informTheCompanyCorporateName: {
      message: 'Informe a Razão Social do estabelecimento'
    },
    pixel: {
      message: 'Pixel'
    },
    informPixel: {
      message: 'Informe Pixel'
    },
    invalidCnpj: {
      message: 'CNPJ inválido'
    },
    invalidCep: {
      message: 'CEP inválido'
    },
    state: {
      message: 'Estado'
    },
    city: {
      message: 'Cidade'
    },
    cityUpper: {
      message: 'CIDADE'
    },
    neighborhoods: {
      message: 'Bairro'
    },
    street: {
      message: 'Rua'
    },
    number: {
      message: 'Número'
    },
    informTheNumber: {
      message: 'Informe o númeroNúmero'
    },
    complement: {
      message: 'Complemento'
    },
    complementUpper: {
      message: 'COMPLEMENTO'
    },
    URLToYourMenu: {
      message: 'URL para o seu menu'
    },
    emailToLoginToTheManager: {
      message: 'E-mail para login no gerenciador'
    },
    name: {
      message: 'Nome'
    },
    nameUpper: {
      message: 'NOME'
    },
    lastName: {
      message: 'Sobrenome'
    },
    contactEmail: {
      message: 'E-mail para contato'
    },
    cellphoneNumberWhatsApp: {
      message: 'Celular/WhatsApp'
    },
    thisFieldIsRequired: {
      message: 'Este campo é obrigatório.'
    },
    enterYourCreditCardDetails: {
      message: 'Informe os dados do seu cartão de crédito:'
    },
    thisIsTheCurrentPaymentMethod: {
      message: 'Esse é o meio de pagamento atual:'
    },
    paymentMethod: {
      message: 'Meio de pagamento:'
    },
    paymentMethod2: {
      message: 'formas de pagamentos'
    },
    paymentMethod3: {
      message: 'FORMAS DE PAGAMENTO'
    },
    cardNumber: {
      message: 'Número do cartão:'
    },
    enterAValidCardNumber: {
      message: 'Digite um número de cartão válido.'
    },
    enterAValidDate: {
      message: 'Digite uma data válida.'
    },
    cardholderName: {
      message: 'Nome do titular.'
    },
    typeAsWrittenOnCard: {
      message: 'Digite como escrito no cartão.'
    },
    enterTheNameAsItIsWrittenOnTheCard: {
      message: 'Digite o nome da mesma forma que está escrito no cartão.'
    },
    bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable: {
      message: 'Ao salvar os dados de pagamento você autoriza a MyTripMenu a cobrar o valor do plano, automaticamente, todo mês, até que você cancele a inscrição. Os termos e condições estão disponíveis '
    },
    inThisLink: {
      message: 'neste link.'
    },
    savePaymentData: {
      message: 'salvar dados de pagamento'
    },
    addACreditCardToViewPlanInformationAndConfirmYourSubscription: {
      message: 'Adicione um cartão de crédito para ver as informações do plano e confirmar a sua assinatura.'
    },
    deliveryConfiguration: {
      message: 'configuração de entrega'
    },
    schedules: {
      message: 'horários'
    },
    Schedules: {
      message: 'HORÁRIOS'
    },
    add: {
      message: 'adicionar'
    },
    onSiteServiceVisualization: {
      message: 'Atendimento no Local | Visualização'
    },
    deliveryWhatsappOrders: {
      message: 'Delivery | Pedidos por WhatsApp'
    },
    takeAway: {
      message: 'Retirada'
    },
    catalog: {
      message: 'Catálogo'
    },
    typesOfService: {
      message: 'TIPOS DE ATENDIMENTO'
    },
    mandatoryDataForDelivery: {
      message: 'DADOS OBRIGATORIOS PARA ENTREGA'
    },
    phone: {
      message: 'Telefone'
    },
    phoneUpper: {
      message: 'TELEFONE'
    },
    address: {
      message: 'Endereço'
    },
    generalSettings: {
      message: 'CONFIGURAÇÕES GERAIS'
    },
    freeShippingForPurchasesAbove: {
      message: 'FRETE GRÁTIS PARA COMPRAS À CIMA DE'
    },
    noFreeShipping: {
      message: 'Sem frete Grátis'
    },
    minimumValueForDeliveryOrders: {
      message: 'VALOR MÍNIMO PARA PEDIDOS COM ENTREGA'
    },
    minimumDeliveryTime: {
      message: 'TEMPO MÍNIMO DE ENTREGA'
    },
    noForecast: {
      message: 'Sem Previsão'
    },
    maximumDeliveryTime: {
      message: 'TEMPO DE ENTREGA MÁXIMO'
    },
    orderResponseTimeInMinutes: {
      message: 'TEMPO DE RESPOSTA DO PEDIDO EM MINUTOS'
    },
    serviceNumberViaWhats: {
      message: 'NUMERO DO ATENDIMENTO VIA WHATSAPP'
    },
    servicePlaces: {
      message: 'LOCAIS DE ATENDIMENTOS'
    },
    action: {
      message: 'AÇÕES'
    },
    addEditCities: {
      message: 'ADICIONAR/EDITAR CIDADES'
    },
    newCity: {
      message: 'Nova cidade'
    },
    search: {
      message: 'Pesquisar'
    },
    addNew: {
      message: 'Adicionar Novo'
    },
    saveEditions: {
      message: 'SALVAR ALTERAÇÕES'
    },
    exitWithoutSaving: {
      message: 'SAIR SEM SALVAR'
    },
    saveEditionsAndExit: {
      message: 'SALVAR ALTERAÇÕES E SAIR'
    },
    back: {
      message: 'VOLTAR'
    },
    status: {
      message: 'STATUS'
    },
    active: {
      message: 'ATIVO'
    },
    inactive: {
      message: 'INATIVO'
    },
    category: {
      message: 'Categoria'
    },
    subcategory: {
      message: 'Subcategoria'
    },
    categories: {
      message: 'Categorias'
    },
    subcategories: {
      message: 'Subcategorias'
    },
    categoriesUpper: {
      message: 'CATEGORIAS'
    },
    subcategoriesUpper: {
      message: 'SUBCATEGORIAS'
    },
    complements: {
      message: 'Complementos'
    },
    complementsGroup: {
      message: 'grupo de complementos'
    },
    complementsGroupUpper: {
      message: 'GRUPO DE COMPLEMENTOS'
    },
    products: {
      message: 'produtos'
    },
    image: {
      message: 'IMAGEM'
    },
    yes: {
      message: 'Sim'
    },
    no: {
      message: 'Não'
    },
    theHighlightsCategoryCannotBeEdited: {
      message: 'A categoria Destaques não pode ser editada'
    },
    sortFromAZ: {
      message: 'Ordenar de A-Z'
    },
    sortFromZA: {
      message: 'Ordenar de Z-A'
    },
    sortByActive: {
      message: 'Ordenar por active'
    },
    sortByInactive: {
      message: 'Ordenar por inactive'
    },
    listActiveOnly: {
      message: 'Lista Somente Ativos'
    },
    addEditCategory: {
      message: 'Adicionar/editar categoria'
    },
    addEditSubcategory: {
      message: 'Adicionar/editar subcategoria'
    },
    imageOptional: {
      message: 'Imagem (opcional)'
    },
    addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive: {
      message: 'Adicionar imagem ilustrativa à categoria é uma ação opcional,porém, colabora para tornar o seu menu mais atrativo.'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality: {
      message: 'Escolha uma foto que ilustre os itens que compõem essa categoria, tenha uma boa iluminação e seja realista em relação à apresentação real dos produtos. Evite utilizar fotos genéricas, de banco de imagens ou de baixa qualidade.'
    },
    theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB: {
      message: 'A resolução ideal é de 800x600 pixels e a imagem não pode ser maior do que 1 MB.'
    },
    selectPhoto: {
      message: 'SELECIONAR FOTO'
    },
    selectCategory: {
      message: 'SELECIONAR CATEGORIA'
    },
    saveAndAddAnother: {
      message: 'SALVAR E ADICIONAR OUTRO'
    },
    highlightsSubcategoryCannotBeEdited: {
      message: 'A subcategoria Destaques não pode ser editada'
    },
    categoryInWhichTheSubcategoryShouldAppear: {
      message: 'Categoria em que a subcategoria deve aparecer'
    },
    addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive: {
      message: 'Adicionar imagem ilustrativa à subcategoria é uma ação opcional, porém, colabora para tornar o seu menu mais atrativo.'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos: {
      message: 'Escolha uma foto que ilustre os itens que compõem essa subcategoria, tenha uma boa iluminação e seja realista em relação à apresentação real dos produtos. Evite utilizar fotos genéricas, de banco de imagens ou de baixa qualidade.'
    },
    enterANameForTheSubcategory: {
      message: 'Digite um nome para a sub-categoria'
    },
    enterANameForTheCategory: {
      message: 'Digite um nome para a categoria'
    },
    descriptionOfComplement: {
      message: 'Descrição do Complemento'
    },
    descriptionOfComplementUpper: {
      message: 'DESCRIÇÃO DO COMPLEMENTO'
    },
    addComplementGroup: {
      message: 'ADICIONAR GRUPO DE COMPLEMENTO'
    },
    complementGroupName: {
      message: 'Nome do Grupo de Complemento'
    },
    complementName: {
      message: 'Nome do Complemento'
    },
    checkAllFields: {
      message: 'Verifique todos os campos.'
    },
    complementGroupIsInactive: {
      message: 'Grupo de Complementos está inativo.'
    },
    value: {
      message: 'Valor'
    },
    valueUpper: {
      message: 'VALOR'
    },
    addProduct: {
      message: 'ADICIONAR PRODUTO'
    },
    images: {
      message: 'Imagens'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos: {
      message: 'Escolha uma foto que ilustre os itens que compõem esse produto, tenha uma boa iluminação e seja realista em relação à sua apresentação real. Evite utilizar fotos genéricas, de banco de imagens ou de baixa qualidade.'
    },
    enableOn: {
      message: 'Habilitar em'
    },
    categorysInWhichTheProductShouldAppear: {
      message: 'Categoria(s) em que o produto deve aparecer'
    },
    subCategorysInWhichTheProductShouldAppear: {
      message: 'Subcategoria(s) em que o produto deve aparecer'
    },
    categorization: {
      message: 'Categorização'
    },
    shortDescriptionShownInProductDetail: {
      message: 'Breve Descrição (mostrada nos detalhes do produto)'
    },
    longDescriptionShownInProductDetail: {
      message: 'Descrição longa (mostrada no detalhe do produtos)'
    },
    enterTheIngredientsThatMakeUpThisProductItsAccompanimentsAndThePortionSizes: {
      message: 'Digite os ingredientes que compõem esse produto, seu(s) acompanhamento(s) e o tamanho das porções'
    },
    enterSomethingLikeSideDishesOrPortionSize: {
      message: 'Digite algo como acompanhamentos ou tamanho da porção'
    },
    principalInformation: {
      message: 'Informações principais'
    },
    id: {
      message: 'ID'
    },
    type: {
      message: 'TIPO'
    },
    validity: {
      message: 'Validade'
    },
    validityUpper: {
      message: 'VALIDADE'
    },
    useAvailable: {
      message: 'USO/DISPONÍVEIS'
    },
    filter: {
      message: 'Filtrar'
    },
    freeDelivery: {
      message: 'ENTREGA GRÁTIS'
    },
    discount: {
      message: 'DESCONTO'
    },
    aCouponWithThisCodeAlreadyExists: {
      message: 'Já existe um cupom com esse código.'
    },
    dateTime: {
      message: 'DATA/HORA'
    },
    change: {
      message: 'TROCO'
    },
    formOfPayment: {
      message: 'FORMA DE PAGAMENTO'
    },
    customer: {
      message: 'CLIENTE'
    },
    uninformed: {
      message: 'Não Informado'
    },
    recordsFound: {
      message: 'Registros encontrados'
    },
    QRCodeOfYourDigitalMenu: {
      message: 'QR Code do seu menu digital'
    },
    downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu: {
      message: 'Faça o download do seu QR Code e disponibilize-o em seu estabelecimento ou nos materiais de divulgação para facilitar o acesso do cliente ao seu menu digital'
    },
    areYouSureYouWantToDoThis: {
      message: 'Tem certeza que deseja fazer isso?'
    },
    whenDisablingTheComplementAllPreviouslyLinkedComplementGroupsWillBeUnlinkedFromTheProduct: {
      message: 'Ao desabilitar o complemento todos os grupos de complementos vinculados anteriormente serão desvinculados do produto.'
    },
    anErrorOccurredWhileFetchingProductCategories: {
      message: 'Ocorreu um erro ao buscar as categories do produto'
    },
    anErrorOccurredWhileFetchingProductOperations: {
      message: 'Ocorreu um erro ao buscar as operações do produto'
    },
    anErrorOccurredWhileFetchingTheSubcategoriesForTheSelectedCategory: {
      message: 'Ocorreu um erro ao buscar as sub-categories referente a categoria selecionada'
    },
    productImage: {
      message: 'Imagem do Produto'
    },
    whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus: {
      message: 'Ao inativar um produto, ele deixará de aparecer no(s) cardápio(s) online.'
    },
    RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary: {
      message: 'Lembre-se que você poderá voltar a ativá-lo em outro momento caso seja necessário.'
    },
    inactivateTheProduct: {
      message: 'INATIVAR O PRODUTO'
    },
    keepActive: {
      message: 'MANTER ATIVO'
    },
    simpleEasyFastDigital: {
      message: 'Simples. Fácil. Rápido. Digital.'
    },
    errorIdentification: {
      message: 'Identificação do erro'
    },
    tryAgain: {
      message: 'TENTAR NOVAMENTE'
    },
    huhLooksLikeSomethingWentWrong: {
      message: 'Ué! Parece que algo de errado aconteceu...'
    },
    unableToSaveCreditCardDetails: {
      message: 'Não foi possível salvar os dados do cartão de crédito.'
    },
    mmyy: {
      message: 'MM/AA'
    },
    removePhoto: {
      message: 'Retirar foto'
    },
    addEditCoupon: {
      message: 'ADICIONAR/EDITAR CUPOM'
    },
    couponType: {
      message: 'TIPO DE CUPOM'
    },
    freeShipping: {
      message: 'Frete grátis'
    },
    discountByValue: {
      message: 'Desconto por valor'
    },
    percentageDiscount: {
      message: 'Desconto por porcentagem'
    },
    generateCoupon: {
      message: 'GERAR CUPOM'
    },
    limitOfUse: {
      message: 'LIMITE DE USO'
    },
    couponCode: {
      message: 'CÓDIGO DO CUPOM'
    },
    validFrom: {
      message: 'VÁLIDO DE'
    },
    validUntil: {
      message: 'VÁLIDO ATÉ'
    },
    highlightsSubcategoryCannotBeCopied: {
      message: 'A subcategoria Destaques não pode ser copiada'
    },
    nothingHere: {
      message: 'Parece que ainda não há nada aqui.'
    },
    from: {
      message: "DE"
    },
    to: {
      message: "ATÉ"
    },
    sunday: {
      message: "Domingo"
    },
    monday: {
      message: "Segunda"
    },
    tuesday: {
      message: "Terça"
    },
    wesnesday: {
      message: "Quarta"
    },
    thursday: {
      message: "Quinta"
    },
    friday: {
      message: "Sexta"
    },
    saturday: {
      message: "Sabado"
    },
    accessData: {
      message: "Dados de acesso"
    },
    responsibleEstablishment: {
      message: "Responsavel pelo Estabelecimento"
    },
    saveEditions2: {
      message: "Salvar alterações"
    },
    zeit: {
      message: "Horário"
    },
    cardsAccepted: {
      message: "Marque os cartões aceitos pelo seu estabelecimento"
    },
    pixKey: {
      message: "Cadastre aqui a sua chave PIX"
    },
    noOptions: {
      message: "Sem opções"
    },
    neighborhoods2: {
      message: "BAIRROS"
    },
    addEditNeighborhoods: {
      message: "ADICIONAR/EDITAR BAIRROS"
    },
    newNeighborhoods: {
      message: "Novo Bairro"
    },
    qtyMinimum: {
      message: "QTD. MÍNIMA"
    },
    qtymaximum: {
      message: "QTD. MAXIMA"
    },
    linkGroup: {
      message: "VINCULAR GRUPO DE COMPLEMENTOS"
    },
    importantGroupComplements: {
      message: "IMPORTAR GRUPO DE COMPLEMENTOS"
    },
    people: {
      message: "Pessoas"
    },
    house: {
      message: "Casas"
    },
    subcategoryProducts: {
      message: "Essa SubCategoria não tem Produtos Associados"
    },
    productNotAdd: {
      message: "Esse produto não tem nenhum complemento adicionado"
    },
    linkSupplements: {
      message: "VINCULAR COMPLEMENTOS"
    },
    PRICE: {
      message: "PREÇO"
    },
    COMPLEMENTS: {
      message: "COMPLEMENTOS"
    },
    DESCRIPTION: {
      message: "DESCRIÇÃO"
    },
    action2: {
      message: "Ações"
    },
    pixKey2: {
      message: "Sua Chave PIX"
    },
    ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn: {
      message: "Ao sair da página de detalhes do estabelecimento antes de salvar as alterações, você perderá todas as informações que já preencheu."
    },
    couldNotLoadUserData: {
      message: "Não foi possível carregar os dados do usuário"
    },
    termsOfUseAanServices: {
      message: "TERMOS DE USO E SERVIÇOS"
    },
    hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou: {
      message: "Olá! Que bom contar com seu interesse! Antes de usar nossos serviços, tire um tempo para ler nossos Termos de Uso e Serviço e conhecer as regras que regem nossa relação com você."
    },
    belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom: {
      message: "Abaixo esclareceremos alguns pontos que julgamos importantes. Caso persista alguma dúvida acerca de quaisquer pontos discutidos ou não neste documento, por favor, não hesite em contatar-nos pelo endereço hello@mytripmenu.com."
    },
    definitions: {
      message: "1. DEFINIÇÕES:"
    },
    inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions: {
      message: "No presente instrumento, entendemos as expressões abaixo de acordo com as seguintes definições:"
    },
    plataform: {
      message: "PLATFORMA"
    },
    webSystemAvailableThroughMyTripMenuComBrOfferedBy: {
      message: "sistema web disponível através de mytripmenu.com.br, oferecido por"
    },
    andOwnershipOperationAndLiabilityOf: {
      message: " e de propriedade, operação e responsabilidade de "
    },
    users: {
      message: "USUÁRIOS"
    },
    user: {
      message: "USUÁRIO"
    },
    where: {
      message: ", onde"
    },
    allThe: {
      message: "todos os"
    },
    canViewAndUseTheServicesOfferedBy: {
      message: "podem visualizar e utilizar os serviços oferecidos por "
    },
    establishment: {
      message: "ESTABELECIMENTO"
    },
    establishments: {
      message: "ESTABELECIMENTOS"
    },
    legalEntityRegisteredInThe: {
      message: "pessoa jurídica cadastrada na "
    },
    whichContractsTheServicesOfferedBy: {
      message: ", que contrate os serviços ofertados pela "
    },
    client: {
      message: "CLIENTE"
    },
    customers: {
      message: "CLIENTES"
    },
    naturalPersonAccessingThe: {
      message: "pessoa física que acesse a "
    },
    toViewTheMenu: {
      message: "para visualizar o cardápio do "
    },
    dataProcessing: {
      message: "TRATAMENTO DE DADOS"
    },
    pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy: {
      message: " Nos termos do artigo 5, inciso X, da Lei 13.709 de 2018, toda operação realizada pela "
    },
    withPersonalDataOf: {
      message: " com dados pessoais do "
    },
    suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction: {
      message: ", como as que se referem a coleta, produção, recepção, classNameificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração. "
    },
    admission: {
      message: " 2. ADESÃO: "
    },
    thisInstrumentRegulatesTheConditionsOfUseOfThe: {
      message: "Este instrumento regula as condições de uso da"
    },
    being: {
      message: "sendo"
    },
    aContractBetweenThe: {
      message: " um contrato entre o "
    },
    andThe: {
      message: "e a "
    },
    theUseOfTheServicesOfferedThrough: {
      message: ". A utilização dos serviços oferecidos através "
    },
    fromThe: {
      message: "da "
    },
    expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies: {
      message: " indica expressamente que você concorda com todos os termos e condições contidos neste instrumento e com as disposições legais aplicáveis à espécie. "
    },
    the: {
      message: "O "
    },
    understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy: {
      message: " entende que o modelo de contratação dos serviços será por meio de planos pré-pagos de assinatura mensal orçado e oferecido pela "
    },
    accordingToInformationAvailableInThePlansAreaOfTheWebsite: {
      message: "conforme informações disponibilizadas na área de planos do site: "
    },
    forTheContractingOfCustomizedPlansThe: {
      message: "Para a contratação de planos customizados, o "
    },
    shouldContactThe: {
      message: "deverá entrar em contato direto com a "
    },
    throughItsChannelsForTheElaborationOfASpecificBudget: {
      message: "através de seus canais, para elaboração de orçamento específico. "
    },
    youUnderstandAndAgreeThatThe: {
      message: "VOCÊ ENTENDE E CONCORDA QUE A "
    },
    useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies: {
      message: " CONSIDERARÁ O USO DOS SERVIÇOS DISPOSTOS COMO ACEITAÇÃO DESTES TERMOS E TODAS AS DEMAIS DISPOSIÇÕES LEGAIS PERTINENTES À ESPÉCIE."
    },
    ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform: {
      message: "AO ACEITAR OS TERMOS DO PRESENTE INSTRUMENTO, O USUÁRIO AUTORIZA EXPRESSAMENTE O TRATAMENTO DE SEUS DADOS, A FIM DE GARANTIR A MANUTENÇÃO E O BOM DESEMPENHO DAS FUNCIONALIDADES DA PLATAFORMA. "
    },
    theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy: {
      message: "O USUÁRIO, NESTE ATO, MANIFESTA O SEU COMPLETO CONSENTIMENTO PARA O COMPARTILHAMENTO DOS DADOS COLETADOS E TRATADOS PELA "
    },
    pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders: {
      message: ", NOS TERMOS DESTE INSTRUMENTO, COM OUTRAS MY TRIP MENUS QUE FAÇAM PARTE DE SEU GRUPO ECONÔMICO, OU SEJAM SUAS PRESTADORAS DE SERVIÇO."
    },

    caseYou: {
      message: "Caso você "
    },
    doNotAgree: {
      message: "NÃO CONCORDE"
    },
    withTheProvisionsOfThisInstrument: {
      message: " com as disposições previstas neste instrumento, "
    },
    dont: {
      message: 'NÃO'
    },
    accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom: {
      message: " acesse, visualize, baixe ou utilize de qualquer forma nenhuma página, conteúdo, informação ou serviço da "
    },
    theseTermsAreAvailableForReadingAtAnyTimeOn: {
      message: "Os presentes termos estão disponíveis para leitura, a qualquer momento, na "
    },
    inMessage: {
      message: "em "
    },
    whoWeAreAndWhatWeDo: {
      message: "3. QUEM SOMOS E O QUE FAZEMOS:"
    },
    isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp: {
      message: " é empresa prestadora de serviços que desenvolveu e licencia um sistema constituído no formato de SaaS (Software como Serviço), com funcionalidades especificas para auxiliar"
    },
    and: {
      message: "e"
    },
    soThe: {
      message: "Assim, a"
    },
    offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe: {
      message: "oferece um cardápio digital, permitindo uma melhora na eficiência e experiência tanto para o"
    },
    asForThe: {
      message: "quanto para o"
    },
    generalConditionsOfUse: {
      message: "4. CONDIÇÕES GERAIS DE USO:"
    },
    onlyProvidesThe: {
      message: " apenas disponibiliza a "
    },
    whichOffersDigitalMenuServicesForThe: {
      message: " que oferece os serviços de cardápio digital para o "
    },
    itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe: {
      message: " sendo a sua responsabilidade restrita apenas ao funcionamento correto da "
    },
    andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe: {
      message: "e de suas funcionalidades, conforme este instrumento e o plano contratado pelo "
    },
    notStoppingThe: {
      message: ", não detendo a "
    },
    noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu: {
      message: " nenhuma responsabilidade sobre a criação, customização e divulgação dos conteúdos através do cardápio digital. "
    },
    justLicenseThe: {
      message: " apenas licencia a "
    },
    toThe: {
      message: " aos "
    },
    toThe2: {
      message: " O "
    },
    andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe: {
      message: ", não havendo, entre estas partes, qualquer outra relação, de forma que, não é possível imputar à "
    },
    liabilityForAnyDamageCauseToOthers: {
      message: " a responsabilidade por quaisquer danos causado a outros "
    },
    orToThirdPartiesForActsArisingFrom: {
      message: " ou a terceiros, por atos oriundos de "
    },
    whileUsingTheAvailableFeaturesOfThe: {
      message: " durante o uso das funcionalidades disponíveis da "
    },
    declaresThatHeUnderstandsAndAcceptsThatThe: {
      message: "declara que entende e aceita que a "
    },
    doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe: {
      message: "não pretende substituir o cardápio físico disponibilizado na unidade comercial do "
    },
    beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween: {
      message: "sendo apenas uma solução complementar, a fim de facilitar a comunicação entre "
    },
    atTheTimeOfRegistrationThe: {
      message: "No momento do cadastro, o "
    },
    youCanUseAllTheServicesAvailableOnThe: {
      message: " poderá utilizar todos os serviços disponibilizados na "
    },
    declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse: {
      message: ", declarando, para tanto, terem lido, compreendido e aceitado todos os dispositivos contidos neste Termos de Uso."
    },
    expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom: {
      message: "se compromete a utilizar das funcionalidades da "
    },
    theGarantingOfALicenseToUseThe: {
      message: " a outorga de uma licença de uso da "
    },
    isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe: {
      message: ", esta intransferível, sendo vedado o sublicenciamento, para uso em território nacional ou estrangeiro, pelo tempo em que perdurar a adesão a este termo, restando vedado o uso da "
    },
    inDisagreementWithTheProvisionOfThisInstrument: {
      message: " em desacordo com o previsto neste instrumento."
    },
    isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt: {
      message: " é o único responsável pela segurança de sua senha e pelo uso de seu cadastro na "
    },
    thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr: {
      message: ", por isto, recomendamos que não compartilhem com terceiros tais informações e, caso estas informações sejam, por qualquer motivo, extraviadas ou "
    },
    hacked: {
      message: "hackeadas"
    },
    mustImmediatelyInformThe: {
      message: " deverá informar imediatamente à "
    },
    throughHelloMyTripMenuComInOrderToResolveTheIssue: {
      message: ", através do hello@mytripmenu.com, a fim de que se possa solucionar a questão."
    },
    itIsOnlyUpToThe: {
      message: "Cabe somente aos "
    },
    liableForAnyDamagesCausedToThirdPartiesToOther: {
      message: " responderem por quaisquer danos causados a terceiros, a outros "
    },
    orToItself: {
      message: " ou à própria "
    },
    resultingFromTheUseOfTheFeaturesOfThe: {
      message: ", decorrentes do uso das funcionalidades da "
    },
    shouldNotUseTheServicesAvailableOnThe: {
      message: " não devem utilizar os serviços disponíveis na "
    },
    forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe: {
      message: " para quaisquer fins ou meios ilegais, difamatórios, discriminatórios, abusivos, ofensivos, pornográficos, obscenos, agressivos, injuriosos, vexatórios, enganosos, caluniosos, violentos, vulgares, ou de assédio, ameaça ou uso de falsa identidade, ou seja, qualquer uso escuso que possa prejudicar a "
    },
    others: {
      message: " outros"
    },
    orThirdParties: {
      message: " ou terceiros."
    },
    UnderNoCircumstancesWillThe: {
      message: "Em nenhuma hipótese a "
    },
    willBeLiableForAnyDamagesIncurredByThe: {
      message: "será responsabilizada por quaisquer danos suportados pelos "
    },
    forPossibleTemporaryUnavailabilityOfThe: {
      message: " por eventual indisponibilidade temporária da "
    },
    orOnyContentMadeAvailableByThe: {
      message: "ou, ainda, por qualquer conteúdo disponibilizado pelo "
    },
    onTheDigitalMenu: {
      message: "no cardápio digital. "
    },
    mustHaveAllNecessarySoftwareAndHardwareToAccessThe: {
      message: " deve possuir todos os softwares e hardwares necessários para acessar à "
    },
    includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe: {
      message: ", incluindo, mas não se limitando, a computador ou dispositivo móvel com acesso à Internet, cabendo à "
    },
    onlyToProvideThe: {
      message: ", tão somente, disponibilizar a "
    },
    to2: {
      message: " ao "
    },
    underThisInstrumentToThe: {
      message: "nos termos deste instrumento. Para os "
    },
    accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable: {
      message: "acessarem o cardápio digital, será indispensável dispositivo capaz de realizar leitura de QR Code."
    },
    byThe: {
      message: " pelo "
    },
    isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument: {
      message: "fica condicionado ao seu cadastro prévio, bem como ao respeito às disposições constantes neste instrumento. "
    },
    registration: {
      message: "5. DO CADASTRO"
    },
    soThatThe: {
      message: "Para que os "
    },
    registerInThe: {
      message: " realizem o seu cadastro na "
    },
    mustProvideTo: {
      message: "deverão fornecer à "
    },
    theFollowingData: {
      message: " os seguintes dados"
    },
    corporateName: {
      message: "razão social;"
    },
    unitAddressOf: {
      message: " endereço da unidade do "
    },
    contactNumberInTheEventThatThe: {
      message: "número de contato. Na hipótese de o"
    },
    hireThe: {
      message: "contratar a "
    },
    forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest: {
      message: "para mais unidades, deverá cadastrar os demais endereços do seu interesse. "
    },
    forRegularUseOf: {
      message: "Para a utilização regular da "
    },
    mustRegisterFillingInAllTheDataRequestedByThe: {
      message: " deverá efetuar cadastro, preenchendo todos os dados solicitados pela "
    },
    atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy: {
      message: " no momento do cadastramento, bem como selecionar plano de serviços oferecidos pela "
    },
    itIsTheResponsibilityOf: {
      message: " É de responsabilidade do "
    },
    identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting: {
      message: "identificar no plano contratado o número de unidades contempladas na sua contratação. "
    },
    itIsTheSoleResponsibilityOf: {
      message: "É de exclusiva responsabilidade dos "
    },
    provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe: {
      message: "fornecer, atualizar e garantir a veracidade dos dados cadastrais, não recaindo à "
    },
    anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy: {
      message: " qualquer tipo de responsabilidade civil e criminal resultante de dados inverídicos, incorretos ou incompletos fornecidos pelos "
    },
    reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe: {
      message: " se reserva o direito de utilizar todos os meios válidos e possíveis para identificar os "
    },
    asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf: {
      message: ", bem como de solicitar dados adicionais e documentos que estime serem pertinentes a fim de conferir os dados informados. Neste caso, o uso da "
    },
    isConditionedToTheSendingOfTheDocumentsEventuallyRequested: {
      message: " fica condicionado ao envio dos documentos eventualmente solicitados. "
    },
    ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe: {
      message: "Caso um cadastro seja considerado suspeito de conter dados errôneos ou inverídicos, a "
    },
    reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe: {
      message: " se reserva ao direito de suspender, temporária ou definitivamente, sem necessidade de aviso prévio, o "
    },
    responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe: {
      message: " responsável pelo cadastro. No caso de suspensão não assistirá ao "
    },
    rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages: {
      message: " direito a qualquer tipo de indenização ou ressarcimento por perdas e danos, lucros cessantes ou ainda danos morais."
    },
    inTheEventThatThe: {
      message: "Na hipótese de a "
    },
    carryOutTheCollectionOfPersonalDataFromThe: {
      message: "realizar a coleta de dados pessoais dos "
    },
    willAllowAccessToTheInformationCollectedAndAboutThe: {
      message: "permitirá o acesso às informações coletadas e sobre o "
    },
    carriedOutBy: {
      message: " realizado pela "
    },
    freeOfChargeByRequestingHelloMyTripMenuCom: {
      message: ", de forma gratuita, através de solicitação para hello@mytripmenu.com"
    },
    orByRegisteringAt: {
      message: "ou através de seu cadastro na "
    },
    beingAbleToEditOrDeleteThemAtAnyTime: {
      message: " podendo editá-las ou excluí-las a qualquer tempo. "
    },
    theCollectionOfDataFrom: {
      message: "A coleta dos dados do "
    },
    whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe: {
      message: ", quando ocorrer, visa identificá-lo, bem como habilitá-lo ao correto uso da "
    },
    andWithThatThe: {
      message: ", e, com isto, a "
    },
    canEnsureTheGoodQualityOfTheLicensedServices: {
      message: " poderá assegurar a boa qualidade dos serviços licenciados. "
    },
    byConsentingToTheTermsOfThisInstrumentThe: {
      message: "Ao consentir com os termos do presente instrumento, o "
    },
    expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe: {
      message: " declara expressamente estar ciente que a coleta dos seus dados é primordial para o bom funcionamento da "
    },
    authorizingFromNowOnThe: {
      message: ", autorizando, desde já, o "
    },
    forThe: {
      message: " pela "
    },
    offersThePossibilityOf: {
      message: " oferece a possibilidade de o "
    },
    publicizeYourFigitalMenuThroughQRCodeToYour: {
      message: "divulgar o seu cardápio digital através de QR Code para os seus "
    },
    withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe: {
      message: "sem a necessidade de cadastro, entretanto, a ausência de cadastro não descaracteriza a inobservância as regras deste instrumento, as quais o "
    },
    mustEnsureCompliance: {
      message: "deverá assegurar o cumprimento. "
    },
    willAccessYourRegistrationIn: {
      message: " acessará o seu cadastro na "
    },
    through: {
      message: " por meio de "
    },
    andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem: {
      message: " e senha, comprometendo-se a não informar a terceiros estes dados, responsabilizando-se integralmente pelo uso que deles seja feito."
    },
    undertakesToNotifyThe: {
      message: " compromete-se a notificar a "
    },
    immediatelyThroughTheContactChannelsMaintainedBy: {
      message: " imediatamente, por meio dos canais de contato mantidos pela "
    },
    at: {
      message: " na "
    },
    regardingAnyUnauthorizedUseOfYourAccountThe: {
      message: ", a respeito de qualquer uso não autorizado de sua conta. O "
    },
    willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou: {
      message: " será o único responsável pelas operações efetuadas em sua conta, uma vez que o acesso só será possível mediante a utilização de senha de seu exclusivo conhecimento."
    },
    regardingAnyKnowledgeOfWrongdoingByOther: {
      message: ", a respeito de qualquer conhecimento de irregularidades de outros "
    },
    thatMayCauseDamageToTheirOwn: {
      message: " que possam ocasionar danos aos próprios "
    },
    ofThe: {
      message: " da "
    },
    toThisOnTo: {
      message: " a esta, a "
    },
    orToThirdParties: {
      message: " ou a terceiros. "
    },
    undertakesToUsTheFeaturesOfThe: {
      message: "se compromete a utilizar das funcionalidades da "
    },
    inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms: {
      message: " com boa-fé, de acordo com a legislação em vigor, a moral e os bons costumes."
    },
    underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe: {
      message: "Em nenhuma hipótese será permitida a cessão, a venda, o aluguel ou outra forma de transferência do cadastro do "
    },
    atItsSoleDiscretion: {
      message: "Ao seu exclusivo critério a "
    },
    mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO: {
      message: " poderá excluir, inabilitar, criar limites no uso do serviço, suspender, bloquear, por tempo indeterminado, sem aviso prévio ou contrapartida indenizatória, cadastros de "
    },
    thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce: {
      message: " que sejam considerados ofensivos, que infrinjam os termos deste instrumento ou a legislação em vigor."
    },
    reservesTheRightNotToAllowNewRegistrationOf: {
      message: " se reserva ao direito de não permitir novo cadastro de "
    },
    thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom: {
      message: " que já tenham sido cancelados, inabilitados, bloqueados, excluídos ou suspensos da "
    },
    theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe: {
      message: " Não se permitirá, ainda, a criação de novos cadastros por pessoas cujos cadastros originais tenham sido cancelados, bloqueados, inabilitados, excluídos ou suspensos por infrações às políticas da "
    },
    orCurrentLegislation: {
      message: " ou à legislação vigente."
    },
    reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA: {
      message: " se reserva o direito de, unilateralmente, sem prévio aviso, anuência ou contrapartida indenizatória, recusar qualquer solicitação de cadastro de um "
    },
    asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration: {
      message: ", bem como cancelar, inabilitar, bloquear, excluir ou suspender o uso de um cadastro previamente aceito."
    },
    byAgreeingToThisInstrumentThe: {
      message: "Ao concordar com o presente instrumento, o "
    },
    declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo: {
      message: "declara estar ciente de que é o único responsável pelo seu cadastro, sendo certo que qualquer prejuízo causado pela inserção de informações desatualizadas, inexatas ou inverídicas, não poderão ser imputados à "
    },
    orThe: {
      message: "ou à"
    },
    functionalities: {
      message: "6. FUNCIONALIDADES:"
    },
    offersTheFollowingFeatures: {
      message: "  oferece as seguintes funcionalidades:"
    },
    aOnlineDigitalMenu: {
      message: "(a) Cardápio digital on line;"
    },
    bViewingProductsWithPphotos: {
      message: "(b) Visualização de produtos com fotos;"
    },
    cOfferingAMultilingualMenuSubjectToAvailableConditions: {
      message: "(c) Oferecimento de cardápio multilínguas, mediante condições disponíveis;"
    },
    dActivationAndDeactivationOfItemsInRealTime: {
      message: "(d) Ativação e inativação de itens em tempo real;"
    },
    eAccessToTheMenuByScanningAQRCode: {
      message: "(e) Acesso a cardápio mediante leitura de QR Code;"
    },
    fCreationOfHighlightsForPromotionalActions: {
      message: "(f) Criação de destaques para ações promocionais."
    },
    whenUsingTheServicesProvidedByThe: {
      message: "quando da utilização dos serviços disponibilizados pela "
    },
    canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe: {
      message: "poderá selecionar URL (Uniform Resource Locator), a fim de determinar o endereço eletrônico que localizará o cardápio digital do "
    },
    ToDefineTheURLThe: {
      message: "Para definição da URL, o "
    },
    mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices: {
      message: "deverá respeitar a propriedade intelectual de terceiros, portanto, não deverá incluir expressão que possa causar confusão ou alusão à serviços de terceiros. "
    },
    understandThatThe: {
      message: "entender que o "
    },
    resourcesWereUsedToCauseConfusionAmongThe: {
      message: "se utilizou de recursos para causar confusão entre os "
    },
    makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe: {
      message: "dificultando a identificação adequada dos serviços e os induzindo a erro, poderá excluir a URL do "
    },
    asWellAsAdoptOtherMeasuresRelevantToTheCase: {
      message: "bem como adotar as demais medidas pertinentes ao caso"
    },
    mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn: {
      message: " poderá alterar o leque de funcionalidades disponíveis de acordo com o plano contrato, conforme previsto em"
    },
    mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe: {
      message: " poderá a qualquer tempo, mediante envio de aviso prévio, sem necessidade de contrapartida indenizatória, editar e/ou excluir as funcionalidades existentes, bem como incluir novas funcionalidades à "
    },
    translations: {
      message: "7. TRADUÇÕES:"
    },
    youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe: {
      message: "poderá utilizar os serviços de tradução, a fim de disponibilizar os seus cardápios nos idiomas de sua escolha. Para tanto, serão cobrados valores pelos serviços, conforme disponibilizado no plano do "
    },
    forTheExecutionOfTranslationServicesThe: {
      message: "Para a execução dos serviços de traduções, a "
    },
    requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe: {
      message: "solicitará no mínimo o prazo de 72h (setenta e duas horas). A depender da extensão do texto a ser traduzido, a "
    },
    withPriorNoticeTo: {
      message: "mediante aviso prévio ao "
    },
    mayExtendTheServiceExecutionPeriodAtItsDiscretion: {
      message: "poderá dilatar a seu critério o prazo de execução do serviço. "
    },
    declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions: {
      message: "declara que entende e aceita que determinadas expressões não serão passíveis de tradução, haja vista são reflexo de determinadas culturas ou regiões específicas. "
    },
    isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe: {
      message: " não se responsabiliza por qualquer dano decorrente de traduções imprecisas no cardápio digital do "
    },
    howeverInTheEventThatThe: {
      message: ". Contudo, na hipótese de o "
    },
    identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe: {
      message: "identificar qualquer erro ou equívoco na tradição, poderá comunicar ao "
    },
    whichMustRespondToTheRequestAsSoonAsPossible: {
      message: "o qual deverá atender a solicitação com a maior brevidade possível. "
    },
    suppertService: {
      message: "8. SERVIÇO DE SUPORTE"
    },
    willSupportThe: {
      message: "prestará suporte aos "
    },
    throughthecontactchannelavailableviaemailhellomytripmenucom: {
      message: " por meio de canal de contato disponível via e-mail hello@mytripmenu.com."
    },
    inSupportRequestsViaEmailTthe: {
      message: "Nas solicitações de suporte via e-mail, a "
    },
    willHaveUpTo72SeventyTwoBusinessHoursToRespond: {
      message: "terá até 72 (setenta e duas) horas úteis para proceder à resposta."
    },
    paymentMethod4: {
      message: "9. MÉTODOS DE PAGAMENTO:"
    },
    priorToAnyChargeBeingMadeByThe: {
      message: "Antes da realização de qualquer cobrança pela "
    },
    willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf: {
      message: "será oferecido período de trial de 07 (sete) dias, para teste gratuito das ferramentas da "
    },
    ifThe: {
      message: "Se o "
    },
    chooseToProceedWithTheContractingOfServicesThe: {
      message: " optar pelo prosseguimento da contratação dos serviços, a "
    },
    makesAvailableTo: {
      message: ">disponibiliza ao "
    },
    oneOfTheFollowingPaymentMethods: {
      message: "um dos métodos de pagamento a seguir:"
    },
    VISACreditCardOr: {
      message: "Cartão de Crédito VISA; ou,"
    },
    MASTERCARDCreditCardOr: {
      message: "Cartão de Crédito MASTERCARD; ou,"
    },
    AMERICANEXPRESSCreditCardOr: {
      message: "Cartão de Crédito AMERICAN EXPRESS; ou,"
    },
    DINNERSCLUBCreditCardOr: {
      message: "Cartão de Crédito DINNERS CLUB; ou,"
    },
    ELOCreditCardOr: {
      message: "Cartão de Crédito ELO, ou;"
    },
    DISCOVERCreditCard: {
      message: "Cartão de Crédito DISCOVER."
    },
    toProcessCreditCardPaymentsItWillBeNecessaryForThe: {
      message: "Para processar os pagamentos através de cartão de crédito, será necessário que o "
    },
    registerOn: {
      message: "cadastre na "
    },
    theFollowingDetailsOfTheChosenCreditCard: {
      message: "os seguintes dados do Cartão de Crédito escolhido:"
    },
    NameOfTheCreditCardHolder: {
      message: "Nome do titular do Cartão de Crédito;"
    },
    CreditCardNumber: {
      message: "Número do Cartão de Crédito;"
    },
    CreditCardFlag: {
      message: "Bandeira do Cartão de Crédito;"
    },
    CreditCardExpirationMonthandYear: {
      message: "Vencimento do Cartão de Crédito (Mês e Ano);"
    },
    CreditCardsecuritynumber: {
      message: "Número de segurança do Cartão de Crédito."
    },
    Paymentsforservicescontractedinthe: {
      message: "Os pagamentos dos serviços contratados na "
    },
    willBe: {
      message: " serão"
    },
    processedthroughtheGETNETplatform: {
      message: " processados através da plataforma GETNET"
    },
    beingnecessarythatall: {
      message: "sendo necessário que todos os "
    },
    alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform: {
      message: " também leiam e aceitem os termos de uso e serviços da plataforma GETNET"
    },
    viaemailaddress: {
      message: "através do endereço eletrônico: "
    },
    Whenhiringthe: {
      message: "Ao contratar a "
    },
    withpaymentbycreditcardorbankslipthe: {
      message: ", com pagamento através de cartão de crédito ou boleto, o "
    },
    expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform: {
      message: "indica expressamente que leu e aceitou todas as condições presentes neste instrumento e nos termos de uso e serviços específicos da plataforma GETNET. "
    },
    AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe: {
      message: "Qualquer contestação de pagamentos efetuados através da plataforma GETNET deverá ser resolvida somente entre os "
    },
    andGETNETnotfallingto: {
      message: " e a GETNET, não recaindo a "
    },
    anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform: {
      message: " qualquer reponsabilidade pelo processamento dos pagamentos efetuados junto à plataforma GETNET."
    },
    TheGETNETplatform: {
      message: "A plataforma GETNET"
    },
    maychargefeesforitsuseanditisonlyuptothe: {
      message: "poderá cobrar taxas pela sua utilização, cabendo somente ao "
    },
    informationaboutthevalueschargedbyGETNET: {
      message: " information about the values charged by GETNET. "
    },
    isonlyresponsiblefordeletingthepaymentdataprovidedbythe: {
      message: " somente se responsabiliza por deletar os dados de pagamento fornecidos pelo "
    },
    fromitsowndatabasedeclaringthe: {
      message: " de sua própria base de dados, declarando o "
    },
    beawarethatitisnotupto: {
      message: " estar ciente de que não compete a "
    },
    promotetheexclusionofsaiddatafromtheGETNETdatabase: {
      message: " promover a exclusão dos referidos dados da base de dados da GETNET. "
    },
    PRIVACYANDDATAPROCESSINGPOLICY: {
      message: "10. POLÍTICA DE PRIVACIDADE E DO TRATAMENTO DOS DADOS:"
    },
    Duringtheuseof: {
      message: "Durante a utilização da "
    },
    willcollectandstoretheinformationprovidedbythe: {
      message: " coletará e armazenará as informações fornecidas pelos "
    },
    underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof: {
      message: ", nos termos deste instrumento, conjuntamente com informações geradas automaticamente, tais como, as características do dispositivo de acesso, do navegador, registros de acesso à aplicação (IP, com data e hora), informações acessadas, telas acessadas, dados de geolocalização, histórico de aplicações, dentre outras, dos "
    },
    whichwillbestoredinthedatabaseandalsointhebrowsercookie: {
      message: ", que serão armazenadas no banco de dados e também no cookie do browser. "
    },
    Thedatacollectedfrom: {
      message: "Os dados coletados dos "
    },
    throughtheuseof: {
      message: ", por meio da utilização da "
    },
    willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe: {
      message: ", serão utilizados para a adequada prestação de serviços, para fins de aprimoramento da navegação do "
    },
    aswellasforadvertisingandstatisticalpurposes: {
      message: ", bem como para fins publicitários e estatísticos."
    },
    declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato: {
      message: "declara compreender, aceitar e consentir que na hipótese de envio de dados pessoais à "
    },
    mustdosounderthetermsofarticle5itemXofLaw13709of2018: {
      message: "deverá realiza-lo nos termos do artigo 5, inciso X, da Lei 13.709 de 2018. "
    },
    Intheeventthatpersonaldataisprovidedbythe: {
      message: "No caso de fornecimento de dados pessoais pelo "
    },
    willbeconsideredconfidentialbythe: {
      message: ", serão considerados confidenciais pela "
    },
    anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas: {
      message: ", e esta se compromete a adotar todos os esforços com o intuito de preservar a segurança de seus sistemas na guarda de tais dados, atendendo aos padrões de segurança estabelecidas no Decreto nº 8.771/2016, tais como:"
    },
    iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability: {
      message: "(i) Utilização de métodos padrões de mercado para criptografar os dados coletados, além de demais formas padrão de encriptação, para garantir sua inviolabilidade;"
    },
    iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments: {
      message: "(ii) Emprego de softwares de alta tecnologia para proteção contra o acesso não autorizado aos sistemas, sendo estes considerados ambientes controlados e de segurança;"
    },
    Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement: {
      message: "(iii) Disponibilização de acesso controlado a locais de armazenamento de dados pessoais apenas a pessoas previamente autorizadas e autenticadas, comprometidas ao sigilo de tais dados, inclusive mediante a assinatura de termo de confidencialidade;"
    },
    ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe: {
      message: "(iv) Aplicação de mecanismos de autenticação de acesso aos registros capazes de individualizar o responsável pelo tratamento e acesso dos dados coletados em decorrência da utilização da"
    },
    vAnonymizationofdatafromthe: {
      message: "(v)  Anonimização dos dados do"
    },
    whensharedwiththirdpartiesnotpartnerswith: {
      message: "ao serem compartilhados com terceiros não parceiros da "
    },
    viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016: {
      message: "(vi) Manutenção do inventário indicando momento, duração, identidade do funcionário ou do responsável pelo acesso e o arquivo objeto, com base nos registros de conexão e de acesso a aplicações, conforme determinado no artigo 13 do Decreto nº 8.771/2016."
    },
    Ifdatacollectionfromthe: {
      message: "Se realizada coletata de dados dos "
    },
    throughtheuse: {
      message: ", por meio da utilização "
    },
    thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow: {
      message: " estes somente poderão ser compartilhados com terceiros, nas hipóteses relacionadas abaixo:"
    },
    iFortheprotectionoftheinterestsof: {
      message: "(i) Para a proteção dos interesses da "
    },
    incaseofconflictincludinglegalclaims: {
      message: "em caso de conflito, inclusive em demandas judiciais;"
    },
    iiIncaseoftransactionsandcorporatechangesinvolvingthe: {
      message: "(ii) Em caso de transações e alterações societárias envolvendo a "
    },
    inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe: {
      message: ", hipótese em que a transferência dos dados será necessária para a continuidade dos serviços ofertados por meio da "
    },
    iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest: {
      message: "(iii) Mediante ordem judicial ou por requerimento de autoridades administrativas que detenham competência legal para sua requisição."
    },
    guaranteesto: {
      message: "garante ao "
    },
    withregardtotheprocessingofpersonaldatathefollowingrights: {
      message: ", no que diz respeito ao processamento de dados pessoais, os seguintes direitos:"
    },
    iConfirmationoftheexistenceofprocessingofyourpersonaldata: {
      message: "(i) A confirmação da existência de tratamento de seus dados pessoais;"
    },
    iiAccesstoyourdatacollectedby: {
      message: "(ii) O acesso aos seus dados coletados pela"
    },
    throughyourownloginorbyrequestinghellomytripmenucom: {
      message: ", através do seu próprio login ou mediante requisição ao hello@mytripmenu.com;"
    },
    iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate: {
      message: "(iii) A correção de seus dados, caso estes estejam incompletos, inexatos ou desatualizados;"
    },
    ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation: {
      message: "(iv) O bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a legislação brasileira aplicável;"
    },
    vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe: {
      message: "(v) A portabilidade dos dados pessoais, para si ou para terceiro, mediante requisição expressa feita pelo "
    },
    viahellomytripmenucom: {
      message: ", através de hello@mytripmenu.com;"
    },
    viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe: {
      message: "(vi) A eliminação dos dados pessoais tratados com o seu consentimento, desde que não haja determinação legal para mantê-los registrados junto à "
    },
    viiObtaininginformationaboutpublicorprivateentitieswithwhichthe: {
      message: "(vii) A obtenção de informações sobre entidades públicas ou privadas com as quais a "
    },
    sharedyourdataand: {
      message: "compartilhou seus dados; e,"
    },
    viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe: {
      message: "(viii) Informações sobre a possibilidade e consequência do não fornecimento do consentimento do "
    },
    youcansendemailto: {
      message: " poderá enviar e-mail para "
    },
    hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata: {
      message: "hello@mytripmenu.com, apontando dúvidas e/ou requerimentos relacionados a seus dados pessoais."
    },
    maydeletethepersonaldatacollectedfrom: {
      message: "poderá excluir os dados pessoais coletados dos "
    },
    iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy: {
      message: "(i) Quando a finalidade para a qual foram coletados seja alcançada; ou, quando deixarem de ser necessários ou pertinentes para o alcance da finalidade, conforme finalidades descritas nos presentes Termos de Uso e Política de Privacidade;"
    },
    iiWhenthe: {
      message: "(ii) Quando o"
    },
    revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe: {
      message: " revogar seu consentimento, nos casos em que este se fizer necessário, requerendo a exclusão à "
    },
    viahellomytripmenucomor: {
      message: "através do hello@mytripmenu.com; ou,"
    },
    iiiIfdeterminedbycompetentauthority: {
      message: "(iii) Caso seja determinado por autoridade competente."
    },
    GENERALPROVISIONS: {
      message: "11. DISPOSIÇÕES GERAIS:"
    },
    AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent: {
      message: "Qualquer cláusula ou condição deste instrumento que, por qualquer razão, venha a ser reputada nula ou ineficaz por qualquer juízo ou tribunal, não afetará a validade das demais disposições destes Termos, as quais permanecerão plenamente válidas e vinculantes, gerando efeitos em sua máxima extensão."
    },
    Thefailureof: {
      message: "A falha da "
    },
    inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines: {
      message: " em exigir quaisquer direitos ou disposições dos presentes Termos não constituirá renúncia, podendo este exercer regularmente o seu direito, dentro dos prazos legais."
    },
    Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe: {
      message: "Todos os materiais, patentes, marcas, registros, domínios, nomes, privilégios, criações, imagens e todos os direitos conexos e relacionados com a "
    },
    anddevelopedby: {
      message: " e desenvolvidos pela "
    },
    areandwillremainthesoleandexclusivepropertyof: {
      message: ", são e permanecerão de única e exclusiva propriedade da "
    },
    agreeingwiththe: {
      message: ", concordando os "
    },
    nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem: {
      message: "em não praticar ato ou fato que, por qualquer modo, prejudique os direitos previstos aqui e tampouco reivindicar qualquer direito ou privilégio sobre os mesmos."
    },
    maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreasonwestronglyrecommendthatyoualwaysvisitthissectionofourSiteandreaditperiodicallyButtocontributetothegoodrelationshipwewillalsosendanemailinformingyouaboutthesechanges: {
      message: "poderá alterar este instrumento a qualquer momento, bastando, para tanto, publicarmos uma versão revisada em nosso site. Por este motivo, recomendamos veementemente que sempre visite esta seção de nosso Site, lendo-a, periodicamente. Mas, para contribuir com o bom relacionamento, também enviaremos um e-mail informando acerca dessas mudanças."
    },
    Thisinstrumentconstitutestheintegralunderstandingbetweenthe: {
      message: "O presente instrumento constitui o entendimento integral entre o "
    },
    andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe: {
      message: "e é regido pelas Leis Brasileiras, ficando eleito o foro da cidade de São Leopoldo/RS como único competente para dirimir questões decorrentes do presente instrumento, com renúncia expressa a qualquer outro foro, por mais privilegiado que seja. "
    },
    recoverPassword: {
      message: "RECUPERAR SENHA"
    },
    receiveNewPassword: {
      message: "Você receberá uma nova senha em seu e-mail, lembre-se de verificar também sua caixa de spam."
    },
    dontWorry: {
      message: "Não se preocupe, nós vamos ajudá-lo a recuperá-lo. Informe seu e-mail de login"
    },
    enterEmail: {
      message: "Digite o e-mail"
    },
    notBeSent: {
      message: "Não foi possível enviar o e-mail, tente novamente."
    },
    pleasureToHaveYou: {
      message: "É um prazer ter você aqui conosco! "
    },
    beforeStarting: {
      message: "Antes de começar a incluir os seus produtos e ver o seu cardápio tomar forma, é necessário realizar a assinatura do plano de manutenção no valor de      "
    },
    BRL99: {
      message: " R$ 99,90      "
    },
    month: {
      message: " por mês.      "
    },
    includesFacilities: {
      message: "Ele inclui facilidades como:      "
    },
    menuWithPhotos: {
      message: " menu completo com fotos;      "
    },
    priceUpdate: {
      message: "atualização de preço em tempo real;      "
    },
    activateDeactivate: {
      message: " ativar e inativar produtos com rapidez;      "
    },
    categorizeFilteReorder: {
      message: "categorizar, filtrar e reordenar produtos;      "
    },
    newFeatures: {
      message: "novas funcionalidades adicionadas a cada semana!      "
    },
    thePlan: {
      message: "assinar o plano"
    },
    company: {
      message: "EMPRESA      "
    },
    fantasyName: {
      message: "NOME FANTASIA      "
    },
    numberAccesses: {
      message: "NÚMERO DE ACESSOS      "
    },
    entryDate: {
      message: "DATA DE ENTRADA      "
    },
    wantLeave:{
      message: "Agora que você já chegou até aqui, tem certeza que deseja ir embora?"
    },
    cancelYourSubscription:{
      message: "Ficamos chateados que você esteja querendo cancelar a sua assinatura do plano MyTripMenu."
    },
    beforeConfirming:{
      message: "Antes de confirmar, preciso te contar que se você for embora os seus clientes não terão mais acesso ao cardápio do seu estabelecimento e o acesso à URL e ao QRCode ficarão indisponíveis até você voltar a assinar o plano."
    },
    goAhead:{
      message: "Se você estiver com certeza disso, vá em frente..."
    },
    unsubscribePlan:{
      message: "cancelar a assinatura do plano"
    },
    dontCancel:{
      message: "não quero mais cancelar"
    },
    noChange:{
      message: "Sem Troco"
    },
    CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180:{
      message: "CAXA LABS SERVICOS DE DESENVOLVIMENTO DE SOFTWARE LTDA, Sociedade Empresária Limitada, inscrita regularmente no CNPJ sob o nº 34.177.633/0001-91, com sede na Rua Felipe Dos Santos, nº 77, 1203 torre A, bairro Padre Reus, na cidade de São Leopoldo/RS, sob o CEP: 93.020-180."
    },
    Delivery: {
      message: "Entrega"
    },
    withdrawal: {
      message: "Retirada"
    },
    Pickup: {
      message: "Retirar no local"
    },
    Catalog: {
      message: "Catálogo"
    },
    Enteremail: {
      message: "Digite o e-mail      "
    },
    emailinvalid: {
      message: "Este e-mail é inválido      "
    },
    Typepassword: {
      message: "Digite a senha      "
    },
    least6digits: {
      message: "Este campo deve ter no mínimo 6 digitos      "
    },
    Cashpayment: {
      message: "A forma de pagamento Dinheiro não pode ser editada"
    },
    Checkpayment: {
      message: "A forma de pagamento Cheque não pode ser editada"
    },
    save: {
      message: "Salvar"
    },
    logIn: {
      message: "LOGAR EM"
    },
    logged: {
      message: "VOCÊ ESTA LOGADO EM"
    },
    enterAShortEasilyIdentifiableName: {
      message: "Digite um nome curto e de fácil identificação"
    },
    highlightssubcategoryCannotBeCopied: {
      message: "A sub-categoria Destaques não pode ser copiada"
    },
    aaaaaaaaaaaaaaaaaa: {
      message: "aaaaaaaaaaaaaaaaaaaaa"
    },
    unableToLoadCards : {
      message: "Cartões não podiam ser carregados"
    },
    invalidUser: {
      message: "Utilizador inválido"
    },
    invalidPassword: {
      message: "Senha inválida"
    },
    inactiveUser: {
      message: "Utilizador inativo"
    }
  }
}
