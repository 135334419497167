import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormPaymentForm from "./formPaymentForm";
import PropTypes from "prop-types";
import ModalMytm from "../../../../../components/modal-mytm/ModalMytm";
import { MuiTextFieldStyled } from "../../../../../components/inputs/mui-inputs/Styles";
import {
  ModalWrapper,
  ModalTitle,
  ModalBody,
  ModalBodyScrollSection,
  ModalFooter,
} from "../../../../../components/modal-mytm/Styles";
import {
  NewFormPaymentButtonSave,
  NewFormPaymentButtonGoBack,
  NewFormPaymentForm,
  NewFormPaymentInputGroupWithToggle,
  NewFormPaymentInputContent,
  NewFormPaymentSubTitle,
  NewFormPaymentGrid,
} from "./Styles";
import Checkbox from "../../../../../components/checkbox/Checkbox";
import ButtonAdd from "../../../../../components/button-add/button-add";
import restFormPayment from "../../../../../api/formpayment/rest-formpayment";
import TranslatorFunction from "../../../../../components/I18n/Translator"

const EditFormPayment = (props) => {
  const saveEditions = TranslatorFunction({ path: 'saveEditions.message' });
  const back = TranslatorFunction({ path: 'back.message' });
  const addNew = TranslatorFunction({ path: 'addNew.message' });
  const cardsAccepted = TranslatorFunction({ path: 'cardsAccepted.message' });
  const pixKey = TranslatorFunction({ path: 'pixKey.message' });
  const language = TranslatorFunction({ path: 'language.message' });
  const pixKey2 = TranslatorFunction({ path: 'pixKey2.message' });

  const { open, onClose, formPayment } = props;
  const [openModal, setOpenModal] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({ ...FormPaymentForm });
  const { register, handleSubmit, reset } = useForm();
  const description = [
    "",
    cardsAccepted,
    cardsAccepted,
    cardsAccepted,
    cardsAccepted,
    "",
    pixKey,
  ];

  const [pix, setPix] = useState({
    name: formPayment?.name === "Pix" ? formPayment?.type[0]?.name : "",
    active: true,
  });

  const disableSubmitButton = () => {
    return form?.touch;
  };

  const putFormPayment = () => {
    setIsLoading(true);
    restFormPayment
      .putFormPayment(formPayment._id, form)
      .then((res) => handleResponseSuccess(res))
      .catch((err) => handleResponseError(err));
  };

  const handleResponseSuccess = () => {
    setForm({ ...FormPaymentForm });
    setOpenModal(false);
    setIsLoading(false);
    onClose();
  };

  const handleResponseError = () => {
    setForm({ ...FormPaymentForm });
    setIsLoading(false);
    setOpenModal(false);
    onClose();
  };

  const handleOnClickSave = () => {
    if (form.name === "Pix") {
      form.type[0] = pix;
    }
    putFormPayment();
  };

  const handleOnClickGoBack = () => {
    setOpenModal(false);
    onClose();
    setForm({ ...FormPaymentForm });
  };

  const handleOnChangeCheckBox = (event) => {
    const { name, value } = event;
    let newForm = { ...form };
    form.touch = true;
    newForm.type.map((payment, index) =>
      payment.name === name ? (payment.active = value) : ""
    );
    setForm(newForm);
  };

  const verify = (finalForm) => {
    if (finalForm.Name === "") return false;

    return true;
  };

  const handleOnClickAdd = (finalForm) => {
    if (verify(finalForm)) {
      let newForm = { ...form };
      form.touch = true;
      const newItem = {
        active: false,
        name: finalForm.Name,
      };

      newForm.type.push(newItem);
      setForm(newForm);
      reset({
        Name: ""
      });
    }
  };

  const handleOnChangeInputPix = (event) => {
    const { value } = event.target;

    let newPix = { ...pix };
    newPix.name = value;

    setPix(newPix);
  };

  useEffect(() => {
    setOpenModal(open);
    setForm(formPayment);

    let newPix = { ...pix };
    newPix.name = formPayment.type ? formPayment?.type[0]?.name : "";
    setPix(newPix);
    //eslint-disable-next-line
  }, [open]);//.translatedName[0].name[0][language])
   
  function nameTraslated(form) {
    let variavel = form?.translatedName;
    if(form?.translatedName !== undefined){
      return variavel[0][language]?.toUpperCase();
    }
  };

  
  return (
    <ModalMytm open={openModal} isLoading={isLoading}>
      <ModalWrapper>
        <ModalTitle>{nameTraslated(form)}</ModalTitle>
        <NewFormPaymentSubTitle>
          {description[form?.idGenericMethod]}
        </NewFormPaymentSubTitle>
        <ModalBody noOverflow style={{ height: "32vh" }}>
          {}
          {form?.name !== "Pix" ? (
            <>
              <form>
                <NewFormPaymentInputContent>
                  <MuiTextFieldStyled
                    name="newMet"
                    label={addNew}
                    variant="outlined"
                    margin="dense"
                    InputProps={register("Name", { required: true })}
                  />
                  <ButtonAdd onClick={handleSubmit(handleOnClickAdd)} />
                </NewFormPaymentInputContent>
              </form>
              <ModalBodyScrollSection>
                <NewFormPaymentForm>
                  <NewFormPaymentGrid>
                    {form.type?.map((itens, index) => (
                      <NewFormPaymentInputGroupWithToggle key={index}>
                        <Checkbox
                          key={form?.type?.length}
                          name={itens.name}
                          value={itens.active}
                          onChange={handleOnChangeCheckBox}
                        >
                          {itens.name}
                        </Checkbox>
                      </NewFormPaymentInputGroupWithToggle>
                    ))}
                  </NewFormPaymentGrid>
                </NewFormPaymentForm>
              </ModalBodyScrollSection>
            </>
          ) : (
            <NewFormPaymentForm>
              <ModalBodyScrollSection>
                <NewFormPaymentInputContent>
                  <MuiTextFieldStyled
                    value={pix.name}
                    name="newMet"
                    label={pixKey2}
                    variant="outlined"
                    margin="dense"
                    onChange={handleOnChangeInputPix}
                  />
                </NewFormPaymentInputContent>
              </ModalBodyScrollSection>
            </NewFormPaymentForm>
          )}
        </ModalBody>

        <ModalFooter>
          <NewFormPaymentButtonSave
            type="button"
            disabled={disableSubmitButton()}
            onClick={handleOnClickSave}
          >
            {saveEditions}
          </NewFormPaymentButtonSave>
          <NewFormPaymentButtonGoBack
            type="button"
            btnType="tertiary"
            onClick={handleOnClickGoBack}
          >
            {back}
          </NewFormPaymentButtonGoBack>
        </ModalFooter>
      </ModalWrapper>
    </ModalMytm>
  );
};

EditFormPayment.defaultProps = {
  formPayment: {},
  open: false,
  onClose: () => {},
};

EditFormPayment.propTypes = {
  formPayment: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EditFormPayment;
