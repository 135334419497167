import React from "react";

import { TableCol } from "../../Styles";
import Toggle from "../../../toggle/Toggle";

import  { Translator } from '../../../../components/I18n';
function StatusCol({ withStatus, obj, statusKey, handleOnClickToggle }) {
  return (
    <>
      {withStatus ? (
        <TableCol colStatus>
          <Toggle
            value={obj[statusKey]}
            onChange={() => handleOnClickToggle(obj)}
            textLeft={< Translator path="active.message" />}
            textRight={< Translator path="inactive.message" />}
          />
        </TableCol>
      ) : null}
    </>
  );
}

export default StatusCol;
