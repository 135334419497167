import styled from "styled-components";

export const ListProductsTableWrapper = styled.div`
  height: calc(100vh - 155px);
  width: 100%;
  overflow: auto;
  table {
    margin-left: 16px;
  }
`;
export const ContainerFilter = styled.div`
  padding-top: 5px;
  margin-left: 16px;
  width: 80%;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 16px;
`;

export const SearchContainer = styled.div`
  position: relative;
  svg {
    position: absolute;
    top: 11px;
    right: 16px;
  }
  .MuiSvgIcon-root {
    display: none;
  }
`;
