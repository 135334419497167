import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import restSubscriptions from '../../../../../../api/subscriptions/rest-subscriptions';

import Card from '../../../../../../components/styled/Card';
import CardSubtitle from '../../../../../../components/styled/CardSubtitle';
import Table from '../../../../../../components/table/Table';
import SignaturesPlanSignInModal from './signatures-plan-sign-in-modal/SignaturesPlanSignInModal';
import SignaturesPlanSignIn from './signatures-plan-sign-in/SignaturesPlanSignIn';
import { SignaturesPlanListParagraph, SignaturesPlanListContent } from './Styles';
import { useAPIErrorContext } from '../../../../../../contexts/APIErrorContext';
import { useBlockLoadingContext } from '../../../../../../contexts/BlockLoaderContext';
import apiErrorHandler from '../../../../../../utils/api-error-handler';
import SignaturesPlanCancelModal from './signatures-plan-cancel-modal/SignaturesPlanCancelModal';

const SignaturesPlansList = ({ cards, onClickedTerms }) => {
  const subTitle = 'Meu plano';
  const tableSubTitle = 'Recibos';
  const description = 'Plano disponível para você:';
  const tableTitles = ['DATA', 'IDENTIFICADOR DA TRANSAÇÃO', 'VALOR', 'STATUS'];
  const paymentReceipts = [];

  const [openModal, setOpenModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const [isActivePlan, setIsActivePlan] = useState(false);
  const [paymentDate, setPaymentDate] = useState('');

  const modalError = useAPIErrorContext();
  const { setIsLoading } = useBlockLoadingContext();

  const handleGetResponseSuccess = (response) => {
    setIsLoading(false);
    const { subscription } = response.data;
    if (subscription !== null) {
      const { payment_date: reponsePaymentDate, plan } = subscription;
      setPaymentDate(`todo dia ${reponsePaymentDate}`);
      setIsActivePlan(plan.status === 'active');
    }
  };

  const tryAgainGetSignaturesSubscriptions = () => {
    setIsLoading(true);
    restSubscriptions.getSubscriptions()
      .then(handleGetResponseSuccess)
      .catch((err) => {
        setIsLoading(false);
        modalError.setMessage('Não foi possível carregar suas assinaturas');
        modalError.setError(apiErrorHandler.handleError(err));
        modalError.setOnClickTryAgainFunc(() => tryAgainGetSignaturesSubscriptions());
        modalError.setOnClickGoBackFunc(() => modalError.setOpen(false));
        modalError.setOpen(true);
      });
  };

  const handleGetResponseError = (err) => {
    setIsLoading(false);
    modalError.setMessage('Não foi possível carregar suas assinaturas');
    modalError.setError(apiErrorHandler.handleError(err));
    modalError.setOnClickTryAgainFunc(() => tryAgainGetSignaturesSubscriptions());
    modalError.setOnClickGoBackFunc(() => modalError.setOpen(false));
    modalError.setOpen(true);
  };

  const getSubscriptions = () => {
    setIsLoading(true);
    restSubscriptions.getSubscriptions()
      .then(handleGetResponseSuccess)
      .catch(handleGetResponseError);
  };

  const handleResponseSuccess = () => {
    setIsLoading(false);
    getSubscriptions();
  };

  const tryAgainPostSignatureSubscription = (value) => {
    setIsLoading(true);
    modalError.setOpen(false);
    restSubscriptions.postSubscription({ cardSecurityCode: value })
      .then(handleResponseSuccess)
      .catch((err) => {
        setIsLoading(false);
        modalError.setMessage('Não foi possível realizar sua assinatura.');
        modalError.setError(apiErrorHandler.handleError(err));
        modalError.setOnClickTryAgainFunc(() => tryAgainPostSignatureSubscription(value));
        modalError.setOnClickGoBackFunc(() => modalError.setOpen(false));
        modalError.setOpen(true);
      });
  };

  const handleResponseError = (err, value) => {
    setIsLoading(false);
    modalError.setMessage('Não foi possível realizar sua assinatura.');
    modalError.setError(apiErrorHandler.handleError(err));
    modalError.setOnClickTryAgainFunc(() => tryAgainPostSignatureSubscription(value));
    modalError.setOnClickGoBackFunc(() => modalError.setOpen(false));
    modalError.setOpen(true);
  };

  const postSignaturesSubscription = (value) => {
    setIsLoading(true);
    restSubscriptions.postSubscription({ cardSecurityCode: value })
      .then(handleResponseSuccess)
      .catch((err) => handleResponseError(err, value));
  };

  const handleOnClickModalSignIn = (value) => {
    setOpenModal(false);
    postSignaturesSubscription(value);
  };

  const handleOnSignInPlan = () => {
    setOpenModal(true);
  };

  const handleOnCancelPlan = () => {
    setOpenCancelModal(true);
  };

  const handleDeleteResponseSuccess = () => {
    setIsLoading(false);
    setIsActivePlan(false);
  };

  const tryAgainOnDeleteSubscription = () => {
    setIsLoading(true);
    setOpenCancelModal(false);
    restSubscriptions.deleteSubscription()
      .then(handleDeleteResponseSuccess)
      .catch((err) => {
        setIsLoading(false);
        modalError.setMessage('Ocorreu um erro ao cancelar sua assinatura');
        modalError.setError(apiErrorHandler.handleError(err));
        modalError.setOnClickTryAgainFunc(() => tryAgainOnDeleteSubscription());
        modalError.setOnClickGoBackFunc(() => modalError.setOpen(false));
        modalError.setOpen(true);
      });
  };

  const handleDeleteResponseError = (err) => {
    setIsLoading(false);
    modalError.setMessage('Ocorreu um erro ao cancelar sua assinatura');
    modalError.setError(apiErrorHandler.handleError(err));
    modalError.setOnClickTryAgainFunc(() => tryAgainOnDeleteSubscription());
    modalError.setOnClickGoBackFunc(() => modalError.setOpen(false));
    modalError.setOpen(true);
  };

  const handleOnConfirmCancel = () => {
    setIsLoading(true);
    setOpenCancelModal(false);
    restSubscriptions.deleteSubscription()
      .then(handleDeleteResponseSuccess)
      .catch(handleDeleteResponseError);
  };

  const handleOnDismissCancel = () => {
    setOpenCancelModal(false);
  };

  useEffect(() => {
    getSubscriptions();
    // eslint-disable-next-line
  }, []);

  const renderPaymentReceipsTable = () => (
    paymentReceipts.length > 0
      ? (
        <>
          <CardSubtitle>
            {tableSubTitle}
          </CardSubtitle>
          <Table
            withActions="false"
            withCategories="false"
            withImage="false"
            titles={tableTitles}
            data={[]}
          />
        </ >
      ) : null
  );

  return (
    <Card>
      <SignaturesPlanListContent>
        <CardSubtitle>
          {subTitle}
        </CardSubtitle>
        <SignaturesPlanListParagraph>
          {description}
        </SignaturesPlanListParagraph>
        <SignaturesPlanSignIn
          planExpDate={paymentDate}
          isActive={isActivePlan}
          onSignInPlan={handleOnSignInPlan}
          onCancelPlan={handleOnCancelPlan}
          onClickedTerms={onClickedTerms}
        />
        {renderPaymentReceipsTable()}
      </SignaturesPlanListContent>
      <SignaturesPlanSignInModal
        open={openModal}
        onClickSignIn={handleOnClickModalSignIn}
        card={cards[0]}
      />
      <SignaturesPlanCancelModal
        open={openCancelModal}
        onConfirmCancel={handleOnConfirmCancel}
        onDismissCancel={handleOnDismissCancel}
      />
    </Card>
  );
};

SignaturesPlansList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClickedTerms: PropTypes.func.isRequired,
};

export default SignaturesPlansList;
