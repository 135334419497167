import React, { useEffect } from "react";
import { Switch, Redirect, useLocation } from "react-router-dom";
import Login from "../pages/login/Login";
import Home from "../pages/domain/Domain";
import ProtectedRoute from "./protected-route/ProtectedRoute";
import ProtectedRouteAuthenticated from "./protected-route-authenticated/ProtectedRouteAuthenticated";
import MenusJSON from "../layouts/mytm/page-side-menu/menus";
import { usePageContext } from "../contexts/PageContext";
import RecoveryPassword from "../pages/recovery-password/RecoveryPassword";

const Routes = () => {
  const { setPageTitle } = usePageContext();
  const location = useLocation();
  const menus = MenusJSON;
  useEffect(() => {
    const { pathname } = location;
    if (pathname !== '/' && !pathname.includes('/login')) {
      const searchText = pathname.substr(5, pathname.length);
      const resultMenu = menus.find((menu) => menu.route.includes(searchText));
      if (resultMenu) {
        setPageTitle(resultMenu.pageTitle);
      }
    }
  }, [location, menus, setPageTitle]);

  return (
    <Switch>
      <ProtectedRouteAuthenticated path="/recovery">
        <RecoveryPassword />
      </ProtectedRouteAuthenticated>
      <ProtectedRouteAuthenticated path="/login">
        <Login />
      </ProtectedRouteAuthenticated>
      <ProtectedRoute path="/home">
        <Home />
      </ProtectedRoute>
      <ProtectedRoute path="/">
        <Home />
      </ProtectedRoute>
      <Redirect to="/login" />
    </Switch>
  );
};

export default Routes;
