import React from 'react';
import './Styles.css';
import TranslatorFunction from '../../../components/I18n/Translator';

const Terms = () => {

  const hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou = TranslatorFunction({ path: "hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou.message" })
  const belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom = TranslatorFunction({ path: "belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom.message" })
  const definitions = TranslatorFunction({ path: "definitions.message" })
  const inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions = TranslatorFunction({ path: "inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions.message" })
  const CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180 = TranslatorFunction({ path: "CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180.message" })
  const plataform = TranslatorFunction({ path: "plataform.message" })
  const webSystemAvailableThroughMyTripMenuComBrOfferedBy = TranslatorFunction({ path: "webSystemAvailableThroughMyTripMenuComBrOfferedBy.message" })
  const andOwnershipOperationAndLiabilityOf = TranslatorFunction({ path: "andOwnershipOperationAndLiabilityOf.message" })
  const users = TranslatorFunction({ path: "users.message" })
  const user = TranslatorFunction({ path: "user.message" })
  const where = TranslatorFunction({ path: "where.message" })
  const allThe = TranslatorFunction({ path: "allThe.message" })
  const canViewAndUseTheServicesOfferedBy = TranslatorFunction({ path: "canViewAndUseTheServicesOfferedBy.message" })
  const establishment = TranslatorFunction({ path: "establishment.message" })
  const establishments = TranslatorFunction({ path: "establishments.message" })
  const legalEntityRegisteredInThe = TranslatorFunction({ path: "legalEntityRegisteredInThe.message" })
  const whichContractsTheServicesOfferedBy = TranslatorFunction({ path: "whichContractsTheServicesOfferedBy.message" })
  const client = TranslatorFunction({ path: "client.message" })
  const customers = TranslatorFunction({ path: "customers.message" })
  const naturalPersonAccessingThe = TranslatorFunction({ path: "naturalPersonAccessingThe.message" })
  const toViewTheMenu = TranslatorFunction({ path: "toViewTheMenu.message" })
  const dataProcessing = TranslatorFunction({ path: "dataProcessing.message" })
  const pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy = TranslatorFunction({ path: "pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy.message" })
  const withPersonalDataOf = TranslatorFunction({ path: "withPersonalDataOf.message" })
  const suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction = TranslatorFunction({ path: "suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction.message" })
  const admission = TranslatorFunction({ path: "admission.message" })
  const thisInstrumentRegulatesTheConditionsOfUseOfThe = TranslatorFunction({ path: "thisInstrumentRegulatesTheConditionsOfUseOfThe.message" })
  const being = TranslatorFunction({ path: "being.message" })
  const aContractBetweenThe = TranslatorFunction({ path: "aContractBetweenThe.message" })
  const andThe = TranslatorFunction({ path: "andThe.message" })
  const theUseOfTheServicesOfferedThrough = TranslatorFunction({ path: "theUseOfTheServicesOfferedThrough.message" })
  const fromThe = TranslatorFunction({ path: "fromThe.message" })
  const expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies = TranslatorFunction({ path: "expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies.message" })
  const the = TranslatorFunction({ path: "the.message" })
  const understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy = TranslatorFunction({ path: "understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy.message" })
  const accordingToInformationAvailableInThePlansAreaOfTheWebsite = TranslatorFunction({ path: "accordingToInformationAvailableInThePlansAreaOfTheWebsite.message" })
  const forTheContractingOfCustomizedPlansThe = TranslatorFunction({ path: "forTheContractingOfCustomizedPlansThe.message" })
  const shouldContactThe = TranslatorFunction({ path: "shouldContactThe.message" })
  const throughItsChannelsForTheElaborationOfASpecificBudget = TranslatorFunction({ path: "throughItsChannelsForTheElaborationOfASpecificBudget.message" })
  const youUnderstandAndAgreeThatThe = TranslatorFunction({ path: "youUnderstandAndAgreeThatThe.message" })
  const useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies = TranslatorFunction({ path: "useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies.message" })
  const ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform = TranslatorFunction({ path: "ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform.message" })
  const caseYou = TranslatorFunction({ path: "caseYou.message" })
  const doNotAgree = TranslatorFunction({ path: "doNotAgree.message" })
  const withTheProvisionsOfThisInstrument = TranslatorFunction({ path: "withTheProvisionsOfThisInstrument.message" })
  const dont = TranslatorFunction({ path: "dont.message" })
  const accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom = TranslatorFunction({ path: "accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom.message" })
  const theseTermsAreAvailableForReadingAtAnyTimeOn = TranslatorFunction({ path: "theseTermsAreAvailableForReadingAtAnyTimeOn.message" })
  const inMessage = TranslatorFunction({ path: "inMessage.message" })
  const whoWeAreAndWhatWeDo = TranslatorFunction({ path: "whoWeAreAndWhatWeDo.message" })
  const isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp = TranslatorFunction({ path: "isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp.message" })
  const and = TranslatorFunction({ path: "and.message" })
  const soThe = TranslatorFunction({ path: "soThe.message" })
  const offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe = TranslatorFunction({ path: "offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe.message" })
  const asForThe = TranslatorFunction({ path: "asForThe.message" })
  const generalConditionsOfUse = TranslatorFunction({ path: "generalConditionsOfUse.message" })
  const onlyProvidesThe = TranslatorFunction({ path: "onlyProvidesThe.message" })
  const whichOffersDigitalMenuServicesForThe = TranslatorFunction({ path: "whichOffersDigitalMenuServicesForThe.message" })
  const itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe = TranslatorFunction({ path: "itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe.message" })
  const andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe = TranslatorFunction({ path: "andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe.message" })
  const notStoppingThe = TranslatorFunction({ path: "notStoppingThe.message" })
  const noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu = TranslatorFunction({ path: "noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu.message" })
  const justLicenseThe = TranslatorFunction({ path: "justLicenseThe.message" })
  const toThe = TranslatorFunction({ path: "toThe.message" })
  const toThe2 = TranslatorFunction({ path: "toThe2.message" })
  const andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe = TranslatorFunction({ path: "andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe.message" })
  const liabilityForAnyDamageCauseToOthers = TranslatorFunction({ path: "liabilityForAnyDamageCauseToOthers.message" })
  const orToThirdPartiesForActsArisingFrom = TranslatorFunction({ path: "orToThirdPartiesForActsArisingFrom.message" })
  const whileUsingTheAvailableFeaturesOfThe = TranslatorFunction({ path: "whileUsingTheAvailableFeaturesOfThe.message" })
  const declaresThatHeUnderstandsAndAcceptsThatThe = TranslatorFunction({ path: "declaresThatHeUnderstandsAndAcceptsThatThe.message" })
  const doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe = TranslatorFunction({ path: "doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe.message" })
  const beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween = TranslatorFunction({ path: "beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween.message" })
  const atTheTimeOfRegistrationThe = TranslatorFunction({ path: "atTheTimeOfRegistrationThe.message" })
  const youCanUseAllTheServicesAvailableOnThe = TranslatorFunction({ path: "youCanUseAllTheServicesAvailableOnThe.message" })
  const declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse = TranslatorFunction({ path: "declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse.message" })
  const inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms = TranslatorFunction({ path: "inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms.message" })
  const undertakesToUsTheFeaturesOfThe = TranslatorFunction({ path: "undertakesToUsTheFeaturesOfThe.message" })
  const expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom = TranslatorFunction({ path: "expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom.message" })
  const theGarantingOfALicenseToUseThe = TranslatorFunction({ path: "theGarantingOfALicenseToUseThe.message" })
  const isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe = TranslatorFunction({ path: "isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe.message" })
  const inDisagreementWithTheProvisionOfThisInstrument = TranslatorFunction({ path: "inDisagreementWithTheProvisionOfThisInstrument.message" })
  const isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt = TranslatorFunction({ path: "isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt.message" })
  const thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr = TranslatorFunction({ path: "thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr.message" })
  const hacked = TranslatorFunction({ path: "hacked.message" })
  const mustImmediatelyInformThe = TranslatorFunction({ path: "mustImmediatelyInformThe.message" })
  const throughHelloMyTripMenuComInOrderToResolveTheIssue = TranslatorFunction({ path: "throughHelloMyTripMenuComInOrderToResolveTheIssue.message" })
  const itIsOnlyUpToThe = TranslatorFunction({ path: "itIsOnlyUpToThe.message" })
  const liableForAnyDamagesCausedToThirdPartiesToOther = TranslatorFunction({ path: "liableForAnyDamagesCausedToThirdPartiesToOther.message" })
  const orToItself = TranslatorFunction({ path: "orToItself.message" })
  const resultingFromTheUseOfTheFeaturesOfThe = TranslatorFunction({ path: "resultingFromTheUseOfTheFeaturesOfThe.message" })
  const shouldNotUseTheServicesAvailableOnThe = TranslatorFunction({ path: "shouldNotUseTheServicesAvailableOnThe.message" })
  const forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe = TranslatorFunction({ path: "forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe.message" })
  const others = TranslatorFunction({ path: "others.message" })
  const orThirdParties = TranslatorFunction({ path: "orThirdParties.message" })
  const UnderNoCircumstancesWillThe = TranslatorFunction({ path: "UnderNoCircumstancesWillThe.message" })
  const willBeLiableForAnyDamagesIncurredByThe = TranslatorFunction({ path: "willBeLiableForAnyDamagesIncurredByThe.message" })
  const forPossibleTemporaryUnavailabilityOfThe = TranslatorFunction({ path: "forPossibleTemporaryUnavailabilityOfThe.message" })
  const orOnyContentMadeAvailableByThe = TranslatorFunction({ path: "orOnyContentMadeAvailableByThe.message" })
  const onTheDigitalMenu = TranslatorFunction({ path: "onTheDigitalMenu.message" })
  const mustHaveAllNecessarySoftwareAndHardwareToAccessThe = TranslatorFunction({ path: "mustHaveAllNecessarySoftwareAndHardwareToAccessThe.message" })
  const includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe = TranslatorFunction({ path: "includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe.message" })
  const onlyToProvideThe = TranslatorFunction({ path: "onlyToProvideThe.message" })
  const to2 = TranslatorFunction({ path: "to2.message" })
  const underThisInstrumentToThe = TranslatorFunction({ path: "underThisInstrumentToThe.message" })
  const accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable = TranslatorFunction({ path: "accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable.message" })
  const theUseOf = TranslatorFunction({ path: "theUseOf.message" })
  const byThe = TranslatorFunction({ path: "byThe.message" })
  const isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument = TranslatorFunction({ path: "isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument.message" })
  const registration = TranslatorFunction({ path: "registration.message" })
  const soThatThe = TranslatorFunction({ path: "soThatThe.message" })
  const registerInThe = TranslatorFunction({ path: "registerInThe.message" })
  const mustProvideTo = TranslatorFunction({ path: "mustProvideTo.message" })
  const theFollowingData = TranslatorFunction({ path: "theFollowingData.message" })
  const corporateName = TranslatorFunction({ path: "corporateName.message" })
  const unitAddressOf = TranslatorFunction({ path: "unitAddressOf.message" })
  const mail = TranslatorFunction({ path: "mail.message" })
  const password = TranslatorFunction({ path: "password.message" })
  const contactNumberInTheEventThatThe = TranslatorFunction({ path: "contactNumberInTheEventThatThe.message" })
  const hireThe = TranslatorFunction({ path: "hireThe.message" })
  const forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest = TranslatorFunction({ path: "forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest.message" })
  const forRegularUseOf = TranslatorFunction({ path: "forRegularUseOf.message" })
  const mustRegisterFillingInAllTheDataRequestedByThe = TranslatorFunction({ path: "mustRegisterFillingInAllTheDataRequestedByThe.message" })
  const atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy = TranslatorFunction({ path: "atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy.message" })
  const itIsTheResponsibilityOf = TranslatorFunction({ path: "itIsTheResponsibilityOf.message" })
  const identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting = TranslatorFunction({ path: "identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting.message" })
  const itIsTheSoleResponsibilityOf = TranslatorFunction({ path: "itIsTheSoleResponsibilityOf.message" })
  const provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe = TranslatorFunction({ path: "provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe.message" })
  const anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy = TranslatorFunction({ path: "anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy.message" })
  const reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe = TranslatorFunction({ path: "reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe.message" })
  const asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf = TranslatorFunction({ path: "asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf.message" })
  const isConditionedToTheSendingOfTheDocumentsEventuallyRequested = TranslatorFunction({ path: "isConditionedToTheSendingOfTheDocumentsEventuallyRequested.message" })
  const ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe = TranslatorFunction({ path: "ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe.message" })
  const reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe = TranslatorFunction({ path: "reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe.message" })
  const responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe = TranslatorFunction({ path: "responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe.message" })
  const rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages = TranslatorFunction({ path: "rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages.message" })
  const inTheEventThatThe = TranslatorFunction({ path: "inTheEventThatThe.message" })
  const carryOutTheCollectionOfPersonalDataFromThe = TranslatorFunction({ path: "carryOutTheCollectionOfPersonalDataFromThe.message" })
  const willAllowAccessToTheInformationCollectedAndAboutThe = TranslatorFunction({ path: "willAllowAccessToTheInformationCollectedAndAboutThe.message" })
  const carriedOutBy = TranslatorFunction({ path: "carriedOutBy.message" })
  const freeOfChargeByRequestingHelloMyTripMenuCom = TranslatorFunction({ path: "freeOfChargeByRequestingHelloMyTripMenuCom.message" })
  const orByRegisteringAt = TranslatorFunction({ path: "orByRegisteringAt.message" })
  const beingAbleToEditOrDeleteThemAtAnyTime = TranslatorFunction({ path: "beingAbleToEditOrDeleteThemAtAnyTime.message" })
  const theCollectionOfDataFrom = TranslatorFunction({ path: "theCollectionOfDataFrom.message" })
  const whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe = TranslatorFunction({ path: "whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe.message" })
  const andWithThatThe = TranslatorFunction({ path: "andWithThatThe.message" })
  const canEnsureTheGoodQualityOfTheLicensedServices = TranslatorFunction({ path: "canEnsureTheGoodQualityOfTheLicensedServices.message" })
  const byConsentingToTheTermsOfThisInstrumentThe = TranslatorFunction({ path: "byConsentingToTheTermsOfThisInstrumentThe.message" })
  const expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe = TranslatorFunction({ path: "expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe.message" })
  const authorizingFromNowOnThe = TranslatorFunction({ path: "authorizingFromNowOnThe.message" })
  const forThe = TranslatorFunction({ path: "forThe.message" })
  const offersThePossibilityOf = TranslatorFunction({ path: "offersThePossibilityOf.message" })
  const publicizeYourFigitalMenuThroughQRCodeToYour = TranslatorFunction({ path: "publicizeYourFigitalMenuThroughQRCodeToYour.message" })
  const withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe = TranslatorFunction({ path: "withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe.message" })
  const mustEnsureCompliance = TranslatorFunction({ path: "mustEnsureCompliance.message" })
  const willAccessYourRegistrationIn = TranslatorFunction({ path: "willAccessYourRegistrationIn.message" })
  const through = TranslatorFunction({ path: "through.message" })
  const LoginFormTitle = TranslatorFunction({ path: "LoginFormTitle.message" })
  const andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem = TranslatorFunction({ path: "andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem.message" })
  const undertakesToNotifyThe = TranslatorFunction({ path: "undertakesToNotifyThe.message" })
  const immediatelyThroughTheContactChannelsMaintainedBy = TranslatorFunction({ path: "immediatelyThroughTheContactChannelsMaintainedBy.message" })
  const at = TranslatorFunction({ path: "at.message" })
  const regardingAnyUnauthorizedUseOfYourAccountThe = TranslatorFunction({ path: "regardingAnyUnauthorizedUseOfYourAccountThe.message" })
  const willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou = TranslatorFunction({ path: "willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou.message" })
  const regardingAnyKnowledgeOfWrongdoingByOther = TranslatorFunction({ path: "regardingAnyKnowledgeOfWrongdoingByOther.message" })
  const thatMayCauseDamageToTheirOwn = TranslatorFunction({ path: "thatMayCauseDamageToTheirOwn.message" })
  const ofThe = TranslatorFunction({ path: "ofThe.message" })
  const toThisOnTo = TranslatorFunction({ path: "toThisOnTo.message" })
  const orToThirdParties = TranslatorFunction({ path: "orToThirdParties.message" })
  const underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe = TranslatorFunction({ path: "underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe.message" })
  const atItsSoleDiscretion = TranslatorFunction({ path: "atItsSoleDiscretion.message" })
  const mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO = TranslatorFunction({ path: "mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO.message" })
  const thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce = TranslatorFunction({ path: "thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce.message" })
  const reservesTheRightNotToAllowNewRegistrationOf = TranslatorFunction({ path: "reservesTheRightNotToAllowNewRegistrationOf.message" })
  const thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom = TranslatorFunction({ path: "thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom.message" })
  const theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe = TranslatorFunction({ path: "theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe.message" })
  const orCurrentLegislation = TranslatorFunction({ path: "orCurrentLegislation.message" })
  const reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA = TranslatorFunction({ path: "reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA.message" })
  const asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration = TranslatorFunction({ path: "asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration.message" })
  const byAgreeingToThisInstrumentThe = TranslatorFunction({ path: "byAgreeingToThisInstrumentThe.message" })
  const declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo = TranslatorFunction({ path: "declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo.message" })
  const orThe = TranslatorFunction({ path: "orThe.message" })
  const functionalities = TranslatorFunction({ path: "functionalities.message" })
  const offersTheFollowingFeatures = TranslatorFunction({ path: "offersTheFollowingFeatures.message" })
  const aOnlineDigitalMenu = TranslatorFunction({ path: "aOnlineDigitalMenu.message" })
  const bViewingProductsWithPphotos = TranslatorFunction({ path: "bViewingProductsWithPphotos.message" })
  const cOfferingAMultilingualMenuSubjectToAvailableConditions = TranslatorFunction({ path: "cOfferingAMultilingualMenuSubjectToAvailableConditions.message" })
  const dActivationAndDeactivationOfItemsInRealTime = TranslatorFunction({ path: "dActivationAndDeactivationOfItemsInRealTime.message" })
  const eAccessToTheMenuByScanningAQRCode = TranslatorFunction({ path: "eAccessToTheMenuByScanningAQRCode.message" })
  const fCreationOfHighlightsForPromotionalActions = TranslatorFunction({ path: "fCreationOfHighlightsForPromotionalActions.message" })
  const whenUsingTheServicesProvidedByThe = TranslatorFunction({ path: "whenUsingTheServicesProvidedByThe.message" })
  const canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe = TranslatorFunction({ path: "canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe.message" })
  const ToDefineTheURLThe = TranslatorFunction({ path: "ToDefineTheURLThe.message" })
  const mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices = TranslatorFunction({ path: "mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices.message" })
  const understandThatThe = TranslatorFunction({ path: "understandThatThe.message" })
  const resourcesWereUsedToCauseConfusionAmongThe = TranslatorFunction({ path: "resourcesWereUsedToCauseConfusionAmongThe.message" })
  const makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe = TranslatorFunction({ path: "makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe.message" })
  const asWellAsAdoptOtherMeasuresRelevantToTheCase = TranslatorFunction({ path: "asWellAsAdoptOtherMeasuresRelevantToTheCase.message" })
  const mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn = TranslatorFunction({ path: "mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn.message" })
  const mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe = TranslatorFunction({ path: "mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe.message" })
  const translations = TranslatorFunction({ path: "translations.message" })
  const youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe = TranslatorFunction({ path: "youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe.message" })
  const forTheExecutionOfTranslationServicesThe = TranslatorFunction({ path: "forTheExecutionOfTranslationServicesThe.message" })
  const requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe = TranslatorFunction({ path: "requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe.message" })
  const withPriorNoticeTo = TranslatorFunction({ path: "withPriorNoticeTo.message" })
  const mayExtendTheServiceExecutionPeriodAtItsDiscretion = TranslatorFunction({ path: "mayExtendTheServiceExecutionPeriodAtItsDiscretion.message" })
  const declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions = TranslatorFunction({ path: "declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions.message" })
  const isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe = TranslatorFunction({ path: "isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe.message" })
  const howeverInTheEventThatThe = TranslatorFunction({ path: "howeverInTheEventThatThe.message" })
  const identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe = TranslatorFunction({ path: "identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe.message" })
  const whichMustRespondToTheRequestAsSoonAsPossible = TranslatorFunction({ path: "whichMustRespondToTheRequestAsSoonAsPossible.message" })
  const suppertService = TranslatorFunction({ path: "suppertService.message" })
  const willSupportThe = TranslatorFunction({ path: "willSupportThe.message" })
  const throughthecontactchannelavailableviaemailhellomytripmenucom = TranslatorFunction({ path: "throughthecontactchannelavailableviaemailhellomytripmenucom.message" })
  const inSupportRequestsViaEmailTthe = TranslatorFunction({ path: "inSupportRequestsViaEmailTthe.message" })
  const willHaveUpTo72SeventyTwoBusinessHoursToRespond = TranslatorFunction({ path: "willHaveUpTo72SeventyTwoBusinessHoursToRespond.message" })
  const paymentMethod4 = TranslatorFunction({ path: "paymentMethod4.message" })
  const priorToAnyChargeBeingMadeByThe = TranslatorFunction({ path: "priorToAnyChargeBeingMadeByThe.message" })
  const willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf = TranslatorFunction({ path: "willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf.message" })
  const ifThe = TranslatorFunction({ path: "ifThe.message" })
  const chooseToProceedWithTheContractingOfServicesThe = TranslatorFunction({ path: "chooseToProceedWithTheContractingOfServicesThe.message" })
  const makesAvailableTo = TranslatorFunction({ path: "makesAvailableTo.message" })
  const oneOfTheFollowingPaymentMethods = TranslatorFunction({ path: "oneOfTheFollowingPaymentMethods.message" })
  const VISACreditCardOr = TranslatorFunction({ path: "VISACreditCardOr.message" })
  const MASTERCARDCreditCardOr = TranslatorFunction({ path: "MASTERCARDCreditCardOr.message" })
  const AMERICANEXPRESSCreditCardOr = TranslatorFunction({ path: "AMERICANEXPRESSCreditCardOr.message" })
  const DINNERSCLUBCreditCardOr = TranslatorFunction({ path: "DINNERSCLUBCreditCardOr.message" })
  const ELOCreditCardOr = TranslatorFunction({ path: "ELOCreditCardOr.message" })
  const DISCOVERCreditCard = TranslatorFunction({ path: "DISCOVERCreditCard.message" })
  const toProcessCreditCardPaymentsItWillBeNecessaryForThe = TranslatorFunction({ path: "toProcessCreditCardPaymentsItWillBeNecessaryForThe.message" })
  const registerOn = TranslatorFunction({ path: "registerOn.message" })
  const theFollowingDetailsOfTheChosenCreditCard = TranslatorFunction({ path: "theFollowingDetailsOfTheChosenCreditCard.message" })
  const NameOfTheCreditCardHolder = TranslatorFunction({ path: "NameOfTheCreditCardHolder.message" })
  const CreditCardNumber = TranslatorFunction({ path: "CreditCardNumber.message" })
  const CreditCardFlag = TranslatorFunction({ path: "CreditCardFlag.message" })
  const CreditCardExpirationMonthandYear = TranslatorFunction({ path: "CreditCardExpirationMonthandYear.message" })
  const CreditCardsecuritynumber = TranslatorFunction({ path: "CreditCardsecuritynumber.message" })
  const Paymentsforservicescontractedinthe = TranslatorFunction({ path: "Paymentsforservicescontractedinthe.message" })
  const willBe = TranslatorFunction({ path: "willBe.message" })
  const processedthroughtheGETNETplatform = TranslatorFunction({ path: "processedthroughtheGETNETplatform.message" })
  const beingnecessarythatall = TranslatorFunction({ path: "beingnecessarythatall.message" })
  const alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform = TranslatorFunction({ path: "alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform.message" })
  const viaemailaddress = TranslatorFunction({ path: "viaemailaddress.message" })
  const Whenhiringthe = TranslatorFunction({ path: "Whenhiringthe.message" })
  const withpaymentbycreditcardorbankslipthe = TranslatorFunction({ path: "withpaymentbycreditcardorbankslipthe.message" })
  const expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform = TranslatorFunction({ path: "expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform.message" })
  const AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe = TranslatorFunction({ path: "AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe.message" })
  const andGETNETnotfallingto = TranslatorFunction({ path: "andGETNETnotfallingto.message" })
  const anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform = TranslatorFunction({ path: "anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform.message" })
  const TheGETNETplatform = TranslatorFunction({ path: "TheGETNETplatform.message" })
  const maychargefeesforitsuseanditisonlyuptothe = TranslatorFunction({ path: "maychargefeesforitsuseanditisonlyuptothe.message" })
  const informationaboutthevalueschargedbyGETNET = TranslatorFunction({ path: "informationaboutthevalueschargedbyGETNET.message" })
  const isonlyresponsiblefordeletingthepaymentdataprovidedbythe = TranslatorFunction({ path: "isonlyresponsiblefordeletingthepaymentdataprovidedbythe.message" })
  const fromitsowndatabasedeclaringthe = TranslatorFunction({ path: "fromitsowndatabasedeclaringthe.message" })
  const beawarethatitisnotupto = TranslatorFunction({ path: "beawarethatitisnotupto.message" })
  const promotetheexclusionofsaiddatafromtheGETNETdatabase = TranslatorFunction({ path: "promotetheexclusionofsaiddatafromtheGETNETdatabase.message" })
  const PRIVACYANDDATAPROCESSINGPOLICY = TranslatorFunction({ path: "PRIVACYANDDATAPROCESSINGPOLICY.message" })
  const Duringtheuseof = TranslatorFunction({ path: "Duringtheuseof.message" })
  const willcollectandstoretheinformationprovidedbythe = TranslatorFunction({ path: "willcollectandstoretheinformationprovidedbythe.message" })
  const underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof = TranslatorFunction({ path: "underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof.message" })
  const whichwillbestoredinthedatabaseandalsointhebrowsercookie = TranslatorFunction({ path: "whichwillbestoredinthedatabaseandalsointhebrowsercookie.message" })
  const Thedatacollectedfrom = TranslatorFunction({ path: "Thedatacollectedfrom.message" })
  const throughtheuseof = TranslatorFunction({ path: "throughtheuseof.message" })
  const willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe = TranslatorFunction({ path: "willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe.message" })
  const aswellasforadvertisingandstatisticalpurposes = TranslatorFunction({ path: "aswellasforadvertisingandstatisticalpurposes.message" })
  const declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato = TranslatorFunction({ path: "declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato.message" })
  const mustdosounderthetermsofarticle5itemXofLaw13709of2018 = TranslatorFunction({ path: "mustdosounderthetermsofarticle5itemXofLaw13709of2018.message" })
  const Intheeventthatpersonaldataisprovidedbythe = TranslatorFunction({ path: "Intheeventthatpersonaldataisprovidedbythe.message" })
  const willbeconsideredconfidentialbythe = TranslatorFunction({ path: "willbeconsideredconfidentialbythe.message" })
  const anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas = TranslatorFunction({ path: "anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas.message" })
  const iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability = TranslatorFunction({ path: "iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability.message" })
  const iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments = TranslatorFunction({ path: "iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments.message" })
  const Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement = TranslatorFunction({ path: "Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement.message" })
  const ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe = TranslatorFunction({ path: "ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe.message" })
  const vAnonymizationofdatafromthe = TranslatorFunction({ path: "vAnonymizationofdatafromthe.message" })
  const whensharedwiththirdpartiesnotpartnerswith = TranslatorFunction({ path: "whensharedwiththirdpartiesnotpartnerswith.message" })
  const viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016 = TranslatorFunction({ path: "viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016.message" })
  const Ifdatacollectionfromthe = TranslatorFunction({ path: "Ifdatacollectionfromthe.message" })
  const throughtheuse = TranslatorFunction({ path: "throughtheuse.message" })
  const thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow = TranslatorFunction({ path: "thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow.message" })
  const iFortheprotectionoftheinterestsof = TranslatorFunction({ path: "iFortheprotectionoftheinterestsof.message" })
  const incaseofconflictincludinglegalclaims = TranslatorFunction({ path: "incaseofconflictincludinglegalclaims.message" })
  const iiIncaseoftransactionsandcorporatechangesinvolvingthe = TranslatorFunction({ path: "iiIncaseoftransactionsandcorporatechangesinvolvingthe.message" })
  const inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe = TranslatorFunction({ path: "inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe.message" })
  const iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest = TranslatorFunction({ path: "iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest.message" })
  const guaranteesto = TranslatorFunction({ path: "guaranteesto.message" })
  const withregardtotheprocessingofpersonaldatathefollowingrights = TranslatorFunction({ path: "withregardtotheprocessingofpersonaldatathefollowingrights.message" })
  const iConfirmationoftheexistenceofprocessingofyourpersonaldata = TranslatorFunction({ path: "iConfirmationoftheexistenceofprocessingofyourpersonaldata.message" })
  const iiAccesstoyourdatacollectedby = TranslatorFunction({ path: "iiAccesstoyourdatacollectedby.message" })
  const throughyourownloginorbyrequestinghellomytripmenucom = TranslatorFunction({ path: "throughyourownloginorbyrequestinghellomytripmenucom.message" })
  const iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate = TranslatorFunction({ path: "iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate.message" })
  const ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation = TranslatorFunction({ path: "ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation.message" })
  const vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe = TranslatorFunction({ path: "vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe.message" })
  const viahellomytripmenucom = TranslatorFunction({ path: "viahellomytripmenucom.message" })
  const viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe = TranslatorFunction({ path: "viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe.message" })
  const viiObtaininginformationaboutpublicorprivateentitieswithwhichthe = TranslatorFunction({ path: "viiObtaininginformationaboutpublicorprivateentitieswithwhichthe.message" })
  const sharedyourdataand = TranslatorFunction({ path: "sharedyourdataand.message" })
  const viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe = TranslatorFunction({ path: "viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe.message" })
  const youcansendemailto = TranslatorFunction({ path: "youcansendemailto.message" })
  const hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata = TranslatorFunction({ path: "hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata.message" })
  const maydeletethepersonaldatacollectedfrom = TranslatorFunction({ path: "maydeletethepersonaldatacollectedfrom.message" })
  const iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy = TranslatorFunction({ path: "iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy.message" })
  const iiWhenthe = TranslatorFunction({ path: "iiWhenthe.message" })
  const revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe = TranslatorFunction({ path: "revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe.message" })
  const viahellomytripmenucomor = TranslatorFunction({ path: "viahellomytripmenucomor.message" })
  const iiiIfdeterminedbycompetentauthority = TranslatorFunction({ path: "iiiIfdeterminedbycompetentauthority.message" })
  const GENERALPROVISIONS = TranslatorFunction({ path: "GENERALPROVISIONS.message" })
  const AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent = TranslatorFunction({ path: "AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent.message" })
  const Thefailureof = TranslatorFunction({ path: "Thefailureof.message" })
  const inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines = TranslatorFunction({ path: "inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines.message" })
  const Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe = TranslatorFunction({ path: "Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe.message" })
  const anddevelopedby = TranslatorFunction({ path: "anddevelopedby.message" })
  const areandwillremainthesoleandexclusivepropertyof = TranslatorFunction({ path: "areandwillremainthesoleandexclusivepropertyof.message" })
  const agreeingwiththe = TranslatorFunction({ path: "agreeingwiththe.message" })
  const nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem = TranslatorFunction({ path: "nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem.message" })
  const maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreason = TranslatorFunction({ path: "maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreasonwestronglyrecommendthatyoualwaysvisitthissectionofourSiteandreaditperiodicallyButtocontributetothegoodrelationshipwewillalsosendanemailinformingyouaboutthesechanges.message" })
  const Thisinstrumentconstitutestheintegralunderstandingbetweenthe = TranslatorFunction({ path: "Thisinstrumentconstitutestheintegralunderstandingbetweenthe.message" })
  const andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe = TranslatorFunction({ path: "andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe.message" })
  const theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy = TranslatorFunction({ path: "theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy.message" })
  const pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders = TranslatorFunction({ path: "pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders.message" })


  return (
    <div
      dir="ltr"
      style={{
        maxWidth: '21.001cm', marginTop: '0.501cm', marginBottom: '0cm', marginLeft: '2cm', marginRight: '2cm',
      }}
    >
      <p className="P10"> </p>
      <p className="P24"><span className="T7">{hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou}</span></p>
      <p className="P15"> </p>
      <p className="P24">
        <span className="T7">{belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom}</span>
        <span className="T11"> </span>
      </p>
      <p className="P25"> </p>
      <p className="P7"><span className="T3">{definitions}</span></p>
      <p className="P11"> </p>
      <p className="P7"><span className="T7">{inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions}</span></p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T2">MY TRIP MENU</span>
        <span className="T7">: </span>
        {CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180}
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T3">{plataform}</span>
        <span className="T7">:</span>
        <span className="T11"> </span>
        <span className="T7">{webSystemAvailableThroughMyTripMenuComBrOfferedBy} </span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T7">{andOwnershipOperationAndLiabilityOf}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T7">{where}</span>
        <span className="T8">{allThe} </span>
        <span className="T3">{users} </span>
        <span className="T7">{canViewAndUseTheServicesOfferedBy} </span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T7">.</span>
      </p>
      <p className="P15"> </p>
      <p className="P7"><span className="T2">{user}:</span></p>
      <ol>
        <li>
          <p className="P6" style={{ marginLeft: '1,27cm' }}>
            <span style={{ marginRight: '8px' }}>a.</span>
            <span className="T3">{establishment}</span>
            <span className="T7">: </span>
            <span className="T8">{legalEntityRegisteredInThe}</span>
            <span className="T3">{plataform}</span>
            <span className="T8">{whichContractsTheServicesOfferedBy}</span>
            <span className="T3">MY TRIP MENU</span>
            <span className="T7"> </span>
            <span className="odfLiEnd" />
            {' '}
          </p>

        </li>
        <li>
          <p className="P6" style={{ marginLeft: '1,27cm' }}>
            <span style={{ marginRight: '8px' }}>b.</span>
            <span className="T3">{client}</span>
            <span className="T7">: </span>
            <span className="T8">{naturalPersonAccessingThe}</span>
            <span className="T2">{plataform}</span>
            <span className="T8">{toViewTheMenu}</span>
            <span className="T2">{establishment}. </span>
            <span className="odfLiEnd" />
            {' '}
          </p>

        </li>
      </ol>
      <p className="P13"> </p>
      <p className="P7">
        <span className="T2">{dataProcessing}:</span>
        <span className="T7">{pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy}</span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T7">{withPersonalDataOf}</span>
        <span className="T2">{user}</span>
        <span className="T7">{suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7"><span className="T3">{admission}</span></p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T7">{thisInstrumentRegulatesTheConditionsOfUseOfThe}</span>
        <span className="T2"> {plataform}</span>
        <span className="T7"> </span>
        <span className="T8">{being}</span>
        <span className="T7">{aContractBetweenThe}</span>
        <span className="T3">{establishment} </span>
        <span className="T7">{andThe}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T7">.{theUseOfTheServicesOfferedThrough}</span>
        <span className="T8">{fromThe}</span>
        <span className="T2">{plataform}</span>
        <span className="T7">{expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies}</span>
      </p>
      <p className="P15"> </p>
      <p className="P26">
        <span className="T16">{the}</span>
        <span className="T17">{establishment}</span>
        <span className="T16">{understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy}</span>
        <span className="T21">MY TRIP MENU</span>
        <span className="T17">, </span>
        <span className="T16">{accordingToInformationAvailableInThePlansAreaOfTheWebsite}</span>
        <a href="http://www.mytripmenu.com" className="Internet_20_link"><span className="Internet_20_link"><span className="T20">www.mytripmenu.com</span></span></a>
        <span className="T20"> .</span>
      </p>
      <p className="P31"> </p>
      <p className="P26">
        <span className="T16">{forTheContractingOfCustomizedPlansThe}</span>
        <span className="T17">{user} </span>
        <span className="T16">{shouldContactThe}</span>
        <span className="T21">MY TRIP MENU</span>
        <span className="T17">, </span>
        <span className="T16">{throughItsChannelsForTheElaborationOfASpecificBudget}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T3">{youUnderstandAndAgreeThatThe}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T3">{useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies}</span>
      </p>
      <p className="P11"> </p>
      <p className="P7"><span className="T3">{ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform}. </span></p>
      <p className="P11"> </p>
      <p className="P7">
        <span className="T3">{theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T3">{pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders}</span>
      </p>
      <p className="P11"> </p>
      <p className="P7">
        <span className="T7">{caseYou}</span>
        <span className="T3">{doNotAgree}</span>
        <span className="T7">{withTheProvisionsOfThisInstrument}</span>
        <span className="T3">{dont}</span>
        <span className="T7">{accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T8">.</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T7">{theseTermsAreAvailableForReadingAtAnyTimeOn}</span>
        <span className="T3">{plataform}, </span>
        <span className="T8">{inMessage}</span>
        <a href="http://www.mytripmenu.com/termos-de-uso" className="Internet_20_link"><span className="Internet_20_link"><span className="T8">www.mytripmenu.com/termos-de-uso</span></span></a>
        <span className="T8">. </span>
        <span className="T14"> </span>
      </p>
      <p className="P15"> </p>
      <p className="P7"><span className="T3">{whoWeAreAndWhatWeDo}</span></p>
      <p className="P9"> </p>
      <p className="P7">
        <span className="T7">{the}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T7">{isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp}</span>
        {' '}
        <span className="T6">{customers} </span>
        {and}
        {' '}
        <span className="T6">{establishments}. </span>
        {soThe}
        {' '}
        <span className="T6">MT TRIP MENU </span>
        {offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe}
        {' '}
        <span className="T6">{client} </span>
        {asForThe}
        {' '}
        <span className="T6">{establishment}. </span>
      </p>
      <p className="P15"> </p>
      <p className="P7"><span className="T3">{generalConditionsOfUse}</span></p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T7">{onlyProvidesThe}</span>
        <span className="T3">{plataform}</span>
        <span className="T7">{whichOffersDigitalMenuServicesForThe}</span>
        <span className="T3">{establishment},</span>
        <span className="T7">{itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe}</span>
        <span className="T3">{plataform}</span>
        <span className="T2"> </span>
        <span className="T7">{andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe}</span>
        <span className="T2">{user}</span>
        <span className="T7">{notStoppingThe}</span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T7">{noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T7">{justLicenseThe}</span>
        <span className="T2">{plataform}</span>
        <span className="T7">{toThe}</span>
        <span className="T3">{establishments}</span>
        <span className="T7">{andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe}</span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T7">{liabilityForAnyDamageCauseToOthers}</span>
        <span className="T3">{users},</span>
        <span className="T7">{orToThirdPartiesForActsArisingFrom}</span>
        <span className="T3">{users}</span>
        <span className="T7">{whileUsingTheAvailableFeaturesOfThe}</span>
        <span className="T3">{plataform}</span>
        <span className="T7">. </span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T3">{establishment} </span>
        <span className="T7">{declaresThatHeUnderstandsAndAcceptsThatThe}</span>
        <span className="T3">MY TRIP MENU </span>
        <span className="T7">{doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe}</span>
        <span className="T3">{establishment}, </span>
        <span className="T7">{beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween}</span>
        <span className="T3">{client} </span>
        <span className="T7">{and} </span>
        <span className="T3">{establishment}. </span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T8">{atTheTimeOfRegistrationThe}</span>
        <span className="T2">{establishment}</span>
        <span className="T8">{youCanUseAllTheServicesAvailableOnThe}</span>
        <span className="T2">{plataform}</span>
        <span className="T8">{declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T2">{establishment}</span>
        <span className="T8"> </span>
        <span className="T7">{undertakesToUsTheFeaturesOfThe}</span>
        <span className="T3">{plataform}</span>
        <span className="T7">{inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T2">{establishment}</span>
        <span className="T8"> </span>
        <span className="T7">{expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T7">{theGarantingOfALicenseToUseThe}</span>
        <span className="T2">{plataform}</span>
        <span className="T7">{isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe}</span>
        <span className="T2">{plataform}</span>
        <span className="T7">{inDisagreementWithTheProvisionOfThisInstrument}</span>
      </p>
      <p className="P13"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T3">{user}</span>
        <span className="T7">{isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt}</span>
        <span className="T2">{plataform}</span>
        <span className="T7">{thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr}</span>
        <span className="T25">{hacked}</span>
        <span className="T7">, {the} </span>
        <span className="T3">{user}</span>
        <span className="T7">{mustImmediatelyInformThe}</span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T7">{throughHelloMyTripMenuComInOrderToResolveTheIssue}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T8">{itIsOnlyUpToThe}</span>
        <span className="T2">{users}</span>
        <span className="T8">{liableForAnyDamagesCausedToThirdPartiesToOther}</span>
        <span className="T2">{users}</span>
        <span className="T8">, {toThe2} </span>
        <span className="T2">{plataform}</span>
        <span className="T8">{orToItself}</span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T8">{resultingFromTheUseOfTheFeaturesOfThe}</span>
        <span className="T2">{plataform}</span>
        <span className="T8">. </span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{the} </span>
        <span className="T2">{users}</span>
        <span className="T8">{shouldNotUseTheServicesAvailableOnThe}</span>
        <span className="T2">{plataform}</span>
        <span className="T8">{forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe}</span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T8">, {others} </span>
        <span className="T2">{users}</span>
        <span className="T8">{orThirdParties}.</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{UnderNoCircumstancesWillThe}</span>
        <span className="T8">{UnderNoCircumstancesWillThe}</span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T7"> </span>
        <span className="T8">{willBeLiableForAnyDamagesIncurredByThe}</span>
        <span className="T2">{users}</span>
        <span className="T8">{forPossibleTemporaryUnavailabilityOfThe}</span>
        <span className="T2">{plataform} </span>
        <span className="T8">{orOnyContentMadeAvailableByThe}</span>
        <span className="T8">{orOnyContentMadeAvailableByThe}</span>
        <span className="T2">{establishment} </span>
        <span className="T8">{onTheDigitalMenu}</span>
        <span className="T8">{onTheDigitalMenu}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T3">{user}</span>
        <span className="T7">{mustHaveAllNecessarySoftwareAndHardwareToAccessThe}</span>
        <span className="T3">{plataform}</span>
        <span className="T7">{includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T7">{onlyToProvideThe}</span>
        <span className="T2">{plataform}</span>
        <span className="T7">{to2}</span>
        <span className="T2">{user}, </span>
        <span className="T7">{underThisInstrumentToThe}</span>
        <span className="T3">{customers} </span>
        <span className="T7">do </span>
        <span className="T3">{establishment} </span>
        <span className="T7">{accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{theUseOf}</span>
        <span className="T3">{plataform}</span>
        <span className="T7">{byThe}</span>
        <span className="T3">{establishment} </span>
        <span className="T7">{isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7"><span className="T3">{registration}</span></p>
      <p className="P20"> </p>
      <p className="P7">
        <span className="T8">{soThatThe}</span>
        <span className="T3">{establishments}</span>
        <span className="T8">{registerInThe}</span>
        <span className="T3">{plataform}, </span>
        <span className="T8">{mustProvideTo}</span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8">{theFollowingData}</span>
        <span className="T12"> </span>
        <span className="T8">(i) {corporateName} (ii) {unitAddressOf}</span>
        <span className="T2">{establishment}</span>
        <span className="T8">; (iii) {mail}; (v) {password}; (vi) CNPJ {and} (vii){contactNumberInTheEventThatThe}</span>
        <span className="T2">{establishment} </span>
        <span className="T8">{hireThe}</span>
        <span className="T2">MY TRIP MENU </span>
        <span className="T8">{forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{forRegularUseOf}</span>
        <span className="T2">{plataform}</span>
        <span className="T8">, {the} </span>
        <span className="T2">{establishment}</span>
        <span className="T8">{mustRegisterFillingInAllTheDataRequestedByThe}</span>
        <span className="T2">{plataform}</span>
        <span className="T8">{atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy}</span>
        <span className="T8">{atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T8">.{itIsTheResponsibilityOf}</span>
        <span className="T2">{establishment} </span>
        <span className="T8">{identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting}</span>
      </p>
      <p className="P16" />
      <p className="P7">
        <span className="T8">{itIsTheSoleResponsibilityOf}</span>
        <span className="T3">{users}</span>
        <span className="T7"> </span>
        <span className="T8">{provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe}</span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8">{anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy}</span>
        <span className="T3">{users}</span>
        <span className="T8">.</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{the} </span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8">{reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe}</span>
        <span className="T2">{establishments}</span>
        <span className="T8">,{asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf}</span>
        <span className="T2">{plataform}</span>
        <span className="T8">{byThe}</span>
        <span className="T2">{establishment}</span>
        <span className="T8">{isConditionedToTheSendingOfTheDocumentsEventuallyRequested}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe}</span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8">{reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe}</span>
        <span className="T2">{establishment}</span>
        <span className="T8">{responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe}</span>
        <span className="T2">{establishment}</span>
        <span className="T8">{rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T7">{inTheEventThatThe}</span>
        <span className="T3">MY TRIP MENU </span>
        <span className="T7">{carryOutTheCollectionOfPersonalDataFromThe}</span>
        <span className="T3">{users}, </span>
        <span className="T7">{willAllowAccessToTheInformationCollectedAndAboutThe}</span>
        <span className="T2">{dataProcessing}</span>
        <span className="T7">{carriedOutBy}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T7">{freeOfChargeByRequestingHelloMyTripMenuCom}</span>
        <span className="T3">, </span>
        <span className="T7">{orByRegisteringAt}</span>
        <span className="T2">{plataform}</span>
        <span className="T7">,{beingAbleToEditOrDeleteThemAtAnyTime} </span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{theCollectionOfDataFrom}</span>
        <span className="T3">{user}</span>
        <span className="T8">{whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe}</span>
        <span className="T3">{plataform}</span>
        <span className="T3">{plataform}</span>
        <span className="T8">{andWithThatThe}</span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8">{canEnsureTheGoodQualityOfTheLicensedServices}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{byConsentingToTheTermsOfThisInstrumentThe}</span>
        <span className="T2">{establishment}</span>
        <span className="T8">{expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe}</span>
        <span className="T3">{plataform}</span>
        <span className="T7">{authorizingFromNowOnThe}</span>
        <span className="T2">{dataProcessing}</span>
        <span className="T7">{forThe}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T3">.</span>
      </p>
      <p className="P11"> </p>
      <p className="P7">
        <span className="T8">{the} </span>
        <span className="T2">{plataform}</span>
        <span className="T8">{offersThePossibilityOf}</span>
        <span className="T2">{establishment} </span>
        <span className="T8">{publicizeYourFigitalMenuThroughQRCodeToYour}</span>
        <span className="T2">{customers}, </span>
        <span className="T8">{withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe}</span>
        <span className="T2">{establishment} </span>
        <span className="T8">{mustEnsureCompliance}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{the} </span>
        <span className="T2">{establishment}</span>
        <span className="T8">{willAccessYourRegistrationIn}</span>
        <span className="T2">{plataform}</span>
        <span className="T8">{through}</span>
        <span className="T26">{LoginFormTitle}</span>
        <span className="T8"> {andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{the} </span>
        <span className="T2">{establishment}</span>
        <span className="T8">{undertakesToNotifyThe}</span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8">{immediatelyThroughTheContactChannelsMaintainedBy}</span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8"> {at} </span>
        <span className="T2">{plataform}</span>
        <span className="T8">{regardingAnyUnauthorizedUseOfYourAccountThe}</span>
        <span className="T2">{establishment}</span>
        <span className="T8">{willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{the} </span>
        <span className="T2">{establishment}</span>
        <span className="T8">{undertakesToNotifyThe}</span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8">{immediatelyThroughTheContactChannelsMaintainedBy}</span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8"> {at} </span>
        <span className="T2">{plataform}</span>
        <span className="T8">{regardingAnyKnowledgeOfWrongdoingByOther}</span>
        <span className="T2">{users}</span>
        <span className="T8">{thatMayCauseDamageToTheirOwn}</span>
        <span className="T2">{users}</span>
        <span className="T8"> {ofThe} </span>
        <span className="T2">{plataform}</span>
        <span className="T8">{toThisOnTo}</span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8">{orToThirdParties}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe}</span>
        <span className="T2">{establishment}</span>
        <span className="T8">. </span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{atItsSoleDiscretion}</span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8">{mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO}</span>
        <span className="T2">{establishment}</span>
        <span className="T8">{thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{the} </span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8">{reservesTheRightNotToAllowNewRegistrationOf}</span>
        <span className="T2">{establishment}</span>
        <span className="T8">{thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom}</span>
        <span className="T2">{plataform}</span>
        <span className="T8">.{theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe}</span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8">{orCurrentLegislation}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{the} </span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8">{reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA}</span>
        <span className="T2">{establishment}</span>
        <span className="T8"> {at} </span>
        <span className="T2">{plataform}</span>
        <span className="T8">{asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T7">{byAgreeingToThisInstrumentThe}</span>
        <span className="T2">{establishment}</span>
        <span className="T8"> </span>
        <span className="T7">{declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo}</span>
        <span className="T2">MY TRIP MENU </span>
        <span className="T7">{orThe}</span>
        <span className="T2"> {plataform}</span>
        <span className="T7">.</span>
      </p>
      <p className="P16"> </p>
      <h1 className="P8">
        <span className="T3">{functionalities}</span>
      </h1>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T7">{toThe2} </span>
        <span className="T3">{establishments}</span>
        <span className="T7"> {the} </span>
        <span className="T3">{plataform}</span>
        <span className="T7">{offersTheFollowingFeatures}</span>
        <span className="T11"> </span>
      </p>
      <p className="P21"> </p>
      <p className="P7"><span className="T8">{aOnlineDigitalMenu}</span></p>
      <p className="P7"><span className="T8">{bViewingProductsWithPphotos}</span></p>
      <p className="P7"><span className="T8">{cOfferingAMultilingualMenuSubjectToAvailableConditions}</span></p>
      <p className="P7"><span className="T8">{dActivationAndDeactivationOfItemsInRealTime} </span></p>
      <p className="P7"><span className="T8">{eAccessToTheMenuByScanningAQRCode}</span></p>
      <p className="P7"><span className="T8">{fCreationOfHighlightsForPromotionalActions}</span></p>
      <p className="P16"> </p>
      <p className="P7">
        ]
        <span className="T8">{the} </span>
        <span className="T2">{establishment} </span>
        <span className="T8">{whenUsingTheServicesProvidedByThe}</span>
        <span className="T2">MY TRIP MENU </span>
        <span className="T8">{canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe}</span>
        <span className="T2">{establishment}. </span>
        <span className="T8">{ToDefineTheURLThe}</span>
        <span className="T2">{establishment} </span>
        <span className="T8">{mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices}</span>
      </p>
      <p className="P18" />
      <p className="P7">
        <span className="T8">{inTheEventThatThe} </span>
        <span className="T2">MY TRIP MENU </span>
        <span className="T8">{understandThatThe} </span>
        <span className="T2">{establishment} </span>
        <span className="T8">{resourcesWereUsedToCauseConfusionAmongThe} </span>
        <span className="T2">{customers}, </span>
        <span className="T8">{makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe}</span>
        <span className="T2">{establishment}, </span>
        <span className="T8">{asWellAsAdoptOtherMeasuresRelevantToTheCase}</span>
        <span className="T2">. </span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{toThe2} </span>
        <span className="T3">{establishments}</span>
        <span className="T7"> {the} </span>
        <span className="T3">{plataform}</span>
        <span className="T7">{mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn}</span>
        <span className="T11"> </span>
        <a href="http://www.mytripmenu.com.br" className="Internet_20_link"><span className="Internet_20_link"><span className="T7">www.mytripmenu.com.br</span></span></a>
        <span className="T7"> .</span>
      </p>
      <p className="P22" />
      <p className="P7">
        <span className="T8">{the} </span>
        <span className="T6">MY TRIP MENU</span>
        <span className="T8">{mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe}</span>
        <span className="T2">{plataform}</span>
        <span className="T8">. </span>
      </p>
      <p className="P16" />
      <h1 className="P8">
        <span className="T2">{translations}</span>
        <span className="T11"> </span>
      </h1>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T3">{establishment} </span>
        <span className="T7">{youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe}</span>
        <span className="T3">{establishment}. </span>
      </p>
      <p className="P11"> </p>
      <p className="P7">
        <span className="T7">{forTheExecutionOfTranslationServicesThe}</span>
        <span className="T3">MY TRIP MENU </span>
        <span className="T7">{requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe}</span>
        <span className="T3">MY TRIP MENU, </span>
        <span className="T7">{withPriorNoticeTo}</span>
        <span className="T3">{establishment}, </span>
        <span className="T7">{mayExtendTheServiceExecutionPeriodAtItsDiscretion}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T3">{establishment} </span>
        <span className="T7">{declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T7">{isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe}</span>
        <span className="T3">{establishment}</span>
        <span className="T7">{howeverInTheEventThatThe}</span>
        <span className="T3">{establishment} </span>
        <span className="T7">{identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe}</span>
        <span className="T7">{identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe}</span>
        <span className="T3">{establishment}, </span>
        <span className="T7">{whichMustRespondToTheRequestAsSoonAsPossible}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7"><span className="T3">{suppertService}</span></p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T7"> </span>
        <span className="T7">{willSupportThe}</span>
        <span className="T3">{users}</span>
        <span className="T7"> {ofThe} </span>
        <span className="T3">{plataform}</span>
        <span className="T7">{throughthecontactchannelavailableviaemailhellomytripmenucom}</span>
        <span className="T11"> </span>
        <span className="T7">{inSupportRequestsViaEmailTthe}</span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T7"> </span>
        <span className="T7">{willHaveUpTo72SeventyTwoBusinessHoursToRespond}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7"><span className="T6">{paymentMethod4}</span></p>
      <p className="P32"> </p>
      <p className="P7">
        <span className="T8">{priorToAnyChargeBeingMadeByThe}</span>
        <span className="T2">MY TRIP MENU </span>
        <span className="T8">{to2} </span>
        <span className="T2">{establishment}, </span>
        <span className="T8">{willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf}</span>
        <span className="T2">{plataform}.</span>
      </p>
      <p className="P13"> </p>
      <p className="P7">
        <span className="T8">{ifThe}</span>
        <span className="T2">{establishment}</span>
        <span className="T8">{chooseToProceedWithTheContractingOfServicesThe}</span>
        <span className="T3">{plataform} </span>
        <span className="T8">{makesAvailableTo}</span>
        <span className="T3">{user}</span>
        <span className="T8"> </span>
        <span className="T7">{oneOfTheFollowingPaymentMethods}</span>
      </p>
      <p className="P7"> </p>
      <ol>
        <li>
          <p className="P27" style={{ marginLeft: '0cm' }}>
            <span style={{ marginRight: '8px' }}>a)</span>
            <span className="T16">{VISACreditCardOr}</span>
            <span className="odfLiEnd" />
            {' '}
          </p>

        </li>
        <li>
          <p className="P28" style={{ marginLeft: '0cm' }}>
            <span style={{ marginRight: '7px' }}>b)</span>
            <span className="T16">{MASTERCARDCreditCardOr}</span>
            <span className="odfLiEnd" />
            {' '}
          </p>

        </li>
        <li>
          <p className="P28" style={{ marginLeft: '0cm' }}>
            <span style={{ marginRight: '9px' }}>c)</span>
            <span className="T16">{AMERICANEXPRESSCreditCardOr}</span>
            <span className="odfLiEnd" />
            {' '}
          </p>

        </li>
        <li>
          <p className="P28" style={{ marginLeft: '0cm' }}>
            <span style={{ marginRight: '7px' }}>d)</span>
            <span className="T16">{DINNERSCLUBCreditCardOr}</span>
            <span className="odfLiEnd" />
            {' '}
          </p>

        </li>
        <li>
          <p className="P28" style={{ marginLeft: '0cm' }}>
            <span style={{ marginRight: '8px' }}>e)</span>
            <span className="T16">{ELOCreditCardOr}</span>
            <span className="odfLiEnd" />
            {' '}
          </p>

        </li>
        <li>
          <p className="P28" style={{ marginLeft: '0cm' }}>
            <span style={{ marginRight: '10px' }}>f)</span>
            <span className="T16">{DISCOVERCreditCard}</span>
            <span className="odfLiEnd" />
            {' '}
          </p>

        </li>
      </ol>
      <p className="P33"> </p>
      <p className="P26">
        <span className="T16">{toProcessCreditCardPaymentsItWillBeNecessaryForThe}</span>
        <span className="T22">{user}</span>
        <span className="T23"> </span>
        <span className="T19">{registerOn}</span>
        <span className="T17">{plataform}</span>
        <span className="T19"> </span>
        <span className="T16">{theFollowingDetailsOfTheChosenCreditCard}</span>
      </p>
      <p className="P32"> </p>
      <ol>
        <li>
          <p className="P29" style={{ marginLeft: '0cm' }}>
            <span style={{ marginRight: '8px' }}>a)</span>
            <span className="T16">{NameOfTheCreditCardHolder}</span>
            <span className="odfLiEnd" />
            {' '}
          </p>

        </li>
        <li>
          <p className="P30" style={{ marginLeft: '0cm' }}>
            <span style={{ marginRight: '7px' }}>b)</span>
            <span className="T16">{CreditCardNumber}</span>
            <span className="odfLiEnd" />
            {' '}
          </p>

        </li>
        <li>
          <p className="P30" style={{ marginLeft: '0cm' }}>
            <span style={{ marginRight: '9px' }}>c)</span>
            <span className="T16">{CreditCardFlag}</span>
            <span className="odfLiEnd" />
            {' '}
          </p>

        </li>
        <li>
          <p className="P30" style={{ marginLeft: '0cm' }}>
            <span style={{ marginRight: '7px' }}>d)</span>
            <span className="T16">{CreditCardExpirationMonthandYear}</span>
            <span className="odfLiEnd" />
            {' '}
          </p>

        </li>
        <li>
          <p className="P30" style={{ marginLeft: '0cm' }}>
            <span style={{ marginRight: '8px' }}>e)</span>
            <span className="T16">{CreditCardsecuritynumber}</span>
            <span className="odfLiEnd" />
            {' '}
          </p>

        </li>
      </ol>
      <p className="P32"> </p>
      <p className="P26">
        <span className="T16">{Paymentsforservicescontractedinthe} </span>
        <span className="T17">{plataform}</span>
        <span className="T16">{willBe}</span>
        <span className="T20">{processedthroughtheGETNETplatform}</span>
        <span className="T15"> </span>
        <span className="T20">{beingnecessarythatall}</span>
        <span className="T22">{users}</span>
        <span className="T20">{alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform}</span>
        <span className="T15"> </span>
        <span className="T20">{viaemailaddress}</span>
        <a href="https://site.getnet.com.br/" className="Internet_20_link"><span className="Internet_20_link"><span className="T20">https://site.getnet.com.br/</span></span></a>
        <span className="T20">.</span>
      </p>
      <p className="P31"> </p>
      <p className="P26">
        <span className="T16">{Whenhiringthe}</span>
        <span className="T18">{plataform}</span>
        <span className="T16">{withpaymentbycreditcardorbankslipthe}</span>
        <span className="T18">{user} </span>
        <span className="T16">{expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform}</span>
      </p>
      <p className="P32"> </p>
      <p className="P26">
        <span className="T16">{AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe}</span>
        <span className="T18">{users}</span>
        <span className="T16">{andGETNETnotfallingto}</span>
        <span className="T18">MY TRIP MENU</span>
        <span className="T16">{anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform}</span>
      </p>
      <p className="P32"> </p>
      <p className="P26">
        <span className="T16">{TheGETNETplatform}</span>
        <span className="T15"> </span>
        <span className="T16">{maychargefeesforitsuseanditisonlyuptothe}</span>
        <span className="T18">{user}</span>
        <span className="T16">{informationaboutthevalueschargedbyGETNET}</span>
      </p>
      <p className="P32"> </p>
      <p className="P26">
        <span className="T16">{the} </span>
        <span className="T18">MY TRIP MENU</span>
        <span className="T16">{isonlyresponsiblefordeletingthepaymentdataprovidedbythe}</span>
        <span className="T18">{user}</span>
        <span className="T16">{fromitsowndatabasedeclaringthe}</span>
        <span className="T18">{user}</span>
        <span className="T16">{beawarethatitisnotupto}</span>
        <span className="T18">MY TRIP MENU</span>
        <span className="T16">{promotetheexclusionofsaiddatafromtheGETNETdatabase}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7"><span className="T3">{PRIVACYANDDATAPROCESSINGPOLICY}</span></p>
      <p className="P11"> </p>
      <p className="P7">
        <span className="T8">{Duringtheuseof}</span>
        <span className="T3">{plataform}</span>
        <span className="T8">, {forThe} </span>
        <span className="T3">{establishment}</span>
        <span className="T8">, {the} </span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T8">{willcollectandstoretheinformationprovidedbythe}</span>
        <span className="T2">{users}</span>
        <span className="T8">{underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof}</span>
        <span className="T3">{users}</span>
        <span className="T8">{whichwillbestoredinthedatabaseandalsointhebrowsercookie}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{Thedatacollectedfrom}</span>
        <span className="T3">{users}</span>
        <span className="T8">, {byThe} </span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T8">{throughtheuseof}</span>
        <span className="T2">{plataform}</span>
        <span className="T8">{willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe}</span>
        <span className="T2">{user}</span>
        <span className="T8">{aswellasforadvertisingandstatisticalpurposes}</span>
      </p>
      <p className="P7"><span className="T9"> </span></p>
      <p className="P7">
        <span className="T8">{the} </span>
        <span className="T3">{establishment} </span>
        <span className="T8">{declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato}</span>
        <span className="T2">MY TRIP MENU, </span>
        <span className="T8">{mustdosounderthetermsofarticle5itemXofLaw13709of2018}</span>
      </p>
      <p className="P7"><span className="T8"> </span></p>
      <p className="P7">
        <span className="T8">{Intheeventthatpersonaldataisprovidedbythe}</span>
        <span className="T3">{user}</span>
        <span className="T8">{the}</span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T8">{throughtheuseof}</span>
        <span className="T3">{plataform}</span>
        <span className="T8">{willbeconsideredconfidentialbythe}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T8">{anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7"><span className="T8">{iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability}</span></p>
      <p className="P7"><span className="T8">{iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments}</span></p>
      <p className="P7"><span className="T8">{Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement}</span></p>
      <p className="P7">
        <span className="T8">{ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe}</span>
        <span className="T3">{plataform}</span>
        <span className="T8">;</span>
      </p>
      <p className="P7">
        <span className="T8">{vAnonymizationofdatafromthe}</span>
        <span className="T3">{user} </span>
        <span className="T8">{whensharedwiththirdpartiesnotpartnerswith}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T8">; {and},</span>
      </p>
      <p className="P7"><span className="T8">{viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016}</span></p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{Ifdatacollectionfromthe}</span>
        <span className="T3">{users},</span>
        <span className="T8"> {forThe} </span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T8">{throughtheuse}</span>
        <span className="T2">{plataform},</span>
        <span className="T8">{thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{iFortheprotectionoftheinterestsof}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T8">{incaseofconflictincludinglegalclaims}</span>
      </p>
      <p className="P7">
        <span className="T8">{iiIncaseoftransactionsandcorporatechangesinvolvingthe}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T8">{inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe}</span>
        <span className="T2">{plataform}</span>
        <span className="T8">;</span>
      </p>
      <p className="P7"><span className="T8">{iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest}</span></p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T8"> </span>
        <span className="T7">{guaranteesto}</span>
        <span className="T2">{user}</span>
        <span className="T7">{withregardtotheprocessingofpersonaldatathefollowingrights}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7"><span className="T7">{iConfirmationoftheexistenceofprocessingofyourpersonaldata}</span></p>
      <p className="P7">
        <span className="T7">{iiAccesstoyourdatacollectedby}</span>
        <span className="T3">{plataform}</span>
        <span className="T7">{throughyourownloginorbyrequestinghellomytripmenucom}</span>
      </p>
      <p className="P7"><span className="T7">{iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate}</span></p>
      <p className="P7"><span className="T7">{ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation}</span></p>
      <p className="P7">
        <span className="T7">{vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe}</span>
        <span className="T3">{user}</span>
        <span className="T7">{the}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T7">{viahellomytripmenucom}</span>
      </p>
      <p className="P7">
        <span className="T7">{viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T7">;</span>
      </p>
      <p className="P7">
        <span className="T7">{viiObtaininginformationaboutpublicorprivateentitieswithwhichthe}</span>
        <span className="T2">MY TRIP MENU </span>
        <span className="T7">{sharedyourdataand}</span>
      </p>
      <p className="P7">
        <span className="T7">{ viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe}</span>
        <span className="T2">{user}</span>
        <span className="T7">.</span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T2">{user}</span>
        <span className="T7">{youcansendemailto}</span>
        <span className="T11">[</span>
        <span className="T7">{hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T8"> </span>
        <span className="T7">{maydeletethepersonaldatacollectedfrom}</span>
        <span className="T2">{users}</span>
        <span className="T7">: </span>
      </p>
      <p className="P15"> </p>
      <p className="P7"><span className="T7">{iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy}</span></p>
      <p className="P7">
        <span className="T7">{iiWhenthe}</span>
        <span className="T2">{user}</span>
        <span className="T7">{revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe}</span>
        <span className="T3">MY TRIP MENU</span>
        <span className="T7">{viahellomytripmenucomor}</span>
      </p>
      <p className="P7"><span className="T7">{iiiIfdeterminedbycompetentauthority}</span></p>
      <p className="P15"> </p>
      <p className="P7"><span className="T3">{GENERALPROVISIONS}</span></p>
      <p className="P16"> </p>
      <p className="P7"><span className="T8">{AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent}</span></p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T8">{Thefailureof}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T8">{inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines}</span>
      </p>
      <p className="P16"> </p>
      <p className="P7">
        <span className="T7">{Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe}</span>
        <span className="T3">{plataform}</span>
        <span className="T7">{anddevelopedby}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T7">{areandwillremainthesoleandexclusivepropertyof}</span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T7">{agreeingwiththe} </span>
        <span className="T2">{users}</span>
        <span className="T8"> </span>
        <span className="T7">{nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{the} </span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T8"> </span>
        <span className="T7">{maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreason}</span>
      </p>
      <p className="P15"> </p>
      <p className="P7">
        <span className="T7">{Thisinstrumentconstitutestheintegralunderstandingbetweenthe}</span>
        <span className="T2">{user}</span>
        <span className="T7"> {andThe} </span>
        <span className="T2">MY TRIP MENU</span>
        <span className="T8"> </span>
        <span className="T7">{andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe}</span>
      </p>
      <p className="Standard"> </p>
    </div>
  )
};

export default Terms;
