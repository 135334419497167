import React from "react";

import ButtonStar from "../../../button-star/ButtonStar";

import { TableCol, TableButtonStarContent, TableFoodImage } from "../../Styles";

function ImageCol({
  withImage,
  withStarCategory,
  obj,
  handleStarShow,
  handleOnClickStar,
  imageKey,
  defaultNoProductPhoto,
}) {
  return (
    <>
      {withImage ? (
        <TableCol colImage withStar={withStarCategory}>
          {withStarCategory ? (
            <TableButtonStarContent>
              <ButtonStar
                value={handleStarShow(obj)}
                onClick={() => handleOnClickStar(obj)}
              />
            </TableButtonStarContent>
          ) : (
            ""
          )}
          <TableFoodImage img={obj[imageKey] || defaultNoProductPhoto} />
        </TableCol>
      ) : null}
    </>
  );
}

export default ImageCol;
