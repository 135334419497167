const getDefaultTheme = (value) => ({
  name: value || 'defaultTheme',
  colors: {
    mainColor: '#FF5533',
    mainColorDark: '#401E0D',
    mainColorLight: '#FFBBAD',
    mainColorContrast: '#FFFFFF',
    mainColorDisabled: '#FFF1EF',
    textColor: '#1A1A1A',
    textColorLight: '#999999',
    textColorDisabled: '#E6E6E6',
    textColorContrast: '#FFFFFF',
    statusColorDanger: '#FF0000',
    statusColorAlert: '#FF9500',
    statusColorAttention: '#FFD500',
    statusColorSuccess: '#00D991',
    statusColorNeutral: '#00BBFF',
  },
});

const getTheme = async (name) => getDefaultTheme(name);

export default {
  getTheme,
  getDefaultTheme,
};
