import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-right: 12px;
`;

const CheckboxStyle = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: 2px solid ${({ theme, selected }) => (selected ? theme.colors.mainColor : theme.colors.textColorDisabled)};
  border-radius: 4px;
  position: relative;
  
  &::after {
    content: '';
    display: block;
    position: absolute;
    background: ${({ theme, selected }) => (selected ? theme.colors.mainColor : theme.colors.textColorDisabled)};
    border-radius: 2px;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  };
`;

const LabelStyle = styled.label`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
`;

export {
  Container,
  CheckboxStyle,
  LabelStyle,
};
