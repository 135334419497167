import formValidators from '../../../../../../utils/form-validators';

const DefaultCreditCardForm = {
  cardNumber: {
    value: '',
    isRequired: true,
    isValid: false,
    touched: false,
    label: "cardNumber.message",
    placeholder: "0000 0000 0000 0000",
    errorsMsg: {
      required: "thisFieldIsRequired.message",
      cardNumber: "enterAValidCardNumber.message",
    },
    hasErrors: {
      required: true,
      cardNumber: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
      cardNumber: formValidators.validateCardNumber,
    },
  },
  expirationDate: {
    value: '',
    isRequired: true,
    isValid: false,
    touched: false,
    label: 'validity.message',
    placeholder: 'mmyy.message',
    errorsMsg: {
      required:  "thisFieldIsRequired.message",
      invalidDate:  "enterAValidDate.message",
    },
    hasErrors: {
      required: true,
      invalidDate: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
      invalidDate: formValidators.validateCardExpDateMinDate,
    },
  },
  cardholderName: {
    value: '',
    isRequired: true,
    isValid: false,
    touched: false,
    label: "cardholderName.message",
    placeholder: "typeAsWrittenOnCard.message",
    errorsMsg: {
      required: "thisFieldIsRequired.message",
      invalidUserName: "enterTheNameAsItIsWrittenOnTheCard.message",
    },
    hasErrors: {
      required: true,
      invalidUserName: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
      invalidUserName: formValidators.validateCardNameHasTwoWords,
    },
  },
  isValid: false,
};

export default DefaultCreditCardForm;
