import HttpClient from "../http-client";
import { id } from "../helper-client";

const putOperations = async (opId, data) => {
  return HttpClient.put(`operations/${opId}?userId=${id ?? ""}`, data);
};

const fetchOperations = async () =>
  HttpClient.get(`operations?userId=${id ?? ""}`);

export default {
  putOperations,
  fetchOperations,
};
