import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ButtonStartContent,
  CustomIconStarOutline,
  CustomIconStarFilled,
  IconStarDisabled,
} from "./Styles";

const ButtonStar = (props) => {
  const { className, value, disabled, onChange, onClick } = props;
  const [inputValue, setInputValue] = useState(value);

  const handleOnClick = () => {
    setInputValue(!inputValue);
    onChange(inputValue);
    onClick();
  };

  const renderStarEnabled = () => (
    <>
      <CustomIconStarFilled />
      <CustomIconStarOutline />
    </>
  );

  const renderStar = () => (
    <ButtonStartContent
      inputValue={inputValue}
      className={className}
      onClick={handleOnClick}
    >
      {disabled ? <IconStarDisabled /> : renderStarEnabled()}
    </ButtonStartContent>
  );

  return renderStar();
};

ButtonStar.defaultProps = {
  value: false,
  className: "",
  disabled: false,
  onChange: () => {},
  onClick: () => {},
};

ButtonStar.propTypes = {
  value: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default ButtonStar;
