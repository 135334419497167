import styled from "styled-components";
import { IconAdd } from "../../styles/icons";
import Button from "../button/Button";

export const Container = styled.div``;

export const TabButtonContent = styled.div`
  position: absolute;
  margin-top: ${({ isTab }) => (isTab ? "24px" : "0")};
  right: 16px;
`;

export const TabButton = styled(Button)`
  padding: 0 14px;
  display: flex;
`;

export const TabButtonIconAdd = styled(IconAdd)`
  background-color: #ffffff;
  border-radius: 50%;
  padding: 5px;
  position: relative;
  bottom: 0;
  margin-right: 6px;
`;
