import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TableColTitle,
  TableRow,
  TableCol,
  TableEditIcon,
  TableCopyIcon,
  TableFoodImage,
  TableCustom,
  TableButtonStarContent,
  ColCategoriesWrapper,
  TableOrderButtons,
  TableColDivCenter,
  TableDrag,
  TableInput,
} from "./Styles";
import Toggle from "../toggle/Toggle";
import Button from "../button/Button";
import TableInputs from "../inputs/table-input/TableInput";
import ButtonStar from "../button-star/ButtonStar";
import defaultNoProductPhoto from "../../assets/productDefaultImg.png";
import formatters from "../../utils/formatters";
import Tag from "../tag/Tag";
import Tooltip from "../tooltip/Tooltip";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import restProducts from "../../api/products/rest-products";
import restCategories from "../../api/categories/rest-categories";
import restSubCategories from "../../api/subcategories/rest-subcategories";
import DragAndDrop from "../drag-and-drop/Drag-and-drop";
import CurrencyMask from "react-currency-format";
import { Translator } from '../../components/I18n';
import TranslatorFunction from '../I18n/Translator';

const TableDnD = (props) => {

  const language = TranslatorFunction({ path: "language.message" });
  const highlightssubcategoryCannotBeCopied = TranslatorFunction({ path: "highlightssubcategoryCannotBeCopied.message" });

  const {
    data,
    setData,
    keys,
    titles,
    withActions,
    withDup,
    onClickCopy,
    withImage,
    imageKey,
    onClickEdit,
    objectKey,
    withStatus,
    withStarCategory,
    statusKey,
    onClickToogle,
    colKeysAlign,
    colKeysCurrency,
    colTitleKeysAlign,
    colEditableField,
    withCategories,
    withSubCategories,
    categoriesKey,
    subCategoriesKey,
    colKeysMaxWidth,
    onClickStar,
    handleStarShow,
    orderTitles,
    onClickOrder,
    disabledKeys,
    isProduct,
    isCategory,
    isSubCategory,
    withCustomButton,
    buttonText,
    onClickButton,
    isEditableProduct,
    clickOnLineProduct,
    handleOnBlurProduct,
  } = props;

  const [currentOrderActive, setCurrentOrderActive] = useState("");
  const [currentOrderArrowActive, setCurrentArrowActive] = useState("");

  const isDisabledObject = (obj) => {
    const filterResult = disabledKeys.find(
      (objKey) => objKey.key === obj[objectKey]
    );
    return !!(filterResult !== null && filterResult !== undefined);
  };

  const getDisabledObject = (obj) =>
    disabledKeys.find((objKey) => objKey.key === obj[objectKey]);

  const TableInp = (props, keyValue, index) => {
    return isProduct ? (
      <TableInput
        {...props}
        onBlur={() => handleOnBlurProduct(index)}
        key={keyValue}
      />
    ) : (
      <TableInput {...props} key={keyValue} />
    );
  };

  const handleOnClickOrder = (headerTitle, arrow) => {
    setCurrentOrderActive(headerTitle);
    setCurrentArrowActive(arrow);
    onClickOrder(headerTitle, arrow);
  };

  const handleOnClickEdit = (obj) => {
    if (!isDisabledObject(obj)) {
      onClickEdit(obj);
    }
  };

  const handleOnClickToggle = (obj) => {
    onClickToogle(obj);
  };

  const handleOnClickStar = (obj) => {
    onClickStar(obj);
  };

  const formatColValue = (value, key) => {
    const isFormatCurrencyField = colKeysCurrency.includes(key);
    return isFormatCurrencyField
      ? formatters.formatToCurrencyWithMonetaryValue(value)
      : value;
  };

  const renderImageCol = (obj) => {
    return withImage ? (
      <TableCol colImage withStar={withStarCategory}>
        <TableDrag>
          <DragAndDrop />
          {withStarCategory && (
            <TableButtonStarContent>
              <ButtonStar
                value={handleStarShow(obj)}
                onClick={() => handleOnClickStar(obj)}
              />
            </TableButtonStarContent>
          )}
          {Array.isArray(obj[imageKey]) && obj[imageKey].length > 0 ? (
            <TableFoodImage img={obj[imageKey][0]} />
          ) : typeof obj[imageKey] === "string" ? (
            <TableFoodImage img={obj[imageKey]} />
          ) : (
            <TableFoodImage img={defaultNoProductPhoto} />
          )}
        </TableDrag>
      </TableCol>
    ) : null;
  };

  const renderActionsCol = (obj) =>
    withActions ? (
      <TableCol colActions>
        <TableColDivCenter>
          {isDisabledObject(obj) ? (
            <Tooltip message={getDisabledObject(obj).tooltip} position="left">
              <TableEditIcon
                isdisabled={(
                  isDisabledObject(obj) &&
                  getDisabledObject(obj).action === "edit"
                ).toString()}
                onClick={() => handleOnClickEdit(obj)}
              />
            </Tooltip>
          ) : (
            <TableEditIcon
              isdisabled={isDisabledObject(obj).toString()}
              onClick={() => handleOnClickEdit(obj)}
            />
          )}
          {isDisabledObject(obj) && withDup ? (
            <Tooltip
              message={highlightssubcategoryCannotBeCopied}
              position="left"
            >
              <TableCopyIcon
                isdisabled={(
                  isDisabledObject(obj) &&
                  getDisabledObject(obj).action === "edit"
                ).toString()}
                onClick={() => handleOnClickEdit(obj)}
              />
            </Tooltip>
          ) : (
            <TableCopyIcon
              isdisabled={isDisabledObject(obj).toString()}
              onClick={() => onClickCopy(obj)}
            />
          )}
        </TableColDivCenter>
      </TableCol>
    ) : null;

  const renderButtonCol = (obj) =>
    withCustomButton ? (
      <TableCol colActions>
        <Button onClick={onClickButton} large>
          {buttonText}
        </Button>
      </TableCol>
    ) : null;

  const renderStatusCol = (obj) =>
    withStatus ? (
      <TableCol colStatus>
        <Toggle
          value={obj[statusKey]}
          onChange={() => handleOnClickToggle(obj)}
          textLeft={<Translator path="active.message" />}
          textRight={<Translator path="inactive.message" />}
        />
      </TableCol>
    ) : null;

  const getTagsOfCategories = (key, categories) => {
    const arrCategoriesTags = categories.map((category) => {
      const categoryName = category && category.translatedName && category.translatedName.length > 0 &&  category?.translatedName?.[0]?.name?.[0]?.[language] !== undefined ? (category?.translatedName?.[0]?.name?.[0]?.[language]?.toUpperCase() || "") : `${category.name} (Não traduzido)`;
      return (
        <Tag key={`${key}-${category._id}`} withButton={false} type="secondary">
          {categoryName}
        </Tag>
      );
    });
    return arrCategoriesTags;
  };

  const renderCategories = (obj) => {
    let categories = isProduct ? obj[subCategoriesKey] : obj[categoriesKey];

    if (categories.length > 0) {
      const subCat = categories;
      categories = [];
      // eslint-disable-next-line
      subCat.map((value) => {
        categories.push(value.category);
      });
      categories = categories.filter(
        (value, index, array) =>
          array.findIndex((x) => x._id === value._id) === index
      );
    } else {
      categories = Array(categories);
    }

    const key = obj[objectKey];

    if (categories.length > 0) {
      return getTagsOfCategories(key, categories);
    }
  };

  const renderCategoriesCol = (obj) =>
    withCategories ? (
      <TableCol colCategories>
        <ColCategoriesWrapper>{renderCategories(obj)}</ColCategoriesWrapper>
      </TableCol>
    ) : null;

  const getTagsOfSubCategories = (key, subCategories) => {
    const arrSubCategoriesTags = subCategories.map((subCategory) => {
      
      return (
      <Tag
        key={`${key}-${subCategory._id}`}
        withButton={false}
        type="secondary"
      >
        {subCategory && subCategory.translatedName && subCategory.translatedName.length > 0 ? (subCategory?.translatedName?.[0]?.name?.[0]?.[language]?.toUpperCase() || ""): `${subCategory.name} (Não traduzido)`}
      </Tag>
    )});
    return arrSubCategoriesTags;
  };

  const renderSubCategories = (obj) => {
    const subCategories = obj[subCategoriesKey];
    const key = obj[objectKey];
    if (subCategories.length > 0) {
      return getTagsOfSubCategories(key, subCategories);
    }
    return "";
  };

  const renderSubCategoriesCol = (obj) =>
    withSubCategories ? (
      <TableCol colSubCategories>
        <ColCategoriesWrapper>{renderSubCategories(obj)}</ColCategoriesWrapper>
      </TableCol>
    ) : null;

  const renderTableHeadColumns = () =>
    titles.map((title, index) => {
      const keyValue = `th-${index}`;
      const textAlign = colTitleKeysAlign[index];
      const currentOrderTitle = orderTitles.find(
        (obj) => obj.titleKeyIndex === index
      );
      return (
        <TableColTitle key={keyValue} textAlign={textAlign}>
          {title}
          {currentOrderTitle !== undefined &&
            currentOrderTitle.titleKey === title ? (
            <TableOrderButtons
              isUpActive={
                title === currentOrderActive && currentOrderArrowActive === "up"
              }
              isDownActive={
                title === currentOrderActive &&
                currentOrderArrowActive === "down"
              }
              tooltipUpText={currentOrderTitle.tooltipUpText}
              tooltipDownText={currentOrderTitle.tooltipDownText}
              onClickUp={() => handleOnClickOrder(title, "up")}
              onClickDown={() => handleOnClickOrder(title, "down")}
            />
          ) : null}
        </TableColTitle>
      );
    });

  const renderTableBodyColumns = (dataObject, indexOut) =>
    keys.map((objKey, index) => {
      const keyValue = `c-${dataObject._id}-${objKey}`;
      const textAlign = colKeysAlign[objKey];
      const maxWidth = colKeysMaxWidth[objKey];

      let field = '';
      
      if (dataObject.translatedComplementGroup !== undefined && dataObject.translatedComplementGroup.length) {
        field = "translatedComplementGroup";
      } else if (dataObject.translatedComplement !== undefined && dataObject.translatedComplement.length) {
        field = "translatedComplement";
      } else if (dataObject.translatedName !== undefined && dataObject.translatedName.length) {
        field = "translatedName";
      }
      const name = field ? (dataObject[field][0][objKey][0][language] ? (dataObject[field][0][objKey][0][language] !== undefined ? dataObject[field][0][objKey][0][language] : dataObject[objKey]) : dataObject[objKey])  : dataObject[objKey];
      const fomattedValue = formatColValue(name, objKey);

      if (isProduct && objKey === "value") {
        return (
          <TableCol key={keyValue} maxWidth={maxWidth} textAlign={textAlign}>
            {isEditableProduct[indexOut] ? (
              <TableInputs
                props={props}
                index={indexOut}
                isProduct={isProduct}
                handleOnBlurProduct={handleOnBlurProduct}
                productId={dataObject._id}
                value={fomattedValue}
              />
            ) : (
              <span onClick={() => clickOnLineProduct(indexOut)}>
                {fomattedValue}
              </span>
            )}
          </TableCol>
        );
      }

      return (
        <TableCol
          key={keyValue}
          maxWidth={maxWidth}
          textAlign={textAlign}
          onClick={() => clickOnLineProduct(indexOut)}
        >
          {colEditableField[index] ? (
            <CurrencyMask
              placeholder="0"
              mask="xxx"
              value={fomattedValue}
              customInput={(props) => TableInp(props, keyValue)}
            />
          ) : (
            fomattedValue
          )}
        </TableCol>
      );
  });

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setData(items);

    isProduct && putProductOrder(items);
    isCategory && putCategoryOrder(items);
    isSubCategory && putSubCategoryOrder(items);
  };

  const putProductOrder = (items) => {
    const newValues = items.map((c, newIndc) => ({
      _id: c._id,
      index: newIndc,
    }));
    restProducts.putProductOrder({ products: newValues });
  };

  const putCategoryOrder = (items) => {
    const newValues = items.map((c, newIndc) => ({
      _id: c._id,
      index: newIndc,
    }));
    restCategories.putCategoryOrder({ categories: newValues });
  };

  const putSubCategoryOrder = (items) => {
    const newValues = items.map((c, newIndc) => ({
      _id: c._id,
      index: newIndc,
    }));

    restSubCategories.putSubCategoryOrder({ subcategories: newValues });
  };

  const renderTableBodyRows = () =>
    data.map((obj, index) => {
      const keyValue = `d-${obj._id}`;
      return (
        <Draggable key={keyValue} draggableId={keyValue} index={index}>
          {(provided) => (
            <TableRow
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {renderImageCol(obj)}
              {renderTableBodyColumns(obj, index)}
              {renderCategoriesCol(obj)}
              {renderSubCategoriesCol(obj)}
              {renderStatusCol(obj)}
              {renderActionsCol(obj)}
              {renderButtonCol(obj)}
            </TableRow>
          )}
        </Draggable>
      );
    });

  const renderTableHead = () => (
    <>
      <thead>
        <TableRow>{renderTableHeadColumns()}</TableRow>
      </thead>
    </>
  );

  const renderTableBody = (provided) => (
    <>
      <tbody {...provided.droppableProps} ref={provided.innerRef}>
        {renderTableBodyRows()}
      </tbody>
    </>
  );

  return (
    <>
      <TableCustom>
        {renderTableHead()}
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="table">
            {(provided) => renderTableBody(provided)}
          </Droppable>
        </DragDropContext>
      </TableCustom>
    </>
  );
};

TableDnD.defaultProps = {
  withActions: true,
  withDup: false,
  withStatus: false,
  withImage: false,
  withStarCategory: true,
  withCategories: false,
  withCustomButton: false,
  imageKey: "",
  categoriesKey: "",
  subCategoriesKey: "",
  statusKey: "",
  objectKey: "",
  buttonText: "",
  colKeysAlign: {},
  colKeysMaxWidth: {},
  colTitleKeysAlign: [],
  colEditableField: [],
  colKeysCurrency: [],
  onClickEdit: () => { },
  onClickCopy: () => { },
  onClickToogle: () => { },
  onClickStar: () => { },
  handleStarShow: () => { },
  onClickOrder: () => { },
  onClickButton: () => { },
  orderTitles: [],
  disabledKeys: [],
  isProduct: false,
  isCategory: false,
  isSubCategory: false,
};

TableDnD.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  colKeysAlign: PropTypes.objectOf(PropTypes.string),
  colKeysMaxWidth: PropTypes.objectOf(PropTypes.string),
  colTitleKeysAlign: PropTypes.arrayOf(PropTypes.string),
  colEditableField: PropTypes.arrayOf(PropTypes.bool),
  colKeysCurrency: PropTypes.arrayOf(PropTypes.string),
  orderTitles: PropTypes.arrayOf(
    PropTypes.shape({
      colKey: PropTypes.string,
      titleKey: PropTypes.string,
      titleKeyIndex: PropTypes.number,
      tooltipUpText: PropTypes.string,
      tooltipDownText: PropTypes.string,
    })
  ),
  withActions: PropTypes.bool,
  withDup: PropTypes.bool,
  withImage: PropTypes.bool,
  withStatus: PropTypes.bool,
  withCategories: PropTypes.bool,
  withStarCategory: PropTypes.bool,
  categoriesKey: PropTypes.string,
  subCategoriesKey: PropTypes.string,
  imageKey: PropTypes.string,
  statusKey: PropTypes.string,
  objectKey: PropTypes.string,
  onClickEdit: PropTypes.func,
  onClickCopy: PropTypes.func,
  onClickToogle: PropTypes.func,
  onClickStar: PropTypes.func,
  onClickOrder: PropTypes.func,
  handleStarShow: PropTypes.func,
  disabledKeys: PropTypes.arrayOf(PropTypes.object),
  isProduct: PropTypes.bool,
  isCategory: PropTypes.bool,
  isSubCategory: PropTypes.bool,
};

export default TableDnD;
