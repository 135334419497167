export default {
  translations: {
    language: {
      message: 'en',
    },
    LoginFormTitle: {
      message: 'Login'
    },
    LoginFormSubtitle: {
      message: 'Hello, enter your email and password to login:'
    },
    LoginFormErroMessage: {
      message: 'Unable to login, please try again.'
    },
    LoginFormButton: {
      message: 'Sing in'
    },
    LoginFormButtonForgotPassword: {
      message: 'Forgot password'
    },
    password: {
      message: 'Password'
    },
    confirmPassword: {
      message: 'Confirm password'
    },
    mail: {
      message: 'Mail'
    },
    myEstablishment: {
      message: 'My Establishment'
    },
    parameterization: {
      message: 'Parameterization'
    },
    itemRegistration: {
      message: 'Item Registration'
    },
    orders: {
      message: 'Orders'
    },
    discountCoupons: {
      message: 'Discount coupons'
    },
    logout: {
      message: 'logout'
    },
    establishmentDetails: {
      message: 'Establishment details'
    },
    subscriptions: {
      message: 'Subscriptions'
    },
    aboutTheEstablishment: {
      message: 'About the establishment'
    },
    mandatoryFilling: {
      message: 'mandatory filling'
    },
    tradeName: {
      message: 'Trade name (name by which your establishment is known)'
    },
    companyCorporateName: {
      message: 'Company name'
    },
    informTheCompanyCorporateName: {
      message: 'Inform the company corporate name'
    },
    pixel: {
      message: 'Pixel'
    },
    informPixel: {
      message: 'inform pixel'
    },
    invalidCnpj: {
      message: 'Invalid CNPJ'
    },
    invalidCep: {
      message: 'Invalid CEP'
    },
    state: {
      message: 'State'
    },
    city: {
      message: 'City'
    },
    cityUpper: {
      message: 'CITY'
    },
    neighborhoods: {
      message: 'Neighborhoods'
    },
    street: {
      message: 'Street'
    },
    number: {
      message: 'Number'
    },
    informTheNumber: {
      message: 'inform the number'
    },
    complement: {
      message: 'Complement'
    },
    complementUpper: {
      message: 'COMPLEMENT'
    },
    URLToYourMenu: {
      message: 'URL to your menu'
    },
    emailToLoginToTheManager: {
      message: 'Email to login to the manager'
    },
    name: {
      message: 'Name'
    },
    nameUpper: {
      message: 'NAME'
    },
    lastName: {
      message: 'Last name'
    },
    contactEmail: {
      message: 'Contact email'
    },
    cellphoneNumberWhatsApp: {
      message: 'cellphone/WhatsApp'
    },
    thisFieldIsRequired: {
      message: 'This field is required.'
    },
    enterYourCreditCardDetails: {
      message: 'Enter your credit card details:'
    },
    thisIsTheCurrentPaymentMethod: {
      message: 'This is the current payment method:'
    },
    paymentMethod: {
      message: 'Payment method:'
    },
    paymentMethod2: {
      message: 'Payment method'
    },
    paymentMethod3: {
      message: 'PAYMENT METHODS'
    },
    cardNumber: {
      message: 'Card number.'
    },
    enterAValidCardNumber: {
      message: 'Enter a valid card number.'
    },
    enterAValidDate: {
      message: 'Enter a valid date.'
    },
    cardholderName: {
      message: 'Cardholder Name.'
    },
    typeAsWrittenOnCard: {
      message: 'Type as written on card.'
    },
    enterTheNameAsItIsWrittenOnTheCard: {
      message: 'Enter the name as it is written on the card.'
    },
    bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable: {
      message: 'By saving the payment details you authorize MyTripMenu to charge the plan amount automatically every month until you cancel subscription. Terms and conditions are available '
    },
    inThisLink: {
      message: 'in this link.'
    },
    savePaymentData: {
      message: 'save payment data'
    },
    addACreditCardToViewPlanInformationAndConfirmYourSubscription: {
      message: 'Add a credit card to view plan information and confirm your subscription.'
    },
    deliveryConfiguration: {
      message: 'delivery configuration'
    },
    schedules: {
      message: 'schedules'
    },
    Schedules: {
      message: 'SCHEDULES'
    },
    add: {
      message: 'add'
    },
    onSiteServiceVisualization: {
      message: 'On-site Service | visualization'
    },
    deliveryWhatsappOrders: {
      message: 'Delivery | whatsapp orders'
    },
    takeAway: {
      message: 'Take Away'
    },
    catalog: {
      message: 'Catalog'
    },
    typesOfService: {
      message: 'TYPES OF SERVICE'
    },
    mandatoryDataForDelivery: {
      message: 'MANDATORY DATA FOR DELIVERY'
    },
    phone: {
      message: 'Phone'
    },
    phoneUpper: {
      message: 'PHONE'
    },
    address: {
      message: 'Address'
    },
    generalSettings: {
      message: 'GENERAL SETTINGS'
    },
    freeShippingForPurchasesAbove: {
      message: 'FREE SHIPPING FOR PURCHASES ABOVE'
    },
    noFreeShipping: {
      message: 'No Free Shipping'
    },
    minimumValueForDeliveryOrders: {
      message: 'MINIMUM VALUE FOR DELIVERY ORDERS'
    },
    minimumDeliveryTime: {
      message: 'MINIMUM DELIVERY TIME'
    },
    noForecast: {
      message: 'No forecast'
    },
    maximumDeliveryTime: {
      message: 'MAXIMUM DELIVERY TIME'
    },
    orderResponseTimeInMinutes: {
      message: 'ORDER RESPONSE TIME IN MINUTES'
    },
    serviceNumberViaWhats: {
      message: 'SERVICE NUMBER VIA WHAT\'S'
    },
    servicePlaces: {
      message: 'SERVICE PLACES'
    },
    action: {
      message: 'ACTION'
    },
    addEditCities: {
      message: 'ADD/EDIT CITIES'
    },
    newCity: {
      message: 'New City'
    },
    search: {
      message: 'Search'
    },
    addNew: {
      message: 'Add New'
    },
    saveEditions: {
      message: 'SAVE EDITIONS'
    },
    exitWithoutSaving: {
      message: 'EXIT WITHOUT SAVING'
    },
    saveEditionsAndExit: {
      message: 'SAVE EDITIONS AND EXIT'
    },
    back: {
      message: 'BACK'
    },
    status: {
      message: 'STATUS'
    },
    active: {
      message: 'ACTIVE'
    },
    inactive: {
      message: 'INACTIVE'
    },
    category: {
      message: 'Category'
    },
    subcategory: {
      message: 'Subcategory'
    },
    categories: {
      message: 'Categories'
    },
    subcategories: {
      message: 'Subcategories'
    },
    categoriesUpper: {
      message: 'CATEGORIES'
    },
    subcategoriesUpper: {
      message: 'SUBCATEGORIES'
    },
    complements: {
      message: 'Complements'
    },
    complementsGroup: {
      message: 'complements group'
    },
    complementsGroupUpper: {
      message: 'COMPLEMENTS GROUP'
    },
    products: {
      message: 'products'
    },
    image: {
      message: 'IMAGE'
    },
    yes: {
      message: 'Yes'
    },
    no: {
      message: 'No'
    },
    theHighlightsCategoryCannotBeEdited: {
      message: 'The Highlights category cannot be edited'
    },
    sortFromAZ: {
      message: 'sort from A-Z'
    },
    sortFromZA: {
      message: 'sort from Z-A'
    },
    sortByActive: {
      message: 'Sort by active'
    },
    sortByInactive: {
      message: 'Sort by inactive'
    },
    listActiveOnly: {
      message: 'List active only'
    },
    addEditCategory: {
      message: 'Add/edit category'
    },
    addEditSubcategory: {
      message: 'Add/edit subcategory'
    },
    imageOptional: {
      message: 'Image (optional)'
    },
    addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive: {
      message: 'Adding illustrative image to the category is an optional action,however, it helps to make your menu more attractive.'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality: {
      message: 'Choose a photo that illustrates the items that make up this category, have good lighting and be realistic aboutto the actual presentation of the products.Avoid using photos generic, stock image, or low quality.'
    },
    theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB: {
      message: 'The ideal resolution is 800x600 pixels and the image is not may be larger than 1 MB.'
    },
    selectPhoto: {
      message: 'SELECT PHOTO'
    },
    selectCategory: {
      message: 'SELECT CATEGORY'
    },
    saveAndAddAnother: {
      message: 'SAVE AND ADD ANOTHER'
    },
    highlightsSubcategoryCannotBeEdited: {
      message: 'Highlights subcategory cannot be edited'
    },
    categoryInWhichTheSubcategoryShouldAppear: {
      message: 'Category in which the subcategory should appear'
    },
    addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive: {
      message: 'Adding illustrative image to the subcategory is an action optional, however, helps to make your menu more attractive.'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos: {
      message: 'Choose a photo that illustrates the items that make up this subcategory, has good lighting, and is realistic in relation to the actual presentation of the products. Avoid using generic, stock, or low-quality photos.'
    },
    enterANameForTheSubcategory: {
      message: 'Enter a name for the subcategory'
    },
    enterANameForTheCategory: {
      message: 'Enter a name for the category'
    },
    descriptionOfComplement: {
      message: 'description of complement'
    },
    descriptionOfComplementUpper: {
      message: 'DESCRIPTION OF COMPLEMENT'
    },
    addComplementGroup: {
      message: 'ADD COMPLEMENT GROUP'
    },
    complementGroupName: {
      message: 'Complement group name'
    },
    complementName: {
      message: 'Complement Name'
    },
    checkAllFields: {
      message: 'Check all fields.'
    },
    complementGroupIsInactive: {
      message: 'complementGroup is inactive.'
    },
    value: {
      message: 'Value'
    },
    valueUpper: {
      message: 'VALUE'
    },
    addProduct: {
      message: 'ADD PRODUCT'
    },
    images: {
      message: 'Images'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos: {
      message: 'Choose a photo that illustrates the items that make up this product, has good lighting, and is realistic in relation to your actual presentation. Avoid using generic, stock, or low-quality photos.'
    },
    enableOn: {
      message: 'Enable on'
    },
    categorysInWhichTheProductShouldAppear: {
      message: 'Category(s) in which the product should appear'
    },
    subCategorysInWhichTheProductShouldAppear: {
      message: 'Subcategory(s) in which the product should appear'
    },
    categorization: {
      message: 'Categorization'
    },
    shortDescriptionShownInProductDetail: {
      message: 'Short Description (shown in product detail)'
    },
    longDescriptionShownInProductDetail: {
      message: 'Long Description (shown in product detail)'
    },
    enterTheIngredientsThatMakeUpThisProductItsAccompanimentsAndThePortionSizes: {
      message: 'Enter the ingredients that make up this product, its accompaniment(s) and the portion sizes'
    },
    enterSomethingLikeSideDishesOrPortionSize: {
      message: 'Enter something like side dishes or portion size'
    },
    principalInformation: {
      message: 'Principal information'
    },
    id: {
      message: 'ID'
    },
    type: {
      message: 'TYPE'
    },
    validity: {
      message: 'Validity'
    },
    validityUpper: {
      message: 'VALIDITY'
    },
    useAvailable: {
      message: 'USE/AVAILABLE'
    },
    filter: {
      message: 'Filter'
    },
    freeDelivery: {
      message: 'FREE DELIVERY'
    },
    discount: {
      message: 'DISCOUNT'
    },
    aCouponWithThisCodeAlreadyExists: {
      message: 'A coupon with this code already exists.'
    },
    dateTime: {
      message: 'DATE/TIME'
    },
    change: {
      message: 'CHANGE'
    },
    formOfPayment: {
      message: 'FORM OF PAYMENT'
    },
    customer: {
      message: 'CUSTOMER'
    },
    uninformed: {
      message: 'Uninformed'
    },
    recordsFound: {
      message: 'Records found'
    },
    QRCodeOfYourDigitalMenu: {
      message: 'QR Code of your digital menu'
    },
    downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu: {
      message: 'Download your QR Code and make it available at your establishment or in promotional materials to facilitate customer access to your digital menu'
    },
    areYouSureYouWantToDoThis: {
      message: "Are you sure you want to do this?"
    },
    whenDisablingTheComplementAllPreviouslyLinkedComplementGroupsWillBeUnlinkedFromTheProduct: {
      message: 'When disabling the complement, all previously linked complement groups will be unlinked from the product.'
    },
    anErrorOccurredWhileFetchingProductCategories: {
      message: 'An error occurred while fetching product categories'
    },
    anErrorOccurredWhileFetchingProductOperations: {
      message: 'An error occurred while fetching product operations'
    },
    anErrorOccurredWhileFetchingTheSubcategoriesForTheSelectedCategory: {
      message: 'An error occurred while fetching the subcategories for the selected category'
    },
    productImage: {
      message: 'Product image'
    },
    whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus: {
      message: 'When you inactivate a product, it will no longer appear on the online menu(s).'
    },
    RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary: {
      message: 'Remember that you can activate it again at another time if necessary.'
    },
    inactivateTheProduct: {
      message: 'INACTIVATE THE PRODUCT'
    },
    keepActive: {
      message: 'KEEP ACTIVE'
    },
    simpleEasyFastDigital: {
      message: 'Simple. Easy. Fast. Digital.'
    },
    errorIdentification: {
      message: 'Error identification'
    },
    tryAgain: {
      message: 'TRY AGAIN'
    },
    huhLooksLikeSomethingWentWrong: {
      message: 'Huh! Looks like something went wrong...'
    },
    unableToSaveCreditCardDetails: {
      message: 'Unable to save credit card details.'
    },
    mmyy: {
      message: 'MM/YY'
    },
    removePhoto: {
      message: 'remove photo'
    },
    addEditCoupon: {
      message: 'ADD/EDIT COUPON'
    },
    couponType: {
      message: 'COUPON TYPE'
    },
    freeShipping: {
      message: 'Free shipping'
    },
    discountByValue: {
      message: 'Discount by value'
    },
    percentageDiscount: {
      message: 'Percentage discount'
    },
    generateCoupon: {
      message: 'GENERATE COUPON'
    },
    limitOfUse: {
      message: 'LIMIT OF USE'
    },
    couponCode: {
      message: 'COUPON CODE'
    },
    validFrom: {
      message: 'VALID FROM'
    },
    validUntil: {
      message: 'VALID UNTIL'
    },
    highlightsSubcategoryCannotBeCopied: {
      message: 'Highlights subcategory cannot be copied'
    },
    nothingHere: {
      message: "Seems like there's still nothing here."
    },
    from: {
      message: "FROM"
    },
    to: {
      message: "TO"
    },
    sunday: {
      message: "Sunday"
    },
    monday: {
      message: "Monday"
    },
    tuesday: {
      message: "Tuesday"
    },
    wesnesday: {
      message: "Wednesday"
    },
    thursday: {
      message: "Thursday"
    },
    friday: {
      message: "Friday"
    },
    saturday: {
      message: "Saturday"
    },
    accessData: {
      message: "Access data"
    },
    responsibleEstablishment: {
      message: "Responsible for the establishment"
    },
    saveEditions2: {
      message: "Save Editions"
    },
    zeit: {
      message: "Time"
    },
    cardsAccepted: {
      message: "Mark the cards accepted by your establishment"
    },
    pixKey: {
      message: "Register your PIX key here"
    },
    noOptions: {
      message: "no options"
    },
    neighborhoods2: {
      message: "NEIGHBORHOODS"
    },
    addEditNeighborhoods: {
      message: "ADD/EDIT NEIGHBORHOODS"
    },
    newNeighborhoods: {
      message: "New Neighborhoods"
    },
    qtyMinimum: {
      message: "QTY MINIMUM"
    },
    qtymaximum: {
      message: "QTY maximum"
    },
    linkGroup: {
      message: "LINK SUPPLIES GROUP"
    },
    importantGroupComplements: {
      message: "IMPORT GROUP OF COMPLEMENTS"
    },
    people: {
      message: "People"
    },
    house: {
      message: "Houses"
    },
    subcategoryProducts: {
      message: "This SubCategory has no Associated Products"
    },
    productNotAdd: {
      message: "This product does not have any add-ons added"
    },
    linkSupplements: {
      message: "LINK SUPPLEMENTS"
    },
    PRICE: {
      message: "PRICE"
    },
    COMPLEMENTS: {
      message: "COMPLEMENTS"
    },
    DESCRIPTION: {
      message: "DESCRIPTION"
    },
    action2: {
      message: "Action"
    },
    pixKey2: {
      message: "Your PIX Key"
    },
    ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn: {
      message: "If you leave the property details page before saving your changes, you will lose any information you have already filled in."
    },
    couldNotLoadUserData: {
      message: "Could not load user data"
    },
    termsOfUseAanServices: {
      message: "TERMS OF USE AND SERVICES"
    },
    hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou: {
      message: "Hi! Glad to have your interest! Before using our services, please take the time to read our Terms of Use and Service and learn about the rules that govern our relationship with you."
    },
    belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom: {
      message: "Below we will clarify some points that we consider important. If you have any doubts about any points discussed or not in this document, please do not hesitate to contact us at hello@mytripmenu.com."
    },
    definitions: {
      message: "1. DEFINITIONS:"
    },
    inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions: {
      message: "In this instrument, we understand the expressions below according to the following definitions:"
    },
    plataform: {
      message: "PLATFORM"
    },
    webSystemAvailableThroughMyTripMenuComBrOfferedBy: {
      message: "web system available through mytripmenu.com.br, offered by"
    },
    andOwnershipOperationAndLiabilityOf: {
      message: "and ownership, operation and liability of"
    },
    users: {
      message: "USERS"
    },
    user: {
      message: "USER"
    },
    where: {
      message: ", where"
    },
    allThe: {
      message: "all the"
    },
    canViewAndUseTheServicesOfferedBy: {
      message: "can view and use the services offered by"
    },
    establishment: {
      message: "ESTABLISHMENT"
    },
    establishments: {
      message: "ESTABLISHMENTS"
    },
    legalEntityRegisteredInThe: {
      message: "legal entity registered in the"
    },
    whichContractsTheServicesOfferedBy: {
      message: ", which contracts the services offered by"
    },
    client: {
      message: "CLIENT"
    },
    customers: {
      message: "CUSTOMERS"
    },
    naturalPersonAccessingThe: {
      message: "natural person accessing the"
    },
    toViewTheMenu: {
      message: "to view the menu "
    },
    dataProcessing: {
      message: "DATA PROCESSING"
    },
    pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy: {
      message: " Pursuant to article 5, item X, of Law 13,709 of 2018, every transaction carried out by "
    },
    withPersonalDataOf: {
      message: " with personal data of "
    },
    suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction: {
      message: " , such as those referring to the collection, production, reception, classification, use, access, reproduction, transmission, distribution, processing, archiving, storage, elimination, evaluation or control of information, modification, communication, transfer, dissemination or extraction. "
    },
    admission: {
      message: " 2. ADMISSION: "
    },
    thisInstrumentRegulatesTheConditionsOfUseOfThe: {
      message: " This instrument regulates the conditions of use of the "
    },
    being: {
      message: "being"
    },
    aContractBetweenThe: {
      message: " a contract between the "
    },
    andThe: {
      message: " and the "
    },
    theUseOfTheServicesOfferedThrough: {
      message: " The use of the services offered through "
    },
    fromThe: {
      message: "from the "
    },
    expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies: {
      message: " expressly indicates that you agree with all the terms and conditions contained in this instrument and with the legal provisions applicable to the species. "
    },
    the: {
      message: "The "
    },
    understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy: {
      message: " understands that the service contracting model will be through prepaid monthly subscription plans budgeted and offered by "
    },
    accordingToInformationAvailableInThePlansAreaOfTheWebsite: {
      message: "according to information available in the plans area of The website: "
    },
    forTheContractingOfCustomizedPlansThe: {
      message: "For the contracting of customized plans, the "
    },
    shouldContactThe: {
      message: "should contact the "
    },
    throughItsChannelsForTheElaborationOfASpecificBudget: {
      message: "through its channels, for the elaboration of a specific budget. "
    },
    youUnderstandAndAgreeThatThe: {
      message: "YOU UNDERSTAND AND AGREE THAT THE "
    },
    useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies: {
      message: " USE OF THE PROVIDED SERVICES WILL BE CONSIDERED YOUR ACCEPTANCE OF THESE TERMS AND ALL OTHER LEGAL PROVISIONS RELEVANT TO THE SPECIES."
    },
    ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform: {
      message: "BY ACCEPTING THE TERMS OF THIS INSTRUMENT, THE USER EXPRESSLY AUTHORIZES THE PROCESSING OF HIS DATA, IN ORDER TO GUARANTEE THE MAINTENANCE AND GOOD PERFORMANCE OF THE FUNCTIONALITIES OF THE PLATFORM"
    },
    theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy: {
      message: "THE USER, IN THIS ACT, MANIFESTS ITS COMPLETE CONSENT TO THE SHARING OF DATA COLLECTED AND PROCESSED BY "
    },
    pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders: {
      message: ", PURSUANT TO THIS INSTRUMENT, WITH OTHER MY TRIP MENUS THAT ARE PART OF YOUR ECONOMIC GROUP, OR ARE YOUR SERVICE PROVIDERS."
    },

    caseYou: {
      message: "Case you "
    },
    doNotAgree: {
      message: "DO NOT AGREE"
    },
    withTheProvisionsOfThisInstrument: {
      message: " with the provisions of this instrument, "
    },
    dont: {
      message: 'D\'ONT'
    },
    accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom: {
      message: " access, view, download or otherwise use any page, content, information or service from "
    },
    theseTermsAreAvailableForReadingAtAnyTimeOn: {
      message: "These terms are available for reading, at any time, on "
    },
    inMessage: {
      message: "in "
    },
    whoWeAreAndWhatWeDo: {
      message: "3. WHO WE ARE AND WHAT WE DO:"
    },
    isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp: {
      message: "is a service provider that has developed and licensed a system in the form of SaaS (Software as a Service), with specific features to help"
    },
    and: {
      message: "and"
    },
    soThe: {
      message: "So, the"
    },
    offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe: {
      message: "offers a digital menu, allowing an improvement in efficiency and experience for both the"
    },
    asForThe: {
      message: "as for the"
    },
    generalConditionsOfUse: {
      message: "4. GENERAL CONDITIONS OF USE:"
    },
    onlyProvidesThe: {
      message: " only provides the "
    },
    whichOffersDigitalMenuServicesForThe: {
      message: " which offers digital menu services for the "
    },
    itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe: {
      message: " its responsibility being restricted only to the correct functioning of the "
    },
    andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe: {
      message: "and its functionalities, according to this instrument and the plan contracted by the "
    },
    notStoppingThe: {
      message: ", not stopping the"
    },
    noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu: {
      message: "no responsibility for the creation, customization and dissemination of content through the digital menu."
    },
    justLicenseThe: {
      message: " just license the "
    },
    toThe: {
      message: " to the "
    },
    toThe2: {
      message: " To the "
    },
    andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe: {
      message: ", and there is no other relationship between these parties, so that it is not possible to attribute to the"
    },
    liabilityForAnyDamageCauseToOthers: {
      message: " liability for any damage caused to others "
    },
    orToThirdPartiesForActsArisingFrom: {
      message: " or to third parties, for acts arising from "
    },
    whileUsingTheAvailableFeaturesOfThe: {
      message: " while using the available features of the "
    },
    declaresThatHeUnderstandsAndAcceptsThatThe: {
      message: "declares that he understands and accepts that the "
    },
    doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe: {
      message: "does not intend to replace the physical menu available at the commercial unit of the "
    },
    beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween: {
      message: "being only a complementary solution, in order to facilitate communication between "
    },
    atTheTimeOfRegistrationThe: {
      message: "At the time of registration, the"
    },
    youCanUseAllTheServicesAvailableOnThe: {
      message: " You can use all the services available on the "
    },
    declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse: {
      message: ", declaring that they have read, understood and accepted all the provisions contained in these Terms of Use."
    },
    expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom: {
      message: "expressly acknowledges that, through this instrument, it receives from "
    },
    theGarantingOfALicenseToUseThe: {
      message: " the granting of a license to use the "
    },
    isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe: {
      message: ", is non-transferable, and sublicensing is prohibited, for use in national or foreign territory, for as long as the adhesion to this term lasts, leaving the use of the "
    },
    inDisagreementWithTheProvisionOfThisInstrument: {
      message: " in disagreement with the provisions of this instrument."
    },
    isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt: {
      message: " is solely responsible for the security of your password and for the use of your registration at "
    },
    thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr: {
      message: ", therefore, we recommend that you do not share such information with third parties and, if this information is, for any reason, lost or "
    },
    hacked: {
      message: "hacked"
    },
    mustImmediatelyInformThe: {
      message: " must immediately inform the "
    },
    throughHelloMyTripMenuComInOrderToResolveTheIssue: {
      message: ", through hello@mytripmenu.com, in order to resolve the issue."
    },
    itIsOnlyUpToThe: {
      message: "It is only up to the "
    },
    liableForAnyDamagesCausedToThirdPartiesToOther: {
      message: " liable for any damages caused to third parties, to other "
    },
    orToItself: {
      message: " or to itself "
    },
    resultingFromTheUseOfTheFeaturesOfThe: {
      message: ", resulting from the use of the features of the "
    },
    shouldNotUseTheServicesAvailableOnThe: {
      message: " should not use the services available on the "
    },
    forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe: {
      message: " for any unlawful, defamatory, discriminatory, abusive, offensive, pornographic, obscene, aggressive, injurious, vexatious, deceptive, slanderous, violent, vulgar, or harassing, threatening, or use of false identity, ie any shady use, purposes or means that could harm the "
    },
    others: {
      message: " others"
    },
    orThirdParties: {
      message: " or third parties"
    },
    UnderNoCircumstancesWillThe: {
      message: "Under no circumstances will the "
    },
    willBeLiableForAnyDamagesIncurredByThe: {
      message: "will be liable for any damages incurred by the "
    },
    forPossibleTemporaryUnavailabilityOfThe: {
      message: " for possible temporary unavailability of the "
    },
    orOnyContentMadeAvailableByThe: {
      message: "or any content made available by the "
    },
    onTheDigitalMenu: {
      message: "on the digital menu."
    },
    mustHaveAllNecessarySoftwareAndHardwareToAccessThe: {
      message: " must have all necessary software and hardware to access the "
    },
    includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe: {
      message: ", including, but not limited to, a computer or mobile device with Internet access, and the "
    },
    onlyToProvideThe: {
      message: ", only to provide the "
    },
    to2: {
      message: " to "
    },
    underThisInstrumentToThe: {
      message: "under this instrument. To the "
    },
    accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable: {
      message: "access the digital menu, a device capable of reading the QR Code will be indispensable."
    },
    byThe: {
      message: " by the "
    },
    isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument: {
      message: "is conditioned to its prior registration, as well as compliance with the provisions contained in this instrument."
    },
    registration: {
      message: "5. REGISTRATION"
    },
    soThatThe: {
      message: "So that the "
    },
    registerInThe: {
      message: " register in the "
    },
    mustProvideTo: {
      message: "must provide to "
    },
    theFollowingData: {
      message: " the following data "
    },
    corporateName: {
      message: "corporate name;"
    },
    unitAddressOf: {
      message: " unit address of "
    },
    contactNumberInTheEventThatThe: {
      message: "Contact number. In the event that the"
    },
    hireThe: {
      message: "hire the"
    },
    forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest: {
      message: "for more units, you must register the other addresses of interest."
    },
    forRegularUseOf: {
      message: "For regular use of "
    },
    mustRegisterFillingInAllTheDataRequestedByThe: {
      message: " must register, filling in all the data requested by the "
    },
    atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy: {
      message: " at the time of registration, as well as selecting the service plan offered by "
    },
    itIsTheResponsibilityOf: {
      message: " It is the responsibility of "
    },
    identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting: {
      message: "identify in the contracted plan the number of units contemplated in its contracting."
    },
    itIsTheSoleResponsibilityOf: {
      message: "It is the sole responsibility of"
    },
    provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe: {
      message: "provide, update and guarantee the veracity of the registration data, not falling to the"
    },
    anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy: {
      message: " any type of civil and criminal liability resulting from untrue, incorrect or incomplete data provided by "
    },
    reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe: {
      message: " reserves the right to use all valid and possible means to identify the "
    },
    asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf: {
      message: " as well as requesting additional data and documents that it deems relevant in order to verify the reported data. In this case, the use of "
    },
    isConditionedToTheSendingOfTheDocumentsEventuallyRequested: {
      message: " is conditioned to the sending of the documents eventually requested."
    },
    ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe: {
      message: "If a registration is considered suspected of containing erroneous or untrue data, the "
    },
    reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe: {
      message: " reserves the right to suspend, temporarily or permanently, without prior notice, the "
    },
    responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe: {
      message: " responsible for the registration. In the event of suspension, you will not attend the "
    },
    rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages: {
      message: " right to any type of indemnity or compensation for losses and damages, loss of profits or moral damages."
    },
    inTheEventThatThe: {
      message: "In the event that the"
    },
    carryOutTheCollectionOfPersonalDataFromThe: {
      message: "carry out the collection of personal data from the "
    },
    willAllowAccessToTheInformationCollectedAndAboutThe: {
      message: "will allow access to the information collected and about the "
    },
    carriedOutBy: {
      message: " carried out by "
    },
    freeOfChargeByRequestingHelloMyTripMenuCom: {
      message: ", free of charge, by requesting hello@mytripmenu.com"
    },
    orByRegisteringAt: {
      message: "or by registering at "
    },
    beingAbleToEditOrDeleteThemAtAnyTime: {
      message: " being able to edit or delete them at any time."
    },
    theCollectionOfDataFrom: {
      message: "The collection of data from "
    },
    whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe: {
      message: ", when it occurs, aims to identify you, as well as enable you to correctly use the "
    },
    andWithThatThe: {
      message: ", and with that, the "
    },
    canEnsureTheGoodQualityOfTheLicensedServices: {
      message: " can ensure the good quality of the licensed services."
    },
    byConsentingToTheTermsOfThisInstrumentThe: {
      message: "By consenting to the terms of this instrument, the"
    },
    expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe: {
      message: " expressly declares to be aware that the collection of your data is essential for the proper functioning of the "
    },
    authorizingFromNowOnThe: {
      message: ", authorizing, from now on, the "
    },
    forThe: {
      message: " for the "
    },
    offersThePossibilityOf: {
      message: " offers the possibility of "
    },
    publicizeYourFigitalMenuThroughQRCodeToYour: {
      message: "publicize your digital menu through QR Code to your "
    },
    withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe: {
      message: "without the need for registration, however, the absence of registration does not detract from the non-compliance with the rules of this instrument, which the "
    },
    mustEnsureCompliance: {
      message: "must ensure compliance."
    },
    willAccessYourRegistrationIn: {
      message: " will access your registration in "
    },
    through: {
      message: " through "
    },
    andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem: {
      message: " and password, pledging not to inform third parties of this data, taking full responsibility for the use made of them."
    },
    undertakesToNotifyThe: {
      message: " undertakes to notify the "
    },
    immediatelyThroughTheContactChannelsMaintainedBy: {
      message: " immediately, through the contact channels maintained by "
    },
    at: {
      message: " at "
    },
    regardingAnyUnauthorizedUseOfYourAccountThe: {
      message: ", regarding any unauthorized use of your account. The"
    },
    willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou: {
      message: " will be solely responsible for the operations carried out on your account, since access will only be possible through the use of a password known exclusively to you."
    },
    regardingAnyKnowledgeOfWrongdoingByOther: {
      message: ", regarding any knowledge of wrongdoing by other"
    },
    thatMayCauseDamageToTheirOwn: {
      message: " that may cause damage to their own "
    },
    ofThe: {
      message: " of the "
    },
    toThisOnTo: {
      message: " to this one, to "
    },
    orToThirdParties: {
      message: " or to third parties."
    },
    undertakesToUsTheFeaturesOfThe: {
      message: "undertakes to use the features of the"
    },
    inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms: {
      message: " in good faith, in accordance with the legislation in force, morals and good customs."
    },
    underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe: {
      message: "Under no circumstances will the assignment, sale, rent or other form of transfer of the"
    },
    atItsSoleDiscretion: {
      message: "At its sole discretion "
    },
    mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO: {
      message: " may exclude, disable, create limits on the use of the service, suspend, block, indefinitely, without prior notice or compensation, registrations o "
    },
    thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce: {
      message: " that are considered offensive, that violate the terms of this instrument or the legislation in force."
    },
    reservesTheRightNotToAllowNewRegistrationOf: {
      message: " reserves the right not to allow new registration of "
    },
    thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom: {
      message: " that have already been cancelled, disabled, blocked, deleted or suspended from "
    },
    theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe: {
      message: " The creation of new registrations by people whose original registrations have been cancelled, blocked, disabled, excluded or suspended due to violations of the policies of the "
    },
    orCurrentLegislation: {
      message: " or current legislation."
    },
    reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA: {
      message: " reserves the right, unilaterally, without prior notice, consent or compensation, to refuse any request for registration of a "
    },
    asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration: {
      message: ", as well as cancel, disable, block, delete or suspend the use of a previously accepted registration."
    },
    byAgreeingToThisInstrumentThe: {
      message: "By agreeing to this instrument, the "
    },
    declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo: {
      message: " declares to be aware that it is solely responsible for its registration, being certain that any damage caused by the insertion of outdated, inaccurate or untrue information cannot be attributed to "
    },
    orThe: {
      message: "Or the"
    },
    functionalities: {
      message: "6. FUNCTIONALITIES:"
    },
    offersTheFollowingFeatures: {
      message: " offers the following features:"
    },
    aOnlineDigitalMenu: {
      message: "(a) Online digital menu;"
    },
    bViewingProductsWithPphotos: {
      message: "(b) Viewing products with photos;"
    },
    cOfferingAMultilingualMenuSubjectToAvailableConditions: {
      message: "(c) Offering a multilingual menu, subject to available conditions;"
    },
    dActivationAndDeactivationOfItemsInRealTime: {
      message: "(d) Activation and deactivation of items in real time;"
    },
    eAccessToTheMenuByScanningAQRCode: {
      message: "(e) Access to the menu by scanning a QR Code;"
    },
    fCreationOfHighlightsForPromotionalActions: {
      message: "(f) Creation of highlights for promotional actions."
    },
    whenUsingTheServicesProvidedByThe: {
      message: " when using the services provided by the "
    },
    canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe: {
      message: " can select URL (Uniform Resource Locator), in order to determine the electronic address that will locate the digital menu of the "
    },
    ToDefineTheURLThe: {
      message: "To define the URL, the "
    },
    mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices: {
      message: "must respect the intellectual property of third parties, therefore, it must not include expressions that may cause confusion or allude to third-party services."
    },
    understandThatThe: {
      message: " understand that the "
    },
    resourcesWereUsedToCauseConfusionAmongThe: {
      message: " resources were used to cause confusion among the "
    },
    makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe: {
      message: "making it difficult to properly identify the services and misleading them, you may exclude the URL of the "
    },
    asWellAsAdoptOtherMeasuresRelevantToTheCase: {
      message: "as well as adopt other measures relevant to the case"
    },
    mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn: {
      message: " may change the range of features available according to the contract plan, as provided for in "
    },
    mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe: {
      message: " may at any time, by sending prior notice, without the need for compensation, edit and/or delete existing features, as well as include new features to the "
    },
    translations: {
      message: "7. TRANSLATIONS:"
    },
    youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe: {
      message: "You can use translation services in order to make your menus available in the languages of your choice. For this purpose, fees will be charged for the services, as provided in the "
    },
    forTheExecutionOfTranslationServicesThe: {
      message: "For the execution of translation services, the"
    },
    requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe: {
      message: "request at least a period of 72 hours (seventy-two hours). Depending on the length of the text to be translated, the"
    },
    withPriorNoticeTo: {
      message: "with prior notice to"
    },
    mayExtendTheServiceExecutionPeriodAtItsDiscretion: {
      message: "may extend the service execution period at its discretion."
    },
    declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions: {
      message: "declares that he understands and accepts that certain expressions cannot be translated, given that they reflect certain cultures or specific regions."
    },
    isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe: {
      message: " is not responsible for any damage resulting from inaccurate translations in the digital menu of the "
    },
    howeverInTheEventThatThe: {
      message: ". However, in the event that the"
    },
    identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe: {
      message: "identify any error or misunderstanding in the tradition, you can communicate to the "
    },
    whichMustRespondToTheRequestAsSoonAsPossible: {
      message: "which must respond to the request as soon as possible."
    },
    suppertService: {
      message: "8. SUPPORT SERVICE"
    },
    willSupportThe: {
      message: "will support the "
    },
    throughthecontactchannelavailableviaemailhellomytripmenucom: {
      message: " through the contact channel available via email hello@mytripmenu.com."
    },
    inSupportRequestsViaEmailTthe: {
      message: "In support requests via email, the "
    },
    willHaveUpTo72SeventyTwoBusinessHoursToRespond: {
      message: "will have up to 72 (seventy-two) business hours to respond."
    },
    paymentMethod4: {
      message: "9. PAYMENT METHODS:"
    },
    priorToAnyChargeBeingMadeByThe: {
      message: "Prior to any charge being made by the "
    },
    willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf: {
      message: "will be offered a trial period of 07 (seven) days, for a free trial of the tools of "
    },
    ifThe: {
      message: "If the "
    },
    chooseToProceedWithTheContractingOfServicesThe: {
      message: " choose to proceed with the contracting of services, the "
    },
    makesAvailableTo: {
      message: "makes available to "
    },
    oneOfTheFollowingPaymentMethods: {
      message: "one of the following payment methods: "
    },
    VISACreditCardOr: {
      message: "VISA Credit Card; or,"
    },
    MASTERCARDCreditCardOr: {
      message: "MASTERCARD Credit Card; or,"
    },
    AMERICANEXPRESSCreditCardOr: {
      message: "AMERICAN EXPRESS Credit Card; or,"
    },
    DINNERSCLUBCreditCardOr: {
      message: "DINNERS CLUB Credit Card; or,"
    },
    ELOCreditCardOr: {
      message: "ELO Credit Card, or;"
    },
    DISCOVERCreditCard: {
      message: "DISCOVER Credit Card."
    },
    toProcessCreditCardPaymentsItWillBeNecessaryForThe: {
      message: "To process credit card payments, it will be necessary for the"
    },
    registerOn: {
      message: "register on "
    },
    theFollowingDetailsOfTheChosenCreditCard: {
      message: "the following details of the chosen Credit Card:"
    },
    NameOfTheCreditCardHolder: {
      message: "Name of the Credit Card holder;"
    },
    CreditCardNumber: {
      message: "Credit card number;"
    },
    CreditCardFlag: {
      message: "Credit Card Flag;"
    },
    CreditCardExpirationMonthandYear: {
      message: "Credit Card Expiration (Month and Year);"
    },
    CreditCardsecuritynumber: {
      message: "Credit Card security number."
    },
    Paymentsforservicescontractedinthe: {
      message: "Payments for services contracted in the "
    },
    willBe: {
      message: " will be"
    },
    processedthroughtheGETNETplatform: {
      message: " processed through the GETNET platform"
    },
    beingnecessarythatall: {
      message: "being necessary that all "
    },
    alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform: {
      message: " also read and accept the terms of use and services of the GETNET platform"
    },
    viaemailaddress: {
      message: "via email address: "
    },
    Whenhiringthe: {
      message: "When hiring the "
    },
    withpaymentbycreditcardorbankslipthe: {
      message: ", with payment by credit card or bank slip, the"
    },
    expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform: {
      message: "expressly indicates that you have read and accepted all the conditions present in this instrument and in the terms of use and specific services of the GETNET platform."
    },
    AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe: {
      message: "Any dispute of payments made through the GETNET platform must be resolved only between the"
    },
    andGETNETnotfallingto: {
      message: " and GETNET, not falling to "
    },
    anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform: {
      message: " any responsibility for processing payments made on the GETNET platform."
    },
    TheGETNETplatform: {
      message: "The GETNET platform"
    },
    maychargefeesforitsuseanditisonlyuptothe: {
      message: "may charge fees for its use, and it is only up to the "
    },
    informationaboutthevalueschargedbyGETNET: {
      message: " information about the values charged by GETNET. "
    },
    isonlyresponsiblefordeletingthepaymentdataprovidedbythe: {
      message: " is only responsible for deleting the payment data provided by the "
    },
    fromitsowndatabasedeclaringthe: {
      message: "from its own database, declaring the"
    },
    beawarethatitisnotupto: {
      message: " be aware that it is not up to "
    },
    promotetheexclusionofsaiddatafromtheGETNETdatabase: {
      message: " promote the exclusion of said data from the GETNET database."
    },
    PRIVACYANDDATAPROCESSINGPOLICY: {
      message: "10. PRIVACY AND DATA PROCESSING POLICY:"
    },
    Duringtheuseof: {
      message: "During the use of "
    },
    willcollectandstoretheinformationprovidedbythe: {
      message: "will collect and store the information provided by the"
    },
    underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof: {
      message: ", under the terms of this instrument, together with automatically generated information, such as the characteristics of the access device, browser, application access logs (IP, with date and time), information accessed, screens accessed, geolocation data, history of applications, among others, of"
    },
    whichwillbestoredinthedatabaseandalsointhebrowsercookie: {
      message: ", which will be stored in the database and also in the browser cookie."
    },
    Thedatacollectedfrom: {
      message: "The data collected from "
    },
    throughtheuseof: {
      message: ", through the use of"
    },
    willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe: {
      message: ", will be used for the adequate provision of services, for the purpose of improving navigation of the"
    },
    aswellasforadvertisingandstatisticalpurposes: {
      message: ", as well as for advertising and statistical purposes."
    },
    declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato: {
      message: "declares to understand, accept and consent that in the event of sending personal data to"
    },
    mustdosounderthetermsofarticle5itemXofLaw13709of2018: {
      message: "must do so under the terms of article 5, item X, of Law 13,709 of 2018."
    },
    Intheeventthatpersonaldataisprovidedbythe: {
      message: "In the event that personal data is provided by the "
    },
    willbeconsideredconfidentialbythe: {
      message: ", will be considered confidential by the"
    },
    anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas: {
      message: ", and it undertakes to make every effort to preserve the security of its systems in the storage of such data, meeting the security standards established in Decree No. 8,771/2016, such as:"
    },
    iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability: {
      message: "(i) Use of industry standard methods to encrypt the collected data, in addition to other standard forms of encryption, to ensure its inviolability;"
    },
    iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments: {
      message: "(ii) Use of high-tech software to protect against unauthorized access to systems, which are considered controlled and security environments;"
    },
    Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement: {
      message: "(iii) Availability of controlled access to personal data storage locations only to previously authorized and authenticated persons, committed to the secrecy of such data, including by signing a confidentiality agreement;"
    },
    ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe: {
      message: "(iv) Application of authentication mechanisms for access to records capable of individualizing the person responsible for the treatment and access to the data collected as a result of the use of the"
    },
    vAnonymizationofdatafromthe: {
      message: "(v) Anonymization of data from the"
    },
    whensharedwiththirdpartiesnotpartnerswith: {
      message: "when shared with third parties not partners with"
    },
    viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016: {
      message: "(vi) Maintenance of the inventory indicating the time, duration, identity of the employee or person responsible for access and the object file, based on connection and application access records, as determined in article 13 of Decree No. 8,771/2016."
    },
    Ifdatacollectionfromthe: {
      message: "If data collection from the"
    },
    throughtheuse: {
      message: ", through the use"
    },
    thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow: {
      message: "these may only be shared with third parties, in the cases listed below:"
    },
    iFortheprotectionoftheinterestsof: {
      message: "(i) For the protection of the interests of"
    },
    incaseofconflictincludinglegalclaims: {
      message: "in case of conflict, including legal claims;"
    },
    iiIncaseoftransactionsandcorporatechangesinvolvingthe: {
      message: "(ii) In case of transactions and corporate changes involving the"
    },
    inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe: {
      message: ", in which case the transfer of data will be necessary for the continuity of the services offered through the"
    },
    iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest: {
      message: "(iii) By court order or by request of administrative authorities that have legal competence for its request."
    },
    guaranteesto: {
      message: "guarantees to"
    },
    withregardtotheprocessingofpersonaldatathefollowingrights: {
      message: ", with regard to the processing of personal data, the following rights:"
    },
    iConfirmationoftheexistenceofprocessingofyourpersonaldata: {
      message: "(i) Confirmation of the existence of processing of your personal data;"
    },
    iiAccesstoyourdatacollectedby: {
      message: "(ii) Access to your data collected by"
    },
    throughyourownloginorbyrequestinghellomytripmenucom: {
      message: ", through your own login or by requesting hello@mytripmenu.com;"
    },
    iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate: {
      message: "(iii) Correction of your data if it is incomplete, inaccurate or out of date;"
    },
    ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation: {
      message: "(iv) Blocking or deleting unnecessary, excessive or non-conforming data with applicable Brazilian legislation;"
    },
    vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe: {
      message: "(v) The portability of personal data, for you or for a third party, upon express request made by the"
    },
    viahellomytripmenucom: {
      message: ", via hello@mytripmenu.com;"
    },
    viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe: {
      message: "(vi) Deletion of personal data processed with your consent, provided that there is no legal determination to keep them registered with the"
    },
    viiObtaininginformationaboutpublicorprivateentitieswithwhichthe: {
      message: "(vii) Obtaining information about public or private entities with which the"
    },
    sharedyourdataand: {
      message: "shared your data; and,"
    },
    viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe: {
      message: "(viii) Information on the possibility and consequence of not providing the consent of the"
    },
    youcansendemailto: {
      message: "you can send email to"
    },
    hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata: {
      message: "hello@mytripmenu.com, pointing out doubts and/or requirements related to your personal data."
    },
    maydeletethepersonaldatacollectedfrom: {
      message: "may delete the personal data collected from"
    },
    iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy: {
      message: "(i) When the purpose for which they were collected is achieved; or, when they are no longer necessary or relevant for the scope of the purpose, according to the purposes described in these Terms of Use and Privacy Policy;"
    },
    iiWhenthe: {
      message: "(ii) When the"
    },
    revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe: {
      message: "revoke your consent, in cases where it is necessary, requesting the exclusion to the"
    },
    viahellomytripmenucomor: {
      message: "via hello@mytripmenu.com; or,"
    },
    iiiIfdeterminedbycompetentauthority: {
      message: "(iii) If determined by competent authority."
    },
    GENERALPROVISIONS: {
      message: "11. GENERAL PROVISIONS:"
    },
    AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent: {
      message: "Any clause or condition of this instrument that, for any reason, will be considered null or ineffective by any court or court, will not affect the validity of the other provisions of these Terms, which will remain fully valid and binding, generating effects to their fullest extent."
    },
    Thefailureof: {
      message: "The failure of"
    },
    inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines: {
      message: "in claiming any rights or provisions of these Terms will not constitute a waiver, and the latter may regularly exercise their right, within the legal deadlines."
    },
    Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe: {
      message: "All materials, patents, trademarks, registrations, domains, names, privileges, creations, images and all related rights relating to the"
    },
    anddevelopedby: {
      message: "and developed by"
    },
    areandwillremainthesoleandexclusivepropertyof: {
      message: ", are and will remain the sole and exclusive property of"
    },
    agreeingwiththe: {
      message: ", agreeing with the"
    },
    nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem: {
      message: "not to perform an act or fact that, in any way, harms the rights set forth herein, nor to claim any right or privilege over them."
    },
    maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreasonwestronglyrecommendthatyoualwaysvisitthissectionofourSiteandreaditperiodicallyButtocontributetothegoodrelationshipwewillalsosendanemailinformingyouaboutthesechanges: {
      message: "may change this instrument at any time, by simply publishing a revised version on our website. For this reason, we strongly recommend that you always visit this section of our Site and read it periodically. But, to contribute to the good relationship, we will also send an email informing you about these changes."
    },
    Thisinstrumentconstitutestheintegralunderstandingbetweenthe: {
      message: "This instrument constitutes the integral understanding between the"
    },
    andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe: {
      message: "and is governed by Brazilian Laws, with the jurisdiction of the city of São Leopoldo/RS being elected as the only competent court to resolve issues arising from this instrument, with express waiver of any other jurisdiction, however privileged it may be."
    },
    recoverPassword: {
      message: "RECOVER PASSWORD"
    },
    receiveNewPassword: {
      message: "You will receive a new password in your email, remember to also check your spam folder."
    },
    dontWorry: {
      message: "Don't worry, we'll help you get it back. Provide your login email"
    },
    enterEmail: {
      message: "Enter Email"
    },
    notBeSent: {
      message: "The email could not be sent, please try again."
    },
    pleasureToHaveYou: {
      message: "It's a pleasure to have you here with us!"
    },
    beforeStarting: {
      message: "Before starting to include your products and seeing your menu take shape, it is necessary to subscribe to a maintenance plan in the amount of"
    },
    BRL99: {
      message: "BRL 99.90"
    },
    month: {
      message: "a month."
    },
    includesFacilities: {
      message: "It includes facilities such as:"
    },
    menuWithPhotos: {
      message: "full menu with photos;"
    },
    priceUpdate: {
      message: "real-time price update;"
    },
    activateDeactivate: {
      message: "activate and deactivate products quickly;"
    },
    categorizeFilteReorder: {
      message: "categorize, filter and reorder products;"
    },
    newFeatures: {
      message: "new features added every week!"
    },
    thePlan: {
      message: "sign the plan"
    },
    company: {
      message: "COMPANY"
    },
    fantasyName: {
      message: "FANTASY NAME"
    },
    numberAccesses: {
      message: "NUMBER OF ACCESSES"
    },
    entryDate: {
      message: "ENTRY DATE"
    },
    wantLeave: {
      message: "Now that you've come this far, are you sure you want to leave?"
    },
    cancelYourSubscription: {
      message: "We are sorry that you are wanting to cancel your subscription to the MyTripMenu plan."
    },
    beforeConfirming: {
      message: "Before confirming, I need to tell you that if you leave, your customers will no longer have access to your establishment's menu and access to the URL and QRCode will be unavailable until you re-subscribe to the plan."
    },
    goAhead: {
      message: "If you're sure of it, go ahead..."
    },
    unsubscribePlan: {
      message: "unsubscribe from the plan"
    },
    dontCancel: {
      message: "I don't want to cancel anymore"
    },
    noChange: {
      message: "No change"
    },
    CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180: {
      message: "CAXA LABS SERVICOS DE DESENVOLVIMENTO DE SOFTWARE LTDA, Limited Company, regularly registered with the CNPJ under No. 34.177.633/0001-91, headquartered at Rua Felipe Dos Santos, nº 77, 1203 torre A, Padre Reus neighborhood, in the city of São Leopoldo/RS, under CEP: 93.020-180."
    },
    Delivery: {
      message: "Delivery"
    },
    withdrawal: {
      message: "withdrawal"
    },
    Pickup: {
      message: "Pick up on the spot"
    },
    Catalog: {
      message: "Catalog"
    },
    Enteremail: {
      message: "Enter the email"
    },
    emailinvalid: {
      message: "This email is invalid"
    },
    Typepassword: {
      message: "Type the password"
    },
    least6digits: {
      message: "This field must have at least 6 digits"
    },
    Cashpayment: {
      message: "Cash payment method cannot be edited"
    },
    Checkpayment: {
      message: "Check payment method cannot be edited"
    },
    save: {
      message: "To save"
    },
    logIn: {
      message: "LOG IN"
    },
    logged: {
      message: "YOU ARE LOGGED IN"
    },
    enterAShortEasilyIdentifiableName: {
      message: "Enter a short, easily identifiable name"
    },
    highlightssubcategoryCannotBeCopied: {
      message: "Highlights subcategory cannot be copied"
    },
    aaaaaaaaaaaaaaaaaa: {
      message: "aaaaaaaaaaaaaaaaaaaaa"
    },
    unableToLoadCards : {
      message: "Unable to load cards"
    },
    invalidUser: {
      message: "Invalid user"
    },
    invalidPassword: {
      message: "Invalid password"
    },
    inactiveUser: {
      message: "Inactive user"
    }
  }
}
