import styled from 'styled-components';
import { Title24px400Regular, Paragraph16px300Light, Description12px400Regular } from '../../../styles/style-guide';
import Button from '../../../components/button/Button';

const LoginFormContent = styled.form`
  height: 100%;
  padding: 32px 24px;
  text-align: center;
`;

const LoginFormRow = styled.div`
  padding: 0 48px 24px 48px;
  text-align: initial;
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}px`}
`;

const LoginFormTextBox = styled.div`
  margin-bottom: 24px;
`;

const LoginFormTitle = styled.h3`
  ${Title24px400Regular};
  color: ${({ theme }) => theme.colors.textColor};
  margin-bottom: 28px;
`;

const LoginFormSubtitle = styled.h5`
  ${Paragraph16px300Light};
`;

const LoginFormErroMessage = styled.span`
  ${Description12px400Regular}
  color: ${({ theme }) => theme.colors.statusColorDanger};
`;

const LoginFormErrorBox = styled.div`
  margin: 5px 0 24px 0;
  min-height: 21px;
`;

const LoginFormButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginFormButtonForgotPassword = styled(Button)`
  padding: 8px 16px;
  margin-left: auto;
  margin-right: auto;
`;

const LoginFormButton = styled(Button)`
  padding: 8px 16px;
`;

export {
  LoginFormContent,
  LoginFormRow,
  LoginFormTextBox,
  LoginFormTitle,
  LoginFormSubtitle,
  LoginFormButtonBox,
  LoginFormButton,
  LoginFormErrorBox,
  LoginFormErroMessage,
  LoginFormButtonForgotPassword,
};
