import React from 'react';
import PropTypes from 'prop-types';

import MenuLink from './Styles';

const MenuItem = (props) => {
  const {
    children, isActive, to, name, onClick,
  } = props;
  const handleOnClick = (event) => {
    onClick(event);
  };

  return (
    <MenuLink name={name} isactive={isActive.toString()} to={to} onClick={handleOnClick}>
      {children}
    </MenuLink>
  );
};

MenuItem.defaultProps = {
  isActive: false,
  to: PropTypes.string,
  name: '',
  children: '',
  onClick: () => {},
};

MenuItem.propTypes = {
  isActive: PropTypes.bool,
  to: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.any,
    PropTypes.number,
  ]),
};

export default MenuItem;
