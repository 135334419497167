import MUITextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const MuiTextFieldStyled = withStyles({
  root: {
    width: "100%",
    margin: "0px",
    [`& fieldset`]: {
      borderRadius: 12,
    },
    "& label": {
      paddingLeft: 4,
      paddingRight: 8,
      backgroundColor: "#fff",
      color: "#FF5533",
      fontSize: 14,
    },
    "& label.Mui-focused": {
      color: "#FF5533",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#c6c6c6",
      },
      "&:hover fieldset": {
        borderColor: "#808080",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FF5533",
      },
    },
  },
})(MUITextField);

export { MuiTextFieldStyled };
