import formValidators from '../../../../../../../utils/form-validators';

const SignaturesPlanSignInModalForm = {
  securityCode: {
    value: '',
    isRequired: true,
    isValid: false,
    touched: false,
    label: 'CVV',
    placeholder: '000',
    errorsMsg: {
      required: 'Este campo é obrigatório.',
      invalidCode: 'Digite um código válido.',
    },
    hasErrors: {
      required: true,
      invalidCode: false,
    },
    validators: {
      required: formValidators.validateInputRequired,
      invalidCode: formValidators.validateCvvLength,
    },
  },
  isValid: false,
};

export default SignaturesPlanSignInModalForm;
