import React from "react";

import { TabButton, TabButtonContent, TabButtonIconAdd } from "./styles";

function ButtonAddTab({ onClickButton, buttonText, isTab = true }) {
  return (
    <TabButtonContent isTab={isTab}>
      <TabButton onClick={onClickButton}>
        <TabButtonIconAdd />
        {buttonText}
      </TabButton>
    </TabButtonContent>
  );
}

export default ButtonAddTab;
