import styled from 'styled-components';
import { ReactComponent as PaymentSVG } from '../../../../../../assets/payment.svg';
import { Paragraph16px300Light } from '../../../../../../styles/style-guide';

const SignaturesEmptyContent = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100%;
`;

const SignaturesEmptyPaymentImage = styled(PaymentSVG)``;

const SignaturesEmptyParagraph = styled.p`
  ${Paragraph16px300Light}
`;

export {
  SignaturesEmptyContent,
  SignaturesEmptyPaymentImage,
  SignaturesEmptyParagraph,
};
