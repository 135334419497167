import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../../../../../components/button/Button';
import { ModalTitle } from '../../../../../../../components/modal-mytm/Styles';
import {
  SignaturesPlanCancelModalWrapper, SignaturesPlanCancelModalContent,
  SignaturesPlanCancelModalLeftContent, SignaturesPlanCancelModalRightContent,
  SignaturesPlanCancelModalImage, SignaturesPlanCancelModalParagraph,
  SignaturesPlanCancelModalMainContent, SignaturesPlanCancelModalButtonsContent,
} from './Styles';
import TranslatorFunction from '../../../../../../../components/I18n/Translator';
const SignaturesPlanCancelModal = (props) => {
  const {
    open, isLoading, onConfirmCancel, onDismissCancel,
  } = props;

 
  const modalTitle = TranslatorFunction({ path: "wantLeave.message" });
  const cancelYourSubscription = TranslatorFunction({ path: "cancelYourSubscription.message" });
  const beforeConfirming = TranslatorFunction({ path: "beforeConfirming.message" });
  const goAhead = TranslatorFunction({ path: "goAhead.message" });
  const unsubscribePlan = TranslatorFunction({ path: "unsubscribePlan.message" });
  const dontCancel = TranslatorFunction({ path: "dontCancel.message" });
 

  return (
    <SignaturesPlanCancelModalWrapper open={open} isLoading={isLoading}>
      <SignaturesPlanCancelModalContent>
        <SignaturesPlanCancelModalMainContent>
          <SignaturesPlanCancelModalLeftContent>
            <ModalTitle>
              {modalTitle}
            </ModalTitle>
            <SignaturesPlanCancelModalParagraph>
              {cancelYourSubscription}
              <br />
              <br />
              {beforeConfirming}
              <br />
              <br />
              {goAhead}
            </SignaturesPlanCancelModalParagraph>
          </SignaturesPlanCancelModalLeftContent>
          <SignaturesPlanCancelModalRightContent>
            <SignaturesPlanCancelModalImage />
          </SignaturesPlanCancelModalRightContent>
        </SignaturesPlanCancelModalMainContent>
        <SignaturesPlanCancelModalButtonsContent>
          <Button onClick={onConfirmCancel}>
            {unsubscribePlan}
          </Button>
          <Button
            onClick={onDismissCancel}
            btnType="tertiary" >
            {dontCancel}
          </Button>
        </SignaturesPlanCancelModalButtonsContent>
      </SignaturesPlanCancelModalContent>
    </SignaturesPlanCancelModalWrapper>
  );
};
SignaturesPlanCancelModal.defaultProps = {
  isLoading: false,
  open: false,
  onConfirmCancel: () => { },
  onDismissCancel: () => { },
};

SignaturesPlanCancelModal.propTypes = {
  isLoading: PropTypes.bool,
  open: PropTypes.bool,
  onConfirmCancel: PropTypes.func,
  onDismissCancel: PropTypes.func,
};

export default SignaturesPlanCancelModal;
