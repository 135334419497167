import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SubCategoryForm from "./SubCategoryForm";
import ModalMytm from "../../../../../components/modal-mytm/ModalMytm";
import {
  ModalWrapper,
  ModalTitle,
  ModalBody,
  ModalFooter,
  ModalSubTitle,
} from "../../../../../components/modal-mytm/Styles";
import restCategories from "../../../../../api/categories/rest-categories";
import {
  NewSubCategoryForm,
  NewSubCategoryButtonSave,
  NewSubCategoryButtonGoBack,
  NewSubCategoryPhotoContent,
  NewSubCategoryPhotoTextContent,
  NewSubCategoryPhotoParagraph,
  NewSubCategoryPhotoArea,
  NewSubCategoryInputGroupWithToggle,
  NewSubCategoryPhotoAreaContent,
} from "./Styles";
import InputText from "../../../../../components/inputs/input-text/InputText";
import Toggle from "../../../../../components/toggle/Toggle";
import formUtils from "../../../../../utils/form-utils";
import {
  InputGroup,
  InputToggleContent,
} from "../../../../../components/styled/Forms";
import defaultNoProductPhoto from "../../../../../assets/productDefaultImg.png";
import User from "../../../../../utils/user";
import ImageCrop from "../../../../../components/image-crop/ImageCrop";
import formatters from "../../../../../utils/formatters";
import restSubcategories from "../../../../../api/subcategories/rest-subcategories";
import UniSelect from "../../../../../components/uni-select/UniSelect";
import TranslatorFunction from '../../../../../components/I18n/Translator';
const NewSubCategory = (props) => {

  SubCategoryForm.name.placeholder = TranslatorFunction({ path: SubCategoryForm.name.placeholder });
  SubCategoryForm.name.errorsMsg.required = TranslatorFunction({ path: SubCategoryForm.name.errorsMsg.required });
  SubCategoryForm.categoryId.placeholder = TranslatorFunction({ path: SubCategoryForm.categoryId.placeholder });
  SubCategoryForm.categoryId.errorsMsg.required = TranslatorFunction({ path: SubCategoryForm.categoryId.errorsMsg.required });
  const addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive = TranslatorFunction({ path: "addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive.message" });
  const chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos = TranslatorFunction({ path: "chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos.message" });
  const theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB = TranslatorFunction({ path: "theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB.message" });
  const selectPhoto = TranslatorFunction({ path: "selectPhoto.message" });
  const saveEditions = TranslatorFunction({ path: "saveEditions.message" });
  const saveAndAddAnother = TranslatorFunction({ path: "saveAndAddAnother.message" });
  const anErrorOccurredWhileFetchingProductCategories = TranslatorFunction({ path: "anErrorOccurredWhileFetchingProductCategories.message" });
  const addEditSubcategory = TranslatorFunction({ path: "addEditSubcategory.message" });
  const back = TranslatorFunction({ path: "back.message" });
  const language = TranslatorFunction({ path: "language.message" });
  const namee = TranslatorFunction({ path: "name.message" });
  const categoryInWhichTheSubcategoryShouldAppear = TranslatorFunction({ path: "categoryInWhichTheSubcategoryShouldAppear.message" });
  const active = TranslatorFunction({ path: "active.message" });
  const inactive = TranslatorFunction({ path: "inactive.message" });
  const imageOptional = TranslatorFunction({ path: "inactive.message" });


  SubCategoryForm.from.value = language;

  const { open, onClose, subCategory } = props;
  const [form, setForm] = useState({ ...SubCategoryForm });
  const [isEditMode, setIsEditMode] = useState(false);
  const [fileURL, setFileURL] = useState("");
  const [openModal, setOpenModal] = useState(open);
  const [isLoading, setIsLoading] = useState(false);
  const [multSelectOptions, setMultSelectOptions] = useState([]);

  const highlight = User.getHighlight();

  const handleCategoriesResponseSuccess = (categories) => {
    setMultSelectOptions(
      formatters.formatCategoriesToMultSelectOptions(categories, language)
    );
  };

  const fetchCategories = async () => {
    restCategories
      .fetchCategories()
      .then((res) => handleCategoriesResponseSuccess(res.data.categories))
      .catch(() => alert(anErrorOccurredWhileFetchingProductCategories));
  };

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line
  }, []);

  const handleOnMultSelectChange = (categories) => {
    setForm((oldForm) => {
      let newform = { ...oldForm };
      newform.categoryId.value = categories.value;
      newform.categoryId.name = categories.label;
      newform = formUtils.updateFormValidation(newform);
      newform.touched = true;
      return newform;
    });
  };

  const handleResponseError = () => {
    setForm(formUtils.resetForm(form));
    setIsLoading(false);
    setOpenModal(false);
    onClose();
  };

  const handleResponseSuccess = (res, next) => {
    if (next) {
      setForm(formUtils.resetForm(form));
      setFileURL("");
      setIsLoading(false);
      setIsEditMode(false);

    } else {
      setForm(formUtils.resetForm(form));
      setOpenModal(false);
      setIsLoading(false);
      setIsEditMode(false);
      onClose();
    }
  };

  const putSubCategory = (next) => {
    setIsLoading(true);
    const formRaw = formUtils.getFormRawValue(form);
    restSubcategories
      .putSubCategory(subCategory._id, formRaw)
      .then((res) => handleResponseSuccess(res, next))
      .catch((err) => handleResponseError(err, "ERROR"));
  };

  const postSubCategory = (next) => {
    setIsLoading(true);
    const formRaw = formUtils.getFormRawValue(form);
    restSubcategories
      .postSubCategory(formRaw)
      .then((res) => handleResponseSuccess(res, next))
      .catch((err) => handleResponseError(err, "ERROR"));
  };

  const handleOnFileChange = (eventFile) => {
    setForm((oldForm) => {
      let newform = { ...oldForm };
      newform.file.value = eventFile.file;
      newform = formUtils.updateFormValidation(newform);
      newform.touched = true;
      setFileURL(URL.createObjectURL(eventFile.file));
      return newform;
    });
  };

  const handleInputOnChange = (name, value) => {
    setForm((oldForm) => {
      let newform = { ...oldForm };
      newform[name].value = value;
      newform = formUtils.updateFormValidation(newform);
      newform.touched = true;
      return newform;
    });
  };

  const handleInputOnChangeToggle = (event) => {
    const { name, value } = event.target;
    setForm((oldForm) => {
      let newform = { ...oldForm };
      newform[name].value = value;
      newform = formUtils.updateFormValidation(newform);
      newform.touched = true;
      return newform;
    });
  }

  const sendForm = (next) =>
    isEditMode ? putSubCategory(next) : postSubCategory(next);

  const handleOnClickSave = (next) => {
    if (formUtils.isFormValid(form)) {
      sendForm(next);
    }
  };

  const handleOnClickGoBack = () => {
    setOpenModal(false);
    onClose();
  };

  const disableNameInput = () => isEditMode && subCategory._id === highlight;

  const disableSubmitButton = () => !formUtils.isFormValid(form);
  const initEditMode = () => {
    setForm((oldForm) => {
      const newForm = { ...oldForm };
      newForm.name.value = Array.isArray(subCategory.translatedName) && subCategory.translatedName.length > 0 && subCategory.translatedName[0].name[0][language]!==undefined? subCategory.translatedName[0].name[0][language] : subCategory.name;
      newForm.name.isValid = true;
      newForm.active.value = subCategory.active;
      newForm.active.isValid = true;
      newForm.categoryId.value = subCategory.category._id;
      newForm.categoryId.name = Array.isArray(subCategory.category.translatedName) && subCategory.category.translatedName.length > 0 && subCategory.category.translatedName[0].name[0][language] !==undefined? subCategory.category.translatedName[0].name[0][language] : subCategory.category.name;
      return newForm;
    });
    console.log(subCategory);
    if (subCategory.imageURL) {
      setFileURL(subCategory.imageURL);
    } else {
      setFileURL("");
    }
  };

  useEffect(() => {
    setOpenModal(open);
    setForm(formUtils.resetForm(form));
    setFileURL("");
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (Object.keys(subCategory).includes("name")) {
      setIsEditMode(true);
      initEditMode();
    }
    // eslint-disable-next-line
  }, [subCategory]);

  useEffect(() => {
    if (!open) {
      setIsEditMode(false);
    }
  }, [open]);

  const hasErrorOnField = (field) => !field.isValid && form.touched;
  return (
    <ModalMytm open={openModal} isLoading={isLoading}>
      <ModalWrapper>
        <ModalTitle>{addEditSubcategory}</ModalTitle>
        <ModalBody>
          <NewSubCategoryForm noValidate onSubmit={(e) => e.preventDefault()}>
            <InputGroup>
              <NewSubCategoryInputGroupWithToggle>
                <InputText
                  id="subcategoryName"
                  onChange={handleInputOnChange}
                  name="name"
                  value={form.name.value}
                  label={namee}
                  error={hasErrorOnField(form.name)}
                  required={form.name.isRequired}
                  placeholder={form.name.placeholder}
                  errorMessage={form.name.errorsMsg.required}
                  disabled={disableNameInput()}
                  maxLength="40"
                />
                <UniSelect
                  value={{
                    label: form.categoryId.name,
                    value: form.categoryId.value,
                  }}
                  onChange={handleOnMultSelectChange}
                  label={categoryInWhichTheSubcategoryShouldAppear}
                  name="categories"
                  placeholder={form.categoryId.placeholder}
                  error={hasErrorOnField(form.categoryId)}
                  options={multSelectOptions}
                  required={form.categoryId.isRequired}
                  errorMessage={form.categoryId.errorsMsg.required}
                />
              </NewSubCategoryInputGroupWithToggle>
              <InputToggleContent>
                <Toggle
                  value={form.active.value}
                  name="active"
                  textLeft={active}
                  textRight={inactive}
                  onChange={handleInputOnChangeToggle}
                />
              </InputToggleContent>
            </InputGroup>
            <NewSubCategoryPhotoContent>
              <NewSubCategoryPhotoTextContent>
                <ModalSubTitle>{imageOptional}</ModalSubTitle>
                <NewSubCategoryPhotoParagraph>
                  {addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive}
                  <br />
                  <br />
                  {chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos}
                  <br />
                  <br />
                  {theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB}
                </NewSubCategoryPhotoParagraph>
              </NewSubCategoryPhotoTextContent>
              <NewSubCategoryPhotoArea>
                <NewSubCategoryPhotoAreaContent>
                  <ImageCrop
                    value={fileURL}
                    onChange={handleOnFileChange}
                    buttonText={selectPhoto}
                    isDefaultImage={fileURL === defaultNoProductPhoto}
                    withIconAdd
                  />
                </NewSubCategoryPhotoAreaContent>
              </NewSubCategoryPhotoArea>
            </NewSubCategoryPhotoContent>
          </NewSubCategoryForm>
        </ModalBody>
        <ModalFooter>
          <NewSubCategoryButtonSave
            type="button"
            disabled={disableSubmitButton()}
            onClick={() => handleOnClickSave(false)}
          >
            {saveEditions}
          </NewSubCategoryButtonSave>
          <NewSubCategoryButtonSave
            type="button"
            disabled={disableSubmitButton()}
            onClick={() => handleOnClickSave(true)}
          >
            {saveAndAddAnother}
          </NewSubCategoryButtonSave>
          <NewSubCategoryButtonGoBack
            type="button"
            btnType="tertiary"
            onClick={handleOnClickGoBack}
          >
            {back}
          </NewSubCategoryButtonGoBack>
        </ModalFooter>
      </ModalWrapper>
    </ModalMytm >
  );
};
NewSubCategory.defaultProps = {
  subCategory: {},
  open: false,
  onClose: () => { },
};

NewSubCategory.propTypes = {
  subCategory: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default React.memo(NewSubCategory);
