export default {
  translations: {
    language: {
      message: 'de',
    },
    LoginFormTitle: {
      message: 'Anmeldung'
    },
    LoginFormSubtitle: {
      message: 'Hallo, geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein, um sich anzumelden:'
    },
    LoginFormErroMessage: {
      message: 'Anmeldung nicht möglich, bitte versuchen Sie es erneut.'
    },
    LoginFormButton: {
      message: 'Einloggen'
    },
    LoginFormButtonForgotPassword: {
      message: 'Ich habe das Passwort vergessen'
    },
    password: {
      message: 'Passwort'
    },
    confirmPassword: {
      message: 'Passwort bestätigen'
    },
    mail: {
      message: 'Email'
    },
    myEstablishment: {
      message: 'meine Einrichtung'
    },
    parameterization: {
      message: 'Parametrierung'
    },
    itemRegistration: {
      message: 'Artikelregistrierung'
    },
    orders: {
      message: 'Anfragen'
    },
    discountCoupons: {
      message: 'Rabatt Coupons'
    },
    logout: {
      message: 'hinausgehen'
    },
    establishmentDetails: {
      message: 'Einrichtungsdetails'
    },
    subscriptions: {
      message: 'Abonnements'
    },
    aboutTheEstablishment: {
      message: 'über die Einrichtung'
    },
    mandatoryFilling: {
      message: 'obligatorische Füllung'
    },
    tradeName: {
      message: 'Handelsname (Name, unter dem Ihr Betrieb bekannt ist)'
    },
    companyCorporateName: {
      message: 'sozialer Grund'
    },
    informTheCompanyCorporateName: {
      message: 'Informieren Sie den Firmennamen des Unternehmens'
    },
    pixel: {
      message: 'Pixel'
    },
    informPixel: {
      message: 'Pixel melden'
    },
    invalidCnpj: {
      message: 'Ungültiger CNPJ'
    },
    invalidCep: {
      message: 'Ungültige Postleitzahl'
    },
    state: {
      message: 'Zustand'
    },
    city: {
      message: 'Stadt'
    },
    cityUpper: {
      message: 'STADT'
    },
    neighborhoods: {
      message: 'Gegend'
    },
    street: {
      message: 'Straße'
    },
    number: {
      message: 'Nummer'
    },
    informTheNumber: {
      message: 'Geben Sie die Zahl ein'
    },
    complement: {
      message: 'Ergänzen'
    },
    complementUpper: {
      message: 'ERGÄNZEN'
    },
    URLToYourMenu: {
      message: 'URL zu Ihrem Menü'
    },
    emailToLoginToTheManager: {
      message: 'E-Mail zum Anmelden beim Manager'
    },
    name: {
      message: 'Name'
    },
    nameUpper: {
      message: 'NAME'
    },
    lastName: {
      message: 'Nachname'
    },
    contactEmail: {
      message: 'Kontakt E-mail'
    },
    cellphoneNumberWhatsApp: {
      message: 'Handy/WhatsApp'
    },
    thisFieldIsRequired: {
      message: 'Dieses Feld ist erforderlich.'
    },
    enterYourCreditCardDetails: {
      message: 'Geben Sie Ihre Kreditkartendaten ein:'
    },
    thisIsTheCurrentPaymentMethod: {
      message: 'Dies ist die aktuelle Zahlungsmethode:'
    },
    paymentMethod: {
      message: 'Zahlungsmethode:'
    },
    paymentMethod2: {
      message: 'Zahlungsarten'
    },
    paymentMethod3: {
      message: 'ZAHLUNGSARTEN'
    },
    cardNumber: {
      message: 'Kartennummer:'
    },
    enterAValidCardNumber: {
      message: 'Bitte geben Sie eine gültige Kartennummer ein.'
    },
    enterAValidDate: {
      message: 'Bitte gib ein korrektes Datum an.'
    },
    cardholderName: {
      message: 'Name des Karteninhabers.'
    },
    typeAsWrittenOnCard: {
      message: 'Tippen Sie wie auf der Karte geschrieben.'
    },
    enterTheNameAsItIsWrittenOnTheCard: {
      message: 'Geben Sie den Namen so ein, wie er auf der Karte steht.'
    },
    bySavingThePaymentDetailsYouAuthorizeMyTripMenuToChargeThePlanAmountAutomaticallyEveryMonthUntilYouCancelSubscriptionTermsAndConditionsAreAvailable: {
      message: 'Indem Sie Ihre Zahlungsdaten speichern, autorisieren Sie MyTripMenu, den Planbetrag jeden Monat automatisch zu berechnen, bis Sie Ihr Abonnement kündigen. Allgemeine Geschäftsbedingungen sind verfügbar'
    },
    inThisLink: {
      message: 'auf diesem Link.'
    },
    savePaymentData: {
      message: 'Zahlungsdaten speichern'
    },
    addACreditCardToViewPlanInformationAndConfirmYourSubscription: {
      message: 'Fügen Sie eine Kreditkarte hinzu, um Planinformationen anzuzeigen und Ihr Abonnement zu bestätigen.'
    },
    deliveryConfiguration: {
      message: 'Lieferkonfiguration'
    },
    schedules: {
      message: 'Zeitpläne'
    },
    Schedules: {
      message: 'ZEITPLÄNE'
    },
    add: {
      message: 'hinzufügen'
    },
    onSiteServiceVisualization: {
      message: 'Vor-Ort-Service | Visualisierung'
    },
    deliveryWhatsappOrders: {
      message: 'Lieferung | Anfragen per whatsapp'
    },
    takeAway: {
      message: 'Rückzug'
    },
    catalog: {
      message: 'Katalog'
    },
    typesOfService: {
      message: 'ARTEN VON DIENSTLEISTUNGEN'
    },
    mandatoryDataForDelivery: {
      message: 'PFLICHTANGABEN FÜR DIE LIEFERUNG'
    },
    phone: {
      message: 'Telefon'
    },
    phoneUpper: {
      message: 'TELEFON'
    },
    address: {
      message: 'Adresse'
    },
    generalSettings: {
      message: 'ALLGEMEINE EINSTELLUNGEN'
    },
    freeShippingForPurchasesAbove: {
      message: 'KOSTENLOSER VERSAND FÜR EINKÄUFE OBEN'
    },
    noFreeShipping: {
      message: 'Kein kostenloser Versand'
    },
    minimumValueForDeliveryOrders: {
      message: 'MINDESTWERT FÜR LIEFERAUFTRÄGE'
    },
    minimumDeliveryTime: {
      message: 'MINDESTLIEFERZEIT'
    },
    noForecast: {
      message: 'Keine Prognose'
    },
    maximumDeliveryTime: {
      message: 'MAXIMALE LIEFERZEIT'
    },
    orderResponseTimeInMinutes: {
      message: 'BESTELLUNGSANTWORTZEIT IN MINUTEN'
    },
    serviceNumberViaWhats: {
      message: 'SERVICENUMMER ÜBER WHATSAPP'
    },
    servicePlaces: {
      message: 'SERVICEPLÄTZE'
    },
    action: {
      message: 'AKTIONEN'
    },
    addEditCities: {
      message: 'STÄDTE HINZUFÜGEN/BEARBEITEN'
    },
    newCity: {
      message: 'Neue Stadt'
    },
    search: {
      message: 'Suche'
    },
    addNew: {
      message: 'Neue hinzufügen'
    },
    saveEditions: {
      message: 'AUSGABEN SPEICHERN'
    },
    exitWithoutSaving: {
      message: 'BEENDEN OHNE SPEICHERN'
    },
    saveEditionsAndExit: {
      message: 'ÄNDERUNGEN SICHERN UND BEENDEN'
    },
    back: {
      message: 'KOMM ZURÜCK'
    },
    status: {
      message: 'STATUS'
    },
    active: {
      message: 'AKTIV'
    },
    inactive: {
      message: 'INAKTIV'
    },
    category: {
      message: 'Kategorie'
    },
    subcategory: {
      message: 'Unterkategorie'
    },
    categories: {
      message: 'Kategorien'
    },
    subcategories: {
      message: 'Unterkategorien'
    },
    categoriesUpper: {
      message: 'KATEGORIEN'
    },
    subcategoriesUpper: {
      message: 'UNTERKATEGORIEN'
    },
    complements: {
      message: 'Zusätze'
    },
    complementsGroup: {
      message: 'Zusatzgruppe'
    },
    complementsGroupUpper: {
      message: 'GRUPPE VON ERGÄNZUNGEN'
    },
    products: {
      message: 'Produkte'
    },
    image: {
      message: 'BILD'
    },
    yes: {
      message: 'Ja'
    },
    no: {
      message: 'Nein'
    },
    theHighlightsCategoryCannotBeEdited: {
      message: 'Die Kategorie "Highlights" kann nicht bearbeitet werden.'
    },
    sortFromAZ: {
      message: 'Von A-Z sortieren'
    },
    sortFromZA: {
      message: 'Sortieren nach Z-A'
    },
    sortByActive: {
      message: 'Sortieren nach aktiv'
    },
    sortByInactive: {
      message: 'Sortieren nach inaktiv'
    },
    listActiveOnly: {
      message: 'Nur Assets-Liste'
    },
    addEditCategory: {
      message: 'Kategorie hinzufügen/bearbeiten'
    },
    addEditSubcategory: {
      message: 'Unterkategorie hinzufügen/bearbeiten'
    },
    imageOptional: {
      message: 'Bild (optional)'
    },
    addingIllustrativeImageToTheCategoryIsAnOptionalActionHoweverItHelpsToMakeYourMenuMoreAttractive: {
      message: 'Das Hinzufügen eines illustrativen Bildes zur Kategorie ist eine optionale Aktion, hilft jedoch dabei, Ihr Menü attraktiver zu machen.'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisCategoryHaveGoodLightingAndBeRealisticAbouttoTheActualPresentationOfTheProductsAvoidUsingPhotosGenericStockImageOrLowQuality: {
      message: 'Wählen Sie ein Foto, das die Artikel dieser Kategorie darstellt, eine gute Beleuchtung hat und in Bezug auf die tatsächliche Präsentation der Produkte realistisch ist. Vermeiden Sie die Verwendung von generischen, Stock- oder Fotos von geringer Qualität.'
    },
    theIdealResolutionIs800x600PixelsAndTheImageIsNotMayBeLargerThan1MB: {
      message: 'Die optimale Auflösung beträgt 800 x 600 Pixel und das Bild darf nicht größer als 1 MB sein.'
    },
    selectPhoto: {
      message: 'FOTO WÄHLEN'
    },
    selectCategory: {
      message: 'KATEGORIE WÄHLEN'
    },
    saveAndAddAnother: {
      message: 'SPEICHERN UND HINZUFÜGEN'
    },
    highlightsSubcategoryCannotBeEdited: {
      message: 'Die Unterkategorie "Highlights" kann nicht bearbeitet werden.'
    },
    categoryInWhichTheSubcategoryShouldAppear: {
      message: 'Kategorie, in der die Unterkategorie erscheinen soll'
    },
    addingIllustrativeImageToTheSubcategoryIsAnActionOptionalHoweverHelpsToMakeYourMenuMoreAttractive: {
      message: 'Das Hinzufügen eines illustrativen Bildes zur Unterkategorie ist eine optionale Aktion, hilft jedoch dabei, Ihr Menü attraktiver zu machen.'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisSubcategoryHasGoodLightingAndIsRealisticInRelationToTheActualPresentationOfTheProductsAvoidUsingGenericStockOrLowQualityPhotos: {
      message: 'Wählen Sie ein Foto aus, das die Artikel dieser Unterkategorie illustriert, eine gute Beleuchtung hat und in Bezug auf die tatsächliche Präsentation der Produkte realistisch ist. Vermeiden Sie die Verwendung von generischen, Stock- oder Fotos von geringer Qualität.'
    },
    enterANameForTheSubcategory: {
      message: 'Geben Sie einen Namen für die Unterkategorie ein.'
    },
    enterANameForTheCategory: {
      message: 'Geben Sie einen Namen für die Kategorie ein'
    },
    descriptionOfComplement: {
      message: 'Add-on-Beschreibung'
    },
    descriptionOfComplementUpper: {
      message: 'ERGÄNZUNGSBESCHREIBUNG'
    },
    addComplementGroup: {
      message: 'ERGÄNZUNGSGRUPPE HINZUFÜGEN'
    },
    complementGroupName: {
      message: 'Add-on-Gruppenname'
    },
    complementName: {
      message: 'Add-on-Name'
    },
    checkAllFields: {
      message: 'Überprüfen Sie alle Felder.'
    },
    complementGroupIsInactive: {
      message: 'Add-On-Gruppe ist inaktiv.'
    },
    value: {
      message: 'Wert'
    },
    valueUpper: {
      message: 'WERT'
    },
    addProduct: {
      message: 'PRODUKT HINZUFÜGEN'
    },
    images: {
      message: 'Bilder'
    },
    chooseAPhotoThatIllustratesTheItemsThatMakeUpThisProductHasGoodLightingAndIsRealisticInRelationToYourActualPresentationAvoidUsingGenericStockOrLowQualityPhotos: {
      message: 'Wählen Sie ein Foto aus, das die Artikel veranschaulicht, aus denen dieses Produkt besteht, das eine gute Beleuchtung hat und im Verhältnis zu Ihrer tatsächlichen Präsentation realistisch ist. Vermeiden Sie die Verwendung von generischen, Stock- oder Fotos von geringer Qualität.'
    },
    enableOn: {
      message: 'einschalten'
    },
    categorysInWhichTheProductShouldAppear: {
      message: 'Kategorie(n), in der das Produkt erscheinen soll'
    },
    subCategorysInWhichTheProductShouldAppear: {
      message: 'Unterkategorie(n), in denen das Produkt erscheinen soll'
    },
    categorization: {
      message: 'Kategorisierung'
    },
    shortDescriptionShownInProductDetail: {
      message: 'Kurzbeschreibung (wird in den Produktdetails angezeigt)'
    },
    longDescriptionShownInProductDetail: {
      message: 'Lange Beschreibung (wird im Produktdetail angezeigt)'
    },
    enterTheIngredientsThatMakeUpThisProductItsAccompanimentsAndThePortionSizes: {
      message: 'Geben Sie die Zutaten ein, aus denen dieses Produkt besteht, seine Beilage(n) und die Portionsgrößen.'
    },
    enterSomethingLikeSideDishesOrPortionSize: {
      message: 'Geben Sie etwas wie Beilagen oder Portionsgröße ein.'
    },
    principalInformation: {
      message: 'Hauptinformationen'
    },
    id: {
      message: 'ICH WÜRDE'
    },
    type: {
      message: 'TYP'
    },
    validity: {
      message: 'Gültigkeit'
    },
    validityUpper: {
      message: 'GÜLTIGKEIT'
    },
    useAvailable: {
      message: 'VERWENDUNG / VERFÜGBAR'
    },
    filter: {
      message: 'Filter'
    },
    freeDelivery: {
      message: 'KOSTENLOSE LIEFERUNG'
    },
    discount: {
      message: 'RABATT'
    },
    aCouponWithThisCodeAlreadyExists: {
      message: 'Ein Gutschein mit diesem Code existiert bereits.'
    },
    dateTime: {
      message: 'TERMINZEIT'
    },
    change: {
      message: 'DING'
    },
    formOfPayment: {
      message: 'ZAHLUNGSART'
    },
    customer: {
      message: 'CLIENKLIENT'
    },
    uninformed: {
      message: 'Nicht informiert'
    },
    recordsFound: {
      message: 'Aufzeichnungen gefunden'
    },
    QRCodeOfYourDigitalMenu: {
      message: 'QR-Code Ihrer digitalen Speisekarte'
    },
    downloadYourQRCodeAndMakeItAvailableAtYourEstablishmentOrInPromotionalMaterialsToFacilitateCustomerAccessToYourDigitalMenu: {
      message: 'Laden Sie Ihren QR-Code herunter und stellen Sie ihn in Ihrer Einrichtung oder in Werbematerialien zur Verfügung, um den Kunden den Zugang zu Ihrer digitalen Speisekarte zu erleichtern.'
    },
    areYouSureYouWantToDoThis: {
      message: 'Sind Sie sicher, dass Sie das tun wollen?'
    },
    whenDisablingTheComplementAllPreviouslyLinkedComplementGroupsWillBeUnlinkedFromTheProduct: {
      message: 'Durch das Deaktivieren des Add-Ons werden alle zuvor verknüpften Add-On-Gruppen vom Produkt getrennt.'
    },
    anErrorOccurredWhileFetchingProductCategories: {
      message: 'Beim Abrufen der Produktkategorien ist ein Fehler aufgetreten.'
    },
    anErrorOccurredWhileFetchingProductOperations: {
      message: 'Beim Abrufen der Produktvorgänge ist ein Fehler aufgetreten.'
    },
    anErrorOccurredWhileFetchingTheSubcategoriesForTheSelectedCategory: {
      message: 'Beim Abrufen der Unterkategorien für die ausgewählte Kategorie ist ein Fehler aufgetreten.'
    },
    productImage: {
      message: 'Produktbild'
    },
    whenYouInactivateAProductItWillNoLongerAppearOnTheOnlineMenus: {
      message: 'Wenn Sie ein Produkt deaktivieren, wird es nicht mehr in den Online-Menüs angezeigt.'
    },
    RememberThatYouCanActivateItAgainAtAnotherTimeIfNecessary: {
      message: 'Denken Sie daran, dass Sie es bei Bedarf zu einem anderen Zeitpunkt erneut aktivieren können.'
    },
    inactivateTheProduct: {
      message: 'DAS PRODUKT DEAKTIVIEREN'
    },
    keepActive: {
      message: 'BLEIBEN SIE AKTIV'
    },
    simpleEasyFastDigital: {
      message: 'Einfach. Einfach. Schnell. Digital.'
    },
    errorIdentification: {
      message: 'Fehleridentifikation'
    },
    tryAgain: {
      message: 'VERSUCHEN SIE ES NOCHMAL'
    },
    huhLooksLikeSomethingWentWrong: {
      message: 'Huh! Scheint etwas schief gelaufen zu sein...'
    },
    unableToSaveCreditCardDetails: {
      message: 'Kreditkartendetails können nicht gespeichert werden.'
    },
    mmyy: {
      message: 'MM/JJ'
    },
    removePhoto: {
      message: 'Foto entfernen'
    },
    addEditCoupon: {
      message: 'GUTSCHEIN HINZUFÜGEN/BEARBEITEN'
    },
    couponType: {
      message: 'GUTSCHEINTYP'
    },
    freeShipping: {
      message: 'Kostenloser Versand'
    },
    discountByValue: {
      message: 'Rabatt nach Wert'
    },
    percentageDiscount: {
      message: 'Prozentrabatt'
    },
    generateCoupon: {
      message: 'GUTSCHEIN GENERIEREN'
    },
    limitOfUse: {
      message: 'NUTZUNGSBEGRENZUNG'
    },
    couponCode: {
      message: 'GUTSCHEINCODE'
    },
    validFrom: {
      message: 'GÜLTIG AB'
    },
    validUntil: {
      message: 'GÜLTIG BIS'
    },
    highlightsSubcategoryCannotBeCopied: {
      message: 'Die Unterkategorie "Highlights" kann nicht kopiert werden'
    },
    nothingHere: {
      message: 'Sieht so aus, als wäre hier noch nichts.'
    },
    from: {
      message: "IN"
    },
    to: {
      message: "BIS ZU"
    },
    sunday: {
      message: "Sonntag"
    },
    monday: {
      message: "Zweite"
    },
    tuesday: {
      message: "Dienstag"
    },
    wesnesday: {
      message: "Vierte"
    },
    thursday: {
      message: "Fünfte"
    },
    friday: {
      message: "Freitag"
    },
    saturday: {
      message: "Samstag"
    },
    accessData: {
      message: "Zugangsdaten"
    },
    responsibleEstablishment: {
      message: "Verantwortlich für die Einrichtung"
    },
    saveEditions2: {
      message: "Ausgaben speichern"
    },
    zeit: {
      message: "zeit"
    },
    cardsAccepted: {
      message: "Markieren Sie die von Ihrer Einrichtung akzeptierten Karten"
    },
    pixKey: {
      message: "Registrieren Sie Ihren PIX-Schlüssel hier"
    },
    noOptions: {
      message: "keine Optionen"
    },
    neighborhoods2: {
      message: "NACHBARSCHAFTEN"
    },
    addEditNeighborhoods: {
      message: "NACHBARSCHAFTEN HINZUFÜGEN/BEARBEITEN"
    },
    newNeighborhoods: {
      message: "Neue Nachbarschaft"
    },
    qtyMinimum: {
      message: "Menge MINIMUM"
    },
    qtymaximum: {
      message: "Menge MAXIMAL"
    },
    linkGroup: {
      message: "LINK SUPPLIES-GRUPPE"
    },
    importantGroupComplements: {
      message: "GRUPPE VON ERGÄNZUNGEN IMPORTIEREN"
    },
    people: {
      message: "Personen"
    },
    house: {
      message: "Häuser"
    },
    subcategoryProducts: {
      message: "Diese Unterkategorie hat keine zugehörigen Produkte"
    },
    productNotAdd: {
      message: "Diesem Produkt sind keine Add-Ons hinzugefügt"
    },
    linkSupplements: {
      message: "VERKNÜPFUNG ERGÄNZUNGEN"
    },
    PRICE: {
      message: "PREIS"
    },
    COMPLEMENTS: {
      message: "ERGÄNZUNGEN"
    },
    DESCRIPTION: {
      message: "BEZEICHNUNG"
    },
    action2: {
      message: "Aktionen"
    },
    pixKey2: {
      message: "Ihr PIX-Schlüssel"
    },
    ifYouLeaveThePropertyDetailsPageBeforeSavingYourChangesYouWillLoseAnyInformationYouHaveAlreadyFilledIn: {
      message: "Wenn Sie die Eigenschaftsdetailseite verlassen, bevor Sie Ihre Änderungen gespeichert haben, gehen alle Informationen verloren, die Sie bereits ausgefüllt haben."
    },
    couldNotLoadUserData: {
      message: "Benutzerdaten konnten nicht geladen werden"
    },
    termsOfUseAanServices: {
      message: "NUTZUNGSBEDINGUNGEN UND DIENSTLEISTUNGEN"
    },
    //
    hiGladToHaveYourInterestBeforeUsingOurServicesPleaseTakeTheTimeToReadOurTermsOfUseAndServiceAndLearnAboutTheRulesThatGovernOurRelationshipWithYou: {
      message: "Hi! Freut mich über Ihr Interesse! Bevor Sie unsere Dienste nutzen, nehmen Sie sich bitte die Zeit, unsere Nutzungs- und Servicebedingungen zu lesen und sich über die Regeln zu informieren, die unsere Beziehung zu Ihnen regeln."
    },
    belowWeWillClarifySomePointsThatWeConsiderImportantIfYouHaveAnyDoubtsAboutAnyPointsDiscussedOrNotInThisDocumentPleaseDoNotHesitateToContactUsAtHelloMyTripMenuCom: {
      message: "Im Folgenden Werden wir einige Punkte klären, sterben wir für wichtig halten. Wenn Sie Zweifel an Punkten haben, sterben in Dokument diesem besprochen wurden oder nicht, zögern Sie bitte nicht, uns unter hello@mytripmenu.com zu kontaktieren."
    },
    definitions: {
      message: "1. DEFINITIONEN:"
    },
    inThisInstrumentWeUnderstandTheExpressionsBelowAccordingToTheFollowingDefinitions: {
      message: "In diesem Instrument verstehen wir die nachstehenden Ausdrücke gemäß den folgenden Definitionen:"
    },
    plataform: {
      message: "PLATTFORM"
    },
    webSystemAvailableThroughMyTripMenuComBrOfferedBy: {
      message: "Websystem verfügbar über mytripmenu.com.br, angeboten von"
    },
    andOwnershipOperationAndLiabilityOf: {
      message: " und Eigentum, Betrieb und Haftung von "
    },
    users: {
      message: "BENUTZER"
    },
    user: {
      message: "BENUTZER"
    },
    where: {
      message: ", wo"
    },
    allThe: {
      message: "alle sterben"
    },
    canViewAndUseTheServicesOfferedBy: {
      message: "können die von angebotenen Dienste einsehen und nutzen"
    },
    establishment: {
      message: "EINRICHTUNGEN"
    },
    establishments: {
      message: "EINRICHTUNGEN"
    },
    legalEntityRegisteredInThe: {
      message: "juristische Person eingetragen in"
    },
    whichContractsTheServicesOfferedBy: {
      message: ", die die von angebotenen Dienstleistungen vertraglich abschließt"
    },
    client: {
      message: "KLIENT(IN)"
    },
    naturalPersonAccessingThe: {
      message: "natürliche Person, die auf die"
    },
    toViewTheMenu: {
      message: "um das Menü anzuzeigen "
    },
    dataProcessing: {
      message: "DATENVERARBEITUNG"
    },
    pursuantToArticle5IitemXOfLaw13709Of2018EveryTransactionCarriedOutBy: {
      message: " Gemäß Artikel 5, Punkt X des Gesetzes 13.709 von 2018 ist jede Transaktion, die von "
    },
    withPersonalDataOf: {
      message: " mit personenbezogenen Daten von "
    },
    suchAsThoseReferringToTheCollectionProductionReceptionClassificationUseAccessReproductionTransmissionDistributionProcessingArchivingStorageEliminationEvaluationOrControlOfInformationModificationCommunicationTransferDisseminationOrExtraction: {
      message: " , wie zum Beispiel diejenigen, die sich auf die Erhebung, Produktion, den Empfang, die Klassifizierung, die Verwendung, den Zugriff, die Reproduktion, die Übertragung, die Verbreitung, die Verarbeitung, die Archivierung, die Speicherung, die Beseitigung, die Auswertung oder die Kontrolle von Informationen, die Änderung, die Kommunikation, die Übertragung, die Verbreitung oder die Extraktion beziehen. "
    },
    admission: {
      message: " 2. EINTRITT: "
    },
    thisInstrumentRegulatesTheConditionsOfUseOfThe: {
      message: " Dieses Instrument regelt die Nutzungsbedingungen des "
    },
    being: {
      message: "Sein"
    },
    aContractBetweenThe: {
      message: "ein Vertrag zwischen "
    },
    andThe: {
      message: " und die "
    },
    theUseOfTheServicesOfferedThrough: {
      message: " Die Nutzung der angebotenen Dienste durch "
    },
    fromThe: {
      message: "von dem "
    },
    expresslyIndicatesThatYouAgreeWithAllTheTermsAndConditionsContainedInThisInstrumentAndWithTheLegalProvisionsApplicableToTheSpecies: {
      message: " weist ausdrücklich darauf hin, dass Sie mit allen in diesem Dokument enthaltenen Bedingungen und den für die Art geltenden gesetzlichen Bestimmungen einverstanden sind. "
    },
    the: {
      message: "Das "
    },
    understandsThatTheServiceContractingModelWillBeThroughPrepaidMonthlySubscriptionPlansBudgetedAndOfferedBy: {
      message: " versteht, dass das Dienstvertragsmodell durch monatliche Prepaid-Abonnementpläne finanziert und angeboten wird von "
    },
    accordingToInformationAvailableInThePlansAreaOfTheWebsite: {
      message: "gemäß den im Planbereich der Website verfügbaren Informationen: "
    },
    forTheContractingOfCustomizedPlansThe: {
      message: "Für die Beauftragung von kundenspezifischen Plänen ist die "
    },
    shouldContactThe: {
      message: "sollte sich an die "
    },
    throughItsChannelsForTheElaborationOfASpecificBudget: {
      message: "über seine Kanäle zur Ausarbeitung eines spezifischen Budgets. "
    },
    youUnderstandAndAgreeThatThe: {
      message: "SIE VERSTEHEN UND STIMMEN ZU, DASS DIE "
    },
    useOfTheProvidedServicesWillBeConsideredYourAcceptanceOfTheseTermesAndAllOtherProvisionsRelevantToTheSpecies: {
      message: " DIE NUTZUNG DER BEREITGESTELLTEN DIENSTE WIRD ALS IHR EINVERSTÄNDNIS ZU DIESEN BEDINGUNGEN UND ALLEN ANDEREN GESETZLICHEN BESTIMMUNGEN GELTEN, DIE FÜR DIE ARTEN RELEVANT SIND."
    },
    ByAcceptingTheTermsOfThisInstrumentTheUserExpresslyAuthorizesTheProcessingOfHisDataInOrderToGuaranteeTheMaintenanceAndGoodPerformanceOfTheFunctionalitiesOfThePlataform: {
      message: "DURCH DAS AKZEPTIEREN DER BEDINGUNGEN DIESES INSTRUMENTS AUTORISIERT DER BENUTZER AUSDRÜCKLICH DIE VERARBEITUNG SEINER DATEN, UM DIE WARTUNG UND GUTE LEISTUNG DER FUNKTIONALITÄTEN DER PLATTFORM ZU GEWÄHRLEISTEN."
    },
    theUserInThisActManifestsItsCompleteConsentToTheSharingOfDataCollectedAndProcesseBy: {
      message: "DER BENUTZER ERKLÄRT IN DIESEM RECHTSAKT SEINE VOLLSTÄNDIGE ZUSTIMMUNG ZUR WEITERGABE VON DATEN, DIE VON "
    },
    pursuantToThisInstrumentWithOtherMyTripMenusThatArePartOfYourEconomicGroupOrAreYourServiceProviders: {
      message: "GEMÄSS DIESEM INSTRUMENT MIT ANDEREN MENÜS VON MY TRIP, DIE ZU IHRER WIRTSCHAFTSGRUPPE ODER IHREN DIENSTLEISTERN GEHÖREN."
    },

    caseYou: {
      message: "Fall du "
    },
    doNotAgree: {
      message: "NICHT DAFÜR"
    },
    withTheProvisionsOfThisInstrument: {
      message: " mit den Bestimmungen dieses Instruments "
    },
    dont: {
      message: 'NICHT'
    },
    accessViewDownloadOrOtherwiseUseAnyPageContentInformationOrServiceFrom: {
      message: "auf Seiten, Inhalte, Informationen oder Dienste von "
    },
    theseTermsAreAvailableForReadingAtAnyTimeOn: {
      message: "Diese Bedingungen können jederzeit unter "
    },
    inMessage: {
      message: "in "
    },
    whoWeAreAndWhatWeDo: {
      message: "3. WER WIR SIND UND WAS WIR TUN:"
    },
    isAServiceProviderThaHasDevelopedAndLicensedASystemInTheFormOfSaaSWhitSpecificFeatureToHelp: {
      message: "ist ein Dienstanbieter, der ein System in Form von SaaS (Software as a Service) entwickelt und lizenziert hat, mit spezifischen Funktionen zur Unterstützung"
    },
    and: {
      message: "und"
    },
    soThe: {
      message: "Also, die"
    },
    offersADigitalMenuAllowingAnImpovementInEfficiencyAndExperienceForBothThe: {
      message: "bietet ein digitales Menü, das eine Verbesserung der Effizienz und Erfahrung sowohl für die"
    },
    asForThe: {
      message: "Wie für die"
    },
    generalConditionsOfUse: {
      message: "4. ALLGEMEINE NUTZUNGSBEDINGUNGEN:"
    },
    onlyProvidesThe: {
      message: " bietet nur die "
    },
    whichOffersDigitalMenuServicesForThe: {
      message: "die digitale Menüservices für die "
    },
    itsResponsabilityBeingRestrictedOnlyToTheCorrectFunctioningOfThe: {
      message: "seine Verantwortung beschränkt sich nur auf das ordnungsgemäße Funktionieren des "
    },
    andItsFunctionalitiesAccordingToiThisInstrumentAndThePlanContractedByThe: {
      message: "und seine Funktionen gemäß diesem Instrument und dem von der "
    },
    notStoppingThe: {
      message: ", nicht stoppen die"
    },
    noResponsabilityForTheCreationCustomizationAndDisseminationOfContentThroughTheDigitalMenu: {
      message: "keine Verantwortung für die Erstellung, Anpassung und Verbreitung von Inhalten über das digitale Menü."
    },
    justLicenseThe: {
      message: " lizenziere einfach die "
    },
    toThe: {
      message: "zum "
    },
    toThe2: {
      message: "zum "
    },
    andThereIsNoOtherRelationshipBetweenThesePartiesSoThatItIsNotPossibleToAttributeThe: {
      message: ", und es besteht keine andere Beziehung zwischen diesen Parteien, so dass es nicht möglich ist, dem"
    },
    liabilityForAnyDamageCauseToOthers: {
      message: "Haftung für Schäden, die anderen zugefügt werden"
    },
    orToThirdPartiesForActsArisingFrom: {
      message: "oder an Dritte, für Handlungen, die sich aus "
    },
    whileUsingTheAvailableFeaturesOfThe: {
      message: "während Sie die verfügbaren Funktionen des "
    },
    declaresThatHeUnderstandsAndAcceptsThatThe: {
      message: "erklärt, dass er versteht und akzeptiert, dass die "
    },
    doesNotIntendToReplaceThePhysicalMenuAvailableAtTheCommercialUnitOfThe: {
      message: "beabsichtigt nicht, das physische Menü zu ersetzen, das in der Handelseinheit des "
    },
    beingOnlyAComplementarySolutionInOrderToFacilitateComunicationBetween: {
      message: "nur eine ergänzende Lösung, um die Kommunikation zwischen "
    },
    atTheTimeOfRegistrationThe: {
      message: "Zum Zeitpunkt der Registrierung ist die"
    },
    youCanUseAllTheServicesAvailableOnThe: {
      message: "Sie können alle Dienste nutzen, die auf dem "
    },
    declaringThaTheyHaveReadUnderstoodAndAcceptedAllTheProvisionsContainedInTheTermsOfUse: {
      message: " und erklären, dass sie alle in diesen Nutzungsbedingungen enthaltenen Bestimmungen gelesen, verstanden und akzeptiert haben."
    },
    expresselyAcknowledgesThatThroughThisInstrumentItReceivesFrom: {
      message: "erkennt ausdrücklich an, dass sie durch dieses Instrument von "
    },
    theGarantingOfALicenseToUseThe: {
      message: " die Erteilung einer Lizenz zur Nutzung der "
    },
    isNonTransferableAndSublicensingIsProhibitedForUseInNationalOrForeignTerritoryForAsLongAsTheAdhesionToThisTermLastsLeavingTheUseOfThe: {
      message: ", ist nicht übertragbar, und die Unterlizenzierung ist für die Verwendung im In- oder Ausland verboten, solange die Bindung an diese Bedingung andauert, wobei die Verwendung der "
    },
    inDisagreementWithTheProvisionOfThisInstrument: {
      message: " im Widerspruch zu den Bestimmungen dieses Instruments stehen."
    },
    isSolelyResponsibleForTheSecurityOfYourPasswordAndForTheUseOfYourRestrationAt: {
      message: " ist allein verantwortlich für die Sicherheit Ihres Passworts und für die Nutzung Ihrer Registrierung bei "
    },
    thereforeWeRecommendThatYouDoNotShareSuchInformationWithThirdPartiesAndIfThisInformationIsForAnyReasonLostOr: {
      message: "Daher empfehlen wir Ihnen, diese Informationen nicht an Dritte weiterzugeben und, falls diese Informationen aus irgendeinem Grund verloren gehen oder "
    },
    hacked: {
      message: "gehackt"
    },
    mustImmediatelyInformThe: {
      message: "muss unverzüglich die "
    },
    throughHelloMyTripMenuComInOrderToResolveTheIssue: {
      message: ", über hello@mytripmenu.com, um das Problem zu lösen."
    },
    itIsOnlyUpToThe: {
      message: "Es liegt nur an der "
    },
    liableForAnyDamagesCausedToThirdPartiesToOther: {
      message: " haftet für Schäden, die Dritten, anderen"
    },
    orToItself: {
      message: "oder zu sich selbst "
    },
    resultingFromTheUseOfTheFeaturesOfThe: {
      message: ", die sich aus der Nutzung der Funktionen des "
    },
    shouldNotUseTheServicesAvailableOnThe: {
      message: " sollten die auf dem "
    },
    forAnyUnlawfulDefamatoryDiscriminatoryAbusiveOffensivePornographicObsceneAggressiveInjuriousVexatiousDeceptiveSlanderousViolentVulgarOrHarassingThreateningOrUseOfFalseIdentityIeAnyShadyUsePurposesOrMeansThatCouldHarmThe: {
      message: " für rechtswidrige, verleumderische, diskriminierende, beleidigende, beleidigende, pornografische, obszöne, aggressive, verletzende, ärgerliche, täuschende, verleumderische, gewalttätige, vulgäre oder belästigende, bedrohende oder die Verwendung falscher Identitäten, d könnte dem schaden"
    },
    others: {
      message: " Andere"
    },
    orThirdParties: {
      message: " oder Dritte"
    },
    UnderNoCircumstancesWillThe: {
      message: "Unter keinen Umständen wird die "
    },
    willBeLiableForAnyDamagesIncurredByThe: {
      message: "haftet für alle Schäden, die durch die "
    },
    forPossibleTemporaryUnavailabilityOfThe: {
      message: "für eine mögliche vorübergehende Nichtverfügbarkeit des "
    },
    orOnyContentMadeAvailableByThe: {
      message: "oder Inhalte, die von "
    },
    onTheDigitalMenu: {
      message: "auf der digitalen Speisekarte."
    },
    mustHaveAllNecessarySoftwareAndHardwareToAccessThe: {
      message: " muss über die erforderliche Soft- und Hardware verfügen, um auf die "
    },
    includingButNotLimitedToAComputerOrMobileDeviceWithInternetAccessAndThe: {
      message: ", einschließlich, aber nicht beschränkt auf einen Computer oder ein mobiles Gerät mit Internetzugang, und die "
    },
    onlyToProvideThe: {
      message: ", nur um die "
    },
    to2: {
      message: "zu "
    },
    underThisInstrumentToThe: {
      message: "unter diesem Instrument. Zum "
    },
    accessTheDigitalMenuADeviceCapableOfReadingTheQRCodeWillBeIndispensable: {
      message: "Zugriff auf das digitale Menü, ein Gerät, das den QR-Code lesen kann, ist unverzichtbar."
    },
    byThe: {
      message: "bis zum "
    },
    isConditionedToItsPriorRegistrationAsWellAsComplianceWithTheProvisionsContainedInThisInstrument: {
      message: "ist von seiner vorherigen Registrierung sowie der Einhaltung der in diesem Instrument enthaltenen Bestimmungen abhängig."
    },
    registration: {
      message: "5. ANMELDUNG"
    },
    soThatThe: {
      message: "So, dass die "
    },
    registerInThe: {
      message: " registrieren Sie sich im "
    },
    mustProvideTo: {
      message: "muss zur Verfügung stellen "
    },
    theFollowingData: {
      message: " folgende Daten"
    },
    corporateName: {
      message: "Firmenname;"
    },
    unitAddressOf: {
      message: "Geräteadresse von "
    },
    contactNumberInTheEventThatThe: {
      message: "Kontakt Nummer. Für den Fall, dass die"
    },
    hireThe: {
      message: "miete die"
    },
    forMoreUnitsYouMustRegisterTheOtherAddressesOfInterest: {
      message: "für mehr Einheiten müssen Sie die anderen Adressen von Interesse registrieren."
    },
    forRegularUseOf: {
      message: "Für den regelmäßigen Gebrauch von "
    },
    mustRegisterFillingInAllTheDataRequestedByThe: {
      message: "muss sich registrieren und alle vom "
    },
    atTheTimeOfRegistrationAsWellAsSelectingTheServicePlanOfferedBy: {
      message: "zum Zeitpunkt der Registrierung sowie die Auswahl des von  angebotenen Serviceplans"
    },
    itIsTheResponsibilityOf: {
      message: "Es liegt in der Verantwortung von "
    },
    identifyInTheContractedPlanTheNumberOfUnitsContemplatedInItsContracting: {
      message: "Geben Sie im vertraglich vereinbarten Plan die Anzahl der Einheiten an, die in seinem Vertrag vorgesehen sind."
    },
    itIsTheSoleResponsibilityOf: {
      message: "Es liegt in der alleinigen Verantwortung von"
    },
    provideUpdateAndGuaranteeTheVeracityOfTheRegistrationDataNotFallingToThe: {
      message: "Bereitstellung, Aktualisierung und Gewährleistung der Richtigkeit der Registrierungsdaten, die nicht unter die"
    },
    anyTypeOfCivilAndCriminalLiabilityResultingFromUntrueIncorrectOrIncompleteFataProvidedBy: {
      message: "jede Art von zivil- und strafrechtlicher Haftung, die sich aus unwahren, falschen oder unvollständigen Daten ergibt, die von "
    },
    reservesTheRightToUseAllValidAndPossibleMeansToIdentifyThe: {
      message: "behält sich das Recht vor, alle gültigen und möglichen Mittel zu verwenden, um die "
    },
    asWellAsRequestingAdditionalDataAndDocumentsThatItDeemsRelevantInOrderToVerifyTheReportedDataInThisCaseTheUseOf: {
      message: "sowie das Anfordern zusätzlicher Daten und Dokumente, die sie für relevant hält, um die gemeldeten Daten zu überprüfen. In diesem Fall ist die Verwendung von "
    },
    isConditionedToTheSendingOfTheDocumentsEventuallyRequested: {
      message: "ist von der Zusendung der eventuell angeforderten Unterlagen abhängig."
    },
    ifARegistrationIsConsideredSuspectedOfContainingErroneousOrUntrueDataThe: {
      message: "Wenn bei einer Registrierung der Verdacht besteht, dass sie fehlerhafte oder unwahre Daten enthält, wird die "
    },
    reservesTheRightToSuspendTemporarilyOrPermanentlyWithoutPriorNoticeThe: {
      message: "behält sich das Recht vor, ohne vorherige Ankündigung vorübergehend oder dauerhaft die "
    },
    responsibleForTheRegistrationInTheEventOfSuspensionYouWillNotAttendThe: {
      message: "für die Anmeldung zuständig. Im Falle einer Suspendierung nehmen Sie nicht an der "
    },
    rightToAnyTypeOfIndemnityOrCompensationForLossesAndDamagesLossOfProfitsOrMoralDamages: {
      message: "Recht auf jede Art von Entschädigung oder Entschädigung für Verluste und Schäden, entgangenen Gewinn oder immaterielle Schäden."
    },
    inTheEventThatThe: {
      message: "Für den Fall, dass die"
    },
    carryOutTheCollectionOfPersonalDataFromThe: {
      message: "Durchführung der Erhebung personenbezogener Daten aus dem "
    },
    willAllowAccessToTheInformationCollectedAndAboutThe: {
      message: "ermöglicht den Zugriff auf die gesammelten Informationen und über die "
    },
    carriedOutBy: {
      message: "ausgeführt von "
    },
    freeOfChargeByRequestingHelloMyTripMenuCom: {
      message: ", kostenlos, indem Sie hello@mytripmenu.com anfordern"
    },
    orByRegisteringAt: {
      message: "oder durch Registrierung unter "
    },
    beingAbleToEditOrDeleteThemAtAnyTime: {
      message: "jederzeit bearbeiten oder löschen können."
    },
    theCollectionOfDataFrom: {
      message: "Die Erhebung von Daten aus "
    },
    whenItOccursAimsToIdentifyYouAsWellAsEnableYouToCorrectlyUseThe: {
      message: ", wenn es auftritt, zielt darauf ab, Sie zu identifizieren und es Ihnen zu ermöglichen, die "
    },
    andWithThatThe: {
      message: ", und damit die "
    },
    canEnsureTheGoodQualityOfTheLicensedServices: {
      message: "kann die gute Qualität der lizenzierten Dienste sicherstellen."
    },
    byConsentingToTheTermsOfThisInstrumentThe: {
      message: "Durch die Zustimmung zu den Bedingungen dieses Instruments, die"
    },
    expresslyDeclaresToBeAwareThatTheCollectionOfYourDataIsEssentialForTheProperFunctioningOfThe: {
      message: "erklärt sich ausdrücklich bewusst, dass die Erhebung Ihrer Daten für das reibungslose Funktionieren der "
    },
    authorizingFromNowOnThe: {
      message: ", die von nun an die "
    },
    forThe: {
      message: "für die "
    },
    offersThePossibilityOf: {
      message: " offers the possibility of "
    },
    publicizeYourFigitalMenuThroughQRCodeToYour: {
      message: "Veröffentlichen Sie Ihr digitales Menü über QR-Code in Ihrem "
    },
    withoutTheNeedForRegistrationHoweverTheAbsenceOfRegistrationDoesNotDetractFromTheNoncomplianceWwithTheRulesOfThisInstrumentWhichThe: {
      message: "ohne die Notwendigkeit einer Registrierung, das Fehlen einer Registrierung ändert jedoch nichts an der Nichteinhaltung der Vorschriften dieses Instruments, die die "
    },
    mustEnsureCompliance: {
      message: "muss die Einhaltung sicherstellen."
    },
    willAccessYourRegistrationIn: {
      message: " greift auf Ihre Registrierung in "
    },
    through: {
      message: " durch "
    },
    andPasswordPledgingNotToInformThirdPartiesOfThisDataTakingFullResponsibilityForTheUseMadeOfThem: {
      message: " und Passwort und verpflichten sich, diese Daten nicht an Dritte weiterzugeben, und übernehmen die volle Verantwortung für deren Verwendung."
    },
    undertakesToNotifyThe: {
      message: "verpflichtet sich, die "
    },
    immediatelyThroughTheContactChannelsMaintainedBy: {
      message: " unverzüglich über die von "
    },
    at: {
      message: "bei "
    },
    regardingAnyUnauthorizedUseOfYourAccountThe: {
      message: ", bezüglich einer unbefugten Nutzung Ihres Kontos. Das"
    },
    willBeSolelyResponsibleForTheOperationsCarriedOutOnYourAccountSinceAccessWillOnlyBePossibleThroughTheUseOfAPasswordKnownExclusivelyToYou: {
      message: "allein für die auf Ihrem Konto durchgeführten Vorgänge verantwortlich, da der Zugriff nur unter Verwendung eines ausschließlich Ihnen bekannten Passworts möglich ist."
    },
    regardingAnyKnowledgeOfWrongdoingByOther: {
      message: ", in Bezug auf Kenntnis von Fehlverhalten anderer"
    },
    thatMayCauseDamageToTheirOwn: {
      message: " die ihnen selbst Schaden zufügen können "
    },
    ofThe: {
      message: " des "
    },
    toThisOnTo: {
      message: " zu diesem, zu "
    },
    orToThirdParties: {
      message: " oder an Dritte."
    },
    undertakesToUsTheFeaturesOfThe: {
      message: "verpflichtet sich, die Funktionen des"
    },
    inGoodFaithInAccordanceWithTheLegislationInForceMoralsAndGoodCustoms: {
      message: "in gutem Glauben, in Übereinstimmung mit der geltenden Gesetzgebung, Moral und guten Sitten."
    },
    underNoCircumstancesWillTheAssignmentSaleRentOrOtherFormOfTransferOfThe: {
      message: "Unter keinen Umständen wird die Abtretung, der Verkauf, die Vermietung oder eine andere Form der Übertragung der"
    },
    atItsSoleDiscretion: {
      message: "Nach eigenem Ermessen "
    },
    mayExcludeDisableCreateLimitsOnTheUseOfTheServiceSuspendBlockIndefinitelySithoutPriorNoticeOrCompensationRegistrationsO: {
      message: " kann die Nutzung des Dienstes ausschließen, deaktivieren, einschränken, aussetzen, sperren, auf unbestimmte Zeit, ohne vorherige Ankündigung oder Entschädigung, Registrierungen o "
    },
    thatAreConsideredOffensiveThatViolateTheTermsOfThisInstrumentOrTheLegislationInForce: {
      message: " die als anstößig angesehen werden, die gegen die Bestimmungen dieses Instruments oder die geltende Gesetzgebung verstoßen."
    },
    reservesTheRightNotToAllowNewRegistrationOf: {
      message: " behält sich das Recht vor, eine Neuregistrierung von "
    },
    thatHaveAlreadyBeenCancelledDisabledBlockedDeletedOrSuspendedFrom: {
      message: " die bereits gekündigt, deaktiviert, gesperrt, gelöscht oder gesperrt wurden "
    },
    theCreationOfNewRegistrationsByPeopleWhoseOriginalRegistrationsHaveBeenCancelledBlockedDisabledExcludedOrSuspendedDueToViolationsOfThePoliciesOfThe: {
      message: " Die Erstellung neuer Registrierungen durch Personen, deren ursprüngliche Registrierungen aufgrund von Verstößen gegen die Richtlinien des "
    },
    orCurrentLegislation: {
      message: " oder geltende Gesetzgebung."
    },
    reservesTheRightUnilaterallyWithoutPriorNoticeConsentOrCompensationToRefuseAnyRequestForRegistrationOfA: {
      message: " behält sich das Recht vor, einseitig und ohne vorherige Ankündigung, Zustimmung oder Entschädigung jeden Antrag auf Registrierung eines "
    },
    asWellAsCancelDisableBlockDeleteOrSuspendTheUseOfAPreviouslyAcceptedRegistration: {
      message: ", sowie die Nutzung einer zuvor akzeptierten Registrierung zu stornieren, zu deaktivieren, zu sperren, zu löschen oder auszusetzen."
    },
    byAgreeingToThisInstrumentThe: {
      message: "Durch die Zustimmung zu diesem Instrument wird die "
    },
    declaresToBeAwareThatItIsSolelyResponsibleForItsRegistrationBeingCertainThatAnyDamageCausedByTheInsertionOfOutdatedInaccurateOrUntrueInformationCannotBeAttributedTo: {
      message: " erklärt sich bewusst, dass es allein für seine Registrierung verantwortlich ist und sicher ist, dass Schäden, die durch das Einfügen veralteter, ungenauer oder unwahrer Informationen verursacht werden, nicht auf "
    },
    orThe: {
      message: "Oder der"
    },
    functionalities: {
      message: "6. FUNKTIONALITÄTEN:"
    },
    offersTheFollowingFeatures: {
      message: " bietet folgende Funktionen:"
    },
    aOnlineDigitalMenu: {
      message: "(a) digitale Online-Speisekarte;"
    },
    bViewingProductsWithPphotos: {
      message: "(b) Anzeigen von Produkten mit Fotos;"
    },
    cOfferingAMultilingualMenuSubjectToAvailableConditions: {
      message: "(c) Angebot einer mehrsprachigen Speisekarte, vorbehaltlich der verfügbaren Bedingungen;"
    },
    dActivationAndDeactivationOfItemsInRealTime: {
      message: "(d) Aktivierung und Deaktivierung von Artikeln in Echtzeit;"
    },
    eAccessToTheMenuByScanningAQRCode: {
      message: "(e) Zugriff auf das Menü durch Scannen eines QR-Codes;"
    },
    fCreationOfHighlightsForPromotionalActions: {
      message: "(f) Kreation von Highlights für Promotion-Aktionen."
    },
    whenUsingTheServicesProvidedByThe: {
      message: " bei der Nutzung der Dienste der "
    },
    canSelectURLUniformResourceLocatorInOrderToDetermineTheElectronicAddressThatWillLocateTheDigitalMenuOfThe: {
      message: " kann URL (Uniform Resource Locator) auswählen, um die elektronische Adresse zu bestimmen, die das digitale Menü des "
    },
    ToDefineTheURLThe: {
      message: "Um die URL zu definieren, muss das "
    },
    mustRespectTheIntellectualPropertyOfThirdPartiesThereforeItMustNotIncludeExpressionsThatMayCauseConfusionOrAlludeToThirdPartyServices: {
      message: "muss das geistige Eigentum Dritter respektieren und darf daher keine Ausdrücke enthalten, die Verwirrung stiften oder auf Dienste Dritter anspielen könnten."
    },
    understandThatThe: {
      message: " verstehen, dass die "
    },
    resourcesWereUsedToCauseConfusionAmongThe: {
      message: " Ressourcen wurden verwendet, um Verwirrung unter den "
    },
    makingItDifficultToProperlyIdentifyTheServicesAndMisleadingThemYouMayExcludeTheURLOfThe: {
      message: "die ordnungsgemäße Identifizierung der Dienste erschweren und sie irreführen, können Sie die URL des "
    },
    asWellAsAdoptOtherMeasuresRelevantToTheCase: {
      message: "sowie andere für den Fall relevante Maßnahmen ergreifen"
    },
    mayChangeTheRangeOfFeaturesAvailableAccordingToTheContractPlanAsProvidedForIn: {
      message: "kann den Umfang der verfügbaren Funktionen gemäß dem Vertragsplan ändern, wie in "
    },
    mayAtAnyTimeBySendingPriorNoticeWithoutTheNeedFoRCcompensationEditAndOrDeleteExistingFeaturesAsWellAsIncludeNewFeaturesToThe: {
      message: " kann jederzeit, nach vorheriger Ankündigung, ohne dass dafür eine Entschädigung erforderlich ist, bestehende Funktionen bearbeiten und/oder löschen sowie neue Funktionen in das "
    },
    translations: {
      message: "7. ÜBERSETZUNGEN:"
    },
    youCanUseTranslationServicesInOrderToMakeYourMenusAvailableInTheLanguagesOfYourChoiceForThisPurposeFeesWillBeChargedForTheServicesAsProvidedInThe: {
      message: "Sie können Übersetzungsdienste nutzen, um Ihre Menüs in den Sprachen Ihrer Wahl verfügbar zu machen. Zu diesem Zweck werden Gebühren für die Dienste erhoben, wie in den "
    },
    forTheExecutionOfTranslationServicesThe: {
      message: "Für die Ausführung von Übersetzungsleistungen ist die"
    },
    requestAtLeastAPeriodOf72HoursSeventyTwoHoursDependingOnTheLengthOfTheTextToBeTranslatedThe: {
      message: "mindestens eine Frist von 72 Stunden (zweiundsiebzig Stunden) verlangen. Abhängig von der Länge des zu übersetzenden Textes"
    },
    withPriorNoticeTo: {
      message: "mit Vorankündigung an"
    },
    mayExtendTheServiceExecutionPeriodAtItsDiscretion: {
      message: "kann die Serviceausführungsfrist nach eigenem Ermessen verlängern."
    },
    declaresThatHeUnderstandsAndAcceptsThatCertainDxpressionsCannotBeTranslatedGivenThatTheyReflectCertainCulturesOrSpecificRegions: {
      message: "erklärt, dass er versteht und akzeptiert, dass bestimmte Ausdrücke nicht übersetzt werden können, da sie bestimmte Kulturen oder bestimmte Regionen widerspiegeln."
    },
    isNotResponsibleForAnyDamageResultingFromInaccurateTranslationsInTheDigitalMenuOfThe: {
      message: "ist nicht verantwortlich für Schäden, die durch ungenaue Übersetzungen im digitalen Menü des "
    },
    howeverInTheEventThatThe: {
      message: ". Für den Fall, dass die"
    },
    identifyAnyErrorOrMisunderstandingInTheTraditionYouCanCommunicateToThe: {
      message: "Fehler oder Missverständnisse in der Tradition zu erkennen, können Sie dem "
    },
    whichMustRespondToTheRequestAsSoonAsPossible: {
      message: "die auf die Anfrage so schnell wie möglich reagieren müssen."
    },
    suppertService: {
      message: "8. SUPPORT-SERVICE"
    },
    willSupportThe: {
      message: "unterstützt die "
    },
    throughthecontactchannelavailableviaemailhellomytripmenucom: {
      message: "über den Kontaktkanal per E-Mail hello@mytripmenu.com."
    },
    inSupportRequestsViaEmailTthe: {
      message: "Bei Supportanfragen per E-Mail wird das "
    },
    willHaveUpTo72SeventyTwoBusinessHoursToRespond: {
      message: "hat bis zu 72 (zweiundsiebzig) Geschäftsstunden Zeit, um zu antworten."
    },
    paymentMethod4: {
      message: "9. ZAHLUNGSMETHODEN:"
    },
    priorToAnyChargeBeingMadeByThe: {
      message: "Vor jeder Belastung durch die "
    },
    willBeOfferedATrialPeriodOf07SevenDaysForAFreeTrialOfTheToolsOf: {
      message: "wird eine Testphase von 07 (sieben) Tagen angeboten, um die Tools von "
    },
    ifThe: {
      message: "Wenn die"
    },
    chooseToProceedWithTheContractingOfServicesThe: {
      message: " sich entscheiden, mit der Beauftragung von Dienstleistungen fortzufahren, die "
    },
    makesAvailableTo: {
      message: "zur Verfügung stellt"
    },
    oneOfTheFollowingPaymentMethods: {
      message: "eine der folgenden Zahlungsmethoden: "
    },
    VISACreditCardOr: {
      message: "VISA-Kreditkarte; oder,"
    },
    MASTERCARDCreditCardOr: {
      message: "MASTERCARD-Kreditkarte; oder,"
    },
    AMERICANEXPRESSCreditCardOr: {
      message: "AMERICAN EXPRESS-Kreditkarte; oder,"
    },
    DINNERSCLUBCreditCardOr: {
      message: "DINERS CLUB-Kreditkarte; oder,"
    },
    ELOCreditCardOr: {
      message: "ELO-Kreditkarte oder;"
    },
    DISCOVERCreditCard: {
      message: "Kreditkarte ENTDECKEN."
    },
    toProcessCreditCardPaymentsItWillBeNecessaryForThe: {
      message: "Zur Abwicklung von Kreditkartenzahlungen ist es erforderlich, dass die"
    },
    registerOn: {
      message: "registriere dich auf "
    },
    theFollowingDetailsOfTheChosenCreditCard: {
      message: "die folgenden Details der gewählten Kreditkarte:"
    },
    NameOfTheCreditCardHolder: {
      message: "Name des Kreditkarteninhabers;"
    },
    CreditCardNumber: {
      message: "Kreditkartennummer;"
    },
    CreditCardFlag: {
      message: "Kreditkartenkennzeichen;"
    },
    CreditCardExpirationMonthandYear: {
      message: "Ablauf der Kreditkarte (Monat und Jahr);"
    },
    CreditCardsecuritynumber: {
      message: "Kreditkartenprüfnummer."
    },
    Paymentsforservicescontractedinthe: {
      message: "Zahlungen für vertraglich vereinbarte Dienstleistungen im "
    },
    willBe: {
      message: "wird sein"
    },
    processedthroughtheGETNETplatform: {
      message: "über die GETNET-Plattform verarbeitet"
    },
    beingnecessarythatall: {
      message: "notwendig, dass alle"
    },
    alsoreadandacceptthetermsofuseandservicesoftheGETNETplatform: {
      message: " Lesen und akzeptieren Sie auch die Nutzungsbedingungen und Dienste der GETNET-Plattform"
    },
    viaemailaddress: {
      message: "per E-Mail-Adresse: "
    },
    Whenhiringthe: {
      message: "Bei der Anmietung des"
    },
    withpaymentbycreditcardorbankslipthe: {
      message: ", bei Zahlung per Kreditkarte oder Bankbeleg, die"
    },
    expresslyindicatesthatyouhavereadandacceptedalltheconditionspresentinthisinstrumentandinthetermsofuseandspecificservicesoftheGETNETplatform: {
      message: "weist ausdrücklich darauf hin, dass Sie alle in diesem Instrument und in den Nutzungsbedingungen und spezifischen Diensten der GETNET-Plattform enthaltenen Bedingungen gelesen und akzeptiert haben."
    },
    AnydisputeofpaymentsmadethroughtheGETNETplatformmustberesolvedonlybetweenthe: {
      message: "Alle Streitigkeiten über Zahlungen, die über die GETNET-Plattform getätigt werden, müssen nur zwischen dem "
    },
    andGETNETnotfallingto: {
      message: " und GETNET, fallen nicht auf "
    },
    anyresponsibilityforprocessingpaymentsmadeontheGETNETplatform: {
      message: "jegliche Verantwortung für die Verarbeitung von Zahlungen, die auf der GETNET-Plattform getätigt werden."
    },
    TheGETNETplatform: {
      message: "Die GETNET-Plattform"
    },
    maychargefeesforitsuseanditisonlyuptothe: {
      message: "kann für seine Nutzung Gebühren erheben, und es ist nur bis zu "
    },
    informationaboutthevalueschargedbyGETNET: {
      message: "Informationen über die von GETNET berechneten Werte. "
    },
    isonlyresponsiblefordeletingthepaymentdataprovidedbythe: {
      message: "ist nur verantwortlich für die Löschung der Zahlungsdaten, die von der "
    },
    fromitsowndatabasedeclaringthe: {
      message: "aus seiner eigenen Datenbank und erklärt die"
    },
    beawarethatitisnotupto: {
      message: "Seien Sie sich bewusst, dass es nicht an "
    },
    promotetheexclusionofsaiddatafromtheGETNETdatabase: {
      message: "den Ausschluss dieser Daten aus der GETNET-Datenbank fördern."
    },
    PRIVACYANDDATAPROCESSINGPOLICY: {
      message: "10. DATENSCHUTZ- UND DATENVERARBEITUNGSERKLÄRUNG:"
    },
    Duringtheuseof: {
      message: "Bei der Verwendung von "
    },
    willcollectandstoretheinformationprovidedbythe: {
      message: "sammelt und speichert die Informationen, die von der"
    },
    underthetermsofthisinstrumenttogetherwithautomaticallygeneratedinformationsuchasthecharacteristicsoftheaccessdevicebrowserapplicationaccesslogsIPwithdateandtimeinformationaccessedscreensaccessedgeolocationdatahistoryofapplicationsamongothersof: {
      message: ", unter den Bedingungen dieses Instruments, zusammen mit automatisch generierten Informationen, wie z. unter anderem von"
    },
    whichwillbestoredinthedatabaseandalsointhebrowsercookie: {
      message: ", die in der Datenbank und auch im Browser-Cookie gespeichert werden."
    },
    Thedatacollectedfrom: {
      message: "Die gesammelten Daten von "
    },
    throughtheuseof: {
      message: ", durch die Nutzung von"
    },
    willbeusedfortheadequateprovisionofservicesforthepurposeofimprovingnavigationofthe: {
      message: ", werden für die angemessene Bereitstellung von Diensten verwendet, um die Navigation der "
    },
    aswellasforadvertisingandstatisticalpurposes: {
      message: "sowie für Werbe- und Statistikzwecke."
    },
    declarestounderstandacceptandconsentthatintheeventofsendingpersonaldatato: {
      message: "erklärt zu verstehen, zu akzeptieren und zuzustimmen, dass im Falle der Übermittlung personenbezogener Daten an"
    },
    mustdosounderthetermsofarticle5itemXofLaw13709of2018: {
      message: "muss dies gemäß Artikel 5, Punkt X des Gesetzes 13.709 von 2018 tun."
    },
    Intheeventthatpersonaldataisprovidedbythe: {
      message: "Für den Fall, dass personenbezogene Daten von der"
    },
    willbeconsideredconfidentialbythe: {
      message: ", werden von der"
    },
    anditundertakestomakeeveryefforttopreservethesecurityofitssystemsinthestorageofsuchdatameetingthesecuritystandardsestablishedinDecreeNo87712016suchas: {
      message: ", und verpflichtet sich, alle Anstrengungen zu unternehmen, um die Sicherheit seiner Systeme bei der Speicherung solcher Daten zu wahren und die im Dekret Nr. 8.771/2016 festgelegten Sicherheitsstandards zu erfüllen, wie z."
    },
    iUseofindustrystandardmethodstoencryptthecollecteddatainadditiontootherstandardformsofencryptiontoensureitsinviolability: {
      message: "(i) Verwendung von branchenüblichen Methoden zur Verschlüsselung der gesammelten Daten zusätzlich zu anderen Standardformen der Verschlüsselung, um ihre Unverletzlichkeit sicherzustellen;"
    },
    iiUseofhightechsoftwaretoprotectagainstunauthorizedaccesstosystemswhichareconsideredcontrolledandsecurityenvironments: {
      message: "(ii)Verwendung von High-Tech-Software zum Schutz vor unbefugtem Zugriff auf Systeme, die als kontrollierte und sichere Umgebungen gelten;"
    },
    Availabilityofcontrolledaccesstopersonaldatastoragelocationsonlytopreviouslyauthorizedandauthenticatedpersonscommittedtothesecrecyofsuchdataincludingbysigningaconfidentialityagreement: {
      message: "(iii) Verfügbarkeit eines kontrollierten Zugriffs auf Speicherorte für personenbezogene Daten nur für zuvor autorisierte und authentifizierte Personen, die sich zur Geheimhaltung dieser Daten verpflichtet haben, einschließlich durch Unterzeichnung einer Vertraulichkeitsvereinbarung;"
    },
    ivApplicationofauthenticationmechanismsforaccesstorecordscapableofindividualizingthepersonresponsibleforthetreatmentandaccesstothedatacollectedasaresultoftheuseofthe: {
      message: "(iv) Anwendung von Authentifizierungsmechanismen für den Zugriff auf Aufzeichnungen, die in der Lage sind, die für die Behandlung verantwortliche Person zu individualisieren, und den Zugriff auf die gesammelten Daten als Ergebnis der Verwendung des"
    },
    vAnonymizationofdatafromthe: {
      message: "(v) Anonymisierung von Daten aus dem"
    },
    whensharedwiththirdpartiesnotpartnerswith: {
      message: "wenn sie mit Dritten geteilt werden, die keine Partner von"
    },
    viMaintenanceoftheinventoryindicatingthetimedurationidentityoftheemployeeorpersonresponsibleforaccessandtheobjectfilebasedonconnectionandapplicationaccessrecordsasdeterminedinarticle13ofDecreeNo87712016: {
      message: "(vi)Führung des Inventars mit Angabe von Zeit, Dauer, Identität des für den Zugriff verantwortlichen Mitarbeiters oder der Person und der Objektdatei auf der Grundlage von Verbindungs- und Anwendungszugriffsaufzeichnungen, wie in Artikel 13 des Dekrets Nr. 8.771/2016 festgelegt."
    },
    Ifdatacollectionfromthe: {
      message: "Wenn die Datenerhebung von der"
    },
    throughtheuse: {
      message: ", Durch den Einsatz"
    },
    thesemayonlybesharedwiththirdpartiesinthecaseslistedbelow: {
      message: "diese dürfen nur in den unten aufgeführten Fällen an Dritte weitergegeben werden:"
    },
    iFortheprotectionoftheinterestsof: {
      message: "(i) Zum Schutz der Interessen von"
    },
    incaseofconflictincludinglegalclaims: {
      message: "im Konfliktfall, einschließlich Rechtsansprüchen;"
    },
    iiIncaseoftransactionsandcorporatechangesinvolvingthe: {
      message: "(ii)Bei Transaktionen und gesellschaftsrechtlichen Veränderungen, die die"
    },
    inwhichcasethetransferofdatawillbenecessaryforthecontinuityoftheservicesofferedthroughthe: {
      message: ", in diesem Fall ist die Übermittlung von Daten für die Kontinuität der über das "
    },
    iiiBycourtorderorbyrequestofadministrativeauthoritiesthathavelegalcompetenceforitsrequest: {
      message: "(iii) Durch Gerichtsbeschluss oder auf Antrag von Verwaltungsbehörden, die für ihre Anfrage rechtlich zuständig sind."
    },
    guaranteesto: {
      message: "garantiert"
    },
    withregardtotheprocessingofpersonaldatathefollowingrights: {
      message: ", in Bezug auf die Verarbeitung personenbezogener Daten, die folgenden Rechte:"
    },
    iConfirmationoftheexistenceofprocessingofyourpersonaldata: {
      message: "(i)Bestätigung über das Bestehen der Verarbeitung Ihrer personenbezogenen Daten;"
    },
    iiAccesstoyourdatacollectedby: {
      message: "(ii)Zugriff auf Ihre Daten, die von"
    },
    throughyourownloginorbyrequestinghellomytripmenucom: {
      message: ", über Ihr eigenes Login oder indem Sie hello@mytripmenu.com anfordern;"
    },
    iiiCorrectionofyourdataifitisincompleteinaccurateoroutofdate: {
      message: "(iii)Berichtigung Ihrer Daten, wenn diese unvollständig, ungenau oder veraltet sind;"
    },
    ivBlockingordeletingunnecessaryexcessiveornonconformingdatawithapplicableBrazilianlegislation: {
      message: "(iv)Blockieren oder Löschen unnötiger, übermäßiger oder nicht den geltenden brasilianischen Gesetzen entsprechender Daten;"
    },
    vTheportabilityofpersonaldataforyouorforathirdpartyuponexpressrequestmadebythe: {
      message: "(v) Die Übertragbarkeit personenbezogener Daten für Sie oder einen Dritten auf ausdrücklichen Wunsch der"
    },
    viahellomytripmenucom: {
      message: ", über hello@mytripmenu.com;"
    },
    viDeletionofpersonaldataprocessedwithyourconsentprovidedthatthereisnolegaldeterminationtokeepthemregisteredwiththe: {
      message: "(vi) Löschung personenbezogener Daten, die mit Ihrer Einwilligung verarbeitet wurden, sofern keine gesetzliche Verpflichtung besteht, sie bei der"
    },
    viiObtaininginformationaboutpublicorprivateentitieswithwhichthe: {
      message: "(vii) Einholung von Informationen über öffentliche oder private Einrichtungen, mit denen die"
    },
    sharedyourdataand: {
      message: "Ihre Daten geteilt; und,"
    },
    viiiInformationonthepossibilityandconsequenceofnotprovidingtheconsentofthe: {
      message: "(viii) Information über Möglichkeit und Folgen der Nichterteilung der Einwilligung des"
    },
    youcansendemailto: {
      message: "Sie können eine E-Mail senden an"
    },
    hellomytripmenucompointingoutdoubtsandorrequirementsrelatedtoyourpersonaldata: {
      message: "hello@mytripmenu.com, um auf Zweifel und/oder Anforderungen in Bezug auf Ihre persönlichen Daten hinzuweisen."
    },
    maydeletethepersonaldatacollectedfrom: {
      message: "kann die gesammelten personenbezogenen Daten löschen"
    },
    iWhenthepurposeforwhichtheywerecollectedisachievedorwhentheyarenolongernecessaryorrelevantforthescopeofthepurposeaccordingtothepurposesdescribedintheseTermsofUseandPrivacyPolicy: {
      message: "(i)Wenn der Zweck, für den sie erhoben wurden, erreicht ist; oder, wenn sie für den Umfang des Zwecks nicht mehr erforderlich oder relevant sind, gemäß den in diesen Nutzungsbedingungen und Datenschutzrichtlinien beschriebenen Zwecken;"
    },
    iiWhenthe: {
      message: "(ii) Wenn der"
    },
    revokeyourconsentincaseswhereitisnecessaryrequestingtheexclusiontothe: {
      message: "Widerrufen Sie Ihre Einwilligung, in Fällen, in denen dies erforderlich ist, und fordern Sie den Ausschluss von der"
    },
    viahellomytripmenucomor: {
      message: "über hello@mytripmenu.com; oder,"
    },
    iiiIfdeterminedbycompetentauthority: {
      message: "(iii) Sofern von der zuständigen Behörde festgelegt."
    },
    GENERALPROVISIONS: {
      message: "11. ALLGEMEINE BESTIMMUNGEN:"
    },
    AnyclauseorconditionofthisinstrumentthatforanyreasonwillbeconsiderednullorineffectivebyanycourtorcourtwillnotaffectthevalidityoftheotherprovisionsoftheseTermswhichwillremainfullyvalidandbindinggeneratingeffectstotheirfullestextent: {
      message: "Jede Klausel oder Bedingung dieses Instruments, die aus irgendeinem Grund von einem Gericht oder Gericht als nichtig oder unwirksam angesehen wird, berührt nicht die Gültigkeit der anderen Bestimmungen dieser Bedingungen, die vollständig gültig und bindend bleiben und Auswirkungen auf ihre haben vollsten Umfang."
    },
    Thefailureof: {
      message: "Das Scheitern von"
    },
    inclaiminganyrightsorprovisionsoftheseTermswillnotconstituteawaiverandthelattermayregularlyexercisetheirrightwithinthelegaldeadlines: {
      message: "Die Inanspruchnahme von Rechten oder Bestimmungen dieser Bedingungen stellt keinen Verzicht dar, und letztere können ihr Recht regelmäßig innerhalb der gesetzlichen Fristen ausüben."
    },
    Allmaterialspatentstrademarksregistrationsdomainsnamesprivilegescreationsimagesandallrelatedrightsrelatingtothe: {
      message: "Alle Materialien, Patente, Marken, Registrierungen, Domains, Namen, Privilegien, Kreationen, Bilder und alle damit verbundenen Rechte in Bezug auf die"
    },
    anddevelopedby: {
      message: "und entwickelt von"
    },
    areandwillremainthesoleandexclusivepropertyof: {
      message: ", sind und bleiben das alleinige und ausschließliche Eigentum von"
    },
    agreeingwiththe: {
      message: ", einverstanden mit dem "
    },
    nottoperformanactorfactthatinanywayharmstherightssetforthhereinnortoclaimanyrightorprivilegeoverthem: {
      message: "keine Handlung oder Tatsache vorzunehmen, die in irgendeiner Weise die hierin dargelegten Rechte verletzt, noch irgendwelche Rechte oder Privilegien über sie zu beanspruchen."
    },
    maychangethisinstrumentatanytimebysimplypublishingarevisedversiononourwebsiteForthisreasonwestronglyrecommendthatyoualwaysvisitthissectionofourSiteandreaditperiodicallyButtocontributetothegoodrelationshipwewillalsosendanemailinformingyouaboutthesechanges: {
      message: "kann dieses Instrument jederzeit ändern, indem er einfach eine überarbeitete Version auf unserer Website veröffentlicht. Aus diesem Grund empfehlen wir Ihnen dringend, diesen Abschnitt unserer Website immer zu besuchen und regelmäßig zu lesen. Aber um zu der guten Beziehung beizutragen, werden wir Sie auch per E-Mail über diese Änderungen informieren."
    },
    Thisinstrumentconstitutestheintegralunderstandingbetweenthe: {
      message: "Dieses Instrument bildet die integrale Verständigung zwischen den"
    },
    andisgovernedbyBrazilianLawswiththejurisdictionofthecityofSãoLeopoldoRSbeingelectedastheonlycompetentcourttoresolveissuesarisingfromthisinstrumentwithexpresswaiverofanyotherjurisdictionhoweverprivilegeditmaybe: {
      message: "und unterliegt den brasilianischen Gesetzen, wobei die Gerichtsbarkeit der Stadt São Leopoldo/RS als einziges zuständiges Gericht zur Lösung von Streitigkeiten aus diesem Instrument gewählt wird, unter ausdrücklichem Verzicht auf jede andere Gerichtsbarkeit, wie privilegiert diese auch sein mag."
    },
    recoverPassword: {
      message: "PASSWORT WIEDERHERSTELLEN"
    },
    receiveNewPassword: {
      message: "Sie erhalten ein neues Passwort in Ihrer E-Mail, denken Sie daran, auch Ihren Spam-Ordner zu überprüfen."
    },
    dontWorry: {
      message: "Keine Sorge, wir helfen Ihnen, es zurückzubekommen. Geben Sie Ihre Login-E-Mail an"
    },
    enterEmail: {
      message: "E-Mail eingeben"
    },
    notBeSent: {
      message: "Die E-Mail konnte nicht gesendet werden, bitte versuchen Sie es erneut."
    },
    pleasureToHaveYou: {
      message: "Es ist eine Freude, Sie hier bei uns zu haben!"
    },
    beforeStarting: {
      message: "Bevor Sie mit der Aufnahme Ihrer Produkte beginnen und sehen, wie Ihr Menü Gestalt annimmt, müssen Sie einen Wartungsplan in Höhe von abonnieren"
    },
    BRL99: {
      message: "BRL 99,90"
    },
    month: {
      message: "ein Monat."
    },
    includesFacilities: {
      message: "Es umfasst Einrichtungen wie:"
    },
    menuWithPhotos: {
      message: "vollständiges Menü mit Fotos;"
    },
    priceUpdate: {
      message: "Preisaktualisierung in Echtzeit;"
    },
    activateDeactivate: {
      message: "Produkte schnell aktivieren und deaktivieren;"
    },
    categorizeFilteReorder: {
      message: "Produkte kategorisieren, filtern und neu anordnen;"
    },
    newFeatures: {
      message: "Jede Woche werden neue Funktionen hinzugefügt!"
    },
    thePlan: {
      message: "Plan unterschreiben"
    },
    company: {
      message: "GESELLSCHAFT"
    },
    fantasyName: {
      message: "FANTASIENAME"
    },
    numberAccesses: {
      message: "ANZAHL DER ZUGRIFFE"
    },
    entryDate: {
      message: "EINTRITTSDATUM"
    },
    customers: {
      message: "Kunden"
    },
    wantLeave: {
      message: "Jetzt wo du so weit gekommen bist, bist du sicher, dass du gehen willst?"
    },
    cancelYourSubscription: {
      message: "Es tut uns leid, dass Sie Ihr Abonnement des MyTripMenu-Plans kündigen möchten."
    },
    beforeConfirming: {
      message: "Bevor ich bestätige, muss ich Ihnen mitteilen, dass Ihre Kunden nach Ihrer Abreise keinen Zugriff mehr auf die Speisekarte Ihres Unternehmens haben und der Zugriff auf die URL und den QRCode nicht mehr verfügbar ist, bis Sie den Plan erneut abonnieren."
    },
    goAhead: {
      message: "Wenn du dir sicher bist, mach weiter..."
    },
    unsubscribePlan: {
      message: "vom Plan abbestellen"
    },
    dontCancel: {
      message: "Ich möchte nicht mehr kündigen"
    },
    noChange:{
      message: "Keine Änderung"
    },
    CAXALABSSERVICOSDEDESENVOLVIMENTODESOFTWARELTDALimitedCompanyregularlyregisteredwiththeCNPJunderNo34177633000191headquarteredatRuaFelipeDosSantosn771203torreAPadreReusneighborhoodinthecityofSaoLeopoldoRSunderCEP93020180: {
      message: "CAXA LABS SERVICOS DE DESENVOLVIMENTO DE SOFTWARE LTDA, Gesellschaft mit beschränkter Haftung, regulär beim CNPJ unter der Nr. 34.177.633/0001-91, mit Hauptsitz in Rua Felipe Dos Santos, Nr. 77, 1203 Torre A, Viertel Padre Reus, in der Stadt São Leopoldo/RS, unter CEP: 93.020-180."
    },
    Delivery: {
      message: "Lieferung"
    },
    withdrawal: {
      message: "Rückzug"
    },
    Pickup: {
      message: "Abholung vor Ort"
    },
    Catalog: {
      message: "Katalog"
    },
    Enteremail: {
      message: "Geben Sie die E-Mail ein"
    },
    emailinvalid: {
      message: "Diese E-Mail ist nicht gültig"
    },
    Typepassword: {
      message: "Geben Sie das Kennwort ein"
    },
    least6digits: {
      message: "Dieses Feld muss mindestens 6 Ziffern haben"
    },
     Cashpayment: {
      message: "Barzahlungsmethode kann nicht bearbeitet werden"
    },
    Checkpayment: {
      message: "Scheckzahlungsmethode kann nicht bearbeitet werden"
    },
    save: {
      message: "Speichern"
    },
    logIn: {
      message: "EINLOGGEN"
    },
    logged: {
      message: "DU BIST EINGELOGGT"
    },
    enterAShortEasilyIdentifiableName: {
      message: "Geben Sie einen kurzen, leicht identifizierbaren Namen ein"
    },
    highlightssubcategoryCannotBeCopied: {
      message: "Die Unterkategorie Highlights kann nicht kopiert werden"
    },
    aaaaaaaaaaaaaaaaaa: {
      message: "aaaaaaaaaaaaaaaaaaaaa"
    },
    unableToLoadCards : {
      message: "Karten können nicht geladen werden"
    },
    invalidUser: {
      message: "Ungültiger benutzer"
    },
    invalidPassword: {
      message: "Ungültiges Passwort"
    },
    inactiveUser: {
      message: "Inaktiver Benutzer"
    }
  }
}
