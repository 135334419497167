import styled from "styled-components";

export const ListComplementGroupWrapper = styled.div`
  height: calc(100vh - 155px);
  width: 100%;
  overflow: auto;
  table {
    margin-left: 16px;
  }
`;

export const ContainerFilter = styled.div`
  padding-top: 10px;
  width: 25%;
  margin-left: 16px;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 16px;
`;
