import React, { useState } from "react";
import Tab from "../../../components/tab/Tab";
import FormPayment from "./payment-form/formPayment";
import Schedules from "./schedules/schedules";
import DeliveryArea from "./delivery-area/deliveryArea";
import TranslatorFunction from "../../../components/I18n/Translator"



const Parameterization = () => {
  const deliveryConfiguration = TranslatorFunction({ path: 'deliveryConfiguration.message' });
  const schedules = TranslatorFunction({ path: 'schedules.message' });
  const paymentMethod2 = TranslatorFunction({ path: 'paymentMethod2.message' });
  const add = TranslatorFunction({ path: 'add.message' });

  const [openModalNewHorary, setOpenModalNewHorary] = useState(false);
  const [openModalNewFormPayment, setOpenModalNewFormPayment] = useState(false);
  const [openModalNewDeliveryArea, setOpenModalNewDeliveryArea] =
    useState(false);

  const [onLoadTab, setOnLoadTab] = useState(false);

  const tabsConfig = [
    {
      text: deliveryConfiguration,
      path: "delivery-conf",
      component: (
        <DeliveryArea
          openNewDeliveryArea={openModalNewDeliveryArea}
          onHideNewDeliveryArea={() => setOpenModalNewDeliveryArea(false)}
          onLoad={(e) => setOnLoadTab(e)}
        />
      ),
      selectedTabNumber: 0,
    },
    {
      text: schedules,
      path: "schedules",
      component: (
        <Schedules
          openNewHorary={openModalNewHorary}
          onHideNewHorary={() => setOpenModalNewHorary(false)}
          onLoad={(e) => setOnLoadTab(e)}
        />
      ),
      selectedTabNumber: 1,
    },
    {
      text: paymentMethod2,
      path: "payment-methods",
      component: (
        <FormPayment
          openNewFormPayment={openModalNewFormPayment}
          onHideNewFormPayment={() => setOpenModalNewFormPayment(false)}
          onLoad={(e) => setOnLoadTab(e)}
        />
      ),
      selectedTabNumber: 2,
    },
  ];

  return (
    <div>
      <Tab
        buttonText={add}
        isLoadingContent={onLoadTab}
        tabsConfig={tabsConfig}
      />
    </div>
  );
};

export default Parameterization;
