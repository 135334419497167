import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DeliverySettings from "./delivery-setting/DeliverySetting";
import ListDeliveryArea from "./list-delivery-area/ListDeliveryArea";
import MandadoryData from "./mandatory-data/MandatoryData";
import Operation from "./operation/Operation";
import restOperation from "../../../../api/operation/rest-operation";
import restDeliverySetting from "../../../../api/deliverysettings/rest-delivery-settings";
import restMandatoryData from "../../../../api/mandatorydata/rest-mandatory-data";
import EditDeliveryArea from "./edit-delivery-area/EditDeliveryArea";
import restCity from "../../../../api/city/rest-city";
import restNeighborhood from "../../../../api/neighborhood/rest-neighborhood";
import TranslatorFunction from "../../../../components/I18n/Translator"

const DeliveryArea = ({
  openNewDeliveryArea,
  onHideNewDeliveryArea,
  onLoad,
}) => {
  const cityy = TranslatorFunction({ path: 'city.message' });
  const neighborhoodss = TranslatorFunction({ path: 'neighborhoods.message' });
  const [city, setCity] = useState([]);
  const [neighborhood, setNeighborhood] = useState([]);
  const [openNewDeliveryAreaModal, setOpenNewDeliveryAreaModal] =
    useState(openNewDeliveryArea);
  const [operation, setOperation] = useState([]);
  const [deliverySettings, setDeliverySettings] = useState({});
  const [mandatoryData, setMandatoryData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([
    {
      name: "Cidade",
      city: city,
    },
    {
      name: "Bairros",
      neighborhood: neighborhood,
    },
  ]);
  const [location, setLocation] = useState({});

  const handleOnClickEditLocation = (selectedLocation) => {
    setLocation(selectedLocation);
    setOpenNewDeliveryAreaModal(true);
  };

  const handleOnModalClose = () => {
    setOpenNewDeliveryAreaModal(false);
    setLocation({});
    fetchCities();
    fetchNeighborhood();
    onHideNewDeliveryArea();
  };

  const handleOnLoad = (value) => {
    onLoad(value);
    setIsLoading(value);
  };

  const handleResponseSuccess = (response, setData) => {
    setData(response);
    handleOnLoad(false);
  };

  const fetchCities = async () => {
    handleOnLoad(true);
    try {
      const res = await restCity.fetchCities();

      handleResponseSuccess(res.data, setCity);
    } catch {
      handleOnLoad(false);
    }
  };

  const fetchNeighborhood = async () => {
    handleOnLoad(true);
    try {
      const res = await restNeighborhood.fetchNeighborhoods();
      handleResponseSuccess(res.data, setNeighborhood);
    } catch {
      handleOnLoad(false);
    }
  };

  const fecthDeliverySetting = () => {
    handleOnLoad(true);
    restDeliverySetting
      .fetchDeliverySettings()
      .then((res) => handleResponseSuccess(res.data, setDeliverySettings))
      .catch(() => handleOnLoad(false));
  };
  const fecthMandatoryData = () => {
    handleOnLoad(true);
    restMandatoryData
      .fetchMandatoryData()
      .then((res) => handleResponseSuccess(res.data, setMandatoryData))
      .catch(() => handleOnLoad(false));
  };

  const fecthOperation = () => {
    handleOnLoad(true);
    restOperation
      .fetchOperations()
      .then((res) => handleResponseSuccess(res.data, setOperation))
      .catch(() => handleOnLoad(false));
  };

  useEffect(() => {
    fecthOperation();
    fecthDeliverySetting();
    fecthMandatoryData();
    fetchCities();
    fetchNeighborhood();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLocations([
      {
        name: cityy,
        city: city,
      },
      {
        name: neighborhoodss,
        neighborhood: neighborhood,
      },
    ]);
  }, [city, cityy, neighborhood, neighborhoodss]);

  const renderDeliveryArea = !isLoading && (
    <ListDeliveryArea
      location={locations}
      onClickEditLocation={handleOnClickEditLocation}
    />
  );

  const renderDeliverySettings = (
    <DeliverySettings
      deliverySettings={deliverySettings}
      getDeliverySttings={fecthDeliverySetting}
    />
  );

  const renderMandatoryData = (
    <MandadoryData
      mandatoryData={mandatoryData}
      getMandatoryData={fecthMandatoryData}
    />
  );

  const renderOperation = (
    <Operation
      operation={operation}
      handleOnLoad={handleOnLoad}
      getOperation={fecthOperation}
    />
  );

  return (
    <>
      {renderOperation}
      {renderMandatoryData}
      {renderDeliverySettings}
      {renderDeliveryArea}
      <EditDeliveryArea
        open={openNewDeliveryAreaModal}
        location={location}
        onClose={handleOnModalClose}
        cities={city}
      />
      <div style={{ height: "100px" }} />
    </>
  );
};

DeliveryArea.defaultProps = {
  openNewDeliveryArea: false,
  onHideNewDeliveryArea: () => {},
  onLoad: () => {},
};

DeliveryArea.propTypes = {
  openNewDeliveryArea: PropTypes.bool,
  onHideNewDeliveryArea: PropTypes.func,
  onLoad: PropTypes.func,
};

export default DeliveryArea;

