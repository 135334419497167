import React from 'react';
import PropTypes from 'prop-types';
import CustomLoader from './Styles';

const BlockLoader = (props) => {
  const { blocking, children } = props;
  return (
    <CustomLoader tag="div" blocking={blocking}>
      { children }
    </CustomLoader>
  );
};

BlockLoader.defaultProps = {
  blocking: false,
  children: <></>,
};

BlockLoader.propTypes = {
  blocking: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.any,
  ]),
};

export default BlockLoader;
